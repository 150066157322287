import * as types from "redux/constants/leagues";

import { FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

export const actionClearFindOwnLeaguesError = () => ({
	type: types.CLEAR_FIND_OWN_LEAGUES_ERROR,
});

const getFindOwnLeaguesSubtypeStart = payload => ({
	type: types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_START,
	payload,
});

const getFindOwnLeaguesSubtypeSuccess = payload => ({
	type: types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_SUCCESS,
	payload,
});

const getFindOwnLeaguesSubtypeError = payload => ({
	type: types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_ERROR,
	payload,
});

export const actionGetRequestFindOwnLeagues = (backendApiUrl, roundId, pageSize, leaguesSubtype) => (dispatch) => {
	dispatch(getFindOwnLeaguesSubtypeStart({ leaguesSubtype }));

	const config = {
		method: "GET",
		url: `${backendApiUrl.replace(FOR_REPLACEMENT.ID, roundId)}${pageSize ? `?page_size=${pageSize}` : ""}`,
	};
	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getFindOwnLeaguesSubtypeSuccess({
				data, leaguesSubtype
			}));
		})

		.catch((error) => {
			dispatch(getFindOwnLeaguesSubtypeError({ data: errorsParser(error), leaguesSubtype }));
		});
};
