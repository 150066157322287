import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import facebookIcon from "static/assets/images/social-fb-white.svg";
import instagramIcon from "static/assets/images/social-ig-white.svg";
import twitterIcon from "static/assets/images/social-x-white.svg";
import linkedinIcon from "static/assets/images/social-in-white.svg";
import { useTranslation } from "react-i18next";

import backendApiUrls from "routes/backendUrls";

import Social from "modules/common/Social";



const Footer = () => {
	const { t } = useTranslation("footer");
	const location = useLocation();
	const isHome = location && location.pathname === "/";
	return (
		<Row className={`footer ${isHome && "footer-home"}`}>
			<Container>
				<Row>
					<Col sm={12} md={9} className="footer-text px-0">
						<span sm={12} md={6} lg={3} className="footer-left-text">
							{t("Footer Text")}
						</span>
						<span className="d-none d-md-inline ml-md-3 mr-md-3 font-size-18 color-white">|</span>
						<span className="footer-right-text">
							<a rel="noreferrer" target="_blank" href={backendApiUrls.privacyPolicy}>{t("Privacy Policy")}</a>
						</span>
					</Col>

					<Col sm={12} md={3}
						className="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0 px-0">
						<Social icon={facebookIcon} url="https://www.facebook.com/serieafantasyte" />
						<Social icon={instagramIcon} url="https://www.instagram.com/serieafantasyte" />
						<Social icon={twitterIcon} url="https://twitter.com/serieafantasyte" />
						<Social icon={linkedinIcon} url="https://www.linkedin.com/company/serieafantasyte" />
					</Col>
				</Row>
			</Container>
		</Row>
	);
};

export default Footer;

Footer.displayName = "foot";