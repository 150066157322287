export const INIT_TEAM = "INIT_TEAM";
export const SET_FIELD = "SET_FIELD";
export const RESET_TEAM = "RESET_TEAM";
export const MOVE_PLAYER = "MOVE_PLAYER";
export const CLEAR_TEAM = "CLEAR_TEAM";
export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const CHANGE_FORMAT = "CHANGE_FORMAT";
export const SET_CAPTAIN = "SET_CAPTAIN";
export const SET_VICE_CAPTAIN = "SET_VICE_CAPTAIN";
export const SET_CURRENT_TEAM_NAME = "SET_CURRENT_TEAM_NAME";

export const GET_REQUEST_GLOBAL_FORMATIONS_START = "GET_REQUEST_GLOBAL_FORMATIONS_START";
export const GET_REQUEST_GLOBAL_FORMATIONS_SUCCESS = "GET_REQUEST_GLOBAL_FORMATIONS_SUCCESS";
export const GET_REQUEST_GLOBAL_FORMATIONS_ERROR = "GET_REQUEST_GLOBAL_FORMATIONS_ERROR";

export const POST_REQUEST_RANDOM_TEAM_START = "POST_REQUEST_RANDOM_TEAM_START";
export const POST_REQUEST_RANDOM_TEAM_SUCCESS = "POST_REQUEST_RANDOM_TEAM_SUCCESS";
export const POST_REQUEST_RANDOM_TEAM_ERROR = "POST_REQUEST_RANDOM_TEAM_ERROR";

export const SELECT_PLAYER_FROM_LIST = "SELECT_PLAYER_FROM_LIST";

export const CLEAR_TRANSFERS = "CLEAR_TRANSFERS";
