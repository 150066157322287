import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/playersList";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";
import { ACTION_TYPES_BLOCKS } from "helpers/constants/common";

const getRequestPlayerPastSeasonResultsStart = payload => ({
	type: types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_START,
	payload,
});

const getRequestPlayerPastSeasonResultsSuccess = payload => ({
	type: types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_SUCCESS,
	payload,
});

const getRequestPlayerPastSeasonResultsError = payload => ({
	type: types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_ERROR,
	payload,
});

export const actionGetRequestPlayerPastSeasonResults = ({ playerID, playerPosition }) => dispatch => {
	dispatch(getRequestPlayerPastSeasonResultsStart(playerID));

	axiosPlus({ method: "GET", url: `${backendApiUrls.playerPastSeasonResults}?player_id=${playerID}` })
		.then(function (response) {
			const { data } = response;
			const results = data.map(item => {
				const returnValue = {
					...item,
					calculatedPoints: {}
				};

				Object.keys(item.season_actions).forEach(actionKey => {
					const { points, count } = item.season_actions[actionKey];
					if (!ACTION_TYPES_BLOCKS[playerPosition][actionKey]) {
						returnValue.calculatedPoints[actionKey] = points;
						returnValue[actionKey] = count;
					}
				});
				
				return returnValue;
			});
			dispatch(getRequestPlayerPastSeasonResultsSuccess({
				data: results,
				playerID,
			}));
		})
		.catch(function (error) {
			dispatch(getRequestPlayerPastSeasonResultsError( errorsParser(error)));
		});
};
