import { FOR_REPLACEMENT } from "helpers/constants/common";

const backendApiUrls = {
	settings: "/api/v0/settings/",
	leagueSeasons: "/api/v0/leagues/seasons/",
	leagueSeasonLeagueInfo: `/api/v0/leagues/season-league/info/${FOR_REPLACEMENT.ID}/`,
	leagueSeasonLeague: `/api/v0/leagues/season-league/${FOR_REPLACEMENT.ID}/`,
	leagueSeasonLeagueOwn: `/api/v0/leagues/season-league/find-own/${FOR_REPLACEMENT.ID}/`,
	leagueCountryLeagueInfo: `/api/v0/leagues/country-league/info/${FOR_REPLACEMENT.ID}/league/${FOR_REPLACEMENT.COUNTRY_CODE}/`,
	leagueCountryLeague: `/api/v0/leagues/country-league/${FOR_REPLACEMENT.ID}/`,
	leagueCountryLeagueOwn: `/api/v0/leagues/country-league/find-own/${FOR_REPLACEMENT.ID}/`,
	leagueRoundLeagueInfo: `/api/v0/leagues/round-league/info/${FOR_REPLACEMENT.ID}/`,
	leagueRoundLeague: `/api/v0/leagues/round-league/${FOR_REPLACEMENT.ID}/`,
	leagueRoundLeagueOwn: `/api/v0/leagues/round-league/find-own/${FOR_REPLACEMENT.ID}/`,
	leagueQuadLeagues: `/api/v0/leagues/quad-leagues/${FOR_REPLACEMENT.ID}/`,
	leagueQuadLeagueInfo: `/api/v0/leagues/quad-league/info/${FOR_REPLACEMENT.ID}/`,
	leagueQuadLeague: `/api/v0/leagues/quad-league/${FOR_REPLACEMENT.ID}/`,
	leagueQuadLeagueOwn: `/api/v0/leagues/quad-league/find-own/${FOR_REPLACEMENT.ID}/`,
	leaguePrivateRoot: "/api/v0/leagues/private-leagues/",
	leaguePrivateLeagueInfo: `/api/v0/leagues/private-leagues/info/${FOR_REPLACEMENT.ID}/`,
	leaguePrivateLeague: `/api/v0/leagues/private-leagues/${FOR_REPLACEMENT.PRIVATE_LEAGUE_ID}/`,
	leaguePrivateLeagueOwn: `/api/v0/leagues/private-leagues/find-own/${FOR_REPLACEMENT.ID}/`,
	footballRounds: "/api/v0/rounds/football-rounds/",
	roundGames: "/api/v0/rounds/round-games/",
	closestScheduledRoundGames: "/api/v0/rounds/round-games/closest_scheduled_round_games/",
	login: "/api/v0/users/token/",
	resetPassword: "/api/v0/users/password/reset/",
	setNewPassword: "/api/v0/users/password/reset/confirm/",
	check: "/api/v0/users/token/verify/",
	refresh: "/api/v0/users/token/refresh/",
	activateAccount: "/api/v0/users/verify-email-account/",
	countries: "/api/v0/users/countries/autocomplete/",
	users: "/api/v0/users/",
	sendVerificationEmail: "/api/v0/users/send-verification-email/",
	roundDreamTeams: "/api/v0/dreamteams/round-dream-teams/",
	seasonDreamTeams: "/api/v0/dreamteams/season-dream-teams/",
	teamsList: "/api/v0/footballteams/football-clubs/",
	playersList: "/api/v0/footballteams/football-players/",
	playerFixtures: "/api/v0/rounds/football-player-fixtures/",
	playerPastSeasonResults: "/api/v0/rounds/football-player-season-results-list/",
	userTeams: "/api/v0/userteams/user-teams/",
	userTeamsCheckUserTeam: "/api/v0/userteams/user-teams/check-live-season-user-team/",
	userTeamsCheckUserTeamPlayedThisSeason: "/api/v0/userteams/user-teams/check-user-played-any-round/",
	userTeamsUseWildcardForRound: "/api/v0/userteams/user-teams/use-wildcard-for-live-round/",
	userTeamName: "/api/v0/userteams/user-teams/set-new-team-name/",
	userFootballPlayers: "/api/v0/userteams/user-football-players/",
	userTeamBenches: "/api/v0/userteams/user-team-benches/",
	rulesSection: "/api/v0/rules/rules-section/",
	rulesItem: "/api/v0/rules/rules-item/",
	rulesFAQ: "/api/v0/rules/faq/",
	rulesPrizes: "/api/v0/rules/prizes/",
	contactUs: "/api/v0/contact-us/",
	globalFormations: "/api/v0/formations/global-formations/",
	randomTeam: "/api/v0/userteams/random-team/",
	roundUserTeams: "/api/v0/userteams/user-team-for-round/",
	userTeamsResults: "/api/v0/userteams/user-team-season-statistic/",
	roundUserStatistic: "/api/v0/round-league-statistic/",
	roundLeagueStatistic: `/api/v0/leagues/round-league/statistic/${FOR_REPLACEMENT.ID}/`,
	userTransfers: "/api/v0/transfers/user-football-player-transfers/",
	userDetails: "/api/v0/users/me/",
	urlPrivateLeaguesRoot: "/api/v0/leagues/private-leagues/",
	footballPlayerInfo: "/api/v0/rounds/football-player-round-results/",
	footballPlayerRoundResultList: "/api/v0/rounds/football-player-round-results-list/",
	footballActions: "/api/v0/rounds/football-player-point-actions/action-types/autocomplete/",
	termsOfUse: "/api/v0/users/terms/terms-of-use/",
	privacyPolicy: "/api/v0/users/terms/privacy-policy/",
	cookiesPolicy: "/api/v0/users/terms/cookies-policy/",
	updateLiveTeam: "/api/v0/userteams/user-teams/update-live-season-user-team/",
	userTeamsNewSeason: "/api/v0/userteams/create-team-for-next-season/",
	urlNews: "/api/v0/news/news/",
	urlCounters: "/api/v0/news/counters/",
};

export default backendApiUrls;