import PropTypes from "prop-types";

import {
	leaguesSeasonsPropType,
} from "utils/propTypes";
import globalFormationPropType from "utils/propTypes/globalFormation";
import { userPropType } from "utils/propTypes/user";

import {
	userBenchPlayerType,
	userCaptainPlayerType,
	userViceCaptainPlayerType,
	userFootballPlayerType
} from "./footballPlayer";

export const parsedDreamTeamType = {
	midfielders: PropTypes.arrayOf(userFootballPlayerType).isRequired,
	forwards: PropTypes.arrayOf(userFootballPlayerType).isRequired,
	defenders: PropTypes.arrayOf(userFootballPlayerType).isRequired,
	goalkeeper: userFootballPlayerType
};

export const parsedFieldTeamType = {
	midfielders: PropTypes.arrayOf(userFootballPlayerType),
	forwards: PropTypes.arrayOf(userFootballPlayerType),
	defenders: PropTypes.arrayOf(userFootballPlayerType),
	goalkeeper: userFootballPlayerType,
	bench: PropTypes.arrayOf(userFootballPlayerType)
};

export const userTeamPropType = PropTypes.shape({
	abbreviation: PropTypes.string,
	bench_players: PropTypes.arrayOf(userBenchPlayerType),
	captain: userCaptainPlayerType,
	viceCaptain: userViceCaptainPlayerType,
	football_players: PropTypes.arrayOf(userFootballPlayerType),
	formation: globalFormationPropType.globalFormation,
	id: PropTypes.number,
	name: PropTypes.string,
	season: leaguesSeasonsPropType.leagueSeasons,
	user: userPropType,
	wildcard_used: PropTypes.bool,
});