import React  from "react";
import propTypes from "prop-types";
import {
	Container,
} from "reactstrap";
import logoLarge from "static/assets/images/logo-large.svg";
import { Trans } from "react-i18next";

const SignUpClosed = ({
	years,
	startTime,
}) => (
	<Container className='d-flex flex-column align-items-center margin-top-20px margin-top-md-40px'>
		<div className="blue-plank-top-service margin-top-md-10px mt-0" />
		<h1 key="completed-1" className="service-message container text-center mb-0 mb-md-2 px-0 d-inline-block">
			<Trans
				i18nKey="sign_up_closed"
				ns="sign_up_closed"
				components={[
					<span key="0" className="blue" />,
				]}
				values={{
					startTime,
					season: years,
				}}
			/>
		</h1>
		<img src={logoLarge} alt="" className="margin-top-40px logo-large-img"/>
	</Container>
);

SignUpClosed.propTypes = {
	years: propTypes.string.isRequired,
	startTime: propTypes.string.isRequired,
};

export default SignUpClosed;

SignUpClosed.displayName = "succom";