import React from "react";
import i18instance from "i18instance";

import store from "redux/store";

import {
	TEAM_NAME_IS_REQUIRED,
	TEAM_NAME_BAD_WORDS,
	TEAM_NAME_ONLY_LATIN_OR_DIGITS,
	TEAM_NAME_SHOULD_BE_LESS_31
} from "helpers/constants/errors";

const Filter = require("bad-words");
const filter = new Filter();

export function validateUserTeam({ 
	fieldTeam, 
	teamName, 
	captainID, 
	viceCaptainID, 
	currentTotalPrice,
	withoutCaptainValidate,
	budgetLeft = null,
	isTransfersPage = false,
}) {
	const { playersFromOneClubLimit = 0, budgetLimit = 0 } = store.getState().settingsReducer;

	const errors = [];
	if (isTransfersPage ? budgetLeft < 0 : currentTotalPrice > budgetLimit) {
		errors.push(i18instance.t("validate_user_team:team_budget_is_overflown"));
	}
	const { forwards, midfielders, defenders, goalkeepers, bench } = fieldTeam;
	const resultTeam = [...forwards, ...midfielders, ...defenders, ...goalkeepers, ...bench];
	const clubGroupedTeam = {};
	resultTeam.forEach(player => {
		if (player.id && player.football_club.id) {
			clubGroupedTeam[player.football_club.full_name] = clubGroupedTeam[player.football_club.full_name]
				? [player, ...clubGroupedTeam[player.football_club.full_name]]
				: [player];
		}
	});
	const teams = Object.keys(clubGroupedTeam);
	let teamsUnderLimit = "";
	for (let i = 0; i < teams.length; i++) {
		if (clubGroupedTeam[teams[i]].length > playersFromOneClubLimit) {
			teamsUnderLimit = teamsUnderLimit
				? teamsUnderLimit + `, ${teams[i]}`
				: teams[i];
		}
	}

	if (teamsUnderLimit) {
		errors.push(<div className='d-block'><div>{i18instance.t("validate_user_team:max_players_from_one_club", {
			playersFromOneClubLimit,
		})}</div><div>{teamsUnderLimit}</div></div>);
	}

	if ((typeof teamName !== "undefined" && !teamName) || !teamName.replace(/\s/g, "").length) {
		errors.push(TEAM_NAME_IS_REQUIRED);
	} else {
		if (!/^[\w\s]+$/.test(teamName)) {
			errors.push(TEAM_NAME_ONLY_LATIN_OR_DIGITS);
		}
		if (filter.isProfane(teamName)) {
			errors.push(TEAM_NAME_BAD_WORDS);
		}
		if (teamName.length > 30) {
			errors.push(TEAM_NAME_SHOULD_BE_LESS_31);
		}
	}

	if (!captainID && !withoutCaptainValidate) {
		errors.push(i18instance.t("validate_user_team:team_should_have_a_captain_selected"));
	}

	if (!viceCaptainID && !withoutCaptainValidate) {
		errors.push(i18instance.t("validate_user_team:team_should_have_a_vice_captain_selected"));
	}

	if (captainID && captainID === viceCaptainID) {
		errors.push(i18instance.t("validate_user_team:captain_and_vice_captain_can_t_be_the_same"));
	}

	const noFullTeam = [...forwards.filter(val => !!val.id),
		...midfielders.filter(val => !!val.id),
		...defenders.filter(val => !!val.id),
		...goalkeepers.filter(val => !!val.id)].length < 11;

	const notFullBench = [...bench.filter(val => !!val.id)].length < 4;

	if (noFullTeam || notFullBench) {
		errors.push(i18instance.t("validate_user_team:team_should_contain_15_players_for_all_positions_on_the_pitch"));
	}
	return errors;
}