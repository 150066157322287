import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { parsedFieldTeamType } from "utils/propTypes/footballTeam";
import globalFormationPropType from "utils/propTypes/globalFormation";

import {
	actionMovePlayer,
	actionRemovePlayer,
	actionInitTeam,
	actionSetCaptain,
	actionSetViceCaptain,
	actionGetRequestGlobalFormations,
} from "redux/actions/footballField";
import { actionGetRequestRoundGames } from "redux/actions/roundGames";

import FootballFieldSmall from "modules/footballField/components/FootballFieldSmall";

function FootballFieldContainer({
	selectedRoundId,
	fieldTeam,
	formationType,
	globalFormations,
	actionMovePlayer,
	actionSetCaptain,
	actionSetViceCaptain,
	actionRemovePlayer,
	isLiveAndPreviousPage,
	actionGetRequestGlobalFormations,
	dynamicField,
	isSelectTeamPage,
	isNextRoundPage,
	switchPlayersListModal,
	withoutDragAndWithDeletable,
	withoutFieldTopControls,
	withoutPlayerDeleteButton,
	withoutCaptainIcon,
}) {
	useEffect(() => {
		!globalFormations.length && actionGetRequestGlobalFormations();
	}, []);
	
	return (
		<FootballFieldSmall
			switchPlayersListModal={switchPlayersListModal}
			isSelectTeamPage={isSelectTeamPage}
			isNextRoundPage={isNextRoundPage}
			isLiveAndPreviousPage={isLiveAndPreviousPage}
			dynamicField={dynamicField}
			actionSetCaptain={actionSetCaptain}
			actionSetViceCaptain={actionSetViceCaptain}
			fieldTeam={fieldTeam}
			formationType={formationType}
			actionMovePlayer={actionMovePlayer}
			actionRemovePlayer={actionRemovePlayer}
			selectedRoundId={selectedRoundId}
			withoutDragAndWithDeletable={withoutDragAndWithDeletable}
			withoutFieldTopControls={withoutFieldTopControls}
			withoutPlayerDeleteButton={withoutPlayerDeleteButton}
			withoutCaptainIcon={withoutCaptainIcon}
		/>
	);
}

const mapStateToProps = (state) => {
	const { footballFieldReducer } = state;
	const {
		fieldTeam, formation: { formationType = [] } = {}, globalFormations,
	} = footballFieldReducer;
	return {
		fieldTeam, formationType, globalFormations,
	};
};

FootballFieldContainer.propTypes = {
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	selectedRoundId: PropTypes.number,
	footballPlayersIdList: PropTypes.arrayOf(PropTypes.number).isRequired,
	formationType: PropTypes.arrayOf(PropTypes.number).isRequired,
	globalFormations: PropTypes.arrayOf(globalFormationPropType.parsedGlobalFormation).isRequired,
	dynamicField: PropTypes.bool,
	withoutCaptainIcon: PropTypes.bool,
	withoutDragAndWithDeletable: PropTypes.bool,
	withoutFieldTopControls: PropTypes.bool,
	isSelectTeamPage: PropTypes.bool,
	isNextRoundPage: PropTypes.bool,
	withoutPlayerDeleteButton: PropTypes.bool,
	isLiveAndPreviousPage: PropTypes.bool,
	actionMovePlayer: PropTypes.func.isRequired,
	actionSetCaptain: PropTypes.func.isRequired,
	actionSetViceCaptain: PropTypes.func.isRequired,
	actionInitTeam: PropTypes.func.isRequired,
	actionRemovePlayer: PropTypes.func.isRequired,
	actionGetRequestGlobalFormations: PropTypes.func.isRequired,
	actionGetRequestRoundGames: PropTypes.func.isRequired,
	switchPlayersListModal: PropTypes.func
};

FootballFieldContainer.defaultProps = {
	dynamicField: false,
	isLiveAndPreviousPage: false,
	isSelectTeamPage: false,
	isNextRoundPage: false,
};

export default connect(
	mapStateToProps,
	{
		actionMovePlayer,
		actionRemovePlayer,
		actionInitTeam,
		actionSetCaptain,
		actionSetViceCaptain,
		actionGetRequestGlobalFormations,
		actionGetRequestRoundGames,
	}
)(FootballFieldContainer);

FootballFieldContainer.displayName = "ffc";