import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { actionGetNewsPagination } from "redux/actions/news";

import useQuery from "helpers/hooks/useQuery";


import NewsBlockComponent from "../components/NewsBlock";

const NewsBlock = ({
	news,
	actionGetNewsPagination,
}) => {
	const pageNumber = useQuery().get("page");

	useEffect(() => {
		actionGetNewsPagination(pageNumber || 1, 3);
	}, []);

	return <NewsBlockComponent news={news} />;
};

NewsBlock.propTypes = {
	news: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})).isRequired,
	actionGetNewsPagination: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		news: state.newsReducer.newsPaginationData?.results || [],
	};
};

export default connect(
	mapStateToProps, {
		actionGetNewsPagination
	}
)(NewsBlock);

NewsBlock.displayName = "nwb";