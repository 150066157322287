import React from "react";
import PropTypes from "prop-types";

export function SliderRail({ getRailProps }) {
	return (
		<div>
			<div className='slider-rail'  {...getRailProps()} />
			<div className='slider-rail-center' />
		</div>
	);
}

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired,
};

SliderRail.displayName = "sr";