import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/prizes";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getPrizesWinnerType1Start = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_1_START,
	payload,
});

const getPrizesWinnerType1Success = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_1_SUCCESS,
	payload,
});

const getPrizesWinnerType1Error = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_1_ERROR,
	payload,
});

const getPrizesWinnerType2Start = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_2_START,
	payload,
});

const getPrizesWinnerType2Success = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_2_SUCCESS,
	payload,
});

const getPrizesWinnerType2Error = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_2_ERROR,
	payload,
});

const getPrizesWinnerType3Start = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_3_START,
	payload,
});

const getPrizesWinnerType3Success = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_3_SUCCESS,
	payload,
});

const getPrizesWinnerType3Error = payload => ({
	type: types.GET_REQUEST_PRIZES_WINNER_TYPE_3_ERROR,
	payload,
});

export const actionGetRequestPrizes = (params) => dispatch => {
	let startAction = getPrizesWinnerType1Start;
	let successAction = getPrizesWinnerType1Success;
	let errorAction = getPrizesWinnerType1Error;

	switch(params.winner_type) {
	case 2:
		startAction = getPrizesWinnerType2Start;
		successAction = getPrizesWinnerType2Success;
		errorAction = getPrizesWinnerType2Error;
		break;
	case 3:
		startAction = getPrizesWinnerType3Start;
		successAction = getPrizesWinnerType3Success;
		errorAction = getPrizesWinnerType3Error;
		break;
	default:
	}

	dispatch(startAction());

	const config = {
		params,
		method: "GET",
		url: backendApiUrls.rulesPrizes,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(successAction(data));
		})

		.catch((error) => {
			dispatch(errorAction(errorsParser(error)));
		});
};
