import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { roundGamePropType } from "utils/propTypes";

import { actionGetRoundGameItem } from "redux/actions/roundGames";

import useQuery from "helpers/hooks/useQuery";

import MatchInfoComponent from "modules/common/MatchInfoComponent";

const MatchInfoContainer = ({
  roundGame,
  actionGetRoundGameItem,
}) => {
  const roundGameId = useQuery().get("match_id");

  useEffect(() => {
		actionGetRoundGameItem(roundGameId);
	}, [roundGameId]);

  return (
    <MatchInfoComponent
      roundGame={roundGame}
    />
  );
}

MatchInfoContainer.propTypes = {
  roundGame: PropTypes.shape(roundGamePropType.resultGame).isRequired,
	actionGetRoundGameItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		roundGame: state.roundGamesReducer.roundGameItemData,
	};
};

export default connect(
	mapStateToProps, {
		actionGetRoundGameItem,
	}
)(MatchInfoContainer);