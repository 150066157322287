import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import useQuery from "helpers/hooks/useQuery";

import NewsAndSocialComponent from "../components/NewsAndSocial";
import NewsAndArticleComponent from "../components/NewsAndArticle";

const NewsAndSocial = ({
	isLoading,
}) => {
	const articleId = useQuery().get("article_id");

	if (articleId) {
		return <NewsAndArticleComponent />;
	}
	
	return <NewsAndSocialComponent isLoading={isLoading} />;
};

NewsAndSocial.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	return {
		isLoading: state.newsReducer.getNewsPaginationRequestLoading || false,
	};
};

export default connect(
	mapStateToProps,
)(NewsAndSocial);

NewsAndSocial.displayName = "nwss";