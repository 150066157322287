import { createSelector } from "reselect";
import _ from "lodash";
import moment from "moment";

import calculatePercent from "helpers/calculatePercent";

const getFootballRounds = createSelector(
	({ seasonRoundsReducer }) => seasonRoundsReducer.seasonRoundsData,
	results => results
);

export const getTransfers = createSelector(
	({ footballFieldReducer }) => footballFieldReducer,
	({ transferedOutPlayers, transferedInPlayers }) => {
		let notAssignedTransferedOutPlayers = [ ...transferedOutPlayers ];
	
		return transferedInPlayers.reduce((acc, transferedInPlayer) => {
			const transferedOutPlayerForCurrentLine = notAssignedTransferedOutPlayers.find(p => p.position === transferedInPlayer.position);
			const transferedOutPlayer = transferedOutPlayerForCurrentLine ? transferedOutPlayerForCurrentLine : notAssignedTransferedOutPlayers[0];
		
			acc.push({
				transferedOut: transferedOutPlayer,
				transferedIn: transferedInPlayer,
			}); 

			notAssignedTransferedOutPlayers = notAssignedTransferedOutPlayers.filter(p => p.id !== transferedOutPlayer.id);

			return acc;

		}, []);
	}
);

export const getUserTeamIdsPlayersHashList = createSelector(
	({ userTeamsReducer }) => userTeamsReducer.userTeamsData,
	results => {
		const userTeamIdsPlayersHashList = {};
		if (results.length) {
			results[0].football_players.forEach(player => userTeamIdsPlayersHashList[player.football_player.id] = player);
		}
		return userTeamIdsPlayersHashList;
	}
);

export const getUserTeamIdsPlayers = createSelector(
	(state) => state,
	state => {
		const { userTeamsReducer: { userTeamsData } } = state;
		const userTeamFootballPlayers = userTeamsData && userTeamsData[0] && userTeamsData[0].football_players;

		let footballPlayersIdList = [];
		if (userTeamFootballPlayers) {
			footballPlayersIdList = userTeamFootballPlayers.map(player => {
				const { football_player: { id: playerID } } = player;
				return playerID;
			});
		}
		return footballPlayersIdList;
	}
);

export const getSortedRounds = createSelector(
	getFootballRounds,
	rounds => {
		const roundsWithFormattedDate = _.map(rounds, round => {
			const roundModifyde = { ...round };
			roundModifyde.userteam_deadline = moment(round.userteam_deadline).format("DD MMM YYYY | HH:mm");
			roundModifyde.userteam_deadline_without_year =
				moment(round.userteam_deadline).format("DD MMM | HH:mm");
			return roundModifyde;
		});
		const sortedRoundByNumbers = _.sortBy(roundsWithFormattedDate, "number");
		return sortedRoundByNumbers;
	}
);

export const getFormattedDreamTeam = createSelector(
	({ dreamTeamsReducer }) => dreamTeamsReducer.dreamTeamsData,
	dreamTeamArray => {
		return {
			midfielders: dreamTeamArray.filter(player => player.position === "midfielder").map((data) => ({
				...data,
				...data.football_player,
				full_name: `${data.football_player.first_name} ${data.football_player.last_name}`,
			})) || [],
			forwards: dreamTeamArray.filter(player => player.position === "forward").map((data) => ({
				...data,
				...data.football_player,
				full_name: `${data.football_player.first_name} ${data.football_player.last_name}`,
			})) || [],
			defenders: dreamTeamArray.filter(player => player.position === "defender").map((data) => ({
				...data,
				...data.football_player,
				full_name: `${data.football_player.first_name} ${data.football_player.last_name}`,
			})) || [],
			goalkeepers: dreamTeamArray.filter(player => player.position === "goalkeeper").map((data) => ({
				...data,
				...data.football_player,
				full_name: `${data.football_player.first_name} ${data.football_player.last_name}`,
			})) || [],
		};
	}
);

export const getFinishedRounds = createSelector(
	getSortedRounds,
	footballRounds => {
		const finishedRounds = footballRounds.filter(round => round.status === "finished");
		return finishedRounds.length > 0 ? finishedRounds : [];
	}
);

export const getFinishedAndLiveQuadLeagues = createSelector(
	({ leaguesReducer }) => leaguesReducer.quadListData,
	results => {
		let returnValue = results?.filter(league => league.status !== "scheduled");
		if (!returnValue.length) {
			returnValue = [results[results.length - 1] || {}];
		}
		return returnValue;
	}
);

export const getFinishedAndLiveRounds = createSelector(
	getSortedRounds,
	footballRounds => {
		let returnValue = footballRounds.filter(round => round.status === "finished" || round.status === "live");
		if (!returnValue.length) {
			returnValue = [footballRounds[0] || {
				isEmpty: true,
			}];
		}
		return returnValue;
	}
);

export const getFinishedAndLiveRoundsReverse = createSelector(
	getSortedRounds,
	footballRounds => {
		let returnValue = footballRounds.filter(round => round.status === "finished" || round.status === "live").reverse();
		if (!returnValue.length) {
			returnValue = [footballRounds[0] || {}];
		}

		return returnValue;
	}
);

export const getScheduledRounds = createSelector(
	getSortedRounds,
	footballRounds => {
		const finishedRounds = footballRounds.filter(round => round.status === "scheduled");
		return finishedRounds.length > 0 ? finishedRounds : [];
	}
);

export const getNextRound = createSelector(
	getSortedRounds,
	footballRounds => {
		const scheduledRounds = footballRounds.filter(round => round.status === "scheduled");
		return scheduledRounds.length > 0 ? scheduledRounds[0] : {};
	}
);

export const getNextScheduledRound = createSelector(
	getFootballRounds,
	footballRounds => {
		const scheduledRounds = footballRounds.map(round => {
			if (round.status === "scheduled") {
				const roundModifyde = { ...round };
				roundModifyde.userteam_deadline_without_year =
					moment(round.userteam_deadline).format("DD MMM | HH:mm");
				return roundModifyde;
			}
			return false;
		});
		const sortedScheduledRounds = _.sortBy(scheduledRounds, "userteam_deadline");
		return sortedScheduledRounds[0];
	}
);

export const getRunningRound = createSelector(
	getFootballRounds,
	rounds => _.find(rounds, { status: "live" })
);

export const getNecessaryRoundGames = createSelector(
	({ roundGamesReducer }) => roundGamesReducer,
	gamesReducer => {
		const roundsList = { ...gamesReducer.roundGamesHashList };
		const gasmesMaped = _.mapValues(roundsList, round => {
			const gamesSortedByDate = _.sortBy(round, "match_datetime");
			return gamesSortedByDate;
		});
		const withFormattedDate = _.map(gasmesMaped, games => {
			return _.map(games, game => {
				const gameModifyde = { ...game };
				gameModifyde.matchDateAndDayOfWeek = moment(game.match_datetime).format("DD MMM YYYY | dddd");
				gameModifyde.matchLocaleTime = moment(game.match_datetime).format("HH:mm");
				return gameModifyde;
			});
		});
		const flattenedGames = _.flatten(withFormattedDate);
		return flattenedGames;
	}
);

export const getParsedCountries = createSelector(
	({ countries }) => countries.payload,
	countries => {
		const modifiedCountries = countries.map(country => {
			return {
				value: country.code,
				label: country.name,
			};
		});
		return modifiedCountries;
	}
);

export const getUsernameAbbreviation = createSelector(
	({ authReducer }) => authReducer.fullName,
	fullName => {
		if (!fullName) return "";
		const nameValues = fullName.split(" ");
		return nameValues[0][0] + nameValues[1][0];
	}
);

const prizeSelect = (prizes) => {
	const firstPlaceTextItems = prizes[0] && _.filter(prizes[0].text_items, item => {
		return item.text_for_place === 1;
	});
	const secondPlaceTextItems = prizes[0] && _.filter(prizes[0].text_items, item => {
		return item.text_for_place === 2;
	});
	const thirdPlaceTextItems = prizes[0] && _.filter(prizes[0].text_items, item => {
		return item.text_for_place === 3;
	});
	const winnerData = {
		firstPlace: {
			textItems: firstPlaceTextItems,
			image: prizes[0]?.main_image,
		},
		secondPlace: {
			textItems: secondPlaceTextItems,
			image: prizes[0]?.second_place_image,
		},
		thirdPlace: {
			textItems: thirdPlaceTextItems,
			image: prizes[0]?.third_place_image,
		},
	};

	return winnerData;
};

export const getRoundWinnerPrizes = createSelector(
	({ prizesReducer }) => prizesReducer.prizesWinnerType3Data,
	prizeSelect,
);

export const getQuadWinnerPrizes = createSelector(
	({ prizesReducer }) => prizesReducer.prizesWinnerType1Data,
	prizeSelect,
);

export const getSeasonWinnerPrizes = createSelector(
	({ prizesReducer }) => prizesReducer.prizesWinnerType2Data,
	prizeSelect,
);

export const getCommonLeagues = createSelector(
	(state) => state,
	state => {
		const { leaguesReducer } = state;
		const {
			countryData, quadLeagueInfo, roundLeaguesData, seasonLeagueInfo,
		} = leaguesReducer;

		const country = {
			name: countryData?.name,
			members: countryData?.members_count,
			id: `id-country-${Date.now()}`,
			classificationType: "country",
			rank: countryData?.rank
		};

		const quad = {
			name: quadLeagueInfo?.name,
			members: quadLeagueInfo?.members_count,
			id: `id-quad-${Date.now()}`,
			classificationType: "quad",
			rank: quadLeagueInfo?.rank
		};

		const round = {
			name: roundLeaguesData?.name,
			members: roundLeaguesData?.members_count,
			id: `id-round-${Date.now()}`,
			classificationType: "round",
			rank: roundLeaguesData?.rank
		};

		const season = {
			name: seasonLeagueInfo?.name,
			members: seasonLeagueInfo?.members_count,
			id: `id-season-${Date.now()}`,
			classificationType: "season",
			rank: seasonLeagueInfo?.rank
		};

		return [round, quad, country, season];
	},
);
export const getPrivateLeagues = createSelector(
	(state) => state,
	(state) => {
		const { leaguesReducer } = state;
		const {
			privateLeaguesData, privateLeaguesInfoHashList,
		} = leaguesReducer;
		return privateLeaguesData.map(league => {
			const { total_points: points, rank } = privateLeaguesInfoHashList[league.id] || {};
			return {
				...league,
				members: league.members_count,
				points,
				placeInPercentage: calculatePercent(league.members_count, rank),
				classificationType: league.id,
				rank
			};
		});
	},
);

export const getIsChangedTeamStatus = createSelector(
	({ footballFieldReducer }) => footballFieldReducer,
	(({ fieldTeam, currentTeamName }) => {
		let isAnyPlayerOnField = false;
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				if (player.id) {
					isAnyPlayerOnField = true;
					return;
				}
			});
		});
		return currentTeamName || isAnyPlayerOnField;
	})
);

export const getFieldPositionsEmptySpaces = createSelector(
	({ footballFieldReducer }) => footballFieldReducer.fieldTeam,
	fieldTeam => {
		const positionsFullness = {
			forward: 3,
			defender: 5,
			midfielder: 5,
			goalkeeper: 2,
		};
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				if (player.id) {
					positionsFullness[player.position] = positionsFullness[player.position] - 1;
				}
			});
		});
		return positionsFullness;
	}
);

export const getFieldPlayersTotalPrice = createSelector(
	({ footballFieldReducer }) => footballFieldReducer.fieldTeam,
	fieldTeam => {
		let newPrice = 0;
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				if (player.id && player.current_price) {
					newPrice += Number(player.current_price);
				}
			});
		});
		return newPrice.toFixed(2);
	}
);

export const getTransferPlayersPrice = createSelector(
	({ footballFieldReducer }) => footballFieldReducer,
	({ transferedOutPlayers, transferedInPlayers }) => {
		const transferedOutPlayersPrice = transferedOutPlayers.reduce((acc, player) => {
			return acc + Number(player.current_price);
		}, 0);
		const transferedInPlayersPrice = transferedInPlayers.reduce((acc, player) => {
			return acc + Number(player.current_price);
		}, 0);

		return (transferedInPlayersPrice - transferedOutPlayersPrice).toFixed(1);
	}
);

export const getFieldPlayersTotalLength = createSelector(
	({ footballFieldReducer }) => footballFieldReducer.fieldTeam,
	fieldTeam => {
		let newLength = 0;
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				if (player.id) {
					newLength += 1;
				}
			});
		});
		return newLength;
	}
);