import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "static/scss/main.scss";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import { isMobile, isTablet } from "react-device-detect";
import Backend from "react-dnd-html5-backend";
import newsImagePlaceholder from "static/assets/images/news-image-placeholder.jpeg";

import Routes from "routes/frontendRoutes";
import { LIVE, SEASON_ID, STATUS } from "routes/backendFilters";
import frontendUrls from "routes/frontendUrls";

import { actionGetRequestLeagueSeasons } from "redux/actions/leagueSeasons";
import { actionGetRequestSeasonRounds } from "redux/actions/seasonRounds";
import { actionGetRequestUserTeams, actionHeadCheckUserTeamPlayedThisSeasonRequest, actionHeadCheckLiveSeasonUserTeamRequest } from "redux/actions/userTeams";
import { actionGetRequestSettings } from "redux/actions/settings";
import { addGlobalMessageSuccess } from "redux/actions/common";
import { actionGetCloasestScheduledGames } from "redux/actions/roundGames";
import { actionGetUserDetails } from "redux/actions/auth";
import { getNextRound } from "redux/selectors";

import history from "helpers/history";
import useEffectSkipFirst from "helpers/hooks/useEffectSkipFirst";

function App({  lastSeasonID, isUserLoggedIn, activateAccountRequestLoading, actionGetRequestSeasonRounds, actionGetRequestLeagueSeasons,
	actionGetCloasestScheduledGames, lastGlobalMessageSuccess, actionGetRequestUserTeams, actionGetRequestSettings,
	addGlobalMessageSuccess, actionGetUserDetails, actionHeadCheckUserTeamPlayedThisSeasonRequest,
	isUserEmailConfirmed, actionHeadCheckLiveSeasonUserTeamRequest }) {

	const [hideSuccessMessage, setHideSuccessMessage] = useState(true);
	
	useEffect(() => {
		if (isUserLoggedIn && isUserEmailConfirmed && window.location.pathname !== frontendUrls.urlNextSeasonTeam) {
			actionHeadCheckLiveSeasonUserTeamRequest();
		}
	}, []); 

	useEffect(() => {
		actionGetRequestSettings();
		actionGetRequestLeagueSeasons(STATUS, LIVE);
		actionGetCloasestScheduledGames();

		new Image().src = newsImagePlaceholder; // preload placeholder image
	}, []);

	useEffect(() => {
		isUserLoggedIn && actionHeadCheckUserTeamPlayedThisSeasonRequest();
	}, [isUserLoggedIn]);

	useEffectSkipFirst(() => {
		!activateAccountRequestLoading && actionGetUserDetails();
	}, [activateAccountRequestLoading]);

	useEffect(() => {
		lastSeasonID && actionGetRequestSeasonRounds(SEASON_ID, lastSeasonID);
	}, [lastSeasonID]);
	
	useEffect(() => {
		if (lastGlobalMessageSuccess) {
			setHideSuccessMessage(false);
			setTimeout(() => {
				setHideSuccessMessage(true);
				setTimeout(() => {
					addGlobalMessageSuccess("");
				}, 1000);
			}, 2000);
		}
	}, [lastGlobalMessageSuccess]);

	useEffect(() => {
		isUserLoggedIn && actionGetRequestUserTeams();
	}, [isUserLoggedIn]);

	return (
		<Router history={history}>
			<DndProvider backend={isMobile || isTablet ? TouchBackend : Backend}>
				<Routes />
				<span className={`global-success 
			${!hideSuccessMessage && "show"}
			${!!hideSuccessMessage && "hide"} 
			${!lastGlobalMessageSuccess && "disabled"}`}>
					{lastGlobalMessageSuccess}
				</span>
			</DndProvider>
		</Router>
	);
}

const mapStateToProps = (state) => {
	const { authReducer, leagueSeasonsReducer, commonReducer, registerReducer } = state;
	const { lastGlobalMessageSuccess } = commonReducer;
	const { isUserLoggedIn, isUserEmailConfirmed } = authReducer;
	const { seasonsListData } = leagueSeasonsReducer;
	const { activateAccountRequestLoading } = registerReducer;
	const nextRound = getNextRound(state);
	return {
		lastSeasonID: seasonsListData && seasonsListData.length ? seasonsListData[0].id : 0,
		isUserLoggedIn,
		nextRound,
		lastGlobalMessageSuccess,
		activateAccountRequestLoading,
		isUserEmailConfirmed
	};

};

export default connect(
	mapStateToProps,
	{
		actionGetRequestLeagueSeasons,
		actionGetRequestSeasonRounds,
		actionGetCloasestScheduledGames,
		actionGetRequestUserTeams,
		actionGetRequestSettings,
		addGlobalMessageSuccess,
		actionGetUserDetails,
		actionHeadCheckUserTeamPlayedThisSeasonRequest,
		actionHeadCheckLiveSeasonUserTeamRequest,
	}
)(App);

App.propTypes = {
	lastSeasonID: PropTypes.number,
	isUserEmailConfirmed: PropTypes.bool.isRequired,
	isUserLoggedIn: PropTypes.bool.isRequired,
	activateAccountRequestLoading: PropTypes.bool.isRequired,
	nextRound: PropTypes.shape({ id: PropTypes.number }).isRequired,
	actionGetRequestLeagueSeasons: PropTypes.func.isRequired,
	actionGetRequestSeasonRounds: PropTypes.func.isRequired,
	actionGetRequestUserTeams: PropTypes.func.isRequired,
	actionGetRequestSettings: PropTypes.func.isRequired,
	addGlobalMessageSuccess: PropTypes.func.isRequired,
	actionGetUserDetails: PropTypes.func.isRequired,
	actionGetRequestCountriesList: PropTypes.func.isRequired,
	actionGetCloasestScheduledGames: PropTypes.func.isRequired,
	actionHeadCheckUserTeamPlayedThisSeasonRequest: PropTypes.func.isRequired,
	actionHeadCheckLiveSeasonUserTeamRequest: PropTypes.func.isRequired,
	lastGlobalMessageSuccess: PropTypes.string.isRequired,
};

App.defaultProps = {
	lastSeasonID: 0,
};

App.displayName = "a";