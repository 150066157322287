import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive/src";
import { useHistory } from "react-router-dom";

import { actionGetNewsPagination } from "redux/actions/news";

import useQuery from "helpers/hooks/useQuery";

const Pagination = ({
	actionGetNewsPagination,
	newsCount
}) => {
	const pageNumberFromUrl = useQuery().get("page");
	const history = useHistory();

	const [currentPage, setCurrentPage] = useState(pageNumberFromUrl || 1);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 991px)"
	}) || window.innerWidth <= 991;

	const handlePageClick = ({
		selected
	}) => {
		setCurrentPage(selected + 1);
	};

	useEffect(() => {
		if (currentPage != pageNumberFromUrl) {
			history.push({
				search: `?page=${currentPage}`
			});
		}
		
		actionGetNewsPagination(currentPage, 3);
	}, [currentPage]);

	useEffect(() => {
		if (pageNumberFromUrl && pageNumberFromUrl != currentPage) {
			setCurrentPage(pageNumberFromUrl);
		}
	}, [pageNumberFromUrl]);


	return (
		<ReactPaginate
			disableInitialCallback={true}
			onPageChange={handlePageClick}
			pageCount={Math.ceil(newsCount / 3) || 1}
			pageRangeDisplayed={isMobileResolution ? 2 : 4}
			marginPagesDisplayed={1}	
			forcePage={currentPage - 1}
			breakLabel={"."}
			breakClassName="pagination-component__break"
			breakLinkClassName="pagination-component__break-link"
			containerClassName="pagination-component"
			pageClassName="pagination-component__page"
			activeClassName="pagination-component__page_active"
			activeLinkClassName="pagination-component__page-link_active"
			pageLinkClassName="pagination-component__page-link"
			previousClassName="pagination-component__previous"
			previousLinkClassName="pagination-component__previous-link"
			nextClassName="pagination-component__next"
			nextLinkClassName="pagination-component__next-link"
			previousLabel=""
			nextLabel=""
		/>
	);
};

Pagination.propTypes = {
	actionGetNewsPagination: PropTypes.func.isRequired,
	newsCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
	return {
		newsCount: state.newsReducer.newsPaginationData?.count || 0,
	};
};


export default connect(
	mapStateToProps,
	{  
		actionGetNewsPagination,
	}
)(Pagination);

Pagination.displayName = "nwp";