import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { Col } from "reactstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { formValidatorForgorPwd } from "utils/validators";

import { actionSwitchForgotPasswordModalOpenState, actionPostRequestForgotPassword } from "redux/actions/auth";

import SpinnerButton from "modules/common/SpinnerButton";
import ModalWindow from "modules/common/ModalWindow";
import SuccessComponent from "modules/common/SuccessComponent";
import ErrorComponent from "modules/common/ErrorComponent";

const ForgotPasswordModal = (props) => {
	const {
		forgotPasswordModalOpenState,
		forgotPasswordRequestLoading,
		forgotPasswordSuccess,
		forgotPasswordError,
		actionSwitchForgotPasswordModalOpenState,
		actionPostRequestForgotPassword,
	} = props;

	const { t } = useTranslation("forgot_password_modal");

	const [email, setEmail] = useState("");
	const [showSuccessMessage, setShowSuccessMessage] = useState(true);
	const [requestErrors, setRequestErrors] = useState({});
	const [validationErrors, setValidationErrors] = useState({});

	useEffect(() => {
		forgotPasswordError && setRequestErrors({ ...requestErrors, ...forgotPasswordError });
	}, [forgotPasswordError]);

	const handleForgotEmailRequest = () => {
		setShowSuccessMessage(true);
		const errors = formValidatorForgorPwd({ email });
		const newErrorValue = [];
		Object.keys(errors).forEach((key) => {
			newErrorValue.push(errors[key]);
		});
		setValidationErrors({
			...errors,
		});
		if (_.isEmpty(errors)) {
			actionPostRequestForgotPassword({ email });
		}
	};

	return (
		<ModalWindow toggle={actionSwitchForgotPasswordModalOpenState} isOpen={forgotPasswordModalOpenState}>
			<div className='d-flex align-items-center flex-column'>
				<div className='fantasy-modal-title text-uppercase'>{t("forgot password")}</div>
				{!forgotPasswordSuccess ? (
					<Col className="d-flex flex-column align-items-center px-0">
						{requestErrors.parsedTextErrorsWithoutKeys && requestErrors.parsedTextErrorsWithoutKeys.map((error, i) => (
							<Col className="px-0" key={Date.now()}>
								<div className="fantasy-modal-error-msg">
									<ErrorComponent
										handleDelete={() => {
											const newParsedTextErrorsWithoutKeysValue = [...requestErrors.parsedTextErrorsWithoutKeys];
											newParsedTextErrorsWithoutKeysValue.splice(i, 1);
											setRequestErrors({
												...requestErrors,
												parsedTextErrorsWithoutKeys: newParsedTextErrorsWithoutKeysValue
											});
										}}
										text={error}
										isTextCentered
									/>
								</div>
							</Col>
						))}
						<div className='fantasy-modal-subtitle'>
							{t("Please enter your email for password reset")}
						</div>
						<input placeholder={t("Email")}
							className={`fantasy-modal-input ${validationErrors.email && "error"}`}
							type="email" value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{validationErrors.email && <div className='fantasy-modal-error w-100'>{validationErrors.email}</div>}
						<SpinnerButton
							isLoading={forgotPasswordRequestLoading}
							text={t("send")}
							onClick={handleForgotEmailRequest}
							className='col-4 mx-auto limited-height margin-top-20px text-uppercase'
							size="medium"
						/>
					</Col>
				) : (
					<div>
						{showSuccessMessage && (
							<SuccessComponent
								handleDelete={() => {
									setShowSuccessMessage(false);
								}}
								text={t("Email has been successfully sent")}
							/>
						)}
						<div className='fantasy-modal-subtitle mb-0'>
							{t("Please check your email to reset your password")}
						</div>
					</div>
				)}
			</div>
		</ModalWindow>
	);
};

ForgotPasswordModal.propTypes = {
	forgotPasswordModalOpenState: propTypes.bool.isRequired,
	forgotPasswordError: propTypes.object.isRequired,
	forgotPasswordRequestLoading: propTypes.bool.isRequired,
	forgotPasswordSuccess: propTypes.bool.isRequired,
	actionSwitchForgotPasswordModalOpenState: propTypes.func.isRequired,
	actionPostRequestForgotPassword: propTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const {
		forgotPasswordModalOpenState,
		forgotPasswordRequestLoading,
		forgotPasswordError,
		forgotPasswordSuccess
	} = authReducer;
	return {
		forgotPasswordModalOpenState, forgotPasswordRequestLoading, forgotPasswordError, forgotPasswordSuccess
	};
};

export default connect(
	mapStateToProps,
	{ actionSwitchForgotPasswordModalOpenState, actionPostRequestForgotPassword },
)(ForgotPasswordModal);

ForgotPasswordModal.displayName = "fpm";