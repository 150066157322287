import React from "react";
import { Row, Col, Container } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import down from "static/assets/images/down.svg";
import { useTranslation } from "react-i18next";

import SignInUpButtons from "modules/home/components/SignInUpButtons";

const MainScreen = () => {
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const { t } = useTranslation("main_screen");

	const menu = [
		{
			text: t("Build your own 15-man team"),
		},
		{
			text: t("Join common and private leagues"),
		},
		{
			text: t("Win great prizes"),
		},
	];

	return (
		<Container className="main-screen d-flex justify-content-center">
			<Row className="justify-content-between w-100 flex-nowrap">
				<Col xs={6} className="d-flex flex-column pl-0 main-screen__content">
					<h1 className="font-h3-h1">
						{t("Welcome to")}
						<br />
						<span className="color-accent1">Serie A</span> Fantasy
					</h1>
					<div className="short-line mb-0" />
					<div className="text">
						{t("Fantasy football for Italian top division.")}
						{isMobileResolution ? " " : < br />}
						{t("At long last, Serie A gets the fantasy football game it deserves.")}
					</div>
					<SignInUpButtons />
					{!isMobileResolution && <img src={down} alt="" className="main-screen__scroll-image" />}
				</Col>
				<Col xs={6} className="main-screen__banners">
					{menu.map((menuItem, i) =>
						<Row key={menuItem.text} className={"number_" + (i + 1)}>
							<div className="item">
								<div className="img" />
								<div>
									{menuItem.text}
								</div>
							</div>
						</Row>
					)}
				</Col>
				
			</Row>
		</Container>
	);
};

export default MainScreen;

MainScreen.displayName = "ms";