import React from "react";
import { Modal } from "reactstrap";
import propTypes from "prop-types";
import modalClose from "static/assets/images/modal-close.svg";

const ModalWindow = ({ children, isOpen, toggle, contentClassName, modalClassName }) => {
	return (
		<Modal
			modalClassName={`modal-warning ${modalClassName}`}
			className="modal-container"
			contentClassName={`modal-wrapper ${contentClassName}`}
			isOpen={isOpen}
			toggle={toggle}>
			<img onClick={toggle} className='modal-close' src={modalClose} alt=""/>
			{children}
		</Modal>
	);
};

export default ModalWindow;

ModalWindow.propTypes = {
	children: propTypes.node.isRequired,
	isOpen: propTypes.bool.isRequired,
	toggle: propTypes.func.isRequired,
	contentClassName: propTypes.string,
	modalClassName: propTypes.string,
};

ModalWindow.defaultProps = {
	contentClassName: "",
	modalClassName: "",
};

ModalWindow.displayName = "mw";