import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/rules";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getSectionsHowToPlayStart = payload => ({
	type: types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_START,
	payload,
});

const getSectionsHowToPlaySuccess = payload => ({
	type: types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_SUCCESS,
	payload,
});

const getSectionsHowToPlayError = payload => ({
	type: types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_ERROR,
	payload,
});

const getSectionsRulesStart = payload => ({
	type: types.GET_REQUEST_SECTIONS_RULES_START,
	payload,
});

const getSectionsRulesSuccess = payload => ({
	type: types.GET_REQUEST_SECTIONS_RULES_SUCCESS,
	payload,
});

const getSectionsRulesError = payload => ({
	type: types.GET_REQUEST_SECTIONS_RULES_ERROR,
	payload,
});

export const actionGetRequestRulesSections = (params) => dispatch => {
	params.section_type !== "rules"
		? dispatch(getSectionsHowToPlayStart())
		: dispatch(getSectionsRulesStart());
	const config = {
		params,
		method: "GET",
		url: backendApiUrls.rulesSection,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			params.section_type !== "rules"
				? dispatch(getSectionsHowToPlaySuccess(data))
				: dispatch(getSectionsRulesSuccess(data));
		})

		.catch((error) => {
			params.section_type !== "rules"
				? dispatch(getSectionsHowToPlayError(errorsParser(error) ))
				: dispatch(getSectionsRulesError(errorsParser(error) ));
		});
};
