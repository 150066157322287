import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Row, Spinner, Table } from "reactstrap";

import { ACTION_TYPES_BLOCKS, ACTION_TYPES_ORDERED_LONG, PAST_SEASON_ACTION_TYPES_ORDERED_SHORT } from "helpers/constants/common";

const PastSeasonResults = ({
	results = [],
	isLoading = false,
	position,
}) => {
	const { t } = useTranslation("player_info_modal");
	const [showTitleFor, setShowTitleFor] = useState("");
	const [lastSortIsUpKey, setLastSortIsUpKey] = useState(false);
	const [resultsLocal, setResultsLocal] = useState([]);

	useEffect(() => {
		results.length && setResultsLocal(results);
	}, [results]);

	const getHeadTableHeadValue = ({ key, data = {}, isHeader, showTitle }) => {
		const { calculatedPoints } = data;
		if (!isHeader && data && ACTION_TYPES_BLOCKS[position]?.[key]) {
			return <div>-</div>;
		}

		switch (key) {
		case "season_name": {
			if (isHeader) {
				return <div key={`${key}1`}>{t("Season")}</div>;
			}
			return <div key={`${key}1`} className='player-info-modal_table_top-text font-size-15'>{data[key]}</div>;
		}
		case "SA_OP":
		case "TBI":
		case "SoT": {
			if (isHeader) {
				return [<div key={`${key}1`}>{PAST_SEASON_ACTION_TYPES_ORDERED_SHORT[key]}</div>,
					<div key={`${key}2`}>{(key === "SA_OP" || key === "SoT") ? "(2x)" : "(3x)"}{showTitle && <div className='player-info-title'>{ACTION_TYPES_ORDERED_LONG[key]}</div>}</div>];
			}
		}
		// eslint-disable-next-line no-fallthrough
		default: {
			if (typeof calculatedPoints?.[key] === "number") {
				return [<div key={`${key}1`} >{data[key] || 0}</div>,
					<div className={"color-accent1"} key={`${key}2`}>{` (${calculatedPoints[key] || 0})`}</div>];
			} else if (data && typeof data[key] === "number") {
				return <div>{data[key]}</div>;
			} else {
				return <div>{PAST_SEASON_ACTION_TYPES_ORDERED_SHORT[key]}{showTitle && <div className='player-info-title'>{ACTION_TYPES_ORDERED_LONG[key]}</div>}</div>;
			}
		}
		}
	};

	const sortTable = key => {
		if (ACTION_TYPES_BLOCKS[position][key]) {
			return;
		}

		const currentResults = [...resultsLocal];

		if (lastSortIsUpKey !== key) {
			const newResults = currentResults.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA < compareValueB) {
					return 1;
				}
				if (compareValueA > compareValueB) {
					return -1;
				}
				return 0;
			});
			setResultsLocal(newResults);
			setLastSortIsUpKey(key);
		} else {
			const newResults = currentResults.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA > compareValueB) {
					return 1;
				}
				if (compareValueA < compareValueB) {
					return -1;
				}
				return 0;
			});
			setResultsLocal(newResults);
			setLastSortIsUpKey(false);
		}

	};
    
	if (isLoading) {
		return (
			<Row className='w-100 min-height-90px px-0 mx-0 d-flex align-items-center justify-content-center'>
				<Spinner />
			</Row>
		);
	}

	return results.length ? (
		<div className="table-outer past-season-results">
			<div className='table-container'>
				<Table className='player-info-modal_table past-season-results'>
					<thead>
						<tr>
							{Object.keys(PAST_SEASON_ACTION_TYPES_ORDERED_SHORT).map((tableKey, index) => (
								<th onMouseEnter={() => setShowTitleFor(tableKey)} onMouseLeave={() => setShowTitleFor("")} onClick={() => sortTable(tableKey)} key={`player-stat${index}-${tableKey}`}>
									{getHeadTableHeadValue({ key: tableKey, isHeader: true, showTitle: showTitleFor === tableKey && ACTION_TYPES_ORDERED_LONG[tableKey] })}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{resultsLocal.map((result, matchIndex) => {
							const prefix = result.football_player_id + result.season_name;
							
							return (
								<tr key={`player-stat-${matchIndex}-${prefix}`}>
									{Object.keys(PAST_SEASON_ACTION_TYPES_ORDERED_SHORT).map((tableKey, pointIndex) => (
										<td key={`player-stat-value${matchIndex}-${prefix}-${pointIndex}-${tableKey}`}>
											{getHeadTableHeadValue({ key: tableKey, value: result[tableKey], data: result })}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</div>
	) : <div className='font-h4 p-5 text-center'>{t("This player did not play any matches past season")}</div>;
};

export default PastSeasonResults;

PastSeasonResults.propTypes = {
	results: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	position: PropTypes.string.isRequired,
};

PastSeasonResults.displayName = "pimpsr";