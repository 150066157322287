import i18instance from "i18instance";

import backendApiUrls from "routes/backendUrls";
import frontendUrls from "routes/frontendUrls";

import * as types from "redux/constants/leagues";

import { LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE, FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";
import history from "helpers/history";

export const setLeavePrivateLeagueModalState = payload => ({
	type: types.SET_LEAVE_PRIVATE_LEAGUE_MODAL_STATE,
	payload,
});

export const setEditPrivateLeagueModalState = payload => ({
	type: types.SET_EDIT_PRIVATE_LEAGUE_MODAL_STATE,
	payload,
});

const getPrivateLeaguesInfoStart = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUES_INFO_START,
	payload,
});

const getPrivateLeaguesInfoSuccess = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUES_INFO_SUCCESS,
	payload,
});

const getPrivateLeaguesInfoError = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUES_INFO_ERROR,
	payload,
});

const getPrivateLeagueInfoStart = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUE_INFO_START,
	payload,
});

const getPrivateLeagueInfoSuccess = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUE_INFO_SUCCESS,
	payload,
});

const getPrivateLeagueInfoError = payload => ({
	type: types.GET_REQUEST_PRIVATE_LEAGUE_INFO_ERROR,
	payload,
});

const leavePrivateLeagueStart = payload => ({
	type: types.LEAVE_PRIVATE_LEAGUE_START,
	payload,
});

const leavePrivateLeagueSuccess = payload => ({
	type: types.LEAVE_PRIVATE_LEAGUE_SUCCESS,
	payload,
});

const leavePrivateLeagueError = payload => ({
	type: types.LEAVE_PRIVATE_LEAGUE_ERROR,
	payload,
});

const deletePrivateLeagueStart = payload => ({
	type: types.DELETE_PRIVATE_LEAGUE_START,
	payload,
});

const deletePrivateLeagueSuccess = payload => ({
	type: types.DELETE_PRIVATE_LEAGUE_SUCCESS,
	payload,
});

const deletePrivateLeagueError = payload => ({
	type: types.DELETE_PRIVATE_LEAGUE_ERROR,
	payload,
});

const editPrivateLeagueStart = payload => ({
	type: types.EDIT_PRIVATE_LEAGUE_START,
	payload,
});

const editPrivateLeagueSuccess = payload => ({
	type: types.EDIT_PRIVATE_LEAGUE_SUCCESS,
	payload,
});

const editPrivateLeagueError = payload => ({
	type: types.EDIT_PRIVATE_LEAGUE_ERROR,
	payload,
});

const removeTeamFromPrivateLeagueStart = payload => ({
	type: types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_START,
	payload,
});

const removeTeamFromPrivateLeagueSuccess = payload => ({
	type: types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_SUCCESS,
	payload,
});

const removeTeamFromPrivateLeagueError = payload => ({
	type: types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR,
	payload,
});

const getSuggestedUserTeamsStart = payload => ({
	type: types.GET_SUGGESTED_USER_TEAMS_START,
	payload,
});

const getSuggestedUserTeamsSuccess = payload => ({
	type: types.GET_SUGGESTED_USER_TEAMS_SUCCESS,
	payload,
});

const getSuggestedUserTeamsError = payload => ({
	type: types.GET_SUGGESTED_USER_TEAMS_ERROR,
	payload,
});

export const clearSuggestionsUserTeams = () => ({
	type: types.CLEAR_SUGGESTIONS_USER_TEAMS,
});

export const actionClearPrivateLeagueInfoError = payload => ({
	type: types.CLEAR_PRIVATE_LEAGUE_INFO_ERROR,
	payload,
});

const postPrivateLeaguesStart = payload => ({
	type: types.POST_REQUEST_PRIVATE_LEAGUES_START,
	payload,
});

const postPrivateLeaguesSuccess = payload => ({
	type: types.POST_REQUEST_PRIVATE_LEAGUES_SUCCESS,
	payload,
});

const postPrivateLeaguesError = payload => ({
	type: types.POST_REQUEST_PRIVATE_LEAGUES_ERROR,
	payload,
});

const joinPrivateLeaguesStart = payload => ({
	type: types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_START,
	payload,
});

const joinPrivateLeaguesSuccess = payload => ({
	type: types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_SUCCESS,
	payload,
});

const joinPrivateLeaguesError = payload => ({
	type: types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_ERROR,
	payload,
});

export const actionCleanCreateJoinLeagueStates = () => ({
	type: types.CLEAR_CREATE_JOIN_LEAGUE_STATES,
});

export const actionCleanPrivateLeagueModalErrors = () => ({
	type: types.CLEAR_PRIVATE_LEAGUE_MODAL_ERRORS,
});

export const actionGetRequestPrivateLeaguesInfo = (activeSeasonId) => dispatch => {
	dispatch(getPrivateLeaguesInfoStart());
	const config = {
		method: "GET",
		url: backendApiUrls.leaguePrivateLeagueInfo.replace(
			FOR_REPLACEMENT.ID,
			activeSeasonId
		),
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getPrivateLeaguesInfoSuccess(data.sort(function (a, b) {
				if (a.own && !b.own) { return -1; }
				if (!a.own && b.own) { return 1; }

				if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
				if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }

				return 0;
			})));
		})

		.catch((error) => {
			dispatch(getPrivateLeaguesInfoError(errorsParser(error)));
		});
};

export const actionPostRequestPrivateLeagues = ({ seasonID, name, selectedRoundCount }) => dispatch => {
	dispatch(postPrivateLeaguesStart(seasonID));
	const config = {
		method: "POST",
		url: backendApiUrls.leaguePrivateLeagueInfo.replace(
			FOR_REPLACEMENT.ID,
			seasonID
		),
		data: {
			name,
			starts_from_round: selectedRoundCount,
		}
	};

	return axiosPlus(config)
		.then((json) => {
			dispatch(postPrivateLeaguesSuccess(name));
			return json.data;
		})

		.catch((error) => {
			dispatch(postPrivateLeaguesError(errorsParser(error)));
		});
};

export const actionJoinRequestPrivateLeagues = ({ joinCode }) => dispatch => {
	if (!joinCode || /^\s+$/.test(joinCode)) {
		dispatch(joinPrivateLeaguesError({ parsedTextErrorsWithoutKeys: [i18instance.t("private_leagues_errors:league_code_is_required")] }));
		return Promise.resolve();
	}
	dispatch(joinPrivateLeaguesStart({ joinCode }));
	const config = {
		method: "POST",
		url: `${backendApiUrls.urlPrivateLeaguesRoot}join/${joinCode}/`,
		data: { join_code: joinCode }
	};

	return axiosPlus(config)
		.then((json) => {
			dispatch(joinPrivateLeaguesSuccess(joinCode));
			return json.data;
		})

		.catch((error) => {
			dispatch(joinPrivateLeaguesError(errorsParser(error)));
		});
};

export const actionGetRequestPrivateLeagueFindOwn = ({ leagueID }) => (dispatch, getState) => {
	const page = getState().leaguesReducer?.privateLeaguesInfoHashList?.[leagueID]?.page;
	const isInfoReady = page !== undefined;

	if (!isInfoReady) {
		dispatch(getPrivateLeagueInfoStart(leagueID));
		const config = {
			method: "GET",
			url: `${backendApiUrls.leaguePrivateLeagueOwn.replace(
				FOR_REPLACEMENT.ID,
				leagueID
			)}?page_size=${LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE}`
		};

		return axiosPlus(config)
			.then(response => {
				const { data } = response;
				dispatch(getPrivateLeagueInfoSuccess({ data, forLeagueID: leagueID }));
			})

			.catch((error) => {
				dispatch(getPrivateLeagueInfoError({ data: errorsParser(error), forLeagueID: leagueID }));
			});
	} else {
		return Promise.resolve();
	}
};

export const actionRequestLeavePrivateLeague = ({ leagueID }) => dispatch => {
	dispatch(leavePrivateLeagueStart(leagueID));
	const config = {
		method: "DELETE",
		url: `${backendApiUrls.leaguePrivateRoot}league/${leagueID}/leave/`
	};

	axiosPlus(config)
		.then(() => {
			dispatch(leavePrivateLeagueSuccess({ leagueID }));
			dispatch(setLeavePrivateLeagueModalState(null));
			if (window.location.pathname.includes(frontendUrls.urlLeaguesRanking)) {
				history.push(frontendUrls.urlLeagues);
			}
		})

		.catch((error) => {
			dispatch(leavePrivateLeagueError({ data: errorsParser(error), forLeagueID: leagueID }));
		});
};

export const actionRequestEditPrivateLeague = ({ leagueID, leagueName, entriesClosed }) => dispatch => {
	dispatch(editPrivateLeagueStart(leagueID));
	const config = {
		method: "PATCH",
		url: `${backendApiUrls.leaguePrivateRoot}manage/${leagueID}/`,
		data: {
			name: leagueName,
			entries_closed: entriesClosed,
		}
	};

	return axiosPlus(config)
		.then(() => {
			dispatch(editPrivateLeagueSuccess({ leagueID, leagueName, entriesClosed }));
		})
		.catch((error) => {
			dispatch(editPrivateLeagueError({ data: errorsParser(error), forLeagueID: leagueID }));
		});
};

export const actionRequestRemoveTeamFromPrivateLeague = ({ leagueID, userTeamId }) => dispatch => {
	dispatch(removeTeamFromPrivateLeagueStart(leagueID));
	const config = {
		method: "DELETE",
		url: `${backendApiUrls.leaguePrivateRoot}league/${leagueID}/remove/${userTeamId}/`,
	};

	return axiosPlus(config)
		.then(() => {
			return dispatch(removeTeamFromPrivateLeagueSuccess({ leagueID, userTeamId }));
		})

		.catch((error) => {
			return dispatch(removeTeamFromPrivateLeagueError({ data: errorsParser(error), forLeagueID: leagueID }));
		});
};

export const actionRequestSuggestedUserTeams = ({ leagueID, teamName }) => dispatch => {
	dispatch(getSuggestedUserTeamsStart(leagueID));
	const config = {
		method: "GET",
		url: `${backendApiUrls.leaguePrivateRoot}find-team/?privateleague_id=${leagueID}&userteam_name=${teamName}`,
	};

	return axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getSuggestedUserTeamsSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(getSuggestedUserTeamsError({ data: errorsParser(error), forLeagueID: leagueID }));
		}
		);
};

export const actionRequestDeletePrivateLeague = ({ leagueID }) => dispatch => {
	dispatch(deletePrivateLeagueStart(leagueID));
	const config = {
		method: "DELETE",
		url: `${backendApiUrls.leaguePrivateRoot}manage/${leagueID}/`
	};

	axiosPlus(config)
		.then(() => {
			dispatch(deletePrivateLeagueSuccess({ leagueID }));
			dispatch(setEditPrivateLeagueModalState(null));
			if (window.location.pathname.includes(frontendUrls.urlLeaguesRanking)) {
				history.push(frontendUrls.urlLeagues);
			}
		})
		.catch((error) => {
			dispatch(deletePrivateLeagueError({ data: errorsParser(error), forLeagueID: leagueID }));
		});
};