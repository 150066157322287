import * as types from "redux/constants/countries";

import createReducer from "redux/createReducer";

const countriesInitialState = {
	countriesData: [],
	countriesRequestError: {},
	countriesRequestLoading: false,
};

const countriesReducer = createReducer(countriesInitialState)({
	[types.GET_REQUEST_COUNTRIES_START]: state => ({
		...state,
		countriesRequestError: {},
		countriesRequestLoading: true,
	}),
	[types.GET_REQUEST_COUNTRIES_SUCCESS]: (state, { payload }) => ({
		...state,
		countriesData: payload,
		countriesRequestLoading: false,
	}),
	[types.GET_REQUEST_COUNTRIES_ERROR]: (state, { payload }) => ({
		...state,
		countriesRequestError: payload,
		countriesRequestLoading: false,
	})
});

export default countriesReducer;
