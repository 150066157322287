import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/rules";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getFaqListStart = payload => ({
	type: types.GET_REQUEST_FAQ_LIST_START,
	payload,
});

const getFaqListSuccess = payload => ({
	type: types.GET_REQUEST_FAQ_LIST_SUCCESS,
	payload,
});

const getFaqListError = payload => ({
	type: types.GET_REQUEST_FAQ_LIST_ERROR,
	payload,
});

export const actionGetRequestFAQList = () => dispatch => {
	dispatch(getFaqListStart());
	const config = {
		method: "GET",
		url: backendApiUrls.rulesFAQ,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getFaqListSuccess(data));
		})

		.catch((error) => {
			dispatch(getFaqListError(errorsParser(error) ));
		});
};
