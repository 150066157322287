const frontendUrls = {
	urlRoot: "/",
	urlScheduleAndResults: "/schedule-and-results/",
	urlNews: "/news/",
	urlPrizes: "/prizes/",
	urlRulesAndFaq: "/rules-and-faq/",
	urlContactUs: "/contact-us/",
	urlMain: "/main/",
	urlLeagues: "/leagues/",
	urlLeaguesRanking: "/leagues-ranking/",
	urlSignUp: "/auth/sign-up/",
	urlSignUpClosed: "/auth/sign-up/closed/",
	urlSelectTeam: "/auth/sign-up/select-team/",
	urlPersonalDetails: "/auth/sign-up/personal-details/",
	urlConfirmEmail: "/auth/sign-up/confirm-email/",
	urlActivationSuccess: "/auth/sign-up/success",
	urlSetNewPassword: "/auth/new-password/",
	urlNextRoundTeam: "/next-round-team/",
	urlTransfers: "/transfers/",
	urlLivePreviousRoundTeams: "/live-previous-rounds-team/",
	urlPersonalDetailsChange: "/personal-details/",
	urlOtherUserTeam: "/other-user-team/",
	urlNextSeasonTeam: "/next-season-team/",
	urlMatchInfo: "/match-info/",
	urlMaintenance: "/maintenance/",
	urlDownloadTheApp: "/download-the-app/",
};

export default frontendUrls;
