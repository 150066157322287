import React from "react";
import PropTypes from "prop-types";
import success from "static/assets/images/success.svg";
import whiteClose from "static/assets/images/white-close.svg";

const SuccessComponent = ({ text, handleDelete, className }) => (
	<div className={`success-component ${className}`}>
		<img className='success' src={success} alt=""/>
		<span className='w-100 d-flex justify-content-between'>{text}
			<img onClick={() => handleDelete(text)} className='cross' src={whiteClose} alt=""/></span>
	</div>
);

SuccessComponent.propTypes = {
	text: PropTypes.string,
	handleDelete: PropTypes.func,
	className: PropTypes.string,
};

SuccessComponent.defaultTypes = {
	text: "",
	handleDelete: () => {},
	className: "",
};

SuccessComponent.displayName = "suc";

export default SuccessComponent;
