import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/leagues";

import { FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";


const getQuadLeaguesStart = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUES_START,
	payload,
});

const getQuadLeaguesSuccess = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUES_SUCCESS,
	payload,
});

const getQuadLeaguesError = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUES_ERROR,
	payload,
});

const getQuadLeagueInfoStart = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUE_INFO_START,
	payload,
});

const getQuadLeagueInfoSuccess = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUE_INFO_SUCCESS,
	payload,
});

const getQuadLeagueInfoError = payload => ({
	type: types.GET_REQUEST_QUAD_LEAGUE_INFO_ERROR,
	payload,
});

export const actionGetRequestQuadLeagues = (activeSeasonId) => (dispatch, getState) => {
	const lastRequestedId = getState().leaguesReducer?.quadLeaguesData?.requestedId;

	if (lastRequestedId !== activeSeasonId) {
		dispatch(getQuadLeaguesStart());
		const config = {
			method: "GET",
			url: backendApiUrls.leagueQuadLeagues.replace(FOR_REPLACEMENT.ID, activeSeasonId),
		};

		axiosPlus(config)
			.then(response => {
				const { data } = response;
				dispatch(getQuadLeaguesSuccess({
					...data,
					requestedId: activeSeasonId,
				}));
			})

			.catch((error) => {
				dispatch(getQuadLeaguesError(errorsParser(error)));
			});
	}
};

export const actionGetQuadLeagueInfo = (activeQuadLeagueId, otherUserId) => dispatch => {
	dispatch(getQuadLeagueInfoStart());
	const config = {
		method: "GET",
		url: `${backendApiUrls.leagueQuadLeagueInfo.replace(FOR_REPLACEMENT.ID, activeQuadLeagueId)}${otherUserId ? `?user_id=${otherUserId}` : ""}`,
	};

	axiosPlus(config)
		.then(response => {
			dispatch(getQuadLeagueInfoSuccess(response.data));
		})

		.catch((error) => {
			dispatch(getQuadLeagueInfoError(errorsParser(error)));
		});
};
