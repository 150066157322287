import React from "react";
import PropTypes from "prop-types";
import warning from "static/assets/images/warning.svg";
import redClose from "static/assets/images/red-close.svg";

const ErrorComponent = ({ text, handleDelete, isTextCentered, className }) => {
	return (
		<div className={`team-error ${className}`}>
			<img className='warning' src={warning} alt=""/>
			<span className={`w-100 ${isTextCentered && "text-center"}`}>{text}</span>
			<img onClick={() => handleDelete(text)} className='cross' src={redClose} alt=""/>
		</div>);
};

ErrorComponent.propTypes = {
	text: PropTypes.string,
	className: PropTypes.string,
	handleDelete: PropTypes.func,
	isTextCentered: PropTypes.bool,
};

ErrorComponent.defaultTypes = {
	text: "",
	className: "",
	handleDelete: () => {},
	isTextCentered: false,
};

export default ErrorComponent;

ErrorComponent.displayName = "ec";