import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import priceToMillion from "helpers/priceToMillion";

export default function TooltipRail ({ activeHandleID, getRailProps, getEventData }) {
	const [value, setValue] = useState(null);
	const [percent, setPercent] = useState(null);
	const [showTooltip, setShowTooltip] = useState(false);
    
	const onMouseEnter = () => {
		setShowTooltip(true);
	};

	const onMouseLeave = () => {
		setShowTooltip(false);
		setValue(null);
		setPercent(null);
		document.removeEventListener("mousemove", onMouseMove);
	};

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove);
		return function cleanup() {
			document.removeEventListener("mousemove", onMouseMove);
		};
	});

	const onMouseMove = e => {
		if (activeHandleID) {
			setValue(null);
			setPercent(null);
		} else {
			const { value, percent } = getEventData(e);
			setValue(Number(value).toFixed(1));
			setPercent(percent);
		}
	};

	return (
		<div>
			{!activeHandleID && value ? (
				<div
					style={{
						left: `${percent}%`,
						position: "absolute",
						marginLeft: "-11px",
						marginTop: "-35px",
					}}
				>
					{showTooltip && <div className="slider-tooltip">
						<span className="slider-tooltip-text rail-tooltip">{priceToMillion(value)}</span>
					</div>}
				</div>
			) : null}
			<div onMouseLeave={onMouseLeave} className='slider-rail' {...getRailProps({ onMouseEnter, onMouseLeave })}/>
			<div className='slider-rail-center' />
		</div>
	);
}

TooltipRail.propTypes = {
	getEventData: PropTypes.func,
	activeHandleID: PropTypes.string,
	getRailProps: PropTypes.func.isRequired,
};

TooltipRail.defaultProps = {
	disabled: false,
};

TooltipRail.displayName = "tr";