import * as types from "redux/constants/leagues";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getPaginationLeaguesStart = payload => ({
	type: types.GET_REQUEST_PAGINATION_LEAGUES_START,
	payload,
});

const getPaginationLeaguesSuccess = payload => ({
	type: types.GET_REQUEST_PAGINATION_LEAGUES_SUCCESS,
	payload,
});

const getPaginationLeaguesError = payload => ({
	type: types.GET_REQUEST_PAGINATION_LEAGUES_ERROR,
	payload,
});

export const actionResetLeagueMembersPaginationList = payload => ({
	type: types.RESET_LEAGUE_MEMBERS_PAGINATION_LIST,
	payload,
});

export const actionGetRequestLeaguesPagination = ({ incomingUrl, filter }) => dispatch => {
	if (incomingUrl) {
		dispatch(getPaginationLeaguesStart());
		const config = {
			params: filter,
			method: "GET",
			url: incomingUrl,
		};

		axiosPlus(config)
			.then(response => {
				const { data } = response;
				dispatch(getPaginationLeaguesSuccess(data));
			})

			.catch((error) => {
				dispatch(getPaginationLeaguesError(errorsParser(error)));
			});
	}
};
