import * as types from "redux/constants/dreamTeams";

import createReducer from "redux/createReducer";

const dreamTeamInitialState = {
	dreamTeamsData: [],
	dreamTeamsRequestError: {},
	dreamTeamsRequestLoading: false,
};

const footballRoundReducer = createReducer(dreamTeamInitialState)({
	[types.REQUEST_GET_DREAM_TEAM_START]: state => ({
		...state,
		dreamTeamsRequestError: {},
		dreamTeamsRequestLoading: true,
		error: false,
	}),
	[types.REQUEST_GET_DREAM_TEAM_SUCCESS]: (state, { payload }) => ({
		...state,
		dreamTeamsData: payload,
		dreamTeamsRequestLoading: false,
	}),
	[types.REQUEST_GET_DREAM_TEAM_ERROR]: (state, { payload }) => ({
		...state,
		dreamTeamsData: [],
		dreamTeamsRequestError: payload,
		dreamTeamsRequestLoading: false,
	}),
});

export default footballRoundReducer;