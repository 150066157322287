import * as types from "redux/constants/seasonRounds";

import createReducer from "redux/createReducer";

const seasonRoundsInitialState = {
	seasonRoundsData: [],
	seasonRoundsRequestError: {},
	seasonRoundsRequestLoading: false,
	seasonRoundsDataUpdateTime: 0,
	finishedRoundsSortedFromNewData: [],
	finishedRoundsSortedFromNewRequestError: {},
	finishedRoundsSortedFromNewRequestLoading: false,
};

const seasonRoundsReducer = createReducer(seasonRoundsInitialState)({
	[types.GET_REQUEST_SEASON_ROUNDS_START]: state => ({
		...state,
		seasonRoundsRequestError: {},
		seasonRoundsRequestLoading: true,
	}),
	[types.GET_REQUEST_SEASON_ROUNDS_SUCCESS]: (state, { payload }) => ({
		...state,
		seasonRoundsData: payload,
		seasonRoundsRequestLoading: false,
		seasonRoundsDataUpdateTime: new Date().getTime()
	}),
	[types.GET_REQUEST_SEASON_ROUNDS_ERROR]: (state, { payload }) => ({
		...state,
		seasonRoundsRequestError: payload,
		seasonRoundsDataUpdateTime: new Date().getTime(),
		seasonRoundsRequestLoading: false,
	}),

	[types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_START]: state => ({
		...state,
		finishedRoundsSortedFromNewRequestError: {},
		finishedRoundsSortedFromNewRequestLoading: true,
	}),
	[types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_SUCCESS]: (state, { payload }) => ({
		...state,
		finishedRoundsSortedFromNewData: payload,
		finishedRoundsSortedFromNewRequestLoading: false,
	}),
	[types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_ERROR]: (state, { payload }) => ({
		...state,
		finishedRoundsSortedFromNewRequestError: payload,
		finishedRoundsSortedFromNewRequestLoading: false,
	}),
});

export default seasonRoundsReducer;