import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Modal } from "reactstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import scrollToComponent from "react-scroll-to-component";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType,
	leaguesSeasonsPropType
} from "utils/propTypes";
import { parsedFieldTeamType, userTeamPropType } from "utils/propTypes/footballTeam";

import { actionGetUserTransfersRequest } from "redux/actions/userTransfers";
import { actionTeamsListRequest } from "redux/actions/teamsList";
import {
	createEditUserTeamPlayersRequestStartAction,
	actionCleanUserTeamError,
	createEditUserTeamRequestErrorAction,
	actionSetIsWildcardRoundRequest,
	actionSetIsWildcardRoundCleanError,
} from "redux/actions/userTeams";
import {
	actionInitTeam,
	actionClearTransfers,
} from "redux/actions/footballField";
import {
	getFieldPlayersTotalLength,
	getTransferPlayersPrice,
	getTransfers, getUserTeamIdsPlayersHashList,
	getNextScheduledRound,
} from "redux/selectors";

import { validateUserTeam } from "helpers/validateUserTeam";
import {
	TEAM_NAME_IS_REQUIRED,
	TEAM_NAME_ONLY_LATIN_OR_DIGITS,
	TEAM_NAME_BAD_WORDS,
	TEAM_NAME_SHOULD_BE_LESS_31
} from "helpers/constants/errors";

import FootballFieldContainer from "modules/footballField/containers/FootballFieldContainer";
import MainButton from "modules/common/MainButton";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorMessage from "modules/common/ErrorMessage";
import PlayersList from "modules/editTeam/components/PlayersList";
import RoundResultsList from "modules/home/containers/RoundResultsList";
import PlayersListHeadStatsNextRound from "modules/editTeam/components/PlayersListHeadStatsNextRound";
import ModalWindow from "modules/common/ModalWindow";
import PlayersTransferItem from "modules/transfersPage/components/PlayersTransferItem";
import ErrorComponent from "modules/common/ErrorComponent";
import ConfirmWildcardModal from "modules/transfersPage/components/ConfirmWildcardModal";
import SuccessComponent from "modules/common/SuccessComponent";

import AdUnit from "modules/common/AdUnit"

const TransfersPageContainer = ({
	userTeamsEditCreateRequestErrors, leagueSeasons, deductionPoints, currentTransfersPrice, teamName,
	fieldTeam, userTeam, isLoading, formationID, transferedPlayers, nextRound, captainID, viceCaptainID,
	currentUserTeamFootballPlayersHashList, transfersLoadedStoreUpdateTime, createEditUserTeamRequestErrorAction,
	createEditUserTeamPlayersRequestStartAction, actionTeamsListRequest, actionGetUserTransfersRequest,
	actionInitTeam, actionClearTransfers, actionCleanUserTeamError, actionSetIsWildcardRoundRequest, setIsWildcardRoundRequestLoading,
	actionSetIsWildcardRoundCleanError, setIsWildcardRoundRequestErrors, userTeamUpdateDataTime }) => {

	const userTeamRemainingBudget = +userTeam.budget;
	
	const transfersUsed = userTeam.transfers_used;

	const { t } = useTranslation("transfers_page");
	const errorsRef = useRef(null);
	const footballFieldTop = useRef();
	const [isConfrimWildcardModalOpen, setIsConfrimWildcardModalOpen] = useState(false);
	const [playerListModalOpenState, setPlayerListModalOpenState] = useState(false);
	const [playerListModalHideState, setPlayerListModalHideState] = useState(false);
	const [showConfirmTransfersModal, setShowConfirmTransfersModal] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [teamNameLocal, setTeamNameLocal] = useState("");

	const [budgetLeft, setBudgetLeft] = useState(userTeamRemainingBudget);

	const [previousErrorsLength, setPreviousErrorsLength] = useState(0);

	const nextRoundDeadline = nextRound ? nextRound.userteam_deadline_without_year : "-";
	const nextRoundNumber = nextRound ? nextRound.number : "-";
	const nextRoundID = nextRound ? nextRound.id : 0;

	const userHasUnlimitedTransfers = userTeam.has_unlimited_transfers;
	const wildCardUsed = userTeam.wildcard_used;
	const showInfinitySign = userHasUnlimitedTransfers;

	const transfersAllowed = userTeam.transfers_allowed;
	const penaltyPoints = userTeam.penalty_points;

	const isWildCardActive = userHasUnlimitedTransfers || userHasUnlimitedTransfers === undefined || wildCardUsed;
	
	const wildCardActiveReason = (
		userHasUnlimitedTransfers && userTeam.has_unlimited_transfers_reason
	) || (
		wildCardUsed && t("Wildcard has already been used this season")
	) || "";

	const isWildCardBtnDisabled = isLoading || isWildCardActive;
	let location = useLocation();

	useEffect(() => {
		actionClearTransfers();
		return () => {
			actionClearTransfers();
		};
	}, []);

	useEffect(() => {
		setShowSuccessMessage(false);
	}, [location.pathname]);


	useEffect(() => {
		teamName && setTeamNameLocal(teamName);
	}, [teamName]);

	useEffect(() => {
		if (userTeam && userTeam.name) {
			actionInitTeam(userTeam);
		}
	}, [userTeam]);

	useEffect(() => {
		actionTeamsListRequest();
		actionCleanUserTeamError();
	}, []);

	useEffect(() => {
		nextRoundID && actionGetUserTransfersRequest(nextRoundID);
	}, [nextRoundID]);

	useEffect(() => {
		if (!isLoading) {
			if (!transferedPlayers.length && showConfirmTransfersModal) {
				setShowConfirmTransfersModal(false);
			}
		}
	}, [transfersUsed, transferedPlayers.length]);


	useEffect(() => {
		setBudgetLeft(userTeamRemainingBudget - currentTransfersPrice);
	}, [currentTransfersPrice, userTeamRemainingBudget]);

	const toggleConfirmTransfersModal = () => {
		setShowConfirmTransfersModal(!showConfirmTransfersModal);
	};

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const scrollToErrors = () => {
		setShowConfirmTransfersModal(false);
		if (errorsRef.current) {
			errorsRef.current.scrollIntoView(false);
			const scrollCorrection = isMobileResolution ? 20 : 30;
			window.scrollTo( 0,window.scrollY + scrollCorrection);
		}
	};

	const handleConfirmTransfers = () => {
		const seasonID = leagueSeasons && leagueSeasons[0] && leagueSeasons[0].id;
		const errors = validateUserTeam({ fieldTeam, teamName: teamNameLocal, withoutCaptainValidate: true, budgetLeft, isTransfersPage: true });

		if (!errors.length && !isLoading) {
			if (transferedPlayers.length && !showConfirmTransfersModal) {
				toggleConfirmTransfersModal();
			} else {
				createEditUserTeamPlayersRequestStartAction({
					newFormationID: formationID,
					newTeam: fieldTeam,
					newCaptainID: captainID,
					newViceCaptainID: viceCaptainID,
					newTeamName: teamNameLocal,
					currentTeam: userTeam,
					seasonID,
					nextRoundID,
					transferedPlayers,
					currentUserTeamFootballPlayersHashList,
					onlyTransfers: true,
					errorCallback: () => {setShowSuccessMessage(false);scrollToErrors();},
					successCallback: () => {setShowSuccessMessage(true);scrollToErrors();},
				});
			}
		} else if (errors.length) {
			createEditUserTeamRequestErrorAction({ parsedTextErrorsWithoutKeys: errors });
		}
	};

	useLayoutEffect(() => {
		if (userTeamsEditCreateRequestErrors && userTeamsEditCreateRequestErrors.length) {
			setPreviousErrorsLength(userTeamsEditCreateRequestErrors.length);
			if (previousErrorsLength <= userTeamsEditCreateRequestErrors.length) {
				scrollToErrors();
			}
		}
	}, [userTeamsEditCreateRequestErrors]);

	const switchPlayersListModal = params => {
		if (typeof params === "string") {
			setPlayerListModalOpenState(params);
		} else {
			setPlayerListModalOpenState(!playerListModalOpenState);
		}
	};

	const switchPlayersListModalHide = () => {
		scrollToComponent(footballFieldTop.current, { offset: 180 });
		setPlayerListModalHideState(!playerListModalHideState);
	};

	const handleNext = () => {
		handleConfirmTransfers();
	};

	const handleUseWildCard = () => {
		if (!isWildCardBtnDisabled) {
			setIsConfrimWildcardModalOpen(true);
		}
	};

	const handleResetTeam = () => {
		if (userTeam) {
			actionInitTeam(userTeam);
		} 
		actionCleanUserTeamError();
		actionClearTransfers();
	};

	const teamNameErrors = [
		TEAM_NAME_IS_REQUIRED,
		TEAM_NAME_BAD_WORDS,
		TEAM_NAME_ONLY_LATIN_OR_DIGITS,
		TEAM_NAME_SHOULD_BE_LESS_31
	];

	const shownTransfersUsed = transfersUsed + transferedPlayers.length;
	const shownTransfersAllowed = transfersAllowed;
	const shownDeduction = transferedPlayers.length ? 
		(shownTransfersUsed > shownTransfersAllowed ? (shownTransfersUsed - shownTransfersAllowed) * deductionPoints : 0)
		: penaltyPoints;

	const transfers = {
		current: shownTransfersUsed, limit: shownTransfersAllowed
	};

	return (
		<div className="position-relative">
			<Container className='select-team-container'>
			  <AdUnit adUnitId="9942977859" />
				<Row>
					{!isMobileResolution &&
						<Col className='col-3 p-r0 live-previous-left d-flex flex-column justify-content-start z-index-5'>
							<PlayersListHeadStatsNextRound
								isTransfersLoaded={!!transfersLoadedStoreUpdateTime && userTeamUpdateDataTime}
								transfers={transfers}
								deduction={shownDeduction}
								budgetLeft={budgetLeft}
								showInfinitySign={showInfinitySign}
							/>
							<PlayersList/>
						</Col>
					}
					<Col className={`${isMobileResolution
						? "col-12 p-0"
						: "col"} d-flex flex-column align-items-center`}>
						<Row
							className={`select-team-top-controls ${wildCardActiveReason && "with-wildcard-reason"}`}>
							<SpinnerButton
								disabled={isWildCardBtnDisabled}
								text={t("Wildcard")}
								isLoading={false}
								onClick={handleUseWildCard}
								size="medium"
								color="white"
								className={`select-team-button double ${isWildCardBtnDisabled ? "activated-wildcard button_disabled" : ""}`}
							/>
							{wildCardActiveReason ?  
								<span className="wildcard-reason">
									{wildCardActiveReason}
								</span> : null }
							<span className='d-flex flex-column align-items-center justify-content-center'>
								<span
									className='deadline-text source-sans-pro color-secondary-text font-size-13'>
									{t("Deadline", { round: nextRoundNumber })}
								</span>
								<span className='mr-2 ml-2 bebas-neue font-size-36 color-black text-nowrap deadline-text-date'>
									{nextRoundDeadline}
								</span>
							</span>
							<MainButton
								handler={handleResetTeam}
								text={t("Reset Team")}
								size="medium"
								color="white"
								className="select-team-button double"
							/>
						</Row>
						{isMobileResolution &&
								<PlayersListHeadStatsNextRound
									ref={footballFieldTop}
									isTransfersLoaded={!!transfersLoadedStoreUpdateTime && userTeamUpdateDataTime}
									transfers={transfers}
									deduction={shownDeduction}
									budgetLeft={budgetLeft}
									showInfinitySign={showInfinitySign}
								/>
						}
						<FootballFieldContainer switchPlayersListModal={switchPlayersListModal} isNextRoundPage selectedRoundId={nextRoundID} dropCallback={switchPlayersListModal} dynamicField withoutDragAndWithDeletable withoutCaptainIcon withoutFieldTopControls/>
						<Row className='select-team-bottom-controls container'>
							<Col xs={12} md={6} className="d-flex flex-column">
								<Row className='team-name-text'>
									{!!userTeam && userTeam.name}
								</Row>
								{userTeamsEditCreateRequestErrors.filter((el => teamNameErrors.some(teamNameError => teamNameError === el))).map((el, i) => (
									<ErrorMessage
										key={i}
										fieldName={el}
										errors={userTeamsEditCreateRequestErrors}
										className="text-center"
									/>))
								}
							</Col>
							<Col xs={12} md={6} className='select-team-bottom-controls-buttons d-flex flex-columns'>
								<SpinnerButton
									isLoading={isLoading}
									text={t("Confirm transfers")}
									onClick={handleNext}
									className="mw-auto text-uppercase single"
									size="large"
									disabled={!transferedPlayers.length}
								/>
							</Col>
						</Row>
						<Row className="errors-box w-100">
							<span ref={errorsRef} className='w-100 d-flex flex-column align-items-center'>
								{showSuccessMessage && (
									<SuccessComponent
										handleDelete={() => {
											setShowSuccessMessage(false);
										}}
										className="team-error-wrapper"
										text={t("Transfers are successfully completed")}
									/>
								)}
								{userTeamsEditCreateRequestErrors.map((message, i) =>
									<Row
										key={i}
										className="team-error-wrapper"
									>
										<ErrorComponent
											handleDelete={actionCleanUserTeamError}
											text={message}
										/>
									</Row>
								)}
							</span>
						</Row>
					</Col>
				</Row>
			</Container>
			<RoundResultsList onlyFutureRounds
				translation={{
					key: "round_results_title",
					ns: "transfers_page",
				}}
			/>
			<ModalWindow 
				toggle={toggleConfirmTransfersModal} 
				isOpen={showConfirmTransfersModal} 
				modalClassName="confirm-modal" contentClassName="confirm-modal-content"
			>
				<div className='transfers-modal-container'>
					<div className='font-h3 text-uppercase'>{t("confirm transfers")}</div>
					<PlayersListHeadStatsNextRound
						isTransfersLoaded={true}
						isModal
						ref={footballFieldTop}
						transfers={transfers}
						deduction={shownDeduction}
						budgetLeft={budgetLeft}
						showInfinitySign={showInfinitySign}
						classNames="margin-top-20px"
					/>
					<div className='d-flex w-100 mt-3 flex-column align-items-center margin-top-20px'>
						<div className='transfers-modal-header-yellow'>
							<div className='transfers-modal-subheader-yellow '>
								{t("Transfers Out")}
							</div>
							<div className='transfers-modal-subheader-yellow'>
								{t("Transfers In")} 
							</div>
						</div>
						<div className='transfers-modal-players-section margin-top-20px'>
							{transferedPlayers.map((transfer, index) => (
								<div key={`transfer-${index}`} className='transfers-modal-players-column'>
									<PlayersTransferItem data={transfer.transferedOut} />
									<PlayersTransferItem data={transfer.transferedIn} isTransferIn />
								</div>
							))}
						</div>
						<SpinnerButton
							isLoading={isLoading}
							text={t("confirm")}
							onClick={handleConfirmTransfers}
							size="medium"
							className="text-uppercase"
						/>
					</div>
				</div>
			</ModalWindow>
			<Modal
				fade={false}
				modalClassName={`${playerListModalHideState ? "hidden-modal" : "modal-warning"}`}
				backdropClassName={`${playerListModalHideState ? "hidden-modal" : ""}`}
				className={`${playerListModalHideState ? "hidden-modal" : ""} modal-container-players-list`}
				contentClassName="modal-wrapper-players-list"
				isOpen={!!playerListModalOpenState}
				toggle={switchPlayersListModal}>
				<PlayersList
					openWithTab={playerListModalOpenState}
					switchPlayersListModalHide={switchPlayersListModalHide} 
					isMobileResolution={isMobileResolution} 
					setPlayerListModalHideState={setPlayerListModalHideState} 
					setPlayerListModalOpenState={setPlayerListModalOpenState} 
				/>
			</Modal>
			<ConfirmWildcardModal 
				isOpen={isConfrimWildcardModalOpen}
				handleToggle={() => { setIsConfrimWildcardModalOpen(false); }}
				isLoading={setIsWildcardRoundRequestLoading}
				errors={setIsWildcardRoundRequestErrors?.parsedTextErrorsWithoutKeys}
				cleanErrors={actionSetIsWildcardRoundCleanError}
				callback={() => { 
					actionSetIsWildcardRoundRequest(() => {
						setIsConfrimWildcardModalOpen(false);
					});
				}}
			/>
		</div>
	);
};

TransfersPageContainer.propTypes = {
	currentUserEmail: PropTypes.string
};

const mapStateToProps = (state) => {
	const {
		fieldTeam,
		formation: { id: formationID },
		captainID,
		viceCaptainID,
	} = state.footballFieldReducer;
	const {
		userTeamsData,
		createUserTeamLoading,
		userTeamPlayersRequestLoading,
		setIsWildcardRoundRequestLoading,
		setIsWildcardRoundRequestErrors,
		userTeamsEditCreateRequestErrors,
		userTeamUpdateDataTime,
	} = state.userTeamsReducer;
	const userTeam = userTeamsData[0] || {};
	const currentUserTeamFootballPlayersHashList = getUserTeamIdsPlayersHashList(state);

	const isLoading = createUserTeamLoading || userTeamPlayersRequestLoading;
	const { name: teamName } = userTeam;

	const transferedPlayers = getTransfers(state);

	return {
		fieldTeamLength: getFieldPlayersTotalLength(state),
		currentTransfersPrice: getTransferPlayersPrice(state),
		nextRound:  getNextScheduledRound(state),
		leagueSeasons: state.leagueSeasonsReducer.seasonsListData,
		teamName,
		userTeamsEditCreateRequestErrors,
		fieldTeam,
		userTeam,
		isLoading,
		formationID,
		captainID,
		viceCaptainID,
		deductionPoints: state.settingsReducer.deductionPoints,
		currentUserTeamFootballPlayersHashList,
		transferedPlayers,
		setIsWildcardRoundRequestLoading,
		setIsWildcardRoundRequestErrors,
		userTeamUpdateDataTime,
		transfersLoadedStoreUpdateTime : state.userTransfersReducer.transfersLoadedStoreUpdateTime,
	};
};

export default connect(
	mapStateToProps,
	{
		actionClearTransfers,
		actionInitTeam,
		createEditUserTeamPlayersRequestStartAction,
		createEditUserTeamRequestErrorAction,
		actionTeamsListRequest,
		actionGetUserTransfersRequest,
		actionCleanUserTeamError,
		actionSetIsWildcardRoundRequest,
		actionSetIsWildcardRoundCleanError,
	}
)(TransfersPageContainer);



TransfersPageContainer.propTypes = {
	errorsKeysMap: PropTypes.object.isRequired,
	userTeamsEditCreateRequestErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
	setIsWildcardRoundRequestErrors: PropTypes.object.isRequired,
	transferedPlayers: PropTypes.array.isRequired,
	leagueSeasons: PropTypes.arrayOf(PropTypes.shape(leaguesSeasonsPropType.leagueSeasons)).isRequired,
	createEditUserTeamPlayersRequestStartAction: PropTypes.func.isRequired,
	createEditUserTeamRequestErrorAction: PropTypes.func.isRequired,
	actionCleanUserTeamError: PropTypes.func.isRequired,
	deductionPoints: PropTypes.number.isRequired,
	fieldTeamLength: PropTypes.number.isRequired,
	currentTransfersPrice: PropTypes.number.isRequired,
	userTeamUpdateDataTime: PropTypes.number.isRequired,
	currentUserTeamFootballPlayersHashList: PropTypes.object.isRequired,
	nextRound: PropTypes.shape(footballRoundPropType.round).isRequired,
	teamName: PropTypes.string,
	captainID: PropTypes.number.isRequired,
	viceCaptainID: PropTypes.number.isRequired,
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	userTeam: PropTypes.oneOfType([PropTypes.object, userTeamPropType]),
	isLoading: PropTypes.bool.isRequired,
	transfersLoadedStoreUpdateTime: PropTypes.number,
	setIsWildcardRoundRequestLoading: PropTypes.bool.isRequired,
	formationID: PropTypes.number,
	actionTeamsListRequest: PropTypes.func.isRequired,
	actionInitTeam: PropTypes.func.isRequired,
	actionClearTransfers: PropTypes.func.isRequired,
	actionGetUserTransfersRequest: PropTypes.func.isRequired,
	actionSetIsWildcardRoundRequest: PropTypes.func.isRequired,
	actionSetIsWildcardRoundCleanError: PropTypes.func.isRequired,
};

TransfersPageContainer.defaultProps = {
	teamName: "",
	formationID: 0,
};

TransfersPageContainer.displayName = "tpcon";