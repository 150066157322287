import React, { useState } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	actionSwitchLoginModalOpenState,
	actionPostRequestLogin,
	actionSwitchForgotPasswordModalOpenState,
	actionClearUserLoginError,
} from "redux/actions/auth";

import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorComponent from "modules/common/ErrorComponent";

const SignInModal = (props) => {
	const {
		loginModalOpenState,
		userLoginError,
		userLoginRequestLoading,
		actionSwitchLoginModalOpenState,
		actionSwitchForgotPasswordModalOpenState,
		actionPostRequestLogin,
		actionClearUserLoginError,
	} = props;

	const { t } = useTranslation("sign_in_modal");

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const history = useHistory();
	const handleLoginStart = () => {
		actionPostRequestLogin({ email, password, history });
	};

	return (
		<ModalWindow toggle={actionSwitchLoginModalOpenState} isOpen={loginModalOpenState}>
			<div className='fantasy-modal-title text-uppercase'>{t("sign in")}</div>
			{userLoginError && userLoginError.requestError ?
				<div className="fantasy-modal-error-msg">
					<ErrorComponent
						handleDelete={actionClearUserLoginError}
						text={t("Email or Password is invalid")}
						isTextCentered
					/>
				</div>
				: null}
			<input
				placeholder={t("Email")}
				className={`fantasy-modal-input ${userLoginError.email && "error"}`}
				type="email" value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			{userLoginError.email && <div className='fantasy-modal-error'>{userLoginError.email}</div>}
			<input
				placeholder={t("Password")}
				className={`fantasy-modal-input ${userLoginError.password && "error"}`}
				type="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)} />
			{userLoginError.password && <div className='fantasy-modal-error'>{userLoginError.password}</div>}
			<SpinnerButton
				isLoading={userLoginRequestLoading}
				text={t("sign in")}
				onClick={handleLoginStart}
				className="col-4 text-uppercase"
				size="medium"
			/>
			<div onClick={actionSwitchForgotPasswordModalOpenState} className='modal-bottom-link'>
				{t("Forgot your password ?")}
			</div>
		</ModalWindow>
	);
};

SignInModal.propTypes = {
	loginModalOpenState: propTypes.bool.isRequired,
	userLoginError: propTypes.object.isRequired,
	userLoginRequestLoading: propTypes.bool.isRequired,
	actionSwitchLoginModalOpenState: propTypes.func.isRequired,
	actionPostRequestLogin: propTypes.func.isRequired,
	actionSwitchForgotPasswordModalOpenState: propTypes.func.isRequired,
	actionClearUserLoginError: propTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const { loginModalOpenState, userLoginRequestLoading, userLoginError } = authReducer;
	return {
		loginModalOpenState, userLoginRequestLoading, userLoginError
	};
};

export default connect(
	mapStateToProps,
	{ actionSwitchLoginModalOpenState, actionPostRequestLogin, actionSwitchForgotPasswordModalOpenState, actionClearUserLoginError },
)(SignInModal);

SignInModal.displayName = "sim";