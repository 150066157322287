export const POST_REQUEST_CREATE_USER_START = "POST_REQUEST_CREATE_USER_START";
export const POST_REQUEST_CREATE_USER_SUCCESS = "POST_REQUEST_CREATE_USER_SUCCESS";
export const POST_REQUEST_CREATE_USER_ERROR = "POST_REQUEST_CREATE_USER_ERROR";

export const POST_REQUEST_ACTIVATE_ACCOUNT_START = "POST_REQUEST_ACTIVATE_ACCOUNT_START";
export const POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS = "POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS";
export const POST_REQUEST_ACTIVATE_ACCOUNT_ERROR = "POST_REQUEST_ACTIVATE_ACCOUNT_ERROR";

export const POST_REQUEST_RESEND_EMAIL_VERIFICATION_START = "POST_REQUEST_RESEND_EMAIL_VERIFICATION_START";
export const POST_REQUEST_RESEND_EMAIL_VERIFICATION_SUCCESS = "POST_REQUEST_RESEND_EMAIL_VERIFICATION_SUCCESS";
export const POST_REQUEST_RESEND_EMAIL_VERIFICATION_ERROR = "POST_REQUEST_RESEND_EMAIL_VERIFICATION_ERROR";
export const RESET_EMAIL_VERIFICATION_RESULT = "RESET_EMAIL_VERIFICATION_RESULT";
