import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import appBadgeAppStore from "static/assets/images/app-badge-app-store-small.svg";
import appBadgeGooglePlay from "static/assets/images/app-badge-google-play-small.svg";
import close from "static/assets/images/white-close.svg";

const Announcement = () => {
    const { t } = useTranslation("header");

    const [visibility, setVisibility] = useState(true);

    const onClick = () => {
       setVisibility(false);
    }

    if (visibility) {
        return (
            <div className="announcement-wrapper">
                <span className="announcement-text">
                    {t("Announcement")}
                </span>
                <div className="announcement-badge-wrapper">
                    <Link to={frontendUrls.urlDownloadTheApp}>
                      <img className="app-badge-small" src={appBadgeAppStore} alt=""/>
                    </Link>
                    <Link to={frontendUrls.urlDownloadTheApp}>
                      <img className="app-badge-small" src={appBadgeGooglePlay} alt=""/>
                    </Link>
                </div>
                <img className="announcement-close" onClick={onClick}  src={close} alt=""/>
            </div>
        )
    }

    return null;
}

export default Announcement;