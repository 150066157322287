import { all } from "redux-saga/effects";

import selectTeamSaga from "./userTeams";
import selectOtherUserResultsSaga from "./otherUserResults";

export default function* rootSaga() {
	yield all([
		selectTeamSaga(),
		selectOtherUserResultsSaga(),
	]);
}