import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	Row,
	Col,
} from "reactstrap";

import { actionGetOtherUserResultsRequest } from "redux/actions/otherUserResults";

import LeaguesList from "modules/leagues/components/LeaguesList";
import RoundStatistic from "modules/common/RoundStatistic";
import TotalStatistic from "modules/common/TotalStatistic";
import Social from "modules/news/components/Social";

const Boards = ({
	currentUserId,
	otherUserResultsHashList,
	actionGetOtherUserResultsRequest,
}) => {
	useEffect(() => {
		if (currentUserId && otherUserResultsHashList && !otherUserResultsHashList[currentUserId]) {
			actionGetOtherUserResultsRequest({
				user: currentUserId,
			});
		}
	}, [currentUserId]);

	const { 
		total_points: totalPoints, 
		transfers_count: usedTransfers, 
		team_price: teamPrice,
		average_points: averagePoints,
	} = otherUserResultsHashList[currentUserId] || {};

	return(
        <Row className="boards-row global-row">
             <Col>
                <TotalStatistic
                    transfersAmount={usedTransfers}
                    teamPrice={teamPrice}
                    totalPoints={totalPoints}
                    averagePoints={averagePoints}
                    isLoading={!otherUserResultsHashList[currentUserId]}
                />
                <RoundStatistic lastRoundOnly />
            </Col>
            <Col>
                <LeaguesList typeLeagues="common" />
                <LeaguesList typeLeagues="private" />
            </Col>
        </Row>
	);
};

const mapStateToProps = (state) => {
	const { userDetails : { id: currentUserId } } = state.authReducer;
	const { otherUserResultsHashList = {} } = state.otherUserResultsReducer;

	return {
		currentUserId,
		otherUserResultsHashList,
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetOtherUserResultsRequest
	}
)(Boards);

Boards.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	otherUserResultsHashList: PropTypes.object.isRequired,
	actionGetOtherUserResultsRequest: PropTypes.func.isRequired,
};

Boards.displayName = "nar";