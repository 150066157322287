import React, { useState, useEffect } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import arrowUp from "static/assets/images/dropdown-arrow-white.svg";
import logoLarge from "static/assets/images/logo-large.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import facebookIcon from "static/assets/images/social-fb-black.svg";
import instagramIcon from "static/assets/images/social-ig-black.svg";
import twitterIcon from "static/assets/images/social-x-black.svg";
import linkedinIcon from "static/assets/images/social-in-black.svg";
import { useTranslation } from "react-i18next";

import backendApiUrls from "routes/backendUrls";

import { validateEmail } from "helpers/common";
import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

import ModalWindow from "modules/common/ModalWindow";
import MainButton from "modules/common/MainButton";
import ErrorMessage from "modules/common/ErrorMessage";
import Title from "modules/common/Title";
import Social from "modules/common/Social";
import SuccessComponent from "modules/common/SuccessComponent";


const CONTACT_REASONS_MAP = {
	"Bug": "bug",
	"Functionality change recommendation": "functionality-change-recommendation",
	"New functionality recommendation": "new-functionality-recommendation",
	"Incorrect match entry": "incorrect-match-entry",
	"Other": "other"
};

const ContactUs = ({
	currentUserEmail, 
	currentUserFirstName,
	currentUserLastName,
}) => {
	const  { t } = useTranslation("contact_us");
	const [userMessage, setUserMessage] = useState("");
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const [firstName, setFirstName] = useState(currentUserFirstName || "");
	const [lastName, setLastName] = useState(currentUserLastName || "");
	const [email, setEmail] = useState(currentUserEmail || "");
	const [reasonValue, setReasonValue] = useState("");

	const [localErrors, setLocalErrors] = useState({});
	const [requestErrors, setRequestErrors] = useState({});
	const [modalSuccessState, setModalSuccessState] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(true);

	const toggle = () => setDropdownOpen(prevState => !prevState);

	useEffect(() => {
		setEmail(currentUserEmail);
	}, [currentUserEmail]);

	useEffect(() => {
		setFirstName(currentUserFirstName);
	}, [currentUserFirstName]);

	useEffect(() => {
		setLastName(currentUserLastName);
	}, [currentUserLastName]);

	const changeFirstName = (e) => {
		const { target: { value } = {} } = e;
		setFirstName(value);
		if (value) {
			delete errors.first_name;
			setLocalErrors(errors);
		}
	};

	const changeLastName = (e) => {
		const { target: { value } = {} } = e;
		setLastName(value);
		if (value) {
			delete localErrors.last_name;
			setLocalErrors(localErrors);
		}
	};

	const changeEmail = (e) => {
		const { target: { value } = {} } = e;
		setEmail(value);
		if (value) {
			delete localErrors.email;
			setLocalErrors(localErrors);
		}
	};

	const handleSubmit = () => {
		const checkErrors = {};
		if (!validateEmail(email)) {
			checkErrors.email = t("This field is required");
		}

		if (!firstName) {
			checkErrors.first_name = t("This field is required");
		}

		if (!lastName) {
			checkErrors.last_name = t("This field is required");
		}

		setLocalErrors(checkErrors);

		if (Object.keys(checkErrors).length === 0) {
			const requestData = {
				method: "POST",
				url: backendApiUrls.contactUs,
				data: {
					subject: CONTACT_REASONS_MAP[reasonValue],
					text: userMessage,
					first_name: firstName,
					last_name: lastName,
					email
				}
			};
			axiosPlus(requestData).then(() => {
				setShowSuccessMessage(true);
				setModalSuccessState(true);
				setFirstName(currentUserFirstName || "");
				setLastName(currentUserLastName || "");
				setEmail(currentUserEmail || "");
				setReasonValue("");
				setDropdownOpen(false);
				setUserMessage("");
				setRequestErrors({});
			}).catch((error) => {
				setRequestErrors(errorsParser(error));
			});
		}
	};

	const errors = { ...requestErrors, ...localErrors };
	
	return (
		<Container className="contact-us contact-us__container">
			<div className="contact-us__form">
				<Title translation={{
					ns: "contact_us",
					key: "contact_us"
				}} className="margin-bottom-20-40px"/>
				<Row className="m-0 d-flex justify-content-center contact-us__blue">
					<Row className="d-flex justify-content-between w-100">
						<Col md={6} className="contact-us__input-wrapper contact-us__input-wrapper_left-md">
							<input
								disabled={currentUserFirstName}
								value={currentUserFirstName|| firstName}
								onChange={changeFirstName}
								placeholder={t("First Name")}
								className={`contact-us__input${errors.first_name ? " contact-us__input_error" : ""}`}
								type="text"/>
							<ErrorMessage fieldName="first_name" errors={errors} />
						</Col>
						<Col md={6} className="contact-us__input-wrapper contact-us__input-wrapper_right-md">
							<input 
								disabled={currentUserLastName}
								value={currentUserLastName|| lastName}
								onChange={changeLastName}
								placeholder={t("Last Name")}
								className={`contact-us__input${errors.last_name ? " contact-us__input_error" : ""}`}
								type="text"/>
							<ErrorMessage fieldName="last_name" errors={errors} />
						</Col>
						<Col md={6} className="contact-us__input-wrapper contact-us__input-wrapper_left-md">
							<input
								disabled={currentUserEmail}
								value={currentUserEmail || email}
								onChange={changeEmail}
								placeholder={t("Email")}
								className={`contact-us__input${errors.email ? " contact-us__input_error" : ""}`}
								id="contact-form"
								type="email"/>
							<ErrorMessage fieldName="email" errors={errors} />
						</Col>
						<Col md={6} className="contact-us__input-wrapper contact-us__input-wrapper_right-md">
							<Dropdown isOpen={dropdownOpen} toggle={toggle}>
								<DropdownToggle
									className="position-relative d-flex align-items-center"
									tag="span"
									data-toggle="dropdown"
									aria-expanded={dropdownOpen}
								>
									<span
										className={`contact-us__selected-item 
												${dropdownOpen && "isOpen"}
												${errors.subject ? "select-error" : ""}`}>
										<span className={`contact-us__selected-text source-sans-pro ${!reasonValue && "default-value"}`}>
											{reasonValue || t("What's this about")}
										</span>
										<img src={arrowUp} className="cursor-pointer dropdown-up" alt=""/>
									</span>
								</DropdownToggle>
								<DropdownMenu className="border-white-2 drop-item">
									{Object.keys(CONTACT_REASONS_MAP).map((reason, index) => (
										<DropdownItem
											className="pl-2 bg-fill-blue w-100"
											key={`reason${index}-${reason}`}
											onClick={() => setReasonValue(reason)}
										>
											{t(reason)}
										</DropdownItem>
									))}
								</DropdownMenu>
							</Dropdown>
							{errors.subject &&
										<span className="field-error">{errors.subject}</span>
							}
						</Col>
					</Row>
					<Row className="contact-us__message-block">
						{!userMessage &&
									<span className="grey-sans font-15-20 position-absolute">
										{t("Message")}
									</span>}
						<Input
							value={userMessage}
							onChange={e => setUserMessage(e.target.value)}
							className="m-0 w-100 h-100 bg-transparent contact-us__textfield"
							type="textarea"
							name="contact-us"
							id="contact-us" />
						<ErrorMessage fieldName="text" errors={errors} />
					</Row>
					<MainButton handler={handleSubmit} text={t("Send")} color="white" size="medium" className="single mt-4" />
				</Row>
			</div>
			<div className="contact-us__info">
				<Title translation={{
					ns: "contact_us",
					key: "contact_details"
				}}
				className="margin-bottom-20-40px w-md-75"/>
				<Col className="info-block d-flex flex-column justify-content-between w-100">
					<span className="poppins font-size-responsive-24 fw-600">
						{t("Company")}
					</span>
					<span className='poppins font-size-responsive-18 fw-600 mt-2'>
						{t("For marketing/sponsorship")}:
					</span>
					<span className='poppins font-weight-light font-size-responsive-18 mt-3'>
						admin@serieafantasy.com
					</span>
					<div className="d-flex mt-3 mt-md-4">
						<Social icon={facebookIcon} url="https://www.facebook.com/serieafantasyte" />
						<Social icon={instagramIcon} url="https://www.instagram.com/serieafantasyte" />
						<Social icon={twitterIcon} url="https://twitter.com/serieafantasyte" />
						<Social icon={linkedinIcon} url="https://www.linkedin.com/company/serieafantasyte" />
					</div>
				</Col>
				<img src={logoLarge} alt="logo" className="contact-us__logo" />
			</div>
			<ModalWindow toggle={() => setModalSuccessState(false)} isOpen={modalSuccessState} contentClassName={"width-340px"}>
				<div className='d-flex align-items-center flex-column'>
					<div className='fantasy-modal-title'>{t("Contact us")}</div>
					{showSuccessMessage && <SuccessComponent
						handleDelete={() => {
							setShowSuccessMessage(false);
						}}
						text={t("Email has been successfully sent")}
					/>}
					<div className='fantasy-modal-subtitle m-0'>
						{t("Thank you for contacting us")}!
						<br />
						{t("We will get in touch soon")}
					</div>
				</div>
			</ModalWindow>
		</Container>
	);
};

ContactUs.propTypes = {
	currentUserEmail: PropTypes.string,
	currentUserFirstName: PropTypes.string,
	currentUserLastName: PropTypes.string,
};

ContactUs.defaultProps = {
	currentUserEmail: "",
	currentUserFirstName: "",
	currentUserLastName: "",
};

const mapStateToProps = ({ authReducer }) => {
	const { email, userDetails, userLastName } = authReducer;
	return {
		currentUserEmail: email,
		currentUserFirstName: userDetails.first_name,
		currentUserLastName: userLastName,
	};
};

export default connect(
	mapStateToProps,
	{}
)(ContactUs);

ContactUs.displayName = "cu";