import { useEffect, useRef } from "react";


function useEffectSkipFirst(fn, depValue) {
	const isFirstRun = useRef(true);
	useEffect(() => {
		!isFirstRun.current && fn();
		if (isFirstRun.current) {
			isFirstRun.current = false;
		}
	}, [...depValue]);
}

export default useEffectSkipFirst;