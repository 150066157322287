import React from "react";
import PropTypes from "prop-types";
import SelectCaptain from "static/assets/images/select-captain.svg";
import SelectViceCaptain from "static/assets/images/select-vice-captain.svg";

import Formation from "modules/footballField/components/Formation";

const FieldTeamTop = ({ formationType, setIsCaptainMarkerSelected, setIsViceCaptainMarkerSelected }) => (
	<div className='football-field-top'>
		<Formation formationType={formationType}/>
		<div className='position-relative captain-wrapper'>
			<img
				onClick={() => {
					setIsCaptainMarkerSelected((prev) => !prev);
				}}
				className='cursor-pointer' src={SelectCaptain} alt=""/>
			<img
				onClick={() => {
					setIsViceCaptainMarkerSelected((prev) => !prev);
				}}
				className='cursor-pointer vice-captain-img' src={SelectViceCaptain} alt=""/>
		</div>
	</div>
);

FieldTeamTop.propTypes = {
	formationType: PropTypes.arrayOf(PropTypes.number).isRequired,
	setIsCaptainMarkerSelected: PropTypes.func.isRequired,
	setIsViceCaptainMarkerSelected: PropTypes.func.isRequired,
};

export default FieldTeamTop;

FieldTeamTop.displayName = "ftt";