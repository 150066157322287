export const GET_NEWS_PAGINATION_START = "GET_NEWS_PAGINATION_START";
export const GET_NEWS_PAGINATION_SUCCESS = "GET_NEWS_PAGINATION_SUCCESS";
export const GET_NEWS_PAGINATION_ERROR = "GET_NEWS_PAGINATION_ERROR";

export const GET_COUNTERS_START = "GET_COUNTERS_START";
export const GET_COUNTERS_SUCCESS = "GET_COUNTERS_SUCCESS";
export const GET_COUNTERS_ERROR = "GET_COUNTERS_ERROR";

export const GET_SELECTED_NEWS_ITEM_START = "GET_SELECTED_NEWS_ITEM_START";
export const GET_SELECTED_NEWS_ITEM_SUCCESS = "GET_SELECTED_NEWS_ITEM_SUCCESS";
export const GET_SELECTED_NEWS_ITEM_ERROR = "GET_NEWS_PAGINATION_ERROR";