import React, { useState } from "react";
import propTypes from "prop-types";
import {
	Container, Row,
} from "reactstrap";
import dropdownIcon from "static/assets/images/dropdown-closed.svg";
import { Trans, useTranslation } from "react-i18next";

import Progress from "modules/registration/components/Progress";
import MainButton from "modules/common/MainButton";
import SuccessComponent from "modules/common/SuccessComponent";

const ConfirmEmail = ({
	handleSendEmail,
	resendEmailRequestError,
	resendEmailRequestSuccess,
	handleCloseSuccessMessage,
	userEmail,
}) => {
	const { t } = useTranslation("confirm_email");
	const [haventReceivedOpened, setHaventReceivedOpened] = useState(false);

	const switchArrow = () => {
		setHaventReceivedOpened(!haventReceivedOpened);
	};
	return (
		<>
			<Progress step={3} />
			<Container className="confirm-email_mt confirm-email_container">
				{resendEmailRequestSuccess ? 
					<SuccessComponent
						handleDelete={() => {
							setHaventReceivedOpened(false);
							handleCloseSuccessMessage();
						}}
						text={t("Email has been successfully sent")}
					/>
					: null}
				<h6 className="confirm-email__text">
					<Trans
						i18nKey="check_email"
						ns="confirm_email"
						components={[
							<span key="0" className='color-accent1 text-lowercase' />
						]}
						values={{
							email: userEmail,
						}}
					/>
				</h6>
				{!resendEmailRequestSuccess &&
					<Row className='justify-content-center margin-top-75px color-accent1 cursor-pointer'>
						<h6 onClick={switchArrow} className="confirm-email__text mb-0">{t("Havent received email ?")}</h6>
						<img onClick={switchArrow} className={`confirm-dropdown-img ${haventReceivedOpened ? "up" : "down"}`} src={ dropdownIcon } alt="" />
					</Row>
				}
				{!resendEmailRequestSuccess && haventReceivedOpened && (
					<div className="confirm-email__buttons confirm-email__buttons_mt flex-column align-items-center margin-top-40px">
						<h6 className="confirm-email__text">
							{t("Check your spam folder or click below to resend verification email")}
						</h6>
						<MainButton className="mt-3 single" text={t("resend email")} size="medium" handler={handleSendEmail} />
					</div>
				)}
				<div className="d-flex justify-content-center mt-3">
					{resendEmailRequestError.requestError && resendEmailRequestError.parsedTextErrors.map(error => (
						<p key={error} className="font-m1 text-danger container mt-2 text-center">{error}</p>
					))}
				</div>
			</Container>
		</>
	);
};

ConfirmEmail.propTypes = {
	userEmail: propTypes.string.isRequired,
	handleSendEmail: propTypes.func.isRequired,
	handleCloseSuccessMessage: propTypes.func.isRequired,
	resendEmailRequestError: propTypes.object.isRequired,
	resendEmailRequestSuccess: propTypes.bool.isRequired,
};

ConfirmEmail.defaultProps = {
	serverError: {},
};

export default ConfirmEmail;

ConfirmEmail.displayName = "ce";