import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
	footballRoundPropType,
	roundGamePropType,
} from "utils/propTypes";

import {
	ROUND_ID,
} from "routes/backendFilters";

import { actionGetRequestRoundGames } from "redux/actions/roundGames";
import {
	getNecessaryRoundGames,
} from "redux/selectors";

import RoundItemComponent from "modules/scheduleAndResults/components/RoundItem";

const RoundItem = ({
	isLoading,
	roundGames,
	dispatch,
	round,
}) => {
	const isRunning = round.status === "live";
	useEffect(() => {
		round && isRunning && dispatch(actionGetRequestRoundGames(ROUND_ID, round.id));
	}, [round]);

	const [ isOpenDropdown, setIsOpenDropdown ] = useState(isRunning);
	const handleToggleDropdown = () => {
		setIsOpenDropdown(!isOpenDropdown);
		!isOpenDropdown && dispatch(actionGetRequestRoundGames(ROUND_ID, round.id));
	};

	const sendProps = {
		roundGames,
		round,
		isLoading,
		isOpenDropdown,
		handleToggleDropdown,
	};

	return (
		<RoundItemComponent {...sendProps} />
	);
};

RoundItem.propTypes = {
	round: PropTypes.shape(footballRoundPropType.resultGame).isRequired,
	roundGames: PropTypes.arrayOf(PropTypes.shape(roundGamePropType.resultGame)).isRequired,
	isLoading: PropTypes.bool.isRequired,
	dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	roundGames: getNecessaryRoundGames(state) || [],
	isLoading: state.roundGamesReducer.roundGamesRequestLoading,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RoundItem);

RoundItem.displayName = "ri";