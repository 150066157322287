import React from "react";
import { Container } from "reactstrap";

import Title from "modules/common/Title";
import Tabs from "modules/prizes/components/Tabs"

import AdUnit from "modules/common/AdUnit"

const Prizes = () => {
	return (
    <Container className="prizes global-container">
      <AdUnit adUnitId="5785246586" />
      <Title translation={{
          ns: "prizes",
          key: "Prizes",
      }}  />
      <Tabs />
    </Container>
	);
};

export default Prizes;

Prizes.displayName = "prz";