import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import { actionGetCounters } from 'redux/actions/news';

const Counters = ({
	seasonUsersQuantity,
	countryLeaguesQuantity,
	privateLeaguesQuantity,
	actionGetCounters,
	isLoading,
}) => {
	const { t } = useTranslation("numbers");
    const quantities = {
		"Country Leagues": countryLeaguesQuantity,
		"Private Leagues": privateLeaguesQuantity,
		"Season users": seasonUsersQuantity,
	};

	useEffect(() => {
		actionGetCounters();
	}, []);

	return (
        <Row className="counters-wrapper-row global-row">
            <Col>
                <div className="counters-wrapper">
                    <Row className="counters-row">
                        {Object.keys(quantities).map((quantityKey, quantityIndex) => (
                            <Col xs={12} md={6}>
                                <div className="counters-item" key={quantityKey}>
                                    <span className="number">
                                        {[...quantities[quantityKey].toString()].map((digit, i) => (
                                            <span key={i} className="digit">{digit}</span>
                                        ))}
                                    </span>
                                    <span className="font-h4new">
                                        {t(quantityKey)}
                                    </span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Col>
        </Row>
	);
};

Counters.propTypes = {
	seasonUsersQuantity: PropTypes.number.isRequired,
	countryLeaguesQuantity: PropTypes.number.isRequired,
	privateLeaguesQuantity: PropTypes.number.isRequired,
	actionGetCounters: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	const { newsReducer } = state;
	const { countersData, countersRequestLoading } = newsReducer;
	return {
		seasonUsersQuantity: countersData?.season_participants_count || 0,
		countryLeaguesQuantity: countersData?.country_leagues_count || 0,
		privateLeaguesQuantity: countersData?.private_leagues_count || 0,
		isLoading: countersRequestLoading,
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetCounters,
	}
)(Counters);

Counters.displayName = "counters";