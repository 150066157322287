import i18instance from "i18instance";

import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/dreamTeams";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getDreamTeamRequestStart = payload => ({
	type: types.REQUEST_GET_DREAM_TEAM_START,
	payload,
});

export const getDreamTeamRequestSuccess = payload => ({
	type: types.REQUEST_GET_DREAM_TEAM_SUCCESS,
	payload,
});

const getDreamTeamRequestError = payload => ({
	type: types.REQUEST_GET_DREAM_TEAM_ERROR,
	payload,
});

export const actionGetDreamTeam = (options) => dispatch => {
	const { roundID, seasonID } = options;
	dispatch(getDreamTeamRequestStart(options));

	if (seasonID && !isNaN(seasonID)) {
		axiosPlus({ method: "GET", url: `${backendApiUrls.seasonDreamTeams}?season_id=${seasonID}` })
			.then(function (response) {
				const { data: { results } = {} } = response;
				if (results.length > 0
					&& results[0].season_dream_team_players
					&& results[0].season_dream_team_players.length > 0) {
					dispatch(getDreamTeamRequestSuccess(results[0].season_dream_team_players));
				} else {
					dispatch(getDreamTeamRequestError(
						{ text: i18instance.t("dream_team_errors:no_season_team", { season: seasonID })  }));
				}
			})
			.catch(function (error) {
				dispatch(getDreamTeamRequestError(errorsParser(error) ));
			});
	} else if (roundID && !isNaN(roundID)) {
		axiosPlus({ method: "GET", url: `${backendApiUrls.roundDreamTeams}?round_id=${roundID}` })
			.then(function (response) {
				const { data: { results } = {} } = response;
				if (results.length > 0
					&& results[0].round_dream_team_players
					&& results[0].round_dream_team_players.length > 0) {
					dispatch(getDreamTeamRequestSuccess(results[0].round_dream_team_players));
				} else {
					dispatch(getDreamTeamRequestError(
						{ text:   i18instance.t("dream_team_errors:no_round_team", { round: roundID }) }));
				}
			})
			.catch(function (error) {
				dispatch(getDreamTeamRequestError(errorsParser(error) ));
			});
	} else {
		dispatch(getDreamTeamRequestError({ text:  i18instance.t("dream_team_errors:invalid_id") }));
	}
};