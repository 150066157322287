import React, { Fragment } from "react";
import {
	Row,
	Col,
	Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

const TotalStatistic = ({ 
	transfersAmount,
	teamPrice,
	totalPoints,
	averagePoints,
	classNames,
	isLoading,
}) => {
	const { t } = useTranslation("total_statistic");
	return (
		<div className={`d-flex leagues-board season round-stat ${classNames}`}>
			<h3 className="leagues-board__header">
				<Trans
					i18nKey="season_results"
					ns="total_statistic"
					components={[
						<span key="0" className='color-accent1' />
					]}
				/>
			</h3>
			<div>
				{isLoading ? 
					<li className="leagues-board__league-item d-flex justify-content-center align-items-center spinner">
						<Spinner />
					</li>
					:
					<Fragment>
						<li  className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><span>{t("Total Points")}</span></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points"><span>{totalPoints || "0"}</span></div>
								</Col>
							</Row>
						</li>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><span>{t("Total Transfers")}</span></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points"><span>{transfersAmount || "0"}</span></div>
								</Col>
							</Row>
						</li>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><span>{t("Season Average")}</span></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points"><span>{averagePoints || "0"}</span></div>
								</Col>
							</Row>
						</li>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8} className="col-5 d-flex align-items-center">
									<div className="leagues-board__name">
										<span className="d-xl-none">{t("Team Price")}</span>
										<span className="d-none d-xl-block">{t("Team Price")}</span>
									</div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points top-player-section">
										<span className='top-player-section-name'>
										€{" "}{teamPrice || "0.0"}
										</span>
									</div>
								</Col>
							</Row>
						</li>
					</Fragment>
				}
			</div>
		</div>
	);
};

export default TotalStatistic;

TotalStatistic.propTypes = {
	transfersAmount: PropTypes.number.isRequired,
	teamPrice: PropTypes.number.isRequired,
	totalPoints: PropTypes.number.isRequired,
	averagePoints: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
	classNames: PropTypes.string,
};

TotalStatistic.displayName = "ts";