import React from "react";
import propTypes from "prop-types";
import {
	Progress,
	Container,
} from "reactstrap";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import Title from "modules/common/Title";

const ProgressBlock = ({
	step,
}) => {
	const { t } = useTranslation("progress_block");
	const stepIndex = step - 1;
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const step1CssClass = classNames(
		"step__upcoming",
		{ "step__upcoming_taken": false },
		{ "d-none": isMobileResolution && step === 3 },
		{ "step__upcoming_taken": !isMobileResolution && step === 3 },
	);
	const step2CssClass = classNames(
		"step__upcoming step__upcoming_2",
		{ "d-none": step === 3 && isMobileResolution },
		{ "step__upcoming_taken": !isMobileResolution && step === 3 },
	);
	const step3CssClass = classNames(
		"step__upcoming step__upcoming_3",
		{ "d-none": isMobileResolution && step !== 3 },
	);
	return (
		<div className="progress-block progress-block_height progress-block_paddings">
			<Container className="progress-block__container">
				<Title translation={{
					ns: "progress_block",
					key: "start fantasy"
				}} color="white" lineColor="yellow" mb={16} mbMd={20}/>
				<div className={`progress-block__wrapper progress-block__wrapper_step${step}`}>
					<Progress max={2} value={stepIndex} className="progress_mt"/>
					<div
						className={step1CssClass}>
						<span className="step__text step__text_pos1">{t("Select Team")}</span>
					</div>
					<div
						className={step2CssClass}>
						<span className="step__text step__text_pos2">{t("Personal Details")}</span>
					</div>
					<div className={step3CssClass}>
						<span className="step__text step__text_pos3">{t("Confirm Email")}</span>
					</div>
					<div className={`step step_${step}`}>
						<div className="step__inner">{step}</div>
					</div>
					{step !== 3 &&
							[<div key="progress-1" className={`step step_${1}`}>
								<div className="step__inner">{1}</div>
							</div>,
							<div  key="progress-2" className={`step step_${2}`}>
								<div className="step__inner">{2}</div>
							</div>]
					}
				</div>
			</Container>
		</div>
	);
};

ProgressBlock.propTypes = {
	step: propTypes.oneOf([1,2,3]).isRequired,
};

export default ProgressBlock;

ProgressBlock.displayName = "pblock";