import React, { memo } from "react";
import PropTypes from "prop-types";
import captain from "static/assets/images/captain.svg";
import viceCaptain from "static/assets/images/vice-captain.svg";

import { convertName } from "helpers/common";

export const PlayerPreview = memo(({ data }) => {
	const {
		football_club : {
			abbreviation, jersey_goalkeeper, jersey_outfield, /* country */
		} = {},
		full_name, current_price: price, isCaptain, isViceCaptain, position
	} = data || {};
	const isGoalkeeper = position === "goalkeeper";
	const jersey = isGoalkeeper ? jersey_goalkeeper : jersey_outfield;

	return (
		<div className="football-player-wrapper cursor-pointer">
			{isCaptain && <img className='captain-icon' src={captain} alt=""/>}
			{isViceCaptain && <img className='captain-icon' src={viceCaptain} alt=""/>}
			<img className="football-player-shirt" src={jersey} alt=""/>
			<div className="football-player-info">
				<div className="football-player-info-side-wrapper">
					{abbreviation &&
					<div className="football-player-info-side-item">{abbreviation.slice(0,3)}</div>
					}
					{!isNaN(price) &&
					<div className="football-player-info-side-item">
						{Number(price).toFixed(1)}
					</div>
					}
				</div>
				<div className="football-player-info-name">
					<span className='player-info-name-text'>{convertName(full_name, true)}</span>
				</div>
			</div>
		</div>
	);
});

PlayerPreview.propTypes = {
	data: PropTypes.object.isRequired,
};

PlayerPreview.displayName = "pprev";