import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { ROUND_WINNER_TAB_URL, SEASON_WINNER_TAB_URL, QUAD_ROUND_WINNER_TAB_URL, tabs } from "modules/prizes/constants";

import WinnerTab from "./WinnerTab";

const Tabs = () => {
	const { t } = useTranslation("prizes_tabs");
	let location = useLocation();
	let history = useHistory();

	const [activeTab, setActiveTab] = useState(location.search);
	const isSomeTabMatch = tabs.some(tab => location.search === tab);
	useEffect(() => {
		!isSomeTabMatch && history.push(ROUND_WINNER_TAB_URL);
		setActiveTab(location.search);
	}, [location]);

	const toggle = tab => {
		if(activeTab !== tab) {
			setActiveTab(tab);
			history.push(tab);
		}
	};

	return (
		<div className="tabs-main">
			<Nav tabs className="tabs tabs_mt">
				<div className="tabs__wrapper">
					<NavItem className="tabs__item">
						<NavLink
							className={classnames({ active: activeTab === ROUND_WINNER_TAB_URL }, "text-uppercase tabs__link")}
							onClick={() => { toggle(ROUND_WINNER_TAB_URL); }}
							tabIndex="0"
							onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && toggle(ROUND_WINNER_TAB_URL)}
						>
							{t("Round winner")}
						</NavLink>
					</NavItem>			
					<NavItem className="tabs__item">
						<NavLink
							className={classnames({ active: activeTab === QUAD_ROUND_WINNER_TAB_URL }, "text-uppercase tabs__link m-0")}
							onClick={() => { toggle(QUAD_ROUND_WINNER_TAB_URL); }}
							tabIndex="0"
							onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && toggle(QUAD_ROUND_WINNER_TAB_URL)}
						>
							{t("Quad round winner")}
						</NavLink>
					</NavItem>
					<NavItem className="tabs__item">
						<NavLink
							className={classnames({ active: activeTab === SEASON_WINNER_TAB_URL }, "text-uppercase tabs__link")}
							onClick={() => { toggle(SEASON_WINNER_TAB_URL); }}
							tabIndex="0"
							onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && toggle(SEASON_WINNER_TAB_URL)}
						>
							{t("Season winner")}
						</NavLink>
					</NavItem>
				</div>
			</Nav>
			<TabContent activeTab={activeTab} className="margin-top-20px">
				<TabPane tabId={activeTab}>
					<WinnerTab activeTab={activeTab} />
				</TabPane>
			</TabContent>
		</div>
	);
};

export default Tabs;

Tabs.displayName = "tabs";