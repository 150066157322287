import React, { useRef } from "react";
import propTypes from "prop-types";
import {
	Container,
	Row,
	Col,
	Input,
	Form,
} from "reactstrap";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { countriesPropTypes, errorsPropTypes } from "utils/propTypes/registrationUser";


import SpinnerButton from "modules/common/SpinnerButton";
import Select from "modules/common/Select";
import ErrorMessage from "modules/common/ErrorMessage";

import Title from "../../common/Title";

const PersonalDetailsShort = ({
	first_name,
	last_name,
	country,
	countriesData,
	handleSubmit,
	changeFirstName,
	changeLastName,
	changeCountry,
	errors,
	changedFields,
	userDetailsRequestLoading,
	userTeamPlayersRequestLoading,
}) => {
	const { t } = useTranslation("personal_details");
	const hasError = (fieldName) => classNames({
		error: errors[fieldName] && (fieldName !== "password" && false),
		"registration-form__field-wrapper": true,
		"is-logged-variant": true,
	});
	const hasFieldError = () => classNames({
		"registration-form__field": true,
		"is-logged-variant": true,
	});
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});
	let changedFieldsNames = "";
	changedFields.forEach((fieldName, i) => {
		changedFieldsNames = changedFieldsNames + `${fieldName}${i !== changedFields.length -1 ? ",": ""} `;
	});

	return (
		<Container className="personal-details-short d-flex flex-column align-items-center">
			<Title translation={{
				ns: "personal_details",
				key: "personal_details"
			}} className="mt-0 margin-bottom-20-40px d-md-block"/>
			<Form className={"registration-form  position-relative"}>
				<Row className="mx-0 w-100">
					<Col md={6} className={hasError("first_name")}>
						<Input
							value={first_name}
							innerRef={firstNameRef}
							placeholder={t("First Name")}
							type="text"
							id="first-name"
							className={hasFieldError("first_name")}
							onChange={changeFirstName}
						/>

						<ErrorMessage fieldName="first_name" errors={errors} />
					</Col>
					<Col md={6} className={hasError("last_name")}>
						<Input
							innerRef={lastNameRef}
							value={last_name}
							placeholder={t("Last Name")}
							type="text"
							id="last-name"
							className={hasFieldError("last_name")}
							onChange={changeLastName}
						/>

						<ErrorMessage fieldName="last_name" errors={errors} />
					</Col>
				</Row>
				<Row className={`mx-0 ${isMobileResolution ? "w-100" : "w-50"}`}>
					<Col md={12} className={`${hasError("country")} country-select`}>
						<Select
							defaultOption={country}
							placeholder={t("Country")}
							menuPlacement="top"
							handleChange={changeCountry}
							values={countriesData}
							isError={!!errors.country}
							valueObjectKey="code"
							labelObjectKey="name"
						/>
						<ErrorMessage fieldName="country" errors={errors} />
						<CountryText />
					</Col>
				</Row>
				<SpinnerButton
					disabled={userTeamPlayersRequestLoading}
					className="last-mobile mt-1 single"
					size="medium"
					text={t("save")}
					onClick={handleSubmit}
					isLoading={userDetailsRequestLoading}
				/>
			</Form>
		</Container>
	);
};

const CountryText = () => {
	const { t } = useTranslation("personal_details");
	return (
		<span className="text-country text-country_indents">
			{t("You will be enrolled to the league of selected country. Country cant be changed during season")}
		</span>
	);
};

PersonalDetailsShort.propTypes = {
	first_name: propTypes.string,
	last_name: propTypes.string,
	country: propTypes.string,
	userTeamPlayersRequestLoading: propTypes.bool.isRequired,
	changedFields: propTypes.arrayOf(propTypes.string).isRequired,
	countriesData: countriesPropTypes.isRequired,
	userDetailsRequestLoading: countriesPropTypes.isRequired,
	handleSubmit: propTypes.func.isRequired,
	changeFirstName: propTypes.func.isRequired,
	changeLastName: propTypes.func.isRequired,
	changeCountry: propTypes.func.isRequired,
	setChangedFields: propTypes.func.isRequired,
	errors: errorsPropTypes,
};

PersonalDetailsShort.defaultProps = {
	errors: {},
	first_name: "",
	last_name: "",
	country: "",
};

export default PersonalDetailsShort;

PersonalDetailsShort.displayName = "pdscom";