import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import Select, { components } from "react-select";
import arrow from "static/assets/images/dropdown-arrow-blue.svg";

/*
 /* SelectComponent is react-select wrapper
/** @param {function} handleChange - callback from parent component, return incoming option object with all fields
/** @param {array} values - array of objects. Is important to have {value, label} fields, or set labelObjectKey,
	valueObjectKey in SelectComponent params.
/** @param {bool} isError - marker for red color of bottom border
/** @param {string} placeholder - selectpicker placeholder
/** @param {string} labelObjectKey - key to extract String from option object for display labels
/** @param {string} valueObjectKey - key to extract value, for inner react-select logic (the best way is objects ID)
/** @param {boolean} isSearchable - marker for allow search functionality of select
/** @param {boolean} isClearable - marker for allow clear search by click on sross
/** @param {boolean} isLoading - shows state of loading
/** @param {boolean} disabled - disable UI and events of selector
/** @param {object} defaultOption - option for set when collection is loaded. Set to null for show placeholder
 */


const SelectComponent = ({
	handleChange,
	values,
	isError,
	placeholder,
	menuPlacement,
	labelObjectKey,
	valueObjectKey,
	isSearchable,
	isClearable,
	isLoading,
	defaultOption,
	disabled,
	changeDefaultOptionFlag,
	...rest
}) => {
	const [selectedOption, setSelectedOption] = useState(defaultOption);

	useEffect(() => {
		defaultOption && values.length && changeDefaultOptionFlag &&
		setSelectedOption(values.find(option => option[valueObjectKey] && option[valueObjectKey] === defaultOption));
	}, [values, defaultOption, changeDefaultOptionFlag]);

	const handleChangeCombine = selectedOption => {
		handleChange(selectedOption);
		setSelectedOption(selectedOption);
	};

	const customStyles = {
		menuList: styles => ({ ...styles, maxHeight: "300px" }),
		control: (styles, state) => ({
			...styles,
			padding: 0,
			border: "none", 
			borderBottom: state.isFocused ? "2px solid #80bdff !important": `2px solid ${isError ? "red" : "#D1D1D1"}`,
			borderRadius: 0,
			boxShadow: state.isFocused ? "0 0 0 3px rgb(0 123 255 / 25%)" : "none", 
			cursor: "pointer",
			backgroundColor: "#FFFFFF",
			maxHeight: "38px",
		}),
		placeholder: (styles, state) => ({ ...styles, color: state.selectProps.menuIsOpen ? "transparent" : "#B3B3B3", }),
		indicatorSeparator: () => ({ display: "none" }),
		indicatorsContainer: styles => ({ ...styles, alignSelf: "flex-end", marginBottom: 4, display: disabled ? "none" : "flex" }),
		valueContainer: styles => ({ ...styles, paddingLeft: 0 }),
		menu:  (styles) => ({ ...styles, zIndex: 9999 }),
		container:  (styles) => ({ ...styles, width: "100%" }),
		menuPortal: styles => ({ ...styles, zIndex: 9999 }),
		singleValue: styles => ({ ...styles, color: disabled ? "#B3B3B3" : "black", marginLeft: "0 !important" }),
	};

	return (
		<Select
			isClearable={isClearable}
			isSearchable={isSearchable}
			value={selectedOption}
			onChange={handleChangeCombine}
			options={values}
			styles={customStyles}
			components={{ DropdownIndicator }}
			placeholder={placeholder}
			menuPlacement={menuPlacement}
			getOptionLabel={(option) => labelObjectKey ? option[labelObjectKey] : option.label}
			getOptionValue={(option) => valueObjectKey ? option[valueObjectKey] : option.value}
			isLoading={isLoading}
			isDisabled={isLoading || disabled}
			{...rest}
		/>
	);
};

const DropdownIndicator = props => (
	<components.DropdownIndicator {...props}>
		<img src={arrow} alt="" />
	</components.DropdownIndicator>
);

SelectComponent.propTypes = {
	values: propTypes.array.isRequired,
	handleChange: propTypes.func.isRequired,
	placeholder: propTypes.string,
	isError: propTypes.bool,
	labelObjectKey: propTypes.string,
	valueObjectKey: propTypes.string,
	isSearchable: propTypes.bool,
	isClearable: propTypes.bool,
	isLoading: propTypes.bool,
	disabled: propTypes.bool,
	changeDefaultOptionFlag: propTypes.bool,
	defaultOption: propTypes.object,
};

SelectComponent.defaultProps = {
	placeholder: "",
	isError: false,
	labelObjectKey: "",
	valueObjectKey: "",
	isSearchable: true,
	isClearable: false,
	isLoading: false,
	defaultOption: null,
	disabled: false,
	changeDefaultOptionFlag: true,
};

SelectComponent.displayName = "sec";

export default SelectComponent;
