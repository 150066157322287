import React from "react";
import { Spinner } from "reactstrap";
import PropTypes from "prop-types";

const SpinnerWrapper = ({ className }) => (
	<div className={`spinner-wrapper ${className}`}>
		<Spinner />
	</div>
);

SpinnerWrapper.propTypes = {
	className: PropTypes.string,
};

SpinnerWrapper.defaultProps = {
	className: "",
};

SpinnerWrapper.displayName = "sw";

export default SpinnerWrapper;
