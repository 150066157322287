import React from "react";
import PropTypes from "prop-types";
import transferInImg from "static/assets/images/transfer-in.svg";
import transferOutImg from "static/assets/images/transfer-out.svg";

import { basicFootballPlayerType } from "utils/propTypes/footballPlayer";

import priceToMillion from "helpers/priceToMillion";

const PlayersTransferItem = ({ data, isTransferIn }) => {

	const arrowImage = isTransferIn ? transferInImg : transferOutImg;

	const {
		football_club : { full_name: clubName, jersey_goalkeeper, jersey_outfield } = {},
		full_name, current_price: price, position
	} = data || {};

	const isGoalkeeper = position === "goalkeeper";
	const jersey = isGoalkeeper ? jersey_goalkeeper : jersey_outfield;

	return (
		<div className='players-transfer-item'>
			<img className='players-transfer-item-arrow' src={arrowImage}/>
			<img className='players-transfer-item-team-logo' src={jersey}/>
			<div className='players-transfer-item-player-info'>
				<div>{full_name}</div>
				<div>{clubName + " / " + priceToMillion(price)}</div>
				<div>{position}</div>
			</div>
		</div>
	);
};

PlayersTransferItem.propTypes = {
	isTransferIn: PropTypes.bool,
	data: basicFootballPlayerType.isRequired
};

PlayersTransferItem.defaultProps = {
	isTransferIn: false,
};

export default PlayersTransferItem;

PlayersTransferItem.displayName = "pti";