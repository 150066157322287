import React from "react";
import propTypes from "prop-types";
import { Row, Col } from "reactstrap";

const Section = ({
	name, text, image, position, id,
}) => (
	<div id={`rulesItem${id}`} className="section">
		<Row className="margin-top-20px">
			<Col sm={12} lg={12}>
				<div className="section_indents">
					<div className="section__icon section__icon_position">
						<span className="number">{position}</span>
					</div>
					<div className="section__title section__title_mt">{name}</div>
					<p dangerouslySetInnerHTML={{ __html: text.replace(/(\r\n|\n|\r)/gm, "") }} className="section__text section__text_m"></p>
				</div>
			</Col>
			{image &&
				<Col sm={12} lg={5}>
					<div className="section__image-wrapper section__image-wrapper_indents">
						<img className="section__image" src={image} alt="" />
					</div>
				</Col>
			}
		</Row>
	</div>
);

Section.propTypes = {
	position: propTypes.number.isRequired,
	name: propTypes.string.isRequired,
	text: propTypes.string.isRequired,
	image: propTypes.string,
	id: propTypes.number.isRequired,
};

Section.default = {
	image: null,
};

Section.displayName = "faqsec";

export default Section;
