import backendApiUrls from "routes/backendUrls";
import frontendUrls from "routes/frontendUrls";

import * as types from "redux/constants/register";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

import { actionPostRequestLogin } from "./auth";

const createUserStart = payload => ({
	type: types.POST_REQUEST_CREATE_USER_START,
	payload,
});

export const createUserSuccess = payload => ({
	type: types.POST_REQUEST_CREATE_USER_SUCCESS,
	payload,
});

export const createUserError = payload => ({
	type: types.POST_REQUEST_CREATE_USER_ERROR,
	payload,
});

const activateAccountStart = payload => ({
	type: types.POST_REQUEST_ACTIVATE_ACCOUNT_START,
	payload,
});

const activateAccountSuccess = payload => ({
	type: types.POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS,
	payload,
});

const activateAccountError = payload => ({
	type: types.POST_REQUEST_ACTIVATE_ACCOUNT_ERROR,
	payload,
});

export const resendEmailVerificationStart = payload => ({
	type: types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_START,
	payload,
});

export const resendEmailVerificationSuccess = payload => ({
	type: types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_SUCCESS,
	payload,
});

export const resendEmailVerificationError = payload => ({
	type: types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_ERROR,
	payload,
});

export const actionResetEmailVerificationResult = () => ({
	type: types.RESET_EMAIL_VERIFICATION_RESULT,
});

export const actionRequestCreateUser = (payload, history) => dispatch => {
	dispatch(createUserStart(payload));

	const config = {
		method: "POST",
		url: backendApiUrls.users,
		data: payload,
	};

	axiosPlus(config)
		.then(response => {
			dispatch(createUserSuccess({ ...response.data }));
			dispatch(actionPostRequestLogin(payload));
			history.push(frontendUrls.urlConfirmEmail);
		})
		.catch(error => {
			dispatch(createUserError(errorsParser(error)));
		});
};

export const actionRequestActivateAccount = (payload) => dispatch => {
	dispatch(activateAccountStart(payload));

	const config = {
		method: "POST",
		url: `${backendApiUrls.activateAccount}${payload}/`,
		data: payload,
	};

	axiosPlus(config)
		.then(response => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ "event": "sign_up" });
			dispatch(activateAccountSuccess({ ...response.data }));
		})
		.catch(error => {
			dispatch( activateAccountError({
				errors:  errorsParser(error),
				emailVerified: error?.response?.data?.is_email_verified
			})
			);
		});
};

export const actionRequestResendEmailVerification = () => dispatch => {
	dispatch(resendEmailVerificationStart());
	const config = {
		method: "POST",
		url: backendApiUrls.sendVerificationEmail,
	};

	axiosPlus(config)
		.then(response => {
			dispatch(resendEmailVerificationSuccess(response));
		})

		.catch((error) => {
			dispatch(resendEmailVerificationError( errorsParser(error) ));
		});
};