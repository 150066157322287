import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Spinner } from "reactstrap";
import { useMediaQuery } from "react-responsive/src";

import Title from "modules/common/Title";
import SocialComponent from "./Social";
import News from "../containers/News";
import Pagination from "../containers/Pagination";

import AdUnit from "modules/common/AdUnit"

const NewsAndSocial = ({
	isLoading,
}) => {
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 991px)"
	}) || window.innerWidth <= 991;


	return (
		<Container className="news-and-social global-container">
		  <AdUnit adUnitId="9424103104" />
			<Title translation={{
				ns: "news_and_social",
				key: "news_and_social"
			}} />
			<Row className={`justify-content-center mx-0 ${isMobileResolution && "flex-column align-items-center"}`}>
				{isLoading ? 
					<div className="spinner-wrapper">
						<Spinner />
					</div>
					: <News />}
				{isMobileResolution && <Pagination />}
				{!isLoading && !isMobileResolution && <SocialComponent />}
			</Row>
			{!isMobileResolution && <Pagination />}
		</Container>
	);
};

NewsAndSocial.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

export default NewsAndSocial;

NewsAndSocial.displayName = "nwssc";