import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import priceToMillion from "helpers/priceToMillion";

export default function Handle({ domain: [min, max], handle: { id, value, percent }, getHandleProps }) {
	const tooltipClass = classNames({
		"normalize-left": value <= max * 0.05,
		"normalize-right": value >= max * 0.95,
	});
	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					left: `${percent}%`,
					position: "absolute",
					transform: "translate(-50%, -50%)",
					WebkitTapHighlightColor: "rgba(0,0,0,0)",
					zIndex: 5,
					width: 28,
					height: 42,
					cursor: "pointer",
					backgroundColor: "none",
				}}
				{...getHandleProps(id)}
			/>
			<div
				role="slider"
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				className='slider-handle'
				style={{
					left: `${percent}%`,
				}}
			>
				<div className="slider-tooltip">
					<span className={`slider-tooltip-text ${tooltipClass}`}>{priceToMillion(value)}</span>
				</div>
			</div>
		</div>
	);
}

Handle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

Handle.defaultProps = {
	disabled: false,
};

Handle.displayName = "h";