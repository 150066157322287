import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import globalFormationPropType from "utils/propTypes/globalFormation";

import { actionChangeFormation } from "redux/actions/footballField";

import useOnClickOutside from "helpers/hooks/useOnClickOutside";

function Formation ({ formationType, globalFormations, actionChangeFormation, formation_type }) {
	const formationRef = useRef();

	const [showFormations, setShowFormations] = useState(false);

	useOnClickOutside(formationRef, () => handleOptionsSwitch(false));

	const handleOptionsSwitch = value => {
		setShowFormations(value);
	};

	return (
		<div ref={formationRef} onClick={() => {handleOptionsSwitch(!showFormations);}} className='football-field-format'>
			<div className='football-field-format-line'>
				{[...Array(formationType[2])].map((item,index) =>
					<div key={`format-item-1${index}`} className='football-field-format-item'/>
				)}
			</div>
			<div className='football-field-format-line'>
				{[...Array(formationType[1])].map((item,index) =>
					<div key={`format-item-2${index}`} className='football-field-format-item'/>
				)}
			</div>
			<div className='football-field-format-line'>
				{[...Array(formationType[0])].map((item,index) =>
					<div key={`format-item-3${index}`} className='football-field-format-item'/>
				)}
			</div>
			<div className='football-field-format-line'>
				<div className='football-field-format-item'/>
			</div>
			<div className='dropdown-formations'>
				{showFormations && globalFormations.map((formation, index) => (
					<div 
						onClick={() => { actionChangeFormation(formation); }}
						className={`${formation.formation_type === formation_type ? "selected-format" : ""}`}
						key={`formation${index}`}
					>
						{formation.formation_type}
					</div>)
				)}
			</div>
		</div>
	);
}

Formation.propTypes = {
	actionChangeFormation: PropTypes.func.isRequired,
	formation_type: PropTypes.string.isRequired,
	formationType: PropTypes.arrayOf(PropTypes.number).isRequired,
	globalFormations: PropTypes.arrayOf(globalFormationPropType.parsedGlobalFormation).isRequired,
};

Formation.defaultProps = {

};

const mapStateToProps = ({ footballFieldReducer }) => {
	const {
		formation: { formationType = [], formation_type } = {}, globalFormations,
	} = footballFieldReducer;
	return {
		formationType, globalFormations, formation_type
	};
};

export default connect(
	mapStateToProps,
	{
		actionChangeFormation,
	}
)(Formation);

Formation.displayName = "form";