import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import moment from "moment";
import tagIcon from "static/assets/images/tag.svg";
import editorIcon from "static/assets/images/editor.svg";
import calendarIcon from "static/assets/images/calendar.svg";

import LazyLoadImage from "modules/news/components/LazyLoadImage";

const Article = ({
	article,
}) => {
	return (
		<Col className="article">
			<LazyLoadImage 
				image={article.image}
			/>
			<hr />
			<h3>{article.headline}</h3>
			<p dangerouslySetInnerHTML={{ __html: article.full_text?.replace(/(\r\n|\n|\r)/gm, "") }} />
			<Row className="bottom-info">
				<img src={tagIcon} alt="" />
				<span>
					{article?.tags?.map((tag, i) => (
						<span key={tag.id}>
							{i ? ", ": null}
							{tag.name}
						</span>
					))}
				</span>
			</Row>
			<Row className="bottom-info">
				<img src={calendarIcon} alt="" />
				{article.created ? <span>{moment(article.created).format("DD MMM YYYY")}</span> : null}
			</Row>
			<Row className="bottom-info">
				<img src={editorIcon} alt="" />
				<span>{article.author?.full_name}</span>
			</Row>
		</Col>
	);
};

Article.propTypes = {
	article: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Article;

Article.displayName = "artc";