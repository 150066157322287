import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Row, Col } from "reactstrap";

import { actionGetRequestFAQList } from "redux/actions/faqList";

import { RULES_TAB_URL, FAQ_TAB_URL } from "modules/rulesAndFaq/constants";
import Spinner from "modules/common/Spinner";

import FAQItem from "./FAQItem";

const FAQTab = ({
	activeTab,
	faqList,
	actionGetRequestFAQList,
	faqListLoading,
}) => {
	useEffect(() => {
		activeTab === FAQ_TAB_URL &&
		_.isEmpty(faqList) &&
			actionGetRequestFAQList();
	}, [activeTab]);

	return (
		<Row>
			{faqListLoading && <Spinner className="w-100 mt-5" />}
			{!!faqList.length &&
				<Col>
					{faqList.map(faq => 
						<FAQItem key={faq.created} {...faq} />
					)}
				</Col>
			}
		</Row>
	);
};

FAQTab.propTypes = {
	activeTab: PropTypes.oneOf([RULES_TAB_URL, FAQ_TAB_URL]),
	faqList: PropTypes.array.isRequired,
	actionGetRequestFAQList: PropTypes.func.isRequired,
	faqListLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ 
	faqList: state.rulesAndFAQReducer.faqListData,
	faqListLoading: state.rulesAndFAQReducer.faqListRequestLoading,
});

export default connect(
	mapStateToProps,
	{ actionGetRequestFAQList }
)(FAQTab);

FAQTab.displayName = "faqt";