export const POST_REQUEST_LOGIN_START = "POST_REQUEST_LOGIN_START";
export const POST_REQUEST_LOGIN_SUCCESS = "POST_REQUEST_LOGIN_SUCCESS";
export const POST_REQUEST_LOGIN_ERROR = "POST_REQUEST_LOGIN_ERROR";
export const CLEAR_USER_LOGIN_ERROR = "CLEAR_USER_LOGIN_ERROR";
export const SWITCH_LOGIN_MODAL_OPEN_STATE = "SWITCH_LOGIN_MODAL_OPEN_STATE";

export const POST_REQUEST_FORGOT_PASSWORD_START = "POST_REQUEST_FORGOT_PASSWORD_START";
export const POST_REQUEST_FORGOT_PASSWORD_SUCCESS = "POST_REQUEST_FORGOT_PASSWORD_SUCCESS";
export const POST_REQUEST_FORGOT_PASSWORD_ERROR = "POST_REQUEST_FORGOT_PASSWORD_ERROR";
export const SWITCH_FORGOT_PASSWORD_MODAL_OPEN_STATE = "SWITCH_FORGOT_PASSWORD_MODAL_OPEN_STATE";

export const POST_REQUEST_SET_NEW_PASSWORD_START = "POST_REQUEST_SET_NEW_PASSWORD_START";
export const POST_REQUEST_SET_NEW_PASSWORD_SUCCESS = "POST_REQUEST_SET_NEW_PASSWORD_SUCCESS";
export const POST_REQUEST_SET_NEW_PASSWORD_ERROR = "POST_REQUEST_SET_NEW_PASSWORD_ERROR";
export const SWITCH_SET_NEW_PASSWORD_MODAL_OPEN_STATE = "SWITCH_SET_NEW_PASSWORD_MODAL_OPEN_STATE";

export const GET_REQUEST_USER_DETAILS_START = "GET_REQUEST_USER_DETAILS_START";
export const GET_REQUEST_USER_DETAILS_SUCCESS = "GET_REQUEST_USER_DETAILS_SUCCESS";
export const GET_REQUEST_USER_DETAILS_ERROR = "GET_REQUEST_USER_DETAILS_ERROR";

export const PATCH_REQUEST_USER_DETAILS_START = "PATCH_REQUEST_USER_DETAILS_START";
export const PATCH_REQUEST_USER_DETAILS_ERROR = "PATCH_REQUEST_USER_DETAILS_ERROR";

export const DELETE_REQUEST_USER_ACCOUNT_START = "DELETE_REQUEST_USER_ACCOUNT_START";
export const DELETE_REQUEST_USER_ACCOUNT_SUCCESS = "DELETE_REQUEST_USER_ACCOUNT_SUCCESS";
export const DELETE_REQUEST_USER_ACCOUNT_ERROR = "DELETE_REQUEST_USER_ACCOUNT_ERROR";

export const USER_LOGOUT = "USER_LOGOUT";
