import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/playersList";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getRequestPlayerFixturesStart = payload => ({
	type: types.GET_REQUEST_PLAYER_FIXTURES_START,
	payload,
});

const getRequestPlayerFixturesSuccess = payload => ({
	type: types.GET_REQUEST_PLAYER_FIXTURES_SUCCESS,
	payload,
});

const getRequestPlayerFixturesError = payload => ({
	type: types.GET_REQUEST_PLAYER_FIXTURES_ERROR,
	payload,
});

export const actionGetRequestPlayerFixtures = ({ playerID }) => dispatch => {
	dispatch(getRequestPlayerFixturesStart(playerID));

	axiosPlus({ method: "GET", url: `${backendApiUrls.playerFixtures}?player_id=${playerID}` })
		.then(function (response) {
			const { data } = response;
			dispatch(getRequestPlayerFixturesSuccess({
				data,
				playerID,
			}));
		})
		.catch(function (error) {
			dispatch(getRequestPlayerFixturesError( errorsParser(error)));
		});
};
