import React, { useRef } from "react";
import propTypes from "prop-types";
import {
	Container,
	Row,
	Col,
	Input,
	Form,
} from "reactstrap";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import { countriesPropTypes, errorsPropTypes } from "utils/propTypes/registrationUser";

import backendApiUrls from "routes/backendUrls";

import MainButton from "modules/common/MainButton";
import SpinnerButton from "modules/common/SpinnerButton";
import Select from "modules/common/Select";
import ErrorMessage from "modules/common/ErrorMessage";
import SuccessComponent from "modules/common/SuccessComponent";

const RegistrationForm = ({
	first_name,
	last_name,
	country,
	email,
	team_name,
	password,
	countriesData,
	handleSubmit,
	confirmTerms,
	switchConfirmTerms,
	changeFirstName,
	changeLastName,
	changeEmail,
	changeTeamName,
	changeCountry,
	changePassword,
	changeConfirmPassword,
	errors,
	isUserLoggedIn,
	changePasswordModalOpenState,
	changeDeleteAccountModalOpenState,
	changedFields,
	setChangedFields,
	userDetailsRequestLoading,
	createUserRequestLoading,
	userTeamPlayersRequestLoading,
	userTeamsRequestLoading,
}) => {
	const { t } = useTranslation("registration_form");
	const hasError = (fieldName) => classNames({
		error: errors[fieldName] && (fieldName !== "password" && !isUserLoggedIn),
		"registration-form__field-wrapper": true,
		"is-logged-variant": isUserLoggedIn,
	});
	const hasFieldError = (fieldName) => classNames({
		error__field: errors[fieldName] && !isUserLoggedIn,
		"registration-form__field": true,
		"is-logged-variant": isUserLoggedIn,
		"locked": fieldName === "password" && isUserLoggedIn,
		"uneditable": fieldName === "email" && isUserLoggedIn,
	});
	const passwordRef = useRef();

	let changedFieldsNames = "";
	changedFields.forEach((fieldName, i) => {
		changedFieldsNames = changedFieldsNames + `${fieldName}${i !== changedFields.length -1 ? ",": ""} `;
	});

	return (
		<Container className="d-flex flex-column align-items-center">
			<Form className={`registration-form ${isUserLoggedIn ? "margin-top-40px margin-top-md-20px" : "registration-form"}`}>
				{changedFields.length ? (
					<Row className="success-message-wrapper text-center text-md-left">
						<SuccessComponent
							handleDelete={() => {setChangedFields([]);}}
							text={
								<Trans
									i18nKey="changed_fields_success_message"
									ns="registration_form"
									values={{
										changedFieldsNames,
									}}
									count={changedFields.length}
								/>
							}
						/>
					</Row>
				) : null}
				<Row className="mx-0">
					<Col md={6} className={hasError("first_name")}>
						<Input
							value={first_name}
							type="text"
							id="first-name"
							placeholder={isUserLoggedIn ? "" : t("First Name")}
							className={hasFieldError("first_name")}
							onChange={changeFirstName}
						/>
						{isUserLoggedIn && <span className='input-label'>{t("First Name")}</span>}
						<ErrorMessage fieldName="first_name" errors={errors} />
					</Col>
					<Col md={6} className={hasError("last_name")}>
						<Input
							value={last_name}
							type="text"
							id="last-name"
							placeholder={isUserLoggedIn ? "" : t("Last Name")}
							className={hasFieldError("last_name")}
							onChange={changeLastName}
						/>
						{isUserLoggedIn && <span className='input-label'>{t("Last Name")}</span>}
						<ErrorMessage fieldName="last_name" errors={errors} />
					</Col>
					{isUserLoggedIn && <Col md={6} className={hasError("team_name")}>
						<Input
							value={team_name}
							type="text"
							id="team_name"
							placeholder=""
							className={`${hasFieldError("team_name")} test`}
							onChange={changeTeamName}
						/>
						<span className='input-label'>{t("Team Name")}</span>
						<ErrorMessage fieldName="team_name" errors={errors} />
					</Col>}
					<Col md={6} className={hasError("email")}>
						<Input
							disabled={isUserLoggedIn}
							value={email}
							type="email"
							id="email"
							placeholder={isUserLoggedIn ? "" : t("Email")}
							className={`${hasFieldError("email")} test`}
							onChange={changeEmail}
						/>
						{isUserLoggedIn && <span className='input-label'>{t("Email")}</span>}
						<ErrorMessage fieldName="email" errors={errors} />
					</Col>
					{!isUserLoggedIn && <Col md={6} className={`${hasError("country")} country-select`}>
						<Select
							defaultOption={country}
							placeholder={t("Country")}
							menuPlacement="top"
							handleChange={changeCountry}
							values={countriesData}
							isError={!!errors.country}
							valueObjectKey="code"
							labelObjectKey="name"
						/>
						{isUserLoggedIn && <span className='input-label'>{t("Country")}</span>}
						<ErrorMessage fieldName="country" errors={errors} />
						{isUserLoggedIn ? <CountryTextLoggedIn /> : <CountryText />}
					</Col>}
					{!isUserLoggedIn &&
						<Col md={6} className={hasError("password")}>
							<Input
								innerRef={passwordRef}
								disabled={isUserLoggedIn}
								value={isUserLoggedIn ? "**********" : password}
								type="password"
								id="password"
								placeholder={t("Password")}
								className={hasFieldError("password")}
								onChange={changePassword}
							/>
							{isUserLoggedIn && <span className='input-label'>{t("Password")}</span>}
							{!isUserLoggedIn && <ErrorMessage fieldName="password" errors={errors} />}
						</Col>
					}
					<Col sm={isUserLoggedIn && 6} md={6} className={`${hasError("confirm_password")} ${isUserLoggedIn ? "margin-right-10px margin-left-minus15px mx-md-0 btn-wrapper justify-content-end justify-content-md-center" : "justify-content-center"} d-md-block last-buttons`}>
						{isUserLoggedIn ?
							<MainButton 
								handler={() => changePasswordModalOpenState(true)}
								size="large"
								className="change-pwd double text-uppercase"
								text={t("Change Password")}
								color="white"
							/>
							:
							[<Input
								key="1"
								type="password"
								id="confirm-password"
								placeholder={t("Confirm Password")}
								className={hasFieldError("confirm_password")}
								onChange={changeConfirmPassword}
							/>,
							<ErrorMessage key="2" fieldName="confirm_password" errors={errors} />]
						}
					</Col>
					{isUserLoggedIn &&
						<Col sm={6} className={`${hasError("confirm_password")} margin-left-10px margin-right-minus15px mx-md-0 btn-wrapper d-flex d-md-block justify-content-md-center justify-content-start last-buttons`}>
							<MainButton
								handler={() => changeDeleteAccountModalOpenState(true)}
								className="change-pwd double text-uppercase"
								text={t("Delete Account")}
								size="large"
								color="red"
							/>
						</Col>
					}
					{!isUserLoggedIn && 
						<Col md={12} className={`confirm-terms ${isUserLoggedIn && "is-logged-variant"}`}>
							<Input onChange={switchConfirmTerms} type="checkbox" />
							<span>
								<Trans
									i18nKey="agreement"
									ns="registration_form"
									components={[
										<a key="0" rel="noreferrer" target="_blank" className="d-inline" href={backendApiUrls.termsOfUse} />
									]}
								/>
							</span>
						</Col>
					}
				</Row>
				<SpinnerButton 
					disabled={
						((!confirmTerms || createUserRequestLoading || userTeamPlayersRequestLoading) && !isUserLoggedIn)
						|| (isUserLoggedIn && userTeamsRequestLoading)
					} 
					className={`single ${isUserLoggedIn && "last-mobile"}`} 
					size="medium" 
					text={isUserLoggedIn ? t("save") : t("register")} 
					onClick={handleSubmit}
					isLoading={(isUserLoggedIn && userDetailsRequestLoading) || (!isUserLoggedIn && (createUserRequestLoading || userTeamPlayersRequestLoading))}
				/>
			</Form>
		</Container>
	);
};

const CountryTextLoggedIn = () => {
	const { t } = useTranslation("registration_form");
	return (
		<span className="text-country text-country_indents">
			{t("You are enrolled to the league of selected country. Country cant be changed during season")}
		</span>
	);
};

const CountryText = () => {
	const { t } = useTranslation("registration_form");
	return (
		<span className="text-country text-country_indents">
			{t("You will be enrolled to the league of selected country. Country cant be changed during season")}
		</span>
	);
};

RegistrationForm.propTypes = {
	first_name: propTypes.string,
	last_name: propTypes.string,
	team_name: propTypes.string,
	country: propTypes.string,
	email: propTypes.string,
	confirmTerms: propTypes.bool.isRequired,
	userTeamPlayersRequestLoading: propTypes.bool.isRequired,
	userTeamsRequestLoading: propTypes.bool.isRequired,
	switchConfirmTerms: propTypes.func.isRequired,
	changedFields: propTypes.arrayOf(propTypes.string).isRequired,
	password: propTypes.string,
	isUserLoggedIn: propTypes.bool.isRequired,
	isRegisterLoading: propTypes.bool.isRequired,
	createUserRequestLoading: propTypes.bool.isRequired,
	countriesData: countriesPropTypes.isRequired,
	userDetailsRequestLoading: countriesPropTypes.isRequired,
	handleSubmit: propTypes.func.isRequired,
	changeFirstName: propTypes.func.isRequired,
	changeLastName: propTypes.func.isRequired,
	changeEmail: propTypes.func.isRequired,
	changeTeamName: propTypes.func.isRequired,
	changeCountry: propTypes.func.isRequired,
	changePassword: propTypes.func.isRequired,
	changeConfirmPassword: propTypes.func.isRequired,
	changePasswordModalOpenState: propTypes.func.isRequired,
	changeDeleteAccountModalOpenState: propTypes.func.isRequired,
	setChangedFields: propTypes.func.isRequired,
	errors: errorsPropTypes,
};

RegistrationForm.defaultProps = {
	errors: {},
	first_name: "",
	last_name: "",
	country: "",
	email: "",
	password: "",
};

export default RegistrationForm;

RegistrationForm.displayName = "rform";