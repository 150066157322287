import * as types from "redux/constants/leagues";

import createReducer from "redux/createReducer";

const leaguesSeasonsInitialState = {
	seasonsListData: [],
	seasonsListRequestError: {},
	seasonsListRequestLoading: false,
};

const leagueSeasonsReducer = createReducer(leaguesSeasonsInitialState)({
	[types.REQUEST_GET_LEAGUES_SEASONS_START]: state => ({
		...state,
		seasonsListRequestError: {},
		seasonsListRequestLoading: true,
	}),
	[types.REQUEST_GET_LEAGUES_SEASONS_SUCCESS]: (state, { payload }) => ({
		...state,
		seasonsListData: payload,
		seasonsListRequestLoading: false,
	}),
	[types.REQUEST_GET_LEAGUES_SEASONS_ERROR]: (state, { payload }) => {
		return {
			...state,
			seasonsListRequestError: payload,
			seasonsListRequestLoading: false,
		};
	},
});

export default leagueSeasonsReducer;