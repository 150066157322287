import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import CustomButton from "modules/common/CustomButton";

const Switch = ({
	handlePrev,
	handleNext,
	text,
	value,
	switchRange,
	index,
	isLoading,
}) => {
	const existNext = switchRange && index < switchRange - 1;
	const existPrev = switchRange && index > 0;
	return (
		<div className="switch-round">
			{(existPrev && !isLoading)
				? <CustomButton className="round__button" onClick={handlePrev} />
				: <span className='round_button_hidden'/>}
			<h2 className="switch-round__title">
				<Trans
					i18nKey={text === "round" ? "switchRound" : "switchSeason"}
					ns="switch"
					values={{
						value: value || 1,
					}}
					components={[
						<span key="0" className='color-accent1' />
					]}
				/>
			</h2>
			{(existNext && !isLoading)
				? <CustomButton className="round__button round__button_next" onClick={handleNext} />
				: <span className='round_button_hidden'/>}
		</div>
	);
};

Switch.propTypes = {
	handlePrev: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
	switchRange: PropTypes.number,
	index: PropTypes.number,
	isLoading: PropTypes.bool,
};

Switch.defaultProps = {
	value: 1,
	switchRange: false,
	index: 0,
	isLoading: false,
};

Switch.displayName = "swit";

export default Switch;