import * as types from "redux/constants/otherUserResults";

export const actionGetOtherUserResultsRequest = payload => ({
	type: types.GET_REQUEST_OTHER_USER_RESULTS_START,
	payload,
});

export const getOtherUserResultsRequestSuccessAction = payload => ({
	type: types.GET_REQUEST_OTHER_USER_RESULTS_SUCCESS,
	payload,
});

export const getOtherUserResultsRequestErrorAction = payload => ({
	type: types.GET_REQUEST_OTHER_USER_RESULTS_ERROR,
	payload,
});

