import moment from "moment";
import _ from "lodash";

import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/roundGames";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

const getRequestRoundGamesStart = () => ({
	type: types.GET_REQUEST_ROUND_GAMES_START,
});

const getRequestRoundGamesSuccess = (data, roundId, currentRoundNumber) => ({
	type: types.GET_REQUEST_ROUND_GAMES_SUCCESS,
	data,
	meta: { roundId, currentRoundNumber },
});

const getRequestRoundGamesError = error => ({
	type: types.GET_REQUEST_ROUND_GAMES_ERROR,
	payload: error,
});

const getRequestGameFootballPlayersPointsStart = payload => ({
	type: types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_START,
	payload,
});

const getRequestGameFootballPlayersPointsSuccess = payload => ({
	type: types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_SUCCESS,
	payload,
});

const getRequestGameFootballPlayersPointsError = error => ({
	type: types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_ERROR,
	payload: error,
});

export const actionClearRoundGamesHashList = () => ({
	type: types.CLEAR_ROUND_GAMES_HASH_LIST
});

export const actionGetRequestRoundGames = (filter, id, currentRoundNumber) => dispatch => {
	dispatch(getRequestRoundGamesStart());
	const config = {
		method: "GET",
		url: `${backendApiUrls.roundGames}${filter}${id}`,
	};

	return axiosPlus(config)
		.then((json) => {
			const games = json.data.results.sort(function(a,b){
				return moment(a.match_datetime).diff(moment(b.match_datetime));
			});
			const roundGames = _.map(games, game => {
				const gameModifyde = { ...game };
				gameModifyde.matchDateAndDayOfWeek = moment(game.match_datetime).format("DD MMM YYYY | dddd");
				gameModifyde.matchLocaleTime = moment(game.match_datetime).format("HH:mm");
				return gameModifyde;
			});
			const teamsOpponents = {};
			games.forEach(game => {
				const team1ID = game && game.team_1 && game.team_1.id;
				const team2ID = game && game.team_2 && game.team_2.id;
				const team1OpponentAbbreviation = game && game.team_2 && game.team_2.abbreviation;
				const team2OpponentAbbreviation = game && game.team_1 && game.team_1.abbreviation;

				if (team1ID && team2ID && team1OpponentAbbreviation && team2OpponentAbbreviation) {
					teamsOpponents[team1ID] = teamsOpponents[team1ID]
						? [...teamsOpponents[team1ID], {
							game,
							placeOfGame: "H",
							abbreviation: team1OpponentAbbreviation
						}]
						: [{
							game,
							placeOfGame: "H",
							abbreviation: team1OpponentAbbreviation
						}];
					teamsOpponents[team2ID] = teamsOpponents[team2ID]
						? [...teamsOpponents[team2ID], {
							game,
							placeOfGame: "A",
							abbreviation: team2OpponentAbbreviation
						}]
						: [{
							game,
							placeOfGame: "A",
							abbreviation: team2OpponentAbbreviation
						}];
				}
			});
			dispatch(getRequestRoundGamesSuccess({ roundGames, teamsOpponents }, id, currentRoundNumber));
			return roundGames;
		})

		.catch((error) => {
			dispatch(getRequestRoundGamesError(errorsParser(error) ));
		});
};

const getRoundGameItemStart = () => ({
	type: types.GET_ROUND_GAME_ITEM_START,
});

const getRoundGameItemSuccess = payload => ({
	type: types.GET_ROUND_GAME_ITEM_SUCCESS,
	payload,
});

const getRoundGameItemError = payload => ({
	type: types.GET_ROUND_GAME_ITEM_ERROR,
	payload,
});

export const actionGetRoundGameItem = (id) => dispatch => {
	dispatch(getRoundGameItemStart());
	return axiosPlus({
		method: "GET",
		url: `${backendApiUrls.roundGames}${id}/`,
	})
		.then((response) => {
			const { data } = response;
			dispatch(getRoundGameItemSuccess(data));
		})
		.catch((error) => {
			dispatch(getRoundGameItemError(errorsParser(error)));
		});
};

export const actionGetRequestGameFootballPlayersPoints = gameID => dispatch => {
	dispatch(getRequestGameFootballPlayersPointsStart(gameID));
	const config = {
		method: "GET",
		url: backendApiUrls.footballPlayerRoundResultList,
		params: { round_game_id: gameID },
	};

	axiosPlus(config)
		.then(response => {
			const { data:  { results } } = response;
			const parsedGameResultsData = {};
			results.forEach(playerPoints => {
				const { football_player: { first_name, last_name, id: playerId } = {},  played_for_team_id, round_actions } = playerPoints;
				const playerName = `${first_name} ${last_name}`;
				if (Object.keys(round_actions).length) {
					Object.keys(round_actions).forEach(actionKey => {
						const { count, points } = round_actions[actionKey];
						if (points || count) {
							parsedGameResultsData[actionKey] = {
								...parsedGameResultsData[actionKey],
								[played_for_team_id]: parsedGameResultsData[actionKey] && parsedGameResultsData[actionKey][played_for_team_id]
									? [...parsedGameResultsData[actionKey][played_for_team_id], { playerName, points, count, playerId }]
									: [{ playerName, points, count, playerId }]
							};
						}
					});
				}
			});
			dispatch(getRequestGameFootballPlayersPointsSuccess({ data: parsedGameResultsData, forGameID: gameID }));
		})

		.catch((error) => {
			dispatch(getRequestGameFootballPlayersPointsError({ data: errorsParser(error), forGameID: gameID } ));
		});
};


const cloasestScheduledGamesStart = () => ({
	type: types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_START,
});

const cloasestScheduledGamesSuccess = payload => ({
	type: types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_SUCCESS,
	payload,
});

const cloasestScheduledGamesError = payload => ({
	type: types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_ERROR,
	payload,
});

export const actionGetCloasestScheduledGames = (params) => dispatch => {
	dispatch(cloasestScheduledGamesStart());
	const config = {
		params,
		method: "GET",
		url: backendApiUrls.closestScheduledRoundGames,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			const indexedScheduledGames = {};
			const sortedData = data.sort(function(a,b){
				return moment(a.match_datetime).diff(moment(b.match_datetime));
			});
			sortedData.forEach(game => {
				const team1ID = game && game.team_1 && game.team_1.id;
				const team2ID = game && game.team_2 && game.team_2.id;
				const team1OpponentAbbreviation = game && game.team_2 && game.team_2.abbreviation;
				const team2OpponentAbbreviation = game && game.team_1 && game.team_1.abbreviation;

				if (team1ID && team2ID && team1OpponentAbbreviation && team2OpponentAbbreviation) {
					indexedScheduledGames[team1ID] = indexedScheduledGames[team1ID]
						? [...indexedScheduledGames[team1ID], {
							game,
							placeOfGame: "H",
							abbreviation: team1OpponentAbbreviation
						}]
						: [{
							game,
							placeOfGame: "H",
							abbreviation: team1OpponentAbbreviation
						}];
					indexedScheduledGames[team2ID] = indexedScheduledGames[team2ID]
						? [...indexedScheduledGames[team2ID], {
							game,
							placeOfGame: "A",
							abbreviation: team2OpponentAbbreviation
						}]
						: [{
							game,
							placeOfGame: "A",
							abbreviation: team2OpponentAbbreviation
						}];
				}
			});
			dispatch(cloasestScheduledGamesSuccess(indexedScheduledGames));
		})

		.catch((error) => {
			dispatch(cloasestScheduledGamesError(errorsParser(error) ));
		});
};
