import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { actionGetRequestRulesSections } from "redux/actions/rulesSections";
import { actionGetRequestRulesItem } from "redux/actions/rulesItem";

import { RULES_TAB_URL, FAQ_TAB_URL } from "modules/rulesAndFaq/constants";
import Select from "modules/common/Select";
import Spinner from "modules/common/Spinner";

import SectionItem from "./SectionItem";


const RulesTab = ({
	activeTab,
	sections,
	rulesItems,
	sectionsLoading,
	rulesItemLoading,
	actionGetRequestRulesItem,
}) => {
	const { t } = useTranslation("rules_tab");
	const [ currentSectionId, setCurrentSection ] = useState();
	const [ defaultOption, setDefaultOption ] = useState();
	const currentItems = rulesItems.filter(rule => rule.section_type === "rules");
	useEffect(() => {
		sections[0] && setCurrentSection(sections[0].id);
	}, [sections]);

	useEffect(() => {
		currentItems?.[0] && setDefaultOption(currentItems?.[0]);
	}, [currentItems]);

	const handleSelect = value => {
		setCurrentSection(value.id);
	};

	const handleKeyDown = (e, id) => {
		(e.keyCode === 13 || e.keyCode === 32) &&
		setCurrentSection(id);
	};

	const scrollToRule = () => {
		const ruleEl = document.getElementById(`rulesItem${currentSectionId}`);
		if (ruleEl) {
			ruleEl.scrollIntoView(true);
			//Header fix
			window.scrollTo(0, window.scrollY - 90);
		}
	};

	useEffect(() => {
		currentSectionId && scrollToRule();
	}, [currentSectionId]);

	useEffect(() => {
		actionGetRequestRulesItem();
	}, []);

	const sectionNavItemCssClass = (id) => classNames(
		"section-nav__item",
		"margin-top-20px",
		{ "section-nav__item_active-link": currentSectionId === id },
	);

	const selectPlaceholder = sectionsLoading ? t("Loading...") : sections[0] && sections[0].name;

	return (
		<Row>
			<Col sm={12} md={2} className="position-relative px-4 pl-md-3">
				<div className="d-md-none">
					{defaultOption && <Select
						className="d-md-none"
						placeholder={selectPlaceholder}
						handleChange={handleSelect}
						values={currentItems}
						valueObjectKey="id"
						labelObjectKey="name"
						isLoading={sectionsLoading}
						defaultOption={defaultOption}
						changeDefaultOptionFlag={false}
					/>}
				</div>
				<div className="d-none d-md-block section-nav section-nav_indents">
					<h4 className="section-nav__title">content</h4>
					<ul className="section-nav__list">
						{sectionsLoading && <Spinner className="mt-5" />}
						{!!currentItems.length &&
						currentItems.map(currentItem =>
							<li key={currentItem.id} className={sectionNavItemCssClass(currentItem.id)}>
								<a
									tabIndex="0"
									onKeyDown={e => handleKeyDown(e, currentItem.id)}
									onClick={() => setCurrentSection(currentItem.id)}
								>
									{currentItem.name}
								</a>
							</li>
						)
						}
					</ul>
				</div>
			</Col>
			<Col sm={12} md={10} className="padding-x-10px pl-lg-58px pl-md-5 border-left_none border-left_md">
				{rulesItemLoading
					? <Spinner className="w-md-75 mt-5 mt-md-0" />
					: currentItems.map(rule => <SectionItem key={rule.id} {...rule}/>
					)
				}
			</Col>
		</Row>
	);
};

RulesTab.propTypes = {
	activeTab: PropTypes.oneOf([FAQ_TAB_URL, RULES_TAB_URL]).isRequired,
	sections: PropTypes.arrayOf(PropTypes.object),
	rulesItems: PropTypes.arrayOf(PropTypes.object),
	sectionsLoading: PropTypes.bool.isRequired,
	rulesItemLoading: PropTypes.bool.isRequired,
	actionGetRequestRulesSections: PropTypes.func.isRequired,
	actionGetRequestRulesItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	sections: state.rulesAndFAQReducer.sectionsRulesData,
	rulesItems: state.rulesAndFAQReducer.rulesItemRulesData,
	sectionsLoading: state.rulesAndFAQReducer.sectionsRulesRequestLoading,
	rulesItemLoading: state.rulesAndFAQReducer.rulesItemRulesRequestLoading,
});

export default connect(
	mapStateToProps,
	{ actionGetRequestRulesSections, actionGetRequestRulesItem }
)(RulesTab);

RulesTab.displayName = "faqrt";