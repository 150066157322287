import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Deadline = ({
	userteamDeadline,
	isOpenDropdown,
}) => {
	const { t } = useTranslation("deadline");
	const openedTitle = classNames({
		"title": true,
		"title_opened": isOpenDropdown,
	});
	return (
		<div className="deadline deadline_mt">
			<p className={openedTitle}>{t("deadline")}</p>
			<p className="date">{userteamDeadline}</p>
		</div>
	);
};

Deadline.propTypes = {
	userteamDeadline: PropTypes.string.isRequired,
	isOpenDropdown: PropTypes.bool.isRequired,
};

export default Deadline;

Deadline.displayName = "d";