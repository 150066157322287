import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/leagues";

import { FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";


const getSeasonLeagueInfoStart = payload => ({
	type: types.GET_REQUEST_SEASON_LEAGUE_INFO_START,
	payload,
});

const getSeasonLeagueInfoSuccess = payload => ({
	type: types.GET_REQUEST_SEASON_LEAGUE_INFO_SUCCESS,
	payload,
});

const getSeasonLeagueInfoError = payload => ({
	type: types.GET_REQUEST_SEASON_LEAGUE_INFO_ERROR,
	payload,
});

export const actionGetRequestSeasonLeagueInfo = (activeSeasonId, otherUserId) => dispatch => {
	dispatch(getSeasonLeagueInfoStart());
	const config = {
		method: "GET",
		url: `${backendApiUrls.leagueSeasonLeagueInfo.replace(FOR_REPLACEMENT.ID, activeSeasonId)}${otherUserId ? `?user_id=${otherUserId}` : ""}`,
	};

	axiosPlus(config)
		.then(response => {
			dispatch(getSeasonLeagueInfoSuccess(response.data));
		})

		.catch((error) => {
			dispatch(getSeasonLeagueInfoError(errorsParser(error)));
		});
};
