import React, { useState } from "react";
import { Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import PitchLarge from "static/assets/images/pitch-large.svg";
import PitchSmall from "static/assets/images/pitch-small.svg";
import PitchMobile from "static/assets/images/m-pitch.svg";
import { useMediaQuery } from "react-responsive";

import { parsedFieldTeamType } from "utils/propTypes/footballTeam";

import { FIELD_POSITIONS_MAP_REVERSE } from "helpers/constants/common";

import { CustomDragLayer } from "modules/registration/components/CustomDragLayer";
import FieldPlayerItem from "modules/footballField/components/FieldPlayerItem";

import FieldTeamTop from "./FieldTeamTop";

const FootballFieldSmall  = React.forwardRef((props, ref) => {
	const {
		fieldTeam,
		formationType,
		dropCallback,
		actionMovePlayer,
		actionRemovePlayer,
		actionSetCaptain,
		actionSetViceCaptain,
		dynamicField,
		isLiveAndPreviousPage,
		selectedRoundId,
		isSelectTeamPage,
		isNextRoundPage,
		switchPlayersListModal,
		withoutFieldTopControls,
		withoutDragAndWithDeletable,
		withoutPlayerDeleteButton,
		withoutCaptainIcon,
		isLoading,
		isDreamTeam,
	} = props;

	const [isCaptainMarkerSelected, setIsCaptainMarkerSelected] = useState(false);
	const [isViceCaptainMarkerSelected, setIsViceCaptainMarkerSelected] = useState(false);
	const [isFieldImageLoaded, setIsFieldImageLoaded] = useState(false);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});
	const { bench } = fieldTeam;

	const handleDrop = (params) => {
		dropCallback && dropCallback(true);
		const { player: { isCaptain, isViceCaptain }, target  = {} } = params;
		if (isCaptain) {
			actionSetCaptain(target);
		} else if (isViceCaptain) {
			actionSetViceCaptain(target);
		} else {
			actionMovePlayer(params);
		}
	};

	const handleSetCaptain = target => {
		actionSetCaptain(target);
		setIsCaptainMarkerSelected(false);
	};
	
	const handleSetViceCaptain = target => {
		actionSetViceCaptain(target);
		setIsViceCaptainMarkerSelected(false);
	};


	const isLayoutReady = isFieldImageLoaded && !isLoading && fieldTeam && !!formationType.length;


	return (
		<div ref={ref} className={`football-field-container-small ${isSelectTeamPage && "isSelectTeamPage"}`}>
			{dynamicField && !withoutDragAndWithDeletable ? <CustomDragLayer /> : null }
			{dynamicField && !withoutFieldTopControls ? 
				<FieldTeamTop
					formationType={formationType} 
					setIsCaptainMarkerSelected={setIsCaptainMarkerSelected} 
					setIsViceCaptainMarkerSelected={setIsViceCaptainMarkerSelected} 
				/> 
				: null}
			<img 
				className="football-field-image" 
				src={isMobileResolution ? PitchMobile : isDreamTeam ? PitchLarge : PitchSmall} 
				alt=""
				onLoad={() => {
					setIsFieldImageLoaded(true);
				}}
			/>
			{isLayoutReady ?
				<div className="football-field-wrapper">
					<div className="forwards-line static">
						{[...Array(formationType[2])].map((x, i) =>
							<FieldPlayerItem
								isCaptainMarkerSelected={isCaptainMarkerSelected}
								isViceCaptainMarkerSelected={isViceCaptainMarkerSelected}
								handleSetCaptain={handleSetCaptain}
								handleSetViceCaptain={handleSetViceCaptain}
								switchPlayersListModal={switchPlayersListModal}
								isSelectTeamPage={isSelectTeamPage}
								isNextRoundPage={isNextRoundPage}
								selectedRoundId={selectedRoundId}
								isLiveAndPreviousPage={isLiveAndPreviousPage}
								isDraggable={dynamicField && !withoutDragAndWithDeletable}
								withoutDragAndWithDeletable={withoutDragAndWithDeletable}
								withoutPlayerDeleteButton={withoutPlayerDeleteButton}
								withoutCaptainIcon={withoutCaptainIcon}
								lineLength={formationType[2]}
								key={`players-line1${i}`}
								actionRemovePlayer={actionRemovePlayer}
								handleDrop={handleDrop}
								data={fieldTeam.forwards[i]}
								index={i}
								positionShort="FWD"
								isDreamTeam={isDreamTeam}
								position="forwards"
							/>
						)}
					</div>
					<div className="forwards-line static">
						{[...Array(formationType[1])].map((x, i) =>
							<FieldPlayerItem
								isCaptainMarkerSelected={isCaptainMarkerSelected}
								isViceCaptainMarkerSelected={isViceCaptainMarkerSelected}
								handleSetCaptain={handleSetCaptain}
								handleSetViceCaptain={handleSetViceCaptain}
								switchPlayersListModal={switchPlayersListModal}
								isSelectTeamPage={isSelectTeamPage}
								isNextRoundPage={isNextRoundPage}
								selectedRoundId={selectedRoundId}
								isLiveAndPreviousPage={isLiveAndPreviousPage}
								isDraggable={dynamicField && !withoutDragAndWithDeletable}
								withoutDragAndWithDeletable={withoutDragAndWithDeletable}
								withoutPlayerDeleteButton={withoutPlayerDeleteButton}
								withoutCaptainIcon={withoutCaptainIcon}
								lineLength={formationType[1]}
								key={`players-line2${i}`}
								actionRemovePlayer={actionRemovePlayer}
								handleDrop={handleDrop}
								data={fieldTeam.midfielders[i]}
								index={i}
								positionShort="MID"
								isDreamTeam={isDreamTeam}
								position="midfielders"
							/>							
						)}
					</div>
					<div className="forwards-line static">
						{[...Array(formationType[0])].map((x, i) =>
							<FieldPlayerItem
								isCaptainMarkerSelected={isCaptainMarkerSelected}
								isViceCaptainMarkerSelected={isViceCaptainMarkerSelected}
								handleSetCaptain={handleSetCaptain}
								handleSetViceCaptain={handleSetViceCaptain}
								switchPlayersListModal={switchPlayersListModal}
								isSelectTeamPage={isSelectTeamPage}
								isNextRoundPage={isNextRoundPage}
								selectedRoundId={selectedRoundId}
								isLiveAndPreviousPage={isLiveAndPreviousPage}
								isDraggable={dynamicField && !withoutDragAndWithDeletable}
								withoutDragAndWithDeletable={withoutDragAndWithDeletable}
								withoutPlayerDeleteButton={withoutPlayerDeleteButton}
								withoutCaptainIcon={withoutCaptainIcon}
								lineLength={formationType[0]}
								key={`players-line3${i}`}
								actionRemovePlayer={actionRemovePlayer}
								handleDrop={handleDrop}
								data={fieldTeam.defenders[i]}
								index={i} positionShort="DEF"
								isDreamTeam={isDreamTeam}
								position="defenders"
							/>
						)}
					</div>
					<div className="forwards-line static">
						<FieldPlayerItem
							isCaptainMarkerSelected={isCaptainMarkerSelected}
							isViceCaptainMarkerSelected={isViceCaptainMarkerSelected}
							handleSetCaptain={handleSetCaptain}
							handleSetViceCaptain={handleSetViceCaptain}
							switchPlayersListModal={switchPlayersListModal}
							isSelectTeamPage={isSelectTeamPage}
							isNextRoundPage={isNextRoundPage}
							selectedRoundId={selectedRoundId}
							isLiveAndPreviousPage={isLiveAndPreviousPage}
							isDraggable={dynamicField && !withoutDragAndWithDeletable}
							withoutDragAndWithDeletable={withoutDragAndWithDeletable}
							withoutPlayerDeleteButton={withoutPlayerDeleteButton}
							withoutCaptainIcon={withoutCaptainIcon}
							handleDrop={handleDrop}
							actionRemovePlayer={actionRemovePlayer}
							data={fieldTeam.goalkeepers[0]}
							positionShort="GKP"
							position="goalkeepers"
							isDreamTeam={isDreamTeam}
							index={0}
						/>
					</div>
					{isDreamTeam ? null : <div className="football-field-bench-wrapper">
						{[...Array(4)].map((x, i) =>
							<FieldPlayerItem
								isCaptainMarkerSelected={isCaptainMarkerSelected}
								isViceCaptainMarkerSelected={isViceCaptainMarkerSelected}
								handleSetCaptain={handleSetCaptain}
								handleSetViceCaptain={handleSetViceCaptain}
								switchPlayersListModal={switchPlayersListModal}
								isSelectTeamPage={isSelectTeamPage}
								isNextRoundPage={isNextRoundPage}
								selectedRoundId={selectedRoundId}
								isLiveAndPreviousPage={isLiveAndPreviousPage}
								isDraggable={dynamicField && !withoutDragAndWithDeletable}
								withoutDragAndWithDeletable={withoutDragAndWithDeletable}
								withoutPlayerDeleteButton={withoutPlayerDeleteButton}
								withoutCaptainIcon={withoutCaptainIcon}
								positionShort={bench[i] ? FIELD_POSITIONS_MAP_REVERSE[bench[i].position] : "-"}
								isBench
								actionRemovePlayer={actionRemovePlayer}
								handleDrop={handleDrop}
								data={bench[i]}
								key={`player${i}${bench[i] ? bench[i].position : ""}`}
								index={i}
								isDreamTeam={isDreamTeam}
								position="bench"
							/>
						)}
					</div>
					}
				</div>
				: 
				<Row className='dream-team__spinner w-100 px-0 mx-0 d-flex align-items-center justify-content-center'>
					<Spinner/>
				</Row>}
		</div>
	);
});

export default FootballFieldSmall;

FootballFieldSmall.propTypes = {
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	formationType: PropTypes.arrayOf(PropTypes.number).isRequired,
	dynamicField: PropTypes.bool,
	actionMovePlayer: PropTypes.func.isRequired,
	actionRemovePlayer: PropTypes.func.isRequired,
	actionSetCaptain: PropTypes.func.isRequired,
	actionSetViceCaptain: PropTypes.func.isRequired,
	dropCallback: PropTypes.func,
	isLiveAndPreviousPage: PropTypes.bool,
	isSelectTeamPage: PropTypes.bool,
	isNextRoundPage: PropTypes.bool,
	withoutPlayerDeleteButton: PropTypes.bool,
	withoutDragAndWithDeletable: PropTypes.bool,
	withoutFieldTopControls: PropTypes.bool,
	withoutCaptainIcon: PropTypes.bool,
	isLoading: PropTypes.bool,
	isDreamTeam: PropTypes.bool,
	selectedRoundId: PropTypes.number,
	switchPlayersListModal: PropTypes.func,
};

FootballFieldSmall.defaultProps = {
	isLiveAndPreviousPage: false,
	dynamicField: false,
	selectedRoundId: 0,
	isSelectTeamPage: false,
	isNextRoundPage: false,
	isLoading: false,
	isDreamTeam: false,
};

FootballFieldSmall.displayName = "ffs";