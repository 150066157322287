import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/footballActions";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getFootballActionsStart = () => ({
	type: types.GET_REQUEST_FOOTBALL_ACTIONS_START,
});

const getFootballActionsSuccess = payload => ({
	type: types.GET_REQUEST_FOOTBALL_ACTIONS_SUCCESS,
	payload,
});

const getFootballActionsError = payload => ({
	type: types.GET_REQUEST_FOOTBALL_ACTIONS_ERROR,
	payload,
});

export const actionGetRequestFootballActions = () => dispatch => {
	dispatch(getFootballActionsStart());
	const config = {
		method: "GET",
		url: backendApiUrls.footballActions,
	};

	axiosPlus(config)
		.then(response => {
			const { data: { action_types = [] } = {} } = response;
			const parsedActionTypes = action_types.map(actionType => ({ ...actionType, name: actionType.name.split("|")[1] }));
			dispatch(getFootballActionsSuccess(parsedActionTypes || []));
		})

		.catch((error) => {
			dispatch(getFootballActionsError(errorsParser(error) ));
		});
};
