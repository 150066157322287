import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import i18n from "i18instance";

import { formValidatorCreateLeague, formValidatorJoinLeague } from "utils/validators";

import frontendUrls from "routes/frontendUrls";

import { actionJoinRequestPrivateLeagues, actionPostRequestPrivateLeagues, actionCleanCreateJoinLeagueStates } from "redux/actions/leaguesPrivate";

import history from "helpers/history";

import Select from "modules/common/Select";
import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorComponent from "modules/common/ErrorComponent";

const LeaguesModal = ({
	toggle,
	openModalState,
	type,
	activeSeasonId,
	requestErrors,
	lastCreatedLeagueName,
	lastJoinedLeagueCode,
	joinLeagueLoading,
	createNewLeagueLoading,
	actionPostRequestPrivateLeagues,
	actionJoinRequestPrivateLeagues,
	actionCleanCreateJoinLeagueStates,
	seasonRoundsData,
}) => {
	const { t }  = useTranslation("leagues_modal");
	const [validationErrors, setValidationErrors] = useState({});
	const [inputValue, setInputValue] = useState("");
	const [selectedRoundCount, setSelectedRoundCount] = useState(null);
	const title = t(type === "join" ? "join_title" : "create_title");
	const inputPlaceholder = type === "join" ? t("League Code") : t("League Name");

	useEffect(() => {
		if (lastCreatedLeagueName === inputValue || lastJoinedLeagueCode === inputValue) {
			toggle();
			actionCleanCreateJoinLeagueStates();
		}
	}, [lastCreatedLeagueName, lastJoinedLeagueCode]);

	const handleClick = () => {
		if (type === "join") {
			const errors = formValidatorJoinLeague({
				name: inputValue,
			});
			setValidationErrors(errors);
			if (_.isEmpty(errors)) {
				actionJoinRequestPrivateLeagues({ seasonID: activeSeasonId, joinCode: inputValue })
					.then((joinedLeagueDetails) => {
						if (joinedLeagueDetails && joinedLeagueDetails.id) {
							history.push(`${frontendUrls.urlLeaguesRanking}private/?league_id=${joinedLeagueDetails.id}`);
						}
					});
			}
		} else {
			const errors = formValidatorCreateLeague({
				name: inputValue,
				selectedRoundCount,
			});
			setValidationErrors(errors);
			if (_.isEmpty(errors)) {
				actionPostRequestPrivateLeagues({ seasonID: activeSeasonId, name: inputValue, selectedRoundCount })
					.then((createdLeagueDetails) => {
						if (createdLeagueDetails && createdLeagueDetails.id) {
							history.push(`${frontendUrls.urlLeaguesRanking}private/?league_id=${createdLeagueDetails.id}`);
						}
					});
			}
		}
	};

	return (
		<ModalWindow contentClassName="modal-wrapper width-340px" isOpen={openModalState} toggle={() => {
			toggle();
			actionCleanCreateJoinLeagueStates();
			setValidationErrors({});
			setInputValue("");
			setSelectedRoundCount(null);
		}} >
			<div className="fantasy-modal-title">{title}</div>
			{!!requestErrors.length && requestErrors.map((error) => (
				<ErrorComponent
					handleDelete={actionCleanCreateJoinLeagueStates}
					text={error}
					key={Date.now()}
					isTextCentered
					className="margin-bottom-20px"
				/>
			))}
			<input
				placeholder={inputPlaceholder}
				className={`fantasy-modal-input ${validationErrors.name && "error"}`}
				type="text"
				onChange={(e) => setInputValue(e.target.value)}
			/>
			{validationErrors.name && <div className='fantasy-modal-error pre-wrap w-100'>{validationErrors.name}</div>}

			{type !== "join" &&
				<div
					className={`fantasy-modal-input ${validationErrors.selectedRoundCount && "error"}`}
				>
					<Select
						placeholder={t("Point count starts from Round")}
						handleChange={(value) => {
							setSelectedRoundCount(value.id);
						}}
						values={seasonRoundsData}
						valueObjectKey="name"
						labelObjectKey="name"
						isLoading={false}
						isSearchable={false}
					/>
				</div>
			}
			{validationErrors.selectedRoundCount && <div className='fantasy-modal-error pre-wrap w-100'>{validationErrors.selectedRoundCount}</div>}

			<SpinnerButton
				isLoading={type === "join" ? joinLeagueLoading : createNewLeagueLoading}
				text={type || ""}
				onClick={handleClick}
				className="button_medium"
				size="medium"
			/>
		</ModalWindow>
	);
};

const mapStateToProps = ({ leagueSeasonsReducer, leaguesReducer, seasonRoundsReducer }) => {
	const { seasonsListData } = leagueSeasonsReducer;
	const { seasonRoundsData } = seasonRoundsReducer;
	const { createNewLeagueLoading, joinLeagueLoading, lastCreatedLeagueName,
		joinLeagueError: { parsedTextErrorsWithoutKeys: joinLeagueErrors = [] }, lastJoinedLeagueCode,
		createNewLeagueError: { parsedTextErrorsWithoutKeys: createLeagueErrors = [] }
	} = leaguesReducer;
	const formattedSeasonRoundsData = seasonRoundsData.map((seasonRound) => ({
		id: seasonRound.id,
		name: i18n.t("leagues_modal:round", {
			number: seasonRound.number,
		}),
		number: seasonRound.number
	})).sort((a, b) => a.number - b.number);

	return {
		seasonRoundsData: formattedSeasonRoundsData,
		activeSeasonId: seasonsListData[0] && seasonsListData[0].id,
		createNewLeagueLoading,
		joinLeagueLoading,
		requestErrors: [...joinLeagueErrors, ...createLeagueErrors],
		lastCreatedLeagueName,
		lastJoinedLeagueCode,
	};
};

export default connect(
	mapStateToProps,
	{
		actionPostRequestPrivateLeagues,
		actionJoinRequestPrivateLeagues,
		actionCleanCreateJoinLeagueStates
	},
)(LeaguesModal);

LeaguesModal.propTypes = {
	className: PropTypes.string,
	toggle: PropTypes.func.isRequired,
	openModalState: PropTypes.bool.isRequired,
	seasonRoundsData: PropTypes.array.isRequired,
	type: PropTypes.oneOf([false, "create", "join"]).isRequired,
	activeSeasonId: PropTypes.number.isRequired,
	requestErrors: PropTypes.object.isRequired,
	lastCreatedLeagueName: PropTypes.string.isRequired,
	lastJoinedLeagueCode: PropTypes.string.isRequired,
	joinLeagueLoading: PropTypes.bool.isRequired,
	createNewLeagueLoading: PropTypes.bool.isRequired,
	actionPostRequestPrivateLeagues: PropTypes.func.isRequired,
	actionJoinRequestPrivateLeagues: PropTypes.func.isRequired,
	actionCleanCreateJoinLeagueStates: PropTypes.func.isRequired,
};

LeaguesModal.defaultProps = {
	className: "",
};

LeaguesModal.displayName = "lm";