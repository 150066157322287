import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
	footballRoundPropType,
	leaguesSeasonsPropType,
} from "utils/propTypes";
import { parsedDreamTeamType } from "utils/propTypes/footballTeam";

import {
	getFinishedAndLiveRounds,
	getFormattedDreamTeam,
} from "redux/selectors";
import { actionGetDreamTeam, getDreamTeamRequestSuccess } from "redux/actions/dreamTeams";


import DreamTeamComponent from "modules/home/components/DreamTeam";




const DreamTeam = ({
	finishedAndLiveRounds,
	isLoading,
	actionGetDreamTeam,
	dreamTeam,
	seasonsListData,
	getDreamTeamRequestSuccess,
}) => {
	const [roundIndex, setRoundIndex] = useState(-1);
	const [switchValue, setSwitchValue] = useState(1);
	const [periodType, setPeriodType] = useState("round");

	useEffect(() => {
		if (periodType === "round") {
			const selectedRound = finishedAndLiveRounds[roundIndex];
			if (selectedRound) {
				setSwitchValue(selectedRound.number || 1);
				actionGetDreamTeam({ roundID: selectedRound.id });
			} else {
				setSwitchValue(0);
				getDreamTeamRequestSuccess([]);
			}
		}

		if (periodType === "season") {
			const firstActiveSeason = seasonsListData && seasonsListData.length > 0 && seasonsListData[0];
			if (firstActiveSeason) {
				const { id } = firstActiveSeason;
				setSwitchValue(seasonsListData[0].name);
				id && actionGetDreamTeam({ seasonID: id });
			}
		}

	}, [roundIndex, finishedAndLiveRounds, periodType]);

	useEffect(() => {
		const isFirstRoundNotEmpty = !finishedAndLiveRounds[0]?.isEmpty;
		if (finishedAndLiveRounds.length && isFirstRoundNotEmpty) {
			setRoundIndex(finishedAndLiveRounds.length - 1);
		}
	}, [finishedAndLiveRounds]);

	const handleNext = () => {
		if (roundIndex < finishedAndLiveRounds.length -1) {
			setRoundIndex(roundIndex + 1);
		}
	};

	const handlePrev = () => {
		if (roundIndex > 0) {
			setRoundIndex(roundIndex - 1);
		}
	};

	const sendProps = {
		switchValue,
		handlePrev,
		handleNext,
		dreamTeam,
		periodType,
		setPeriodType,
		isLoading,
		switchRange: periodType === "round" ? finishedAndLiveRounds.length : 0,
		roundIndex,
	};

	return <DreamTeamComponent {...sendProps} />;
};

DreamTeam.propTypes = {
	seasonsListData: PropTypes.arrayOf(PropTypes.shape(leaguesSeasonsPropType.leagueSeasons)).isRequired,
	finishedAndLiveRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.resultGame)).isRequired,
	isLoading: PropTypes.bool.isRequired,
	actionGetDreamTeam: PropTypes.func.isRequired,
	getDreamTeamRequestSuccess: PropTypes.func.isRequired,
	dreamTeam: PropTypes.shape(parsedDreamTeamType).isRequired,
};
const mapStateToProps = state => ({
	seasonsListData: state.leagueSeasonsReducer.seasonsListData,
	dreamTeam: getFormattedDreamTeam(state),
	finishedAndLiveRounds: getFinishedAndLiveRounds(state),
	isLoading: state.dreamTeamsReducer.dreamTeamsRequestLoading,
});

export default connect(
	mapStateToProps,
	{ actionGetDreamTeam, getDreamTeamRequestSuccess }
)(DreamTeam);

DreamTeam.displayName = "drt";