import * as types from "redux/constants/otherUserResults";

import createReducer from "redux/createReducer";

const otherUserResultsInitialState = {
	otherUserResultsHashList: {},
	otherUserResultsRequestError: {},
	otherUserResultsDataUpdateTime: 0,
	otherUserResultRequestLoading: false,
};

const otherUserResultsReducer = createReducer(otherUserResultsInitialState)({
	[types.GET_REQUEST_OTHER_USER_RESULTS_START]: state => ({
		...state,
		otherUserResultRequestLoading: true,
		otherUserResultsHashList: {},
		otherUserResultsRequestError: {},
		otherUserResultsDataUpdateTime: 0,
	}),
	[types.GET_REQUEST_OTHER_USER_RESULTS_SUCCESS]: (state, { payload }) => {
		const { forUserID, data } = payload;
		const { otherUserResultsHashList: currentOtherUserResultsHashList } = state;
		const newOtherUserResultsHashList = { ...currentOtherUserResultsHashList, [forUserID]: data };
		return {
			...state,
			otherUserResultsHashList: newOtherUserResultsHashList,
			otherUserResultRequestLoading: false,
			otherUserResultsRequestError: {},
			otherUserResultsDataUpdateTime: new Date().getTime(),
		};
	},
	[types.GET_REQUEST_OTHER_USER_RESULTS_ERROR]: (state, { payload }) => ({
		...state,
		otherUserRoundsTeamsRequestError: payload,
		otherUserResultRequestLoading: false,
		otherUserResultsDataUpdateTime: new Date().getTime(),
	}),
});

export default otherUserResultsReducer;