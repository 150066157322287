import React, { useState, useCallback } from "react";
import { Col, Spinner } from "reactstrap";
import { Timeline } from "react-twitter-widgets";
import { useMediaQuery } from "react-responsive/src";

const twitterMobileHeight = 580;

const Social = () => {
	const [twitterHeight, setTwitterHeight] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);

	const onRefChange = useCallback(twitterWrapper => {
		setTimeout(() => {
			if (twitterWrapper !== null) {
				let twitterWrapperHeight = twitterWrapper?.clientHeight;
				
				setTwitterHeight(twitterWrapperHeight);
			}
		}, 200);
	});

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 991px)"
	}) || window.innerWidth <= 991;

	return (
		<Col className="social-column px-0">
			{!isLoaded && 
			<div className="d-flex justify-content-center">
				<Spinner className="margin-top-20-40px" />
			</div>}
			<div className="h-100" ref={onRefChange}>
				<Timeline
					dataSource={{
						sourceType: "profile",
						screenName: "serieafantasyte"
					}}
					options={{
						height: isMobileResolution ?
							twitterMobileHeight
							: twitterHeight ,
						tweetLimit: 8,
						dnt: true,
					}}
					onLoad={() => {
						setIsLoaded(true);
					}}
				/>
			</div>
		</Col>
	);
};

export default Social;

Social.displayName = "scc";