import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect } from "react";
import KeyIcon from "static/assets/images/key.svg";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { formValidatorEditLeague } from "utils/validators";

import { REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR } from "redux/constants/leagues";

import {
	setEditPrivateLeagueModalState,
	actionRequestEditPrivateLeague,
	actionRequestRemoveTeamFromPrivateLeague,
	actionRequestDeletePrivateLeague,
	actionCleanPrivateLeagueModalErrors,
	actionRequestSuggestedUserTeams,
	clearSuggestionsUserTeams,
} from "redux/actions/leaguesPrivate";

import Select from "modules/common/Select";
import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";
import MainButton from "modules/common/MainButton";
import ErrorComponent from "modules/common/ErrorComponent";
import CopyIcon from "modules/leagues/components/CopyIcon";

import PrivateLeagueDeleteModal from "./PrivateLeagueDeleteModal";
import SuccessComponent from "./SuccessComponent";

const OwnPrivateLeagueSettingsModal = ({
	setEditPrivateLeagueModalState,
	actionRequestEditPrivateLeague,
	actionRequestSuggestedUserTeams,
	actionRequestDeletePrivateLeague,
	actionRequestRemoveTeamFromPrivateLeague,
	actionCleanPrivateLeagueModalErrors,
	requestErrors,
	leagueData,
	isOpen,
	editPrivateLeagueLoading,
	deletePrivateLeagueLoading,
	suggestedUserTeamsLoading,
	suggestedUserTeams,
	clearSuggestionsUserTeams,
}) => {
	const { t } = useTranslation("own_private_league_settings_modal");
	const [isModalOpen, setIsModalOpen] = useState(isOpen);
	const [entriesClosed, setEntriesClosed] = useState(leagueData.entries_closed);
	const [leagueName, setLeagueName] = useState(leagueData.name);
	const [selectedTeamToRemove, setSelectedTeamToRemove] = useState(null);
	const [teamNameToRemove, setTeamNameToRemove] = useState("");
	const [isDeleteLeagueModalOpen, setIsDeleteLeagueModalOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});
	const [allowClearTeamNameToRemove, setAllowClearTeamNameToRemove] = useState(false);
	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
	const [showDeleteTeamSuccessMessage, setShowDeleteTeamSuccessMessage] = useState(false);

	useEffect(() => {
		if (!teamNameToRemove || /^\s+$/.test(teamNameToRemove) || teamNameToRemove === selectedTeamToRemove?.name) {
			setIsLoadingSuggestions(false);
			return;
		}

		setIsLoadingSuggestions(true);

		const timerId = setTimeout(() => {
			
			actionRequestSuggestedUserTeams({
				teamName: teamNameToRemove,
				leagueID: leagueData.id,
			});
			setIsLoadingSuggestions(false);
		}, 1000);

		return () => {
			clearTimeout(timerId);
			clearSuggestionsUserTeams();
		};
	}, [teamNameToRemove]);

	useEffect(() => {
		setIsModalOpen(isOpen);
		if (!isOpen) {
			setIsDeleteLeagueModalOpen(false);
			setSelectedTeamToRemove(null);
			setTeamNameToRemove("");
		}
	}, [isOpen]);

	useEffect(() => {
		setLeagueName(leagueData.name);
	}, [leagueData.name]);

	useEffect(() => {
		setEntriesClosed(leagueData.entries_closed);
	}, [leagueData.entries_closed]);

	const handleKeyDown = event => {
		if (event.key === "Backspace") {
			setAllowClearTeamNameToRemove(true);
			setTimeout(() => {
				setAllowClearTeamNameToRemove(false);
			}, 100);
		}
	};

	const menuIsOpen = teamNameToRemove.replaceAll(" ", "").length > 0 && teamNameToRemove !== selectedTeamToRemove?.name;

	return (
		<Fragment>
			<ModalWindow contentClassName="private-league-settings-modal own" isOpen={isModalOpen} toggle={() => {
				setEditPrivateLeagueModalState(null);
				setIsModalOpen(false);
			}}>
				<div className="fantasy-modal-title">{t("Edit League")}</div>
				<span className="league-code">
					{t("League Code")}
					&nbsp;
					<img
						className='mb-1 join-code-img'
						src={KeyIcon} alt=""
					/>
					&nbsp;
					<span className="font-weight-bold">
						{leagueData.join_code}
					</span>
					&nbsp;
					<CopyIcon code={leagueData.join_code} isModal />
				</span>
				{!!requestErrors.length && requestErrors.map((error) => (
					<ErrorComponent
						handleDelete={actionCleanPrivateLeagueModalErrors}
						text={error}
						key={Date.now()}
						isTextCentered
						className="margin-bottom-20px"
					/>
				))}
				{showDeleteTeamSuccessMessage && <SuccessComponent
					handleDelete={() => {
						setShowDeleteTeamSuccessMessage(false);
					}}
					text={t("Team successfully removed from this private league")}
				/>}
				<input
					value={leagueName}
					className={`fantasy-modal-input ${validationErrors.name && "error"}`}
					type="text"
					onChange={(e) => setLeagueName(e.target.value)}
				/>
				{validationErrors.name && <div className='fantasy-modal-error pre-wrap w-100'>{validationErrors.name}</div>}

				<div
					className="fantasy-modal-input"
				>
					<Select
						placeholder={t("Enter team name to remove")}
						handleChange={(value) => {
							setSelectedTeamToRemove(value);
							setTeamNameToRemove(value?.name || "");
						}}
						value={selectedTeamToRemove}
						inputValue={teamNameToRemove || ""}
						values={suggestedUserTeams}
						valueObjectKey="name"
						labelObjectKey="name"
						onKeyDown={handleKeyDown}
						onInputChange={(value) => {
							const newTeamNameToRemove = allowClearTeamNameToRemove ? 
								value : 
								value || selectedTeamToRemove?.name || ""; 

							setTeamNameToRemove(newTeamNameToRemove);
							if (newTeamNameToRemove !== selectedTeamToRemove?.name) {
								setSelectedTeamToRemove(null);
							}
						}}
						isSearchable={true}
						noOptionsMessage={() => <span className="select-no-options">
							{isLoadingSuggestions || suggestedUserTeamsLoading ? t("Loading...") : t("No teams found")}
						</span> }
						menuIsOpen={menuIsOpen}
					/>
				</div>

				<div 
					className={selectedTeamToRemove ? "" : "hover-tooltip"} 
					data-hover={t("Search and select the team to remove from this private league")}
				>
					<SpinnerButton
						text={t("Remove team")}
						onClick={() => {
							actionRequestRemoveTeamFromPrivateLeague({
								leagueID: leagueData.id,
								userTeamId: selectedTeamToRemove.id,
							}).then((value) => {
								if (value.type !== REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR) {
									setShowDeleteTeamSuccessMessage(true);
								}
							
								setSelectedTeamToRemove(null);
								setTeamNameToRemove("");
							});
						}}
						className="col-4"
						size="medium"
						color="white"
						disabled={!selectedTeamToRemove}
					/>
				</div>
					
				<label className="checkbox-wrapper">
					{t("Close entries to the league")}
					<input checked={entriesClosed} type="checkbox" onChange={() => {
						setEntriesClosed(value => !value);
					}} />
					<span className="checkmark"></span>
				</label>
				<MainButton
					text={t("Delete League")}
					handler={() => {
						setIsModalOpen(false);
						setIsDeleteLeagueModalOpen(true);
					}}
					className="col-4"
					size="medium"
					color="red"
				/>
				<SpinnerButton
					text={t("Save")}
					onClick={() => {
						const errors = formValidatorEditLeague({
							name: leagueName,
						});
						setValidationErrors(errors);
						if (leagueData.name === leagueName && entriesClosed === leagueData.entries_closed) {
							setEditPrivateLeagueModalState(null);
							setIsModalOpen(false);
						} else if (_.isEmpty(errors)) {
							actionRequestEditPrivateLeague({
								leagueID: leagueData.id,
								leagueName,
								entriesClosed,
							}).then(() => {
								setEditPrivateLeagueModalState(null);
								setIsModalOpen(false);
							});
						}
					}}
					className="col-4"
					size="medium"
					isLoading={editPrivateLeagueLoading}
				/>
			</ModalWindow>
			{isDeleteLeagueModalOpen &&
				<PrivateLeagueDeleteModal
					onClose={() => {
						setIsDeleteLeagueModalOpen(false);
						setIsModalOpen(true);
					}}
					onClick={() => {
						actionRequestDeletePrivateLeague({
							leagueID: leagueData.id,
						});
					}}
					isLoading={deletePrivateLeagueLoading}
				/>}
		</Fragment>
	);
};

const mapStateToProps = state => {
	const { leaguesReducer } = state;

	const { 
		removeTeamFromPrivateLeagueError, 
		editPrivateLeagueError, 
		suggestedUserTeams,
		suggestedUserTeamsLoading,
	} = leaguesReducer;
	const removeTeamFromPrivateLeagueErrorFormatted = removeTeamFromPrivateLeagueError?.data?.parsedTextErrorsWithoutKeys || [];
	const editPrivateLeagueErrorFormatted = editPrivateLeagueError?.parsedTextErrorsWithoutKeys || [];

	return {
		suggestedUserTeams,
		suggestedUserTeamsLoading,
		leagueData: leaguesReducer?.editPrivateLeagueModalState || {},
		editPrivateLeagueLoading: leaguesReducer?.editPrivateLeagueLoading,
		deletePrivateLeagueLoading: leaguesReducer?.deletePrivateLeagueLoading,
		requestErrors: [...removeTeamFromPrivateLeagueErrorFormatted, ...editPrivateLeagueErrorFormatted],
	};
};

export default connect(
	mapStateToProps,
	{
		setEditPrivateLeagueModalState,
		actionRequestEditPrivateLeague,
		actionRequestSuggestedUserTeams,
		actionRequestRemoveTeamFromPrivateLeague,
		actionRequestDeletePrivateLeague,
		actionCleanPrivateLeagueModalErrors,
		clearSuggestionsUserTeams
	},
)(OwnPrivateLeagueSettingsModal);

OwnPrivateLeagueSettingsModal.propTypes = {
	setEditPrivateLeagueModalState: PropTypes.func.isRequired,
	actionRequestEditPrivateLeague: PropTypes.func.isRequired,
	actionRequestSuggestedUserTeams: PropTypes.func.isRequired,
	actionRequestRemoveTeamFromPrivateLeague: PropTypes.func.isRequired,
	actionRequestDeletePrivateLeague: PropTypes.func.isRequired,
	actionCleanPrivateLeagueModalErrors: PropTypes.func.isRequired,
	leagueData: PropTypes.object.isRequired,
	requestErrors: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	deletePrivateLeagueLoading: PropTypes.bool.isRequired,
	editPrivateLeagueLoading: PropTypes.bool.isRequired,
	suggestedUserTeams: PropTypes.array.isRequired,
	clearSuggestionsUserTeams: PropTypes.func.isRequired,
	suggestedUserTeamsLoading: PropTypes.bool.isRequired,
};

OwnPrivateLeagueSettingsModal.displayName = "oplsm";