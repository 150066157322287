import React, { useEffect } from "react";
import {
	Route, Switch,
} from "react-router-dom";

import frontendUrls from "routes/frontendUrls";

import useQuery from "helpers/hooks/useQuery";
import history from "helpers/history";

import SelectTeamContainer from "modules/registration/containers/SelectTeamContainer";
import SignUpClosedContainer from "modules/registration/containers/SignUpClosedContainer";
import PersonalDetails from "modules/registration/containers/PersonalDetails";
import ConfirmEmail from "modules/registration/containers/ConfirmEmail";
import RegistrationCompleted from "modules/registration/containers/RegistrationCompleted";
import NotFoundPage from "modules/common/NotFoundPage";

const isSignUpOpen = process.env.REACT_APP_SIGN_UP_OPEN;

export default function Registration() {
	const query = useQuery();
	const hash = query.get("hash");
	const { pathname } = window.location;

	useEffect(() => {
		if (isSignUpOpen === "false" && !pathname.includes(frontendUrls.urlSignUpClosed) && pathname.includes(frontendUrls.urlSelectTeam)) {
			history.push(frontendUrls.urlSignUpClosed);
		}
	}, [isSignUpOpen, pathname]);

	return (
		<Switch>
			{isSignUpOpen === "false" ?
				<Route path={frontendUrls.urlSignUpClosed}>
					<SignUpClosedContainer />
				</Route> :
				<Route path={frontendUrls.urlSelectTeam}>
					<SelectTeamContainer />
				</Route>
			}
			<Route path={frontendUrls.urlPersonalDetails}>
				<PersonalDetails />
			</Route>
			<Route path={frontendUrls.urlConfirmEmail}>
				<ConfirmEmail />
			</Route>
			<Route path={frontendUrls.urlActivationSuccess}>
				{!!hash && <RegistrationCompleted />}
				{!hash && <NotFoundPage/>}
			</Route>
			<Route exact path={frontendUrls.urlSignUp}>
				<NotFoundPage/>
			</Route>
			<Route path="*" >
				<NotFoundPage />
			</Route>
		</Switch>
	);
}

Registration.displayName = "rcon";