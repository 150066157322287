import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import tagIcon from "static/assets/images/tag.svg";
import editorIcon from "static/assets/images/editor.svg";
import calendarIcon from "static/assets/images/calendar.svg";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import LazyLoadImage from "modules/news/components/LazyLoadImage";
import MainButton from "modules/common/MainButton";

const News = ({
	news,
}) => {
	const { t } = useTranslation("news");
	return (
		<Col className="news-column">
			{news.map((newsItem) => (
				<Row key={newsItem.id} className="news-item-wrapper">
					<Col className="news-item">
						<LazyLoadImage
							image={newsItem.image}
						/>
						<hr />
						<h3>{newsItem.headline}</h3>
						<p>{newsItem.short_text}</p>
						
						<Link to={`${frontendUrls.urlNews}?article_id=${newsItem.id}`} tabIndex="-1">
							<MainButton size="large">{t("Read more...")}</MainButton>
						</ Link>
						
						<Row className="bottom-info">
							<img src={tagIcon} alt="" />
							<span>
								{newsItem.tags.map((tag, i) => (
									<span key={tag.id}>
										{i ? ", ": null}
										{tag.name}
									</span>
								))}
							</span>
						</Row>
						<Row className="bottom-info">
							<img src={calendarIcon} alt="" />
							{newsItem.created ? <span>{moment(newsItem.created).format("DD MMM YYYY")}</span> : null}
						</Row>
						<Row className="bottom-info">
							<img src={editorIcon} alt="" />
							<span>{newsItem.author.full_name}</span>
						</Row>
					</Col>
				</Row>
			))}
		</Col>
	);
};

News.propTypes = {
	news: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default News;

News.displayName = "nwsc";