import React from "react";
import { Container } from "reactstrap";
import logoLarge from "static/assets/images/logo-large.svg";
import { useTranslation } from "react-i18next";

import BlueSubheader from "modules/common/BlueSubheader";

const NotFoundPage = () => {
	const { t } = useTranslation("not_found_page");
	return (
		<div className="main-page">
			<BlueSubheader blueTextPart="OOPS... " whiteTextPart="404" />
			<Container className="d-flex align-items-center flex-column">
				<div className="blue-plank-top-service" />
				<div className="service-message">
					<div>{t("Page not found")}</div>
					<img className="main-logo" src={logoLarge} alt="" />
				</div>
			</Container>
		</div>
	);
};

export default NotFoundPage;

NotFoundPage.displayName = "nfp";