import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	Row,
} from "reactstrap";
import classNames from "classnames";
import dropdownClosed from "static/assets/images/dropdown-closed.svg";
import dropdownOpened from "static/assets/images/dropdown-opened.svg";

import {
	footballRoundPropType,
	roundGamePropType,
} from "utils/propTypes";

const FAQItem = ({
	question_text,
	answer_text,
}) => {
	const [ isOpenDropdown, setIsOpenDropdown ] = useState(false);

	const handleToggleDropdown = () => {
		setIsOpenDropdown(!isOpenDropdown);
	};

	const headerCssClass = classNames(
		"faq-item__question",
		"schedule-item__header",
		"schedule-item__header_hover",
		"dropdown",
		{
			"schedule-item__header_opened": isOpenDropdown,
		}
	);
	const scheduleOpened = classNames({
		"schedule-item_opened": isOpenDropdown,
	});
	const dropdownIconOpened = classNames({
		"dropdown__icon_opened": isOpenDropdown,
		"dropdown__icon": !isOpenDropdown,
	});

	return (
		<div className={`${scheduleOpened} schedule-item faq-item faq-item_mb`}>
			<Row className={headerCssClass} onClick={() => handleToggleDropdown("id")}>
				<div className="faq-item__question-title">{question_text}</div>
				<div className={dropdownIconOpened}>
					<img src={isOpenDropdown ? dropdownOpened : dropdownClosed } alt="" />
				</div>
			</Row>
			{isOpenDropdown &&
				<div className="faq-item__answer">
					{answer_text}
				</div>
			}
		</div>
	);
};

FAQItem.propTypes = {
	question_text: PropTypes.string.isRequired,
	answer_text: PropTypes.string.isRequired,
	created: PropTypes.string.isRequired,
	position: PropTypes.number.isRequired,
	round: PropTypes.shape(footballRoundPropType.round).isRequired,
	roundGames: PropTypes.arrayOf(PropTypes.shape(roundGamePropType.resultGame)).isRequired,
	handleToggleDropdown: PropTypes.func.isRequired,
	isOpenDropdown: PropTypes.bool,
};

export default FAQItem;

FAQItem.displayName = "faqi";