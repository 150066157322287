import React from "react";
import PropTypes from "prop-types";
import {
	Route,
	Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import frontendUrls from "routes/frontendUrls";

import {
	actionSwitchLoginModalOpenState,
} from "redux/actions/auth";

const CheckAuthAndVerifiedRoute = ({
	component: Component, isUserEmailConfirmed, userDetailsRequestLoading, isUserLoggedIn, actionSwitchLoginModalOpenState, ...rest
}) => {
	const { urlRoot, urlConfirmEmail } = frontendUrls;
	const checkConfirmed = properties => ((isUserEmailConfirmed || (isUserLoggedIn && userDetailsRequestLoading)) ? (
		<Component {...properties} />
	) : (
		<Redirect
			to={{
				pathname: urlConfirmEmail,
			}}
		/>
	));

	const checkAuthenticated = () => {
		if (isUserLoggedIn) {
			return <Route render={checkConfirmed} />;
		} else {
			actionSwitchLoginModalOpenState({
				forceOpen: true,
			});
			return (
				<Redirect
					to={{
						pathname: urlRoot,
					}}
				/>);
		}
	};

	return (
		<Route
			{...rest}
			render={checkAuthenticated}
		/>
	);
};

CheckAuthAndVerifiedRoute.propTypes = {
	component: PropTypes.func.isRequired,
	actionSwitchLoginModalOpenState: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	isUserEmailConfirmed: PropTypes.bool.isRequired,
	isUserLoggedIn: PropTypes.bool.isRequired,
	exact: PropTypes.bool,
	userDetailsRequestLoading: PropTypes.bool.isRequired,
};

CheckAuthAndVerifiedRoute.defaultProps = {
	exact: false,
};

const mapStateToProps = state => {
	const { authReducer: { userDetailsRequestLoading, userDetails: { is_email_verified: isUserEmailConfirmed } = {}, isUserLoggedIn } } = state;

	return {
		isUserLoggedIn,
		isUserEmailConfirmed,
		userDetailsRequestLoading,
	};
};

export default connect(mapStateToProps, { actionSwitchLoginModalOpenState })(CheckAuthAndVerifiedRoute);

CheckAuthAndVerifiedRoute.displayName = "caavr";