import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/rules";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getRulesItemStart = payload => ({
	type: types.GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_START,
	payload,
});

const getRulesItemRulesStart = payload => ({
	type: types.GET_REQUEST_RULES_ITEM_RULES_START,
	payload,
});

const getRulesItemRulesSuccess = payload => ({
	type: types.GET_REQUEST_RULES_ITEM_RULES_SUCCESS,
	payload,
});

const getRulesItemRulesError = payload => ({
	type: types.GET_REQUEST_RULES_ITEM_RULES_ERROR,
	payload,
});

export const actionGetRequestRulesItem = (params, tabName) => dispatch => {
	tabName !== "rules"
		? dispatch(getRulesItemStart())
		: dispatch(getRulesItemRulesStart());
	const config = {
		params,
		method: "GET",
		url: backendApiUrls.rulesItem,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getRulesItemRulesSuccess(data));
		})

		.catch((error) => {
			dispatch(getRulesItemRulesError(errorsParser(error)));
		});
};
