import React from "react";
import {
	Progress,
	Container,
} from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import Title from "modules/common/Title";

const ProgressNextSeason = ({
	seasonName
}) => {
	const { t } = useTranslation("progress_next_season");
	const step1CssClass = classNames(
		"step__upcoming",
		{ "step__upcoming_taken": false },
	);
	const step2CssClass = classNames(
		"step__upcoming step__upcoming_3"
	);
	return (
		<div className="progress-block progress-block_height progress-block_paddings">
			<Container className="progress-block__container">
				<Title 
					text1={<span className='head-text'>
						<Trans
							i18nKey="welcome"
							ns="progress_next_season"
							components={[
								<span key="0" className='color-yellow' />
							]}
							values={{
								seasonName
							}}
						/>
					</span>} 
					color="white" lineColor="yellow" mb={16} mbMd={20}/>
				<div className={`progress-block__wrapper progress-block__wrapper_step${1} progress-block__wrapper_isNextSeason`}>
					<Progress max={2} value={2} className="progress_mt"/>
					<div
						className={step1CssClass}>
						<span className="step__text step__text_pos1">{t("Select Team")}</span>
					</div>
					<div
						className={step2CssClass}>
						<span className="step__text step__text_pos2">{t("Personal Details")}</span>
					</div>
					<div key="progress-1" className={`step step_${1}`}>
						<div className="step__inner">{1}</div>
					</div>
					<div key="progress-2" className={`step step_${2} step_2_isNextSeason`}>
						<div className="step__inner">{2}</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

ProgressNextSeason.propTypes = {
	seasonName: PropTypes.string.isRequired,
};

export default ProgressNextSeason;

ProgressNextSeason.displayName = "pns";