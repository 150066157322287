import React from "react";
import PropTypes from "prop-types";


const Social = ({ url, icon }) => (
	<a className="social__link" href={url} rel="noopener noreferrer" target="_blank">
		<img className="social__icon" src={icon} alt=""/>
	</a>
);

Social.propTypes = {
	url: PropTypes.string,
	icon: PropTypes.string,
};

Social.defaultTypes = {
	url: "",
	icon: "",
};

Social.displayName = "s";

export default Social;
