import React from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import MainButton from "modules/common/MainButton";

const SignUpBlock = () => {
	const { t } = useTranslation("sign_up_block");
	return (<Row className="sign-up-block">
		<div className="sign-up-block__image-wrapper image">
			<div className="sign-up-block__image image">
				<div className="image__1"/>
				<div className="image__2"/>
				<div className="image__3"/>
				<div className="image__4"/>
				<div className="image__5"/>
				<div className="image__6"/>
			</div>
			<div className="image__7"/>
			<div className="image__8"/>
		</div>
		<div className="sign-up-block__content">
			<Container>
				<h2 className="sign-up-block__title">{t("Create your own 15-man squad")}</h2>
				<Link className="sign-up-block__link" to={frontendUrls.urlSelectTeam} tabIndex="-1">
					<MainButton text={t("Sign Up")} size="medium" color="white" className="single" />
				</Link>
			</Container>
		</div>
	</Row>
	);
};

SignUpBlock.displayName = "sub";

export default SignUpBlock;
