import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive/src";
import { useEffect } from "react";

import backendApiUrls from "routes/backendUrls";

import { actionGetRequestLeaguesPagination } from "redux/actions/leaguesPagination";

import { FOR_REPLACEMENT, LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } from "helpers/constants/common";
import useQuery from "helpers/hooks/useQuery";
import history from "helpers/history";


const PaginationWrapper = ({
	leaguesSubtype,
	className,
	ownPage,
	count,
	selectedItem,
	activeSeasonId,
	seasonLeagueId,
	actionGetRequestLeaguesPagination,
	currentLeaguesSubtypeOrID,
	paginationRequestLoading,
	activeRoundLeagueId,
}) => {
	const pageNumber = useQuery().get("page");
	const leagueId = useQuery().get("league_id");

	useEffect(() => {
		if (ownPage && ownPage !== pageNumber && !pageNumber) {
			// set initial page
			history.push({
				pathname: history.location.pathname,
				search: `?page=${ownPage}${leagueId ? "&league_id=" + leagueId : ""}`,
			});

		}
	}, [paginationRequestLoading, ownPage]);

	const lastPage = Math.ceil(count / LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE);

	const handlePageClick = ({ selected }) => {
		history.push({
			pathname: history.location.pathname,
			search: `?page=${selected + 1}${leagueId ? "&league_id=" + leagueId : ""}`,
		});
	};

	const isPaginationRequestInfoReady = (["quad", "country"].includes(leaguesSubtype) && selectedItem.id)
		|| (leaguesSubtype === "private" && currentLeaguesSubtypeOrID)
		|| (leaguesSubtype === "round" && activeRoundLeagueId)
		|| (leaguesSubtype === "season" && seasonLeagueId);

	useEffect(() => {
		let incomingUrl;

		if (pageNumber && isPaginationRequestInfoReady) {
			switch (leaguesSubtype) {
			case "round":
				incomingUrl = backendApiUrls.leagueRoundLeague.replace(FOR_REPLACEMENT.ID, activeRoundLeagueId);
				selectedItem.id && actionGetRequestLeaguesPagination({ incomingUrl, filter: { page: pageNumber, page_size: LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } });
				break;
			case "season":
				incomingUrl = backendApiUrls.leagueSeasonLeague.replace(FOR_REPLACEMENT.ID, seasonLeagueId);
				activeSeasonId && actionGetRequestLeaguesPagination({ incomingUrl, filter: { page: pageNumber, page_size: LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } });
				break;
			case "quad":
				incomingUrl = `${backendApiUrls.leagueQuadLeague.replace(FOR_REPLACEMENT.ID, selectedItem.id)}`;
				selectedItem.id && actionGetRequestLeaguesPagination({ incomingUrl, filter: { page: pageNumber, page_size: LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } });
				break;
			case "country":
				incomingUrl = backendApiUrls.leagueCountryLeague.replace(FOR_REPLACEMENT.ID, selectedItem.id);
				activeSeasonId &&
						actionGetRequestLeaguesPagination({ incomingUrl, filter: { page: pageNumber, page_size: LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } });
				break;
			case "private":
				incomingUrl = backendApiUrls.leaguePrivateLeague.replace(FOR_REPLACEMENT.PRIVATE_LEAGUE_ID, currentLeaguesSubtypeOrID);
				activeSeasonId && currentLeaguesSubtypeOrID && actionGetRequestLeaguesPagination({ incomingUrl, filter: { page: pageNumber, page_size: LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } });
				break;
			}
		}
	}, [pageNumber, isPaginationRequestInfoReady, selectedItem.id, activeSeasonId]);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	if (!count) {
		return null;
	}

	return (
		<ReactPaginate
			disableInitialCallback={true}
			onPageChange={handlePageClick}
			pageCount={lastPage}
			pageRangeDisplayed={isMobileResolution ? 2 : 4}
			marginPagesDisplayed={1}
			breakLabel={"."}
			breakClassName="pagination-component__break"
			breakLinkClassName="pagination-component__break-link"
			forcePage={pageNumber && pageNumber - 1}
			containerClassName={`${className} pagination-component`}
			pageClassName="pagination-component__page"
			activeClassName="pagination-component__page_active"
			activeLinkClassName="pagination-component__page-link_active"
			pageLinkClassName="pagination-component__page-link"
			previousClassName="pagination-component__previous"
			previousLinkClassName="pagination-component__previous-link"
			nextClassName="pagination-component__next"
			nextLinkClassName="pagination-component__next-link"
			previousLabel=""
			nextLabel=""
		/>
	);
};

PaginationWrapper.propTypes = {
	userCountryCode: PropTypes.string,
	ownPage: PropTypes.number.isRequired,
	paginationRequestLoading: PropTypes.number.isRequired,
	className: PropTypes.string,
	count: PropTypes.number,
	lastFinishedRoundId: PropTypes.number,
	activeSeasonId: PropTypes.number,
	currentListItem: PropTypes.object,
	selectedItem: PropTypes.object.isRequired,
	leaguesSubtype: PropTypes.string,
	currentLeaguesSubtypeOrID: PropTypes.string,
	actionGetRequestLeaguesPagination: PropTypes.func.isRequired,
	activeRoundLeagueId: PropTypes.number.isRequired,
	seasonLeagueId: PropTypes.number.isRequired,
};

PaginationWrapper.defaultProps = {
	className: "",
	count: 0,
	previous: "",
	next: "",
};

const mapStateToProps = state => {
	const { leaguesReducer, leagueSeasonsReducer } = state;
	const { seasonLeagueInfo, leaguesType, roundLeaguesData, leaguesSubtype, paginationData: { count }, paginationRequestLoading } = leaguesReducer;
	const activeSeason = leagueSeasonsReducer.seasonsListData[0];
	return {
		activeRoundLeagueId: roundLeaguesData?.id,
		count,
		paginationRequestLoading,
		leaguesSubtype: leaguesType === "private" ? leaguesType : leaguesSubtype,
		activeSeasonId: activeSeason?.id,
		seasonLeagueId: seasonLeagueInfo?.id,
	};
};

export default connect(
	mapStateToProps,
	{ actionGetRequestLeaguesPagination }
)(PaginationWrapper);

PaginationWrapper.displayName = "pw";