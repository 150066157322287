import React from "react";
import {
	Nav, NavItem, NavLink,
	Container, Row,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Tabs = ({ value, setPeriodType }) => {
	const { t } = useTranslation("tabs");
	return (
		<Container>
			<Row className="d-flex justify-content-center dream-team__tab-list">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: value === "round" })}
							onClick={() => { setPeriodType("round"); }}
						>
							{t("Round")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: value === "season" })}
							onClick={() => { setPeriodType("season"); }}
						>
							{t("Season")}
						</NavLink>
					</NavItem>
				</Nav>
			</Row>
		</Container>
	);
};

Tabs.propTypes = {
	setPeriodType: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

Tabs.displayName = "tabs";

export default Tabs;