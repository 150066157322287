import React from "react";
import propTypes from "prop-types";

import { errorsPropTypes } from "utils/propTypes/registrationUser";

const ErrorMessage = ({ fieldName, errors, className, singleError }) => (
	<span key={errors[fieldName]} className={`error__message mt-1 ${className}`}>{singleError || errors[fieldName]}</span>
);

ErrorMessage.propTypes = {
	fieldName: propTypes.string.isRequired,
	errors: errorsPropTypes,
	className: propTypes.string,
	singleError: propTypes.string
};

ErrorMessage.defaultProps = {
	errors: {},
	className: "",
	singleError: "",
};

export default ErrorMessage;

ErrorMessage.displayName = "em";