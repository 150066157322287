import React, { useEffect, useState } from "react";
import {
	TabContent, TabPane
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { actionGetRequestPlayerInfo } from "redux/actions/playersList";
import { actionGetRequestPlayerFixtures } from "redux/actions/playerFixtures";
import { actionGetRequestPlayerPastSeasonResults } from "redux/actions/playerPastSeasonResults";


import ModalWindow from "modules/common/ModalWindow";

import Header from "./Header";
import RoundResults from "./RoundResults";
import { FIXTURES_TAB, PAST_SEASON_RESULTS_TAB, ROUND_RESULTS_TAB, TABS } from "./constants";
import Fixtures from "./Fixtures";
import PastSeasonResults from "./PastSeasonResults";

const PlayerInfoModal = ({
	switchModal,
	currentPlayerLoading,
	playerID,
	currentPlayerData,
	activeSeasonId,
	actionGetRequestPlayerInfo,
	actionGetRequestPlayerFixtures,
	actionGetRequestPlayerPastSeasonResults,
	currentPlayerFixtures,
	currentPlayerFixturesLoading,
	currentPlayerPastSeasonResults,
	currentPlayerPastSeasonResultsLoading,
}) => {
	const {
		totalPointsList,
		matches = [],
		position,
		popularity_pct,
		status,
		first_name,
		last_name,
		current_price: currentPrice,
		initial_price: initialPrice,
		football_club: { jersey_goalkeeper, jersey_outfield, full_name: clubName } = {}
	} = currentPlayerData;

	const { t } = useTranslation("player_info_modal");

	const [activeTab, setActiveTab] = useState(ROUND_RESULTS_TAB);
	
	const selectActiveTab = tab => {
		if(activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const full_name = `${first_name} ${last_name}`;

	const isGoalkeeper = position === "goalkeeper";
	const jersey = isGoalkeeper ? jersey_goalkeeper : jersey_outfield;

	useEffect(() => {
		if (playerID && activeSeasonId) {
			actionGetRequestPlayerInfo({ playerID, season_id: activeSeasonId });
		}
	}, [playerID, activeSeasonId]);

	useEffect(() => {
		if (playerID && position) {
			actionGetRequestPlayerFixtures({ playerID });

			actionGetRequestPlayerPastSeasonResults({ playerID, playerPosition: position });
		}
	}, [playerID, position]);

	let percent = popularity_pct || 0;
	if (typeof percent === "number") {
		percent = percent.toFixed(1);
	}

	return (
		<ModalWindow contentClassName="player-info-modal" isOpen={true} toggle={switchModal}>
			{currentPlayerData.id && !currentPlayerLoading ?
				<Header 
					jersey={jersey}
					fullName={full_name}
					clubName={clubName}
					currentPrice={currentPrice}
					initialPrice={initialPrice}
					position={position}
					status={status}
					percent={percent}
				/> : null
			}

			<div className="tabs">
				{TABS.map((tab) => {
					return (
						<button 
							onClick={() => {
								selectActiveTab(tab);
							}} 
							key={tab}
							className={`tab ${activeTab === tab ? "active" : ""}`}
						>
							{t(tab)}
						</button>
					);
				})}
			</div>

			<TabContent activeTab={activeTab} className="max-width-100percent margin-top-20px w-100">
				<TabPane tabId={ROUND_RESULTS_TAB}>	
					<RoundResults
						matches={matches}
						position={position}
						totalPointsList={totalPointsList}
						isLoading={currentPlayerLoading || !currentPlayerData.id}
					/> 
				</TabPane>
				<TabPane tabId={FIXTURES_TAB}>
					<Fixtures 
						fixtures={currentPlayerFixtures}
						isLoading={currentPlayerFixturesLoading}
					/>
				</TabPane>
				<TabPane tabId={PAST_SEASON_RESULTS_TAB}>
					<PastSeasonResults
						results={currentPlayerPastSeasonResults}
						position={position}
						isLoading={currentPlayerPastSeasonResultsLoading}
					/>
				</TabPane>
			</TabContent>
		</ModalWindow>
	);
};

const mapStateToProps = ({ playersListReducer, leagueSeasonsReducer }) => {
	const { 
		playersInfoHashList, playersInfoLoadingsHashList, playerInfoModalID: playerID,
		playersFixturesHashList, playersFixturesLoadingsHashList,
		playersPastSeasonResultsHashList, playersPastSeasonResultsLoadingsHashList 
	} = playersListReducer;
	const { seasonsListData } = leagueSeasonsReducer;
	const currentPlayerData = playersInfoHashList[playerID];
	const currentPlayerLoading = playersInfoLoadingsHashList[playerID];

	const currentPlayerFixtures = playersFixturesHashList[playerID];
	const currentPlayerFixturesLoading = playersFixturesLoadingsHashList[playerID];

	const currentPlayerPastSeasonResults = playersPastSeasonResultsHashList[playerID];
	const currentPlayerPastSeasonResultsLoading = playersPastSeasonResultsLoadingsHashList[playerID];

	return {
		currentPlayerData,
		currentPlayerLoading,

		currentPlayerFixtures,
		currentPlayerFixturesLoading,

		currentPlayerPastSeasonResults,
		currentPlayerPastSeasonResultsLoading,

		playerID,
		activeSeasonId: seasonsListData[0] && seasonsListData[0].id,
	};

};

export default connect(
	mapStateToProps,
	{
		actionGetRequestPlayerInfo,
		actionGetRequestPlayerFixtures,
		actionGetRequestPlayerPastSeasonResults,
	}
)(PlayerInfoModal);

PlayerInfoModal.propTypes = {
	className: PropTypes.string,
	switchModal: PropTypes.func.isRequired,
	currentPlayerData: PropTypes.object,
	currentPlayerFixtures: PropTypes.array.isRequired,
	currentPlayerLoading: PropTypes.bool.isRequired,
	currentPlayerFixturesLoading: PropTypes.bool.isRequired,
	currentPlayerPastSeasonResults: PropTypes.array.isRequired,
	currentPlayerPastSeasonResultsLoading: PropTypes.bool.isRequired,
	activeSeasonId: PropTypes.number.isRequired,
	playerID: PropTypes.number.isRequired,
	actionGetRequestPlayerInfo: PropTypes.func.isRequired,
	actionGetRequestPlayerFixtures: PropTypes.func.isRequired,
	actionGetRequestPlayerPastSeasonResults: PropTypes.func.isRequired,
};

PlayerInfoModal.defaultProps = {
	currentPlayerData: {},
};

PlayerInfoModal.displayName = "pim";