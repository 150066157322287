import React from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorComponent from "modules/common/ErrorComponent";

const ConfirmWildcardModal = ({ callback, isOpen, handleToggle, isLoading, errors,	cleanErrors }) => {
	const { t } = useTranslation("confirm_wildcard_modal");
	return (
		<ModalWindow toggle={handleToggle} isOpen={isOpen} contentClassName={"width-340px"}>
			<div className='d-flex align-items-center flex-column'>
				<div className='fantasy-modal-title'>{t("Confirm Wildcard")}</div>
				<div className="fantasy-modal-subtitle">
					{t("Confirmation Text")}
				</div>
				{errors?.length ? <ErrorComponent
					handleDelete={cleanErrors}
					text={errors.join(" ")}
					isTextCentered
					className="margin-bottom-20px"
				/> : null}
				<SpinnerButton
					isLoading={isLoading}
					text={t("Confirm")}
					onClick={callback}
					className="button_medium"
					size="medium"
				/>
			</div>
		</ModalWindow>
	);
};

ConfirmWildcardModal.propTypes = {
	callback: propTypes.func.isRequired,
	handleToggle: propTypes.func.isRequired,
	cleanErrors: propTypes.func.isRequired,
	isOpen: propTypes.bool.isRequired,
	isLoading: propTypes.bool.isRequired,
	errors: propTypes.arrayOf(propTypes.string).isRequired,
};

export default ConfirmWildcardModal;

ConfirmWildcardModal.displayName = "cwm";