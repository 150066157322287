import React, { useEffect } from 'react';
import PropTypes from "prop-types";

const AdUnit = ({ adUnitId }) => {
  useEffect(() => {
    if (typeof window === 'object') {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <ins
      className="adsbygoogle adunit-ins"
      style={{ display: "block" }}
      data-ad-client="ca-pub-3464079739225168"
      data-ad-slot={adUnitId}
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </ins>
  )
}

AdUnit.propTypes = {
	adUnitId: PropTypes.string.isRequired,
};

export default AdUnit;