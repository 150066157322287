import classNames from "classnames";
import { supportedLanguages } from "i18instance";
import React, { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import dropdownIcon from "static/assets/images/dropdown-arrow-black.svg";
import languageEn from "static/assets/images/language-en.svg";
import languageIt from "static/assets/images/language-it.svg";

import frontendUrls from "routes/frontendUrls";

import useOnClickOutside from "helpers/hooks/useOnClickOutside";

const notSupportedUrls = [
	frontendUrls.urlMaintenance
];

const LanguageSelect = () => {
	const { i18n } = useTranslation();
	const [ isOpen, setIsOpen ] = useState(false);

	const mainRef = useRef();
	
	useOnClickOutside(mainRef, () => {
		setIsOpen(false);
	});

	const currentLanguage = i18n.language;

	const iconClass = classNames({
		"icon opened": isOpen,
		"icon": !isOpen,
	});

	const handleSelect = (lang) => {
		i18n.changeLanguage(lang);
		setIsOpen(false);
		window.location.reload(true);
	};

	const languageIconsMapper = {
		en: languageEn,
		it: languageIt,
	};

	if (notSupportedUrls.some(url => window.location.pathname.includes(url))) {
		return null;
	}

	return (
		<div ref={mainRef} className="language-select">
			<button onClick={() => {
				setIsOpen((val) => !val);
			}}>
				<img src={languageIconsMapper[currentLanguage]} className="language-icon selected" alt="" />	
				<span className={iconClass}>
					<img src={dropdownIcon} alt="" />	
				</span>
			</button>

			<Collapse isOpen={isOpen} >
				{supportedLanguages.map(language => {
					const selected = language === currentLanguage;
					return (
						<div
							className={classNames("item", {
								selected,
							})} 
							key={language}
						>
							<button onClick={() => {
								handleSelect(language);
							}}>
								<img src={languageIconsMapper[language]} className={classNames("language-icon", {
									selected,
								})} alt="" />	
								{language}
							</button>
						</div>
					);
				})}
			</Collapse>
		</div>
	);
};

export default LanguageSelect;


LanguageSelect.displayName = "ls";