import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/seasonRounds";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

const startGetRoundsFootballRound = () => ({
	type: types.GET_REQUEST_SEASON_ROUNDS_START,
});

const finishGetRoundsFootballRound = payload => ({
	type: types.GET_REQUEST_SEASON_ROUNDS_SUCCESS,
	payload,
});

const failedGetRoundsFootballRound = error => ({
	type: types.GET_REQUEST_SEASON_ROUNDS_ERROR,
	error,
});

export const actionGetRequestSeasonRounds = (filter, id) => dispatch => {
	dispatch(startGetRoundsFootballRound());
	const config = {
		method: "GET",
		url: `${backendApiUrls.footballRounds}${filter}${id}`,
	};

	return axiosPlus(config)
		.then((response) => {
			dispatch(finishGetRoundsFootballRound(response.data.results));
		})

		.catch((error) => {
			dispatch(failedGetRoundsFootballRound(errorsParser(error)));
		});
};


const startFinishedRoundsSortedFromNewData = () => ({
	type: types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_START,
});

const finishFinishedRoundsSortedFromNewData = payload => ({
	type: types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_SUCCESS,
	payload,
});

const failedFinishedRoundsSortedFromNewData = error => ({
	type: types.GET_REQUEST_FINISHED_ROUNDS_SORTED_NEW_ERROR,
	error,
});

export const actionGetRequestFinishedRoundsSortedFromNew = (filter) => dispatch => {
	dispatch(startFinishedRoundsSortedFromNewData());
	const config = {
		params: filter,
		method: "GET",
		url: backendApiUrls.footballRounds,
	};

	return axiosPlus(config)
		.then((response) => {
			dispatch(finishFinishedRoundsSortedFromNewData(response.data.results));
		})

		.catch((error) => {
			dispatch(failedFinishedRoundsSortedFromNewData(errorsParser(error)));
		});
};
