import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/countries";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getCountriesStart = payload => ({
	type: types.GET_REQUEST_COUNTRIES_START,
	payload,
});

const getCountriesSuccess = payload => ({
	type: types.GET_REQUEST_COUNTRIES_SUCCESS,
	payload,
});

const getCountriesError = payload => ({
	type: types.GET_REQUEST_COUNTRIES_ERROR,
	payload,
});

export const actionGetCountries = () => dispatch => {
	dispatch(getCountriesStart());
	const config = {
		method: "GET",
		url: backendApiUrls.countries,
	};

	axiosPlus(config)
		.then(response => {
			const { data: { countries } = {} } = response;
			dispatch(getCountriesSuccess(countries));
		})

		.catch((error) => {
			dispatch(getCountriesError(errorsParser(error) ));
		});
};
