import _ from "lodash";

import * as types from "redux/constants/playersList";

import createReducer from "redux/createReducer";

export const playersListInitialState = {
	paginatedPlayersListData: {},
	playersListRequestError: {},
	playersListRequestLoading: false,
	lastPlayersRequestParams: {},
	currentSearchParams: {},

	playersInfoLoadingsHashList: {},
	playersInfoErrorsHashList: {},
	playersInfoHashList: {},

	playersFixturesLoadingsHashList: {},
	playersFixturesErrorsHashList: {},
	playersFixturesHashList: {},

	playersPastSeasonResultsLoadingsHashList: {},
	playersPastSeasonResultsErrorsHashList: {},
	playersPastSeasonResultsHashList: {},

	playersListRequestLoadingPagination: false,
	playerInfoModalID: 0,
};

const playersListReducer = createReducer(playersListInitialState)({
	[types.GET_REQUEST_PLAYERS_LIST_START]: (state, { payload }) => {
		const { forParams } = payload;
		const { lastPlayersRequestParams: currentLastPlayersRequestParams } = state;
		const isPaginationSearch = _.isEqual(forParams, currentLastPlayersRequestParams);
		const newPaginatedPlayersListData = isPaginationSearch ? state.paginatedPlayersListData : {};
		return {
			...state,
			currentSearchParams: forParams,
			paginatedPlayersListData: newPaginatedPlayersListData,
			playersListRequestError: {}	,
			playersListRequestLoading: !isPaginationSearch,
			playersListRequestLoadingPagination: isPaginationSearch,
		};
	},
	[types.RESET_PLAYERS_LIST]: state => ({
		...state,
		paginatedPlayersListData: {},
		lastPlayersRequestParams: {},
	}),
	[types.GET_REQUEST_PLAYERS_LIST_SUCCESS]: (state, { payload }) => {
		const { data, forParams, page } = payload;
		const {
			paginatedPlayersListData,
			currentSearchParams = {}, 
			lastPlayersRequestParams: currentLastPlayersRequestParams = {}
		} = state;
		const { position: currentSearchPosition } = currentSearchParams;
		const { position: currentSearchResultsForPosition } = forParams;
		const isForCorrectPosition = !currentSearchPosition || currentSearchPosition === currentSearchResultsForPosition;
		const newPaginatedPlayersListData = _.isEqual(forParams, currentLastPlayersRequestParams) 
			? { ...paginatedPlayersListData, [page]: data } : { [page]: data };
		return isForCorrectPosition ? {
			...state,
			lastPlayersRequestParams: forParams,
			paginatedPlayersListData: newPaginatedPlayersListData,
			playersListRequestLoading: false,
			playersListRequestLoadingPagination: false,
		} : state;
	},
	[types.GET_REQUEST_PLAYERS_LIST_ERROR]: (state, { payload }) => ({
		...state,
		playersListRequestError: payload,
		playersListRequestLoading: false,
		playersListRequestLoadingPagination: false,
	}),
	[types.GET_REQUEST_PLAYER_INFO_START]: (state, { payload }) => {
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [payload]: true };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [payload]: false };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_INFO_SUCCESS]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList, playersInfoHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [playerID]: false };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [playerID]: false };
		const newPlayersInfoHashList = {
			...playersInfoHashList,
			[playerID]: { ...playersInfoHashList[playerID], ...data } };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
			playersInfoHashList: newPlayersInfoHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_INFO_ERROR]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [playerID]: false };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [playerID]: data };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
		};
	},

	[types.GET_REQUEST_PLAYER_FIXTURES_START]: (state, { payload }) => {
		const { playersFixturesLoadingsHashList, playersFixturesErrorsHashList } = state;
		const newPlayersFixturesLoadingsHashList = { ...playersFixturesLoadingsHashList, [payload]: true };
		const newPlayersInfoFixturesHashList = { ...playersFixturesErrorsHashList, [payload]: false };
		return {
			...state,
			playersFixturesLoadingsHashList: newPlayersFixturesLoadingsHashList,
			playersInfoFixturesHashList: newPlayersInfoFixturesHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_FIXTURES_SUCCESS]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersFixturesLoadingsHashList, playersFixturesErrorsHashList, playersFixturesHashList } = state;
		const newPlayersFixturesLoadingsHashList = { ...playersFixturesLoadingsHashList, [playerID]: false };
		const newPlayersInfoFixturesHashList = {
			...playersFixturesErrorsHashList,
			[playerID]: false };
		const newPlayersFixturesHashList = {
			...playersFixturesHashList,
			[playerID]: data
		};

		return {
			...state,
			playersFixturesLoadingsHashList: newPlayersFixturesLoadingsHashList,
			playersInfoFixturesHashList: newPlayersInfoFixturesHashList,
			playersFixturesHashList: newPlayersFixturesHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_FIXTURES_ERROR]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersFixturesLoadingsHashList, playersFixturesErrorsHashList } = state;
		const newPlayersFixturesLoadingsHashList = { ...playersFixturesLoadingsHashList, [playerID]: false };
		const newPlayersInfoFixturesHashList = { ...playersFixturesErrorsHashList, [playerID]: data };
		return {
			...state,
			playersFixturesLoadingsHashList: newPlayersFixturesLoadingsHashList,
			playersInfoFixturesHashList: newPlayersInfoFixturesHashList,
		};
	},

	[types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_START]: (state, { payload }) => {
		const { playersPastSeasonResultsLoadingsHashList, playersPastSeasonResultsErrorsHashList } = state;
		const newPlayersPastSeasonResultsLoadingsHashList = { ...playersPastSeasonResultsLoadingsHashList, [payload]: true };
		const newPlayersPastSeasonResultsErrorsHashList = { ...playersPastSeasonResultsErrorsHashList, [payload]: false };
		return {
			...state,
			playersPastSeasonResultsLoadingsHashList: newPlayersPastSeasonResultsLoadingsHashList,
			playersPastSeasonResultsErrorsHashList: newPlayersPastSeasonResultsErrorsHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_SUCCESS]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersPastSeasonResultsLoadingsHashList, playersPastSeasonResultsErrorsHashList, playersPastSeasonResultsHashList } = state;
		const newPlayersPastSeasonResultsLoadingsHashList = { ...playersPastSeasonResultsLoadingsHashList, [playerID]: false };
		const newPlayersPastSeasonResultsErrorsHashList = {
			...playersPastSeasonResultsErrorsHashList,
			[playerID]: false };
		const newPlayersPastSeasonResultsHashList = {
			...playersPastSeasonResultsHashList,
			[playerID]: data
		};

		return {
			...state,
			playersPastSeasonResultsLoadingsHashList: newPlayersPastSeasonResultsLoadingsHashList,
			playersPastSeasonResultsErrorsHashList: newPlayersPastSeasonResultsErrorsHashList,
			playersPastSeasonResultsHashList: newPlayersPastSeasonResultsHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_ERROR]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersPastSeasonResultsLoadingsHashList, playersPastSeasonResultsErrorsHashList } = state;
		const newPlayersPastSeasonResultsLoadingsHashList = { ...playersPastSeasonResultsLoadingsHashList, [playerID]: false };
		const newPlayersPastSeasonResultsErrorsHashList = { ...playersPastSeasonResultsErrorsHashList, [playerID]: data };
		return {
			...state,
			playersPastSeasonResultsLoadingsHashList: newPlayersPastSeasonResultsLoadingsHashList,
			playersPastSeasonResultsErrorsHashList: newPlayersPastSeasonResultsErrorsHashList,
		};
	},

	[types.GET_REQUEST_PLAYER_DATA_START]: (state, { payload }) => {
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [payload]: true };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [payload]: false };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_DATA_SUCCESS]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList, playersInfoHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [playerID]: false };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [playerID]: false };
		const newPlayersInfoHashList = {
			...playersInfoHashList,
			[playerID]: { ...playersInfoHashList[playerID], ...data } };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
			playersInfoHashList: newPlayersInfoHashList,
		};
	},
	[types.GET_REQUEST_PLAYER_DATA_ERROR]: (state, { payload }) => {
		const { data, playerID } = payload;
		const { playersInfoLoadingsHashList, playersInfoErrorsHashList } = state;
		const newPlayersInfoLoadingsHashList = { ...playersInfoLoadingsHashList, [playerID]: false };
		const newPlayersInfoErrorsHashList = { ...playersInfoErrorsHashList, [playerID]: data };
		return {
			...state,
			playersInfoLoadingsHashList: newPlayersInfoLoadingsHashList,
			playersInfoErrorsHashList: newPlayersInfoErrorsHashList,
		};
	},
	[types.SWITCH_PLAYER_INFO_MODAL_OPEN_STATE]: (state, { payload }) => {
		const { playerInfoModalID } = state;
		return {
			...state,
			playerInfoModalID: playerInfoModalID ? 0 : payload,
		};
	}
});

export default playersListReducer;