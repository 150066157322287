import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/leagues";

import { FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";


const getRoundLeaguesStart = payload => ({
	type: types.GET_REQUEST_ROUND_LEAGUES_START,
	payload,
});

const getRoundLeaguesSuccess = payload => ({
	type: types.GET_REQUEST_ROUND_LEAGUES_SUCCESS,
	payload,
});

const getRoundLeaguesError = payload => ({
	type: types.GET_REQUEST_ROUND_LEAGUES_ERROR,
	payload,
});

export const actionGetRequestRoundLeagueInfo = (roundId, otherUserId) => (dispatch) => {
	dispatch(getRoundLeaguesStart());
	const url = `${backendApiUrls.leagueRoundLeagueInfo.replace(FOR_REPLACEMENT.ID, roundId)}${otherUserId ? `?user_id=${otherUserId}` : ""}`;

	const config = {
		method: "GET",
		url,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getRoundLeaguesSuccess({ ...data, roundId }));
		})

		.catch((error) => {
			dispatch(getRoundLeaguesError(errorsParser(error)));
		});
};
