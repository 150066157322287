import * as types from "redux/constants/leagues";

import createReducer from "redux/createReducer";

const leaguesInitialState = {
	leaguesType: "",
	leaguesSubtype: "",
	countryLeaguesData: {},
	countryLeaguesRequestError: {},
	countryLeaguesRequestLoading: false,
	countryData: [],
	countryDataRequestError: {},
	countryDataRequestLoading: false,
	countryFindOwnData: {},
	countryFindOwnRequestError: {},
	countryFindOwnRequestLoading: false,
	quadLeaguesData: {},
	quadLeaguesRequestError: {},
	quadLeaguesRequestLoading: false,
	quadListData: [],
	quadFindOwnData: {},
	roundLeaguesData: {},
	roundLeaguesRequestError: {},
	roundLeaguesRequestLoading: false,
	roundFindOwnData: {},
	roundFindOwnRequestError: {},
	roundFindOwnRequestLoading: false,
	seasonLeagueInfo: {},
	seasonLeagueInfoRequestError: {},
	seasonLeagueInfoRequestLoading: false,
	seasonFindOwnData: {},
	seasonFindOwnRequestError: {},
	seasonFindOwnRequestLoading: false,
	privateLeaguesData: [],
	privateLeaguesStoreUpdateTime: 0,
	privateLeaguesRequestError: {},
	privateLeaguesRequestLoading: false,
	privateLeaguesInfoHashList: {},
	privateLeaguesInfoLoadingHashList: {},
	privateLeaguesInfoRequestErrorHashList: {},
	findOwnLeaguesData: {},
	findOwnLeaguesRequestError: {},
	findOwnLeaguesRequestLoading: false,
	paginationData: [],
	paginationRequestError: {},
	paginationRequestLoading: false,
	createNewLeagueLoading: false,
	joinLeagueLoading: false,
	createNewLeagueError: {},
	joinLeagueError: {},
	lastCreatedLeagueName: "",
	lastJoinedLeagueCode: "",
	quadLeagueInfo: {},
	quadLeagueInfoLoading: false,
	leavePrivateLeagueModalState: null,
	editPrivateLeagueModalState: null,
	removePrivateLeagueLoading: false,
	removePrivateLeagueError: {},
	editPrivateLeagueLoading: false,
	editPrivateLeagueError: {},
	removeTeamFromPrivateLeagueLoading: false,
	removeTeamFromPrivateLeagueError: {},
	deletePrivateLeagueLoading: false,
	deletePrivateLeagueError: {},
	selectedRoundLeagues: {
		round: null,
		quad: null,
	},
	suggestedUserTeamsLoading: false,
	suggestedUserTeamsError: {},
	suggestedUserTeams: [],
};

const leaguesReducer = createReducer(leaguesInitialState)({
	[types.SET_SELECTED_ROUND_LEAGUE]: (state, { payload }) => {
		const newSelectedRoundLeagues = {
			...state.selectedRoundLeagues,
			[payload.type]: payload.value
		};
		localStorage.setItem("selectedRoundLeagues", JSON.stringify(newSelectedRoundLeagues));

		return { ...state, selectedRoundLeagues: newSelectedRoundLeagues };
	},

	[types.CLEAR_SELECTED_ROUND_LEAGUE]: (state) => {
		localStorage.removeItem("selectedRoundLeagues");
		return { ...state, selectedRoundLeagues:  {
			round: null,
			quad: null,
		} };
	},

	[types.HYDRATE_SELECTED_ROUND_LEAGUE_FROM_STORAGE]: (state) => {
		const selectedRoundLeagues = JSON.parse(localStorage.getItem("selectedRoundLeagues")) || {
			round: null,
			quad: null,
		}; 
		return { ...state, roundLeaguesData: {}, quadLeaguesData: {},selectedRoundLeagues };
	},

	[types.SET_LEAGUE_TYPE]: (state, { payload }) => ({
		...state,
		leaguesType: payload,
	}),
	[types.SET_LEAGUE_SUBTYPE]: (state, { payload }) => ({
		...state,
		leaguesSubtype: payload,
	}),

	[types.GET_REQUEST_COUNTRY_LEAGUES_START]: state => ({
		...state,
		countryLeaguesRequestError: {},
		countryLeaguesRequestLoading: true,
	}),
	[types.GET_REQUEST_COUNTRY_LEAGUES_SUCCESS]: (state, { payload }) => ({
		...state,
		countryLeaguesData: payload,
		countryLeaguesRequestLoading: false,
		countryLeaguesStoreUpdateTime: new Date().getTime()
	}),
	[types.GET_REQUEST_COUNTRY_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		countryLeaguesRequestError: payload,
		countryLeaguesRequestLoading: false,
		countryLeaguesStoreUpdateTime: new Date().getTime()
	}),

	[types.GET_REQUEST_QUAD_LEAGUES_START]: state => ({
		...state,
		quadLeaguesRequestError: {},
		quadLeaguesRequestLoading: true,
	}),
	[types.GET_REQUEST_QUAD_LEAGUES_SUCCESS]: (state, { payload }) => ({
		...state,
		quadLeaguesData: payload,
		quadListData: payload.results,
		quadLeaguesRequestLoading: false,
	}),
	[types.GET_REQUEST_QUAD_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		quadLeaguesRequestError: payload,
		quadLeaguesRequestLoading: false,
	}),
	[types.GET_REQUEST_ROUND_LEAGUES_START]: state => ({
		...state,
		roundLeaguesRequestError: {},
		roundLeaguesRequestLoading: true,
	}),
	[types.GET_REQUEST_ROUND_LEAGUES_SUCCESS]: (state, { payload }) => ({
		...state,
		roundLeaguesData: payload,
		roundLeaguesRequestLoading: false,
		roundLeaguesStoreUpdateTime: new Date().getTime()
	}),
	[types.GET_REQUEST_ROUND_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		roundLeaguesRequestError: payload,
		roundLeaguesRequestLoading: false,
		roundLeaguesStoreUpdateTime: new Date().getTime()
	}),

	// need to remove change on paginationData
	[types.GET_REQUEST_PAGINATION_ROUND_LEAGUES_START]: state => ({
		...state,
		roundLeaguesRequestError: {},
		roundLeaguesRequestLoading: true,
	}),
	[types.GET_REQUEST_PAGINATION_ROUND_LEAGUES_SUCCESS]: (state, { payload }) => ({
		...state,
		roundLeaguesData: payload,
		roundLeaguesRequestLoading: false,
	}),
	[types.GET_REQUEST_PAGINATION_ROUND_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		roundLeaguesRequestError: payload,
		roundLeaguesRequestLoading: false,
	}),
	[types.GET_REQUEST_SEASON_LEAGUE_INFO_START]: state => ({
		...state,
		seasonLeagueInfoRequestError: {},
		seasonLeagueInfoRequestLoading: true,
	}),
	[types.GET_REQUEST_SEASON_LEAGUE_INFO_SUCCESS]: (state, { payload }) => ({
		...state,
		seasonLeagueInfo: payload,
		seasonLeagueInfoRequestLoading: false,
	}),
	[types.GET_REQUEST_SEASON_LEAGUE_INFO_ERROR]: (state, { payload }) => ({
		...state,
		seasonLeagueInfoRequestError: payload,
		seasonLeagueInfoRequestLoading: false,
	}),
	[types.GET_REQUEST_PRIVATE_LEAGUES_INFO_START]: state => ({
		...state,
		privateLeaguesRequestError: {},
		privateLeaguesRequestLoading: true,
	}),
	[types.GET_REQUEST_PRIVATE_LEAGUES_INFO_SUCCESS]: (state, { payload }) => {
		const { privateLeaguesInfoHashList } = state;
		const newPrivateLeaguesInfoHashList = { ...privateLeaguesInfoHashList };
		payload.forEach(privateLeague => {
			if (newPrivateLeaguesInfoHashList[privateLeague.id]) {
				newPrivateLeaguesInfoHashList[privateLeague.id] = { ...newPrivateLeaguesInfoHashList[privateLeague.id], ...privateLeague };
			} else {
				newPrivateLeaguesInfoHashList[privateLeague.id] = privateLeague;
			}
		});
		return {
			...state,
			privateLeaguesData: payload,
			privateLeaguesRequestLoading: false,
			privateLeaguesInfoHashList: newPrivateLeaguesInfoHashList,
			privateLeaguesStoreUpdateTime: new Date().getTime(),
		};
	},
	[types.GET_REQUEST_PRIVATE_LEAGUES_INFO_ERROR]: (state, { payload }) => ({
		...state,
		privateLeaguesStoreUpdateTime: new Date().getTime(),
		privateLeaguesRequestError: payload,
		privateLeaguesRequestLoading: false,
	}),
	[types.GET_REQUEST_PRIVATE_LEAGUE_INFO_START]: (state, { payload }) => {
		const { privateLeaguesInfoLoadingHashList, privateLeaguesInfoRequestErrorHashList } = state;
		const newPrivateLeaguesInfoLoadingHashList = { ...privateLeaguesInfoLoadingHashList, [payload]: true };
		const newPrivateLeaguesInfoRequestErrorHashList = { ...privateLeaguesInfoRequestErrorHashList, [payload]: false };
		return {
			...state,
			privateLeaguesInfoLoadingHashList: newPrivateLeaguesInfoLoadingHashList,
			privateLeaguesInfoRequestErrorHashList: newPrivateLeaguesInfoRequestErrorHashList,
		};
	},
	[types.GET_REQUEST_PRIVATE_LEAGUE_INFO_SUCCESS]: (state, { payload }) => {
		const { data, forLeagueID } = payload;
		const { privateLeaguesInfoLoadingHashList, privateLeaguesInfoRequestErrorHashList, privateLeaguesInfoHashList } = state;
		const newPrivateLeaguesInfoLoadingHashList = { ...privateLeaguesInfoLoadingHashList, [forLeagueID]: false };
		const newPrivateLeaguesInfoRequestErrorHashList = { ...privateLeaguesInfoRequestErrorHashList, [forLeagueID]: false };
		const newPrivateLeaguesInfoHashList = {
			...privateLeaguesInfoHashList,
			[forLeagueID]: { ...privateLeaguesInfoHashList[forLeagueID], ...data }
		};
		return {
			...state,
			privateLeaguesInfoLoadingHashList: newPrivateLeaguesInfoLoadingHashList,
			privateLeaguesInfoRequestErrorHashList: newPrivateLeaguesInfoRequestErrorHashList,
			privateLeaguesInfoHashList: newPrivateLeaguesInfoHashList,
			findOwnLeaguesData: { ...data, forID: forLeagueID }
		};
	},
	[types.GET_REQUEST_PRIVATE_LEAGUE_INFO_ERROR]: (state, { payload }) => {
		const { data, forLeagueID } = payload;
		const { privateLeaguesInfoLoadingHashList, privateLeaguesInfoRequestErrorHashList } = state;
		const newPrivateLeaguesInfoLoadingHashList = { ...privateLeaguesInfoLoadingHashList, [forLeagueID]: false };
		const newPrivateLeaguesInfoRequestErrorHashList = { ...privateLeaguesInfoRequestErrorHashList, [forLeagueID]: data };
		return {
			...state,
			findOwnLeaguesData: { page: 1, forID: forLeagueID },
			privateLeaguesInfoLoadingHashList: newPrivateLeaguesInfoLoadingHashList,
			privateLeaguesInfoRequestErrorHashList: newPrivateLeaguesInfoRequestErrorHashList,
		};
	},
	[types.CLEAR_PRIVATE_LEAGUE_INFO_ERROR]: (state, { payload }) => {
		const { forLeagueID } = payload;
		const { privateLeaguesInfoLoadingHashList, privateLeaguesInfoRequestErrorHashList } = state;
		const newPrivateLeaguesInfoLoadingHashList = { ...privateLeaguesInfoLoadingHashList };
		delete newPrivateLeaguesInfoLoadingHashList[forLeagueID];
		const newPrivateLeaguesInfoRequestErrorHashList = { ...privateLeaguesInfoRequestErrorHashList };
		delete newPrivateLeaguesInfoRequestErrorHashList[forLeagueID];
		return {
			...state,
			privateLeaguesInfoLoadingHashList: newPrivateLeaguesInfoLoadingHashList,
			privateLeaguesInfoRequestErrorHashList: newPrivateLeaguesInfoRequestErrorHashList,
		};
	},
	[types.CLEAR_FIND_OWN_LEAGUES_ERROR]: (state) => ({
		...state,
		findOwnLeaguesRequestError: {},
		roundFindOwnRequestError: {},
		quadFindOwnRequestError: {},
		countryFindOwnRequestError: {},
		privateFindOwnRequestError: {},
		seasonFindOwnRequestError: {},
	}),
	[types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_START]: (state, { payload }) => ({
		...state,
		[`${payload.leaguesSubtype}FindOwnData`]: {},
		[`${payload.leaguesSubtype}FindOwnRequestError`]: {},
		[`${payload.leaguesSubtype}FindOwnRequestLoading`]: true,
	}),
	[types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_SUCCESS]: (state, { payload }) => ({
		...state,
		[`${payload.leaguesSubtype}FindOwnData`]: payload.data,
		[`${payload.leaguesSubtype}FindOwnRequestLoading`]: false,
	}),
	[types.GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_ERROR]: (state, { payload }) => ({
		...state,
		[`${payload.leaguesSubtype}FindOwnRequestError`]: payload.data,
		[`${payload.leaguesSubtype}FindOwnRequestLoading`]: false,
	}),

	[types.GET_REQUEST_LEAGUES_COUNTRY_INFO_START]: state => ({
		...state,
		countryDataRequestError: {},
		countryDataRequestLoading: true,
	}),
	[types.GET_REQUEST_LEAGUES_COUNTRY_INFO_SUCCESS]: (state, { payload }) => ({
		...state,
		countryData: payload,
		countryDataRequestLoading: false,
	}),
	[types.GET_REQUEST_LEAGUES_COUNTRY_INFO_ERROR]: (state, { payload }) => ({
		...state,
		countryDataRequestError: payload,
		countryDataRequestLoading: false,
	}),

	[types.GET_REQUEST_PAGINATION_LEAGUES_START]: state => ({
		...state,
		paginationRequestError: {},
		paginationRequestLoading: true,
	}),
	[types.GET_REQUEST_PAGINATION_LEAGUES_SUCCESS]: (state, { payload }) => ({
		...state,
		paginationData: payload,
		paginationRequestLoading: false,
	}),
	[types.GET_REQUEST_PAGINATION_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		paginationRequestError: payload,
		paginationRequestLoading: false,
	}),
	[types.RESET_LEAGUE_MEMBERS_PAGINATION_LIST]: state => ({
		...state,
		paginationData: leaguesInitialState.paginationData,
		findOwnLeaguesData: {},
	}),
	[types.POST_REQUEST_PRIVATE_LEAGUES_START]: state => ({
		...state,
		createNewLeagueLoading: true,
		createNewLeagueError: {}
	}),
	[types.POST_REQUEST_PRIVATE_LEAGUES_SUCCESS]: (state, action) => ({
		...state,
		createNewLeagueLoading: false,
		lastCreatedLeagueName: action.payload,
	}),
	[types.POST_REQUEST_PRIVATE_LEAGUES_ERROR]: (state, { payload }) => ({
		...state,
		createNewLeagueLoading: false,
		createNewLeagueError: payload,
	}),
	[types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_START]: state => ({
		...state,
		joinLeagueLoading: true,
		joinLeagueError: {}
	}),
	[types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_SUCCESS]: (state, action) => ({
		...state,
		joinLeagueLoading: false,
		lastJoinedLeagueCode: action.payload,
	}),
	[types.POST_REQUEST_JOIN_PRIVATE_LEAGUE_ERROR]: (state, { payload }) => ({
		...state,
		joinLeagueLoading: false,
		joinLeagueError: payload,
	}),
	[types.CLEAR_CREATE_JOIN_LEAGUE_STATES]: state => ({
		...state,
		joinLeagueLoading: false,
		joinLeagueError: {},
		lastJoinedLeagueCode: "",
		createNewLeagueLoading: false,
		lastCreatedLeagueName: "",
		createNewLeagueError: {},
	}),
	[types.GET_REQUEST_QUAD_LEAGUE_INFO_START]: (state) => ({
		...state,
		quadLeagueInfoLoading: true,
	}),
	[types.GET_REQUEST_QUAD_LEAGUE_INFO_ERROR]: (state) => ({
		...state,
		quadLeagueInfoLoading: false,
	}),
	[types.GET_REQUEST_QUAD_LEAGUE_INFO_SUCCESS]: (state, { payload }) => ({
		...state,
		quadLeagueInfo: payload,
		quadLeagueInfoLoading: false,
	}),
	[types.SET_LEAVE_PRIVATE_LEAGUE_MODAL_STATE]: (state, { payload }) => ({
		...state,
		leavePrivateLeagueModalState: payload,
	}),
	[types.SET_EDIT_PRIVATE_LEAGUE_MODAL_STATE]: (state, { payload }) => ({
		...state,
		editPrivateLeagueModalState: payload,
	}),

	[types.LEAVE_PRIVATE_LEAGUE_START]: (state) => ({
		...state,
		removePrivateLeagueLoading: true,
		removePrivateLeagueError: {}
	}),
	[types.LEAVE_PRIVATE_LEAGUE_SUCCESS]: (state, { payload }) => {
		const newPrivateLeaguesInfoHashList = {
			...state.privateLeaguesInfoHashList
		};

		const newPrivateLeaguesData = [...state.privateLeaguesData].filter(
			(privateLeague) => privateLeague.id !== payload.leagueID
		);

		delete newPrivateLeaguesInfoHashList[payload.leagueID];
		return {
			...state,
			removePrivateLeagueLoading: false,
			privateLeaguesInfoHashList: newPrivateLeaguesInfoHashList,
			privateLeaguesData: newPrivateLeaguesData,
		};
	},
	[types.LEAVE_PRIVATE_LEAGUE_ERROR]: (state, { payload }) => ({
		...state,
		removePrivateLeagueError: payload,
		removePrivateLeagueLoading: false,
	}),

	[types.EDIT_PRIVATE_LEAGUE_START]: (state) => ({
		...state,
		editPrivateLeagueLoading: true,
		editPrivateLeagueError: {},
	}),
	[types.EDIT_PRIVATE_LEAGUE_SUCCESS]: (state, { payload }) => {
		const newPrivateLeaguesData = [...state.privateLeaguesData];
		const editedPrivateLeagueIndex = newPrivateLeaguesData.findIndex(privateLeague => privateLeague.id === payload.leagueID);
		newPrivateLeaguesData[editedPrivateLeagueIndex].name = payload.leagueName;
		newPrivateLeaguesData[editedPrivateLeagueIndex].entries_closed = payload.entriesClosed;

		const newPrivateLeaguesInfoHashList = {
			...state.privateLeaguesInfoHashList
		};

		newPrivateLeaguesInfoHashList[payload.leagueID].name = payload.leagueName;
		newPrivateLeaguesInfoHashList[payload.leagueID].entries_closed = payload.entriesClosed;

		return {
			...state,
			editPrivateLeagueLoading: false,
			editPrivateLeagueModalState: {
				...state.editPrivateLeagueModalState,
				name: payload.leagueName,
			},
			privateLeaguesInfoHashList: newPrivateLeaguesInfoHashList,
			privateLeaguesData: newPrivateLeaguesData,
		};
	},
	[types.EDIT_PRIVATE_LEAGUE_ERROR]: (state, { payload }) => ({
		...state,
		editPrivateLeagueError: payload.data,
		editPrivateLeagueLoading: false,
	}),

	[types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_START]: (state) => ({
		...state,
		removeTeamFromPrivateLeagueLoading: true,
		removeTeamFromPrivateLeagueError: {},
	}),
	[types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_SUCCESS]: (state) => {
		return {
			...state,
			removeTeamFromPrivateLeagueLoading: false,
		};
	},
	[types.REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR]: (state, { payload }) => ({
		...state,
		removeTeamFromPrivateLeagueError: payload,
		removeTeamFromPrivateLeagueLoading: false,
	}),

	[types.DELETE_PRIVATE_LEAGUE_START]: (state) => ({
		...state,
		deletePrivateLeagueLoading: true,
		deletePrivateLeagueError: {}
	}),
	[types.DELETE_PRIVATE_LEAGUE_SUCCESS]: (state, { payload }) => {
		const newPrivateLeaguesInfoHashList = {
			...state.privateLeaguesInfoHashList
		};

		const newPrivateLeaguesData = [...state.privateLeaguesData].filter(
			(privateLeague) => privateLeague.id !== payload.leagueID
		);

		delete newPrivateLeaguesInfoHashList[payload.leagueID];
		return {
			...state,
			deletePrivateLeagueLoading: false,
			privateLeaguesInfoHashList: newPrivateLeaguesInfoHashList,
			privateLeaguesData: newPrivateLeaguesData,
		};
	},
	[types.DELETE_PRIVATE_LEAGUE_ERROR]: (state, { payload }) => ({
		...state,
		deletePrivateLeagueError: payload,
		deletePrivateLeagueLoading: false,
	}),

	[types.CLEAR_PRIVATE_LEAGUE_MODAL_ERRORS]: (state) => ({
		...state,
		removeTeamFromPrivateLeagueError: {},
		editPrivateLeagueError: {},
	}),

	[types.GET_SUGGESTED_USER_TEAMS_START]: (state) => ({
		...state,
		suggestedUserTeamsLoading: true,
	}),

	[types.GET_SUGGESTED_USER_TEAMS_SUCCESS]: (state, { payload }) => ({
		...state,
		suggestedUserTeamsLoading: false,
		suggestedUserTeams: payload,
	}),
	[types.GET_SUGGESTED_USER_TEAMS_ERROR]: (state, { payload }) => ({
		...state,
		suggestedUserTeamsLoading: false,
		suggestedUserTeams: [],
		suggestedUserTeamsError: payload, 
	}),

	[types.CLEAR_SUGGESTIONS_USER_TEAMS]: (state) => ({
		...state,
		suggestedUserTeams: [],
	}),
});

export default leaguesReducer;
