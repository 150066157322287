import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useMediaQuery } from "react-responsive";
import propTypes from "prop-types";
import newsImagePlaceholder from "static/assets/images/news-image-placeholder.jpeg";


const Component = ({
	image,
}) => {
	return (
        <LazyLoadImage
            src={image}
            placeholderSrc={newsImagePlaceholder}
            effect="blur"
            alt=""
        />
	);
};

Component.propTypes = {
	image: propTypes.string.isRequired,
};

Component.displayName = "lli";

export default Component;