import _ from "lodash";

import * as types from "redux/constants/footballField";
import * as settingsTypes from "redux/constants/settings";
import * as userTeamsTypes from "redux/constants/userTeams";

import createReducer from "redux/createReducer";

import { FORMATIONS_MAP } from "helpers/constants/common";

const formatPlayers = ({
	footballPlayers,
	isRandomGenerated,
}) => {
	const positionIndexMap = {
		forward: 1,
		midfielder: 2,
		defender: 3,
		goalkeeper: 4,
	};

	const sortedFootballPlayers = footballPlayers.sort((a, b) => {
		const aPositionIndex = positionIndexMap[a.position];
		const bPositionIndex = positionIndexMap[b.position];

		if (aPositionIndex !== bPositionIndex) {
			return aPositionIndex - bPositionIndex;
		}

		return 1;
	}).sort((a, b) => {
		return a.position_index - b.position_index;
	});
	
	let currentForwardIndex = 0;
	let currentMidfielderIndex = 0;
	let currentDefenderIndex = 0;

	return sortedFootballPlayers.map((player, i) => {
		const positionIndex = isRandomGenerated ? i + 1 : player.position_index;
		const isBenchPlayer = positionIndex > 11;
		let playerLineIndex = 0;

		if (isBenchPlayer) {
			playerLineIndex = positionIndex - 12;
		} else if (player.position === "goalkeeper") {
			playerLineIndex = 0;
		} else if (player.position === "forward") {
			playerLineIndex = currentForwardIndex++;
		} else if (player.position === "midfielder") {
			playerLineIndex = currentMidfielderIndex++;
		} else if (player.position === "defender") {
			playerLineIndex = currentDefenderIndex++;
		}

		return {
			isRandomGenerated,
			...player,
			...player.football_player,
			userPlayerID: player.football_player.id,
			isCaptain: player.is_captain,
			isViceCaptain: player.is_vice_captain,
			full_name: `${player.football_player.first_name} ${player.football_player.last_name}`,
			isBenchPlayer,
			benchPlayerID: isBenchPlayer ? player.id : null,
			playerLineIndex,
		};
	});
};

const generateEmptyTeam = (formation) => {
	const forwardsLength = formation.formationType[FORMATIONS_MAP["forward"]];
	const midfieldersLength = formation.formationType[FORMATIONS_MAP["midfielder"]];
	const defendersLength = formation.formationType[FORMATIONS_MAP["defender"]];

	const forwardsLengthBench = 3 - forwardsLength;
	const midfieldersLengthBench = 5 - midfieldersLength;
	const defendersLengthBench = 5 - defendersLength;

	return { 
		forwards: new Array(forwardsLength).fill({ position: "forward" }),
		defenders: new Array(defendersLength).fill({ position: "defender" }),
		midfielders: new Array(midfieldersLength).fill({ position: "midfielder" }),
		goalkeepers: new Array(1).fill({ position: "goalkeeper" }),
		bench: [
			{ position: "goalkeeper" },
			...new Array(defendersLengthBench).fill({ position: "defender" }),
			...new Array(midfieldersLengthBench).fill({ position: "midfielder" }),
			...new Array(forwardsLengthBench).fill({ position: "forward" }),
		]
	};
};

export const footballFieldInitialState = {
	formation: {},
	globalFormations: [],
	fieldTeam: {
		bench: [],
		forwards: [],
		midfielders: [],
		defenders: [],
		goalkeepers: [],
	},
	transferedOutPlayers: [],
	transferedInPlayers: [],
	fieldTeamLength: 0,
	currentTotalPrice: 0,
	fieldErrors: {},
	fieldTeamIdsHashList: {},
	userTeamIdsHashList: {},
	unchangedUserTeam: {},
	captainID: 0,
	viceCaptainID: 0,
	randomTeamIsLoading: false,
	budgetLimit: 0,
	currentTeamName: "",
};

const footballFieldReducer = createReducer(footballFieldInitialState)({
	[types.SET_FIELD]: (state, { payload }) => {
		const newFieldPlaces = {
			forwards: [],
			defenders: [],
			midfielders: [],
			goalkeepers: [],
			bench: []
		};

		const { fieldTeam } = payload;

		Object.keys(fieldTeam).forEach(positionKey => {
			fieldTeam[positionKey].forEach((player, playerIdenx) => {
				if (player) {
					newFieldPlaces[positionKey][playerIdenx] = player;
				}
			});
		});

		return { ...payload, fieldTeam: newFieldPlaces };
	},
	[types.CLEAR_TEAM]: (state) => {
		return {
			...state,
			fieldTeam: {
				bench: [],
				forwards: [],
				midfielders: [],
				defenders: [],
				goalkeepers: [],
			},
			fieldErrors: {},
			fieldTeamIdsHashList: {},
			userTeamIdsHashList: {},
			unchangedUserTeam: {},
			captainID: 0,
			viceCaptainID: 0,
			currentTotalPrice: 0,
			fieldTeamLength: 0,
			formation: {},
		};
	},
	[types.INIT_TEAM]: (state, { payload }) => {
		const {
			userTeamIdsHashList,
			captainID: currentCaptainID,
			viceCaptainID: currentViceCaptainID,
			formation: currentFormation
		} = state;

		const {
			football_players = [],
			isRandomGenerated,
			formation,
			id: initTeamID,
		} = payload;

		const newFormation = formation
			? { ...formation, formationType: formation.formation_type.split("-").map(line => parseInt(line)) }
			: currentFormation;

		const newFieldTeam = generateEmptyTeam(newFormation);

		let newFieldTeamLength = football_players.length;
		
		const newFieldTeamIdsHashList = {};
		let newUserTeamIdsHashList = { ... userTeamIdsHashList };

		const formattedFootballPlayers = formatPlayers({
			footballPlayers: football_players,
			isRandomGenerated,
		});

		const captainFootballPlayerID = formattedFootballPlayers.find(player => player.isCaptain)?.id || currentCaptainID;
		const viceCaptainFootballPlayerID = formattedFootballPlayers.find(player => player.isViceCaptain)?.id || currentViceCaptainID;
		const newPrice = formattedFootballPlayers.reduce((acc, player) => acc + Number(player.current_price), 0);

		formattedFootballPlayers.forEach((player) => {
			newFieldTeam[player.isBenchPlayer ? "bench" : `${player.position}s`][player.playerLineIndex] = player;
			newFieldTeamIdsHashList[player.id] = player;
		});

		if (initTeamID) {
			newUserTeamIdsHashList = newFieldTeamIdsHashList;
		}
		
		return {
			...state,
			fieldTeam: newFieldTeam,
			fieldTeamIdsHashList: newFieldTeamIdsHashList,
			userTeamIdsHashList: newUserTeamIdsHashList,
			captainID: captainFootballPlayerID,
			viceCaptainID: viceCaptainFootballPlayerID,
			currentTotalPrice: newPrice,
			fieldTeamLength: newFieldTeamLength,
			formation: newFormation,
			unchangedUserTeam: JSON.parse(JSON.stringify({
				formation: newFormation,
				fieldTeam: newFieldTeam,
				captainID: captainFootballPlayerID,
				viceCaptainID: viceCaptainFootballPlayerID,
			}))
		};
	},
	[types.RESET_TEAM]: (state, action) => {
		const { fieldTeam, globalFormations, formation } = state;
		const { payload: { withResetFormat } = {} } = action;
		const indexOfFourFourTwoFormation = _.findIndex(globalFormations, { formation_type: "4-4-2" });
		const indexOfInitFormation = indexOfFourFourTwoFormation >= 0 ? indexOfFourFourTwoFormation: 0;
		const newSelectedFormation = withResetFormat && globalFormations.length ? globalFormations[indexOfInitFormation] : formation;
		const newFieldTeam = {
			forwards: [],
			defenders: [],
			midfielders: [],
			goalkeepers: [],
			bench: []
		};
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				newFieldTeam[lineKey].push({ position: player.position });
			});
		});

		return {
			...state,
			fieldTeam: newFieldTeam,
			fieldTeamIdsHashList: {},
			fieldTeamLength: 0,
			currentTotalPrice: 0,
			captainID: 0,
			viceCaptainID: 0,
			formation: newSelectedFormation,
			currentTeamName: "",
			transferedOutPlayers: [],
			transferedInPlayers: [],
		};
	},
	[types.MOVE_PLAYER]: (state, action) => {
		const { payload } = action;
		const {
			fieldTeam,
			fieldTeamLength,
			currentTotalPrice,
			fieldErrors,
			fieldTeamIdsHashList,
			userTeamIdsHashList,
			captainID,
			viceCaptainID,
			budgetLimit,
		} = state;
		const {
			player: { data: playerData, index: playerIndex, position: playerRole },
			target: { index: targetIndex, position: targetRole, data: targetData } }
			= payload;
		const { id: targetID, benchPlayerID: targetBenchID, isViceCaptain: targetIsViceCaptain, isCaptain: targetIsCaptain, position: targetPosition } = targetData;
		const { benchPlayerID: sourceBenchID, isCaptain: playerIsCaptain, isViceCaptain: playerIsViceCaptain } = playerData;
		const newTeam = { ...fieldTeam };
		let newFieldTeamLength = fieldTeamLength;
		let newPrice = currentTotalPrice;
		let newErrors = { ...fieldErrors };
		const newFieldTeamIdsHashList = { ...fieldTeamIdsHashList };
		let updatedCaptainID = captainID;
		let updatedViceCaptainID = viceCaptainID;
		const bothIsBench = targetRole === "bench" && playerRole === "bench";

		if (!isNaN(playerIndex) && !isNaN(targetID)) {
			if (playerIsCaptain && targetRole === "bench") {
				updatedCaptainID = 0;
				playerData.isCaptain = false;
			} else if (targetIsCaptain && playerRole === "bench") {
				updatedCaptainID = 0;
				targetData.isCaptain = false;
			}
			if (playerIsViceCaptain && targetRole === "bench") {
				updatedViceCaptainID = 0;
				playerData.isViceCaptain = false;
			} else if (targetIsViceCaptain && playerRole === "bench") {
				updatedViceCaptainID = 0;
				targetData.isViceCaptain = false;
			}

			newTeam[playerRole][playerIndex] = {
				...targetData, benchPlayerID: bothIsBench ? targetBenchID : sourceBenchID };
		} else if (!isNaN(playerIndex)) {
			if (playerIsCaptain && targetRole === "bench") {
				updatedCaptainID = 0;
				playerData.isCaptain = false;
			}
			if (playerIsViceCaptain && targetRole === "bench") {
				updatedViceCaptainID = 0;
				playerData.isViceCaptain = false;
			}
			newTeam[playerRole][playerIndex] = { position: targetPosition };
		} else {
			if (newPrice <= budgetLimit) {
				if (targetIsCaptain) {
					playerData.isCaptain = true;
					updatedCaptainID = playerData.id;
				}
				if (targetIsViceCaptain) {
					playerData.isViceCaptain = true;
					updatedViceCaptainID = playerData.id;
				}
				newFieldTeamLength = targetID ? newFieldTeamLength : newFieldTeamLength + 1;
				const { current_price: price, id } = playerData;
				newPrice+=Number(price);
				newFieldTeamIdsHashList[targetID] = { position: targetPosition };
				newFieldTeamIdsHashList[id] = true;
			} else {
				newErrors.maxBudget = "You reached maximum budget";
			}
		}
		const newPlayerData = { ...playerData };

		if (!_.isEmpty(userTeamIdsHashList) && !userTeamIdsHashList[playerData.id]) {
			newPlayerData.transferedIn = playerData;
		}

		if (targetBenchID) {
			newPlayerData.benchPlayerID = bothIsBench ? sourceBenchID : targetBenchID;
		}

		newTeam[targetRole][targetIndex] = newPlayerData;
		return {
			...state,
			fieldTeam: newTeam,
			fieldTeamLength: newFieldTeamLength,
			currentTotalPrice: newPrice,
			fieldErrors: newErrors,
			fieldTeamIdsHashList: newFieldTeamIdsHashList,
			captainID: updatedCaptainID,
			viceCaptainID: updatedViceCaptainID,
		};
	},
	[types.CHANGE_FORMAT]: (state, { payload }) => {
		const { fieldTeam, captainID, viceCaptainID } = state;
		let newCaptainID = captainID;
		let newViceCaptainID = viceCaptainID;
		const oldBench = [...fieldTeam.bench];
		const basicTeam = { ...fieldTeam, bench: [] };

		// Data prepare for bench formation recalculation
		const defendersLength = payload.formationType[FORMATIONS_MAP["defender"]];
		const midfieldersLength = payload.formationType[FORMATIONS_MAP["midfielder"]];
		const forwardsLength = payload.formationType[FORMATIONS_MAP["forward"]];
		const leftPositions = {
			goalkeeper: 1,
			defender: 5 - defendersLength,
			midfielder: 5 - midfieldersLength,
			forward: 3 - forwardsLength,
		};
		const preparedBench = [];
		// Prepare bench to the structure of [{position: 'goalkeeper'}, {position: 'defender'}...] for current formation
		Object.keys(leftPositions).forEach(positionKey => {
			const positionsLeft = leftPositions[positionKey];
			if (positionsLeft) {
				for (let i = 0; i < positionsLeft; i++) {
					preparedBench.push({ position: positionKey });
				}
			}
		});

		// We start to fill new bench with old bench players, they can stay on bench after format change
		// We doing additional check, because if 2 players was on bench and we need to keep only 1 of them
		// after reorder, we must keep on bench 2nd player, because 1st player must be moved to field as he is more priority
		oldBench.forEach((player, index) => {
			const indexOfFreePlaceForThisPosition = preparedBench.findIndex(preparedBenchPlace => !preparedBenchPlace.id && preparedBenchPlace.position === player.position);
			const playersForThisPositionInNewBenchLength = preparedBench.filter(preparedBenchPlace => !preparedBenchPlace.id && preparedBenchPlace.position === player.position).length;
			const playersForThisPositionInOldBenchLength = oldBench.filter(preparedBenchPlace => preparedBenchPlace.position === player.position).length;

			const isPlayerFromThisLineMustMovedToField = playersForThisPositionInNewBenchLength < playersForThisPositionInOldBenchLength;

			if (indexOfFreePlaceForThisPosition && !isPlayerFromThisLineMustMovedToField) {
				preparedBench[indexOfFreePlaceForThisPosition] = player;
			} else if (isPlayerFromThisLineMustMovedToField) {
				if (!oldBench[index + 1] || !oldBench[index + 1].position === player.position) {
					preparedBench[indexOfFreePlaceForThisPosition] = player;
				}
			}
		});
		const newBench = [...preparedBench];

		//Field with positions presets also as players from old bench, they must be keep in new
		const newFieldWithSavedCorrectBenchPlayers = {
			bench: newBench,
			goalkeepers: Array(1).fill({ position: "goalkeeper" }),
			defenders: Array(defendersLength).fill({ position: "defender" }),
			midfielders: Array(midfieldersLength).fill({ position: "midfielder" }),
			forwards: Array(forwardsLength).fill({ position: "forward" }),
		};

		//Collect all players to one array
		const allPlayers = [];
		Object.keys(basicTeam).forEach(line => basicTeam[line].forEach(player => {allPlayers.push(player);}));
		fieldTeam.bench.forEach(benchPlayer => {allPlayers.push(benchPlayer);});

		//And starting fill slots without ID (so, it is empty slots)
		allPlayers.forEach(player => {
			const { position } = player;
			const targetLine = newFieldWithSavedCorrectBenchPlayers[`${position}s`];
			
			//Looking for empty place within correct line
			const emptyPlaceID = targetLine.findIndex(player => !player.id);
			
			//Also checking if this player isn't already record to bench
			const playerIsNotAlreadySetToBench = newBench.findIndex(benchPlayer => benchPlayer.id && player.id === benchPlayer.id) < 0;

			if (emptyPlaceID >= 0  && playerIsNotAlreadySetToBench) {
				//Set player to place
				newFieldWithSavedCorrectBenchPlayers[`${position}s`][emptyPlaceID] = { ...newFieldWithSavedCorrectBenchPlayers[`${position}s`][emptyPlaceID], ...player };
			} else if (playerIsNotAlreadySetToBench) {

				//If is no place on field, we need to looking for empty place in bench for this position
				const emptyPlaceForThisPositionIndex = newFieldWithSavedCorrectBenchPlayers.bench.findIndex(
					benchPlayer => !benchPlayer || (benchPlayer.position === position && !benchPlayer.id)
				);

				//if empty place funded, we set player to this place
				if (emptyPlaceForThisPositionIndex >= 0) {
					const newBench = [...newFieldWithSavedCorrectBenchPlayers.bench];
					newBench[emptyPlaceForThisPositionIndex] = { ...player, ...newBench[emptyPlaceForThisPositionIndex] };
					newFieldWithSavedCorrectBenchPlayers.bench = newBench;
				}
			}
		});

		// according rules, if some player before set to bench was CAPTAIN, we must unset captain from him
		const captainOnBenchIndex = newFieldWithSavedCorrectBenchPlayers.bench.findIndex(player => player.isCaptain);
		if (captainOnBenchIndex >=0) {
			newFieldWithSavedCorrectBenchPlayers.bench[captainOnBenchIndex] = { ...newFieldWithSavedCorrectBenchPlayers.bench[captainOnBenchIndex], isCaptain: false };
			newCaptainID = 0;
		}

		const viceCaptainOnBenchIndex = newFieldWithSavedCorrectBenchPlayers.bench.findIndex(player => player.isViceCaptain);
		if (viceCaptainOnBenchIndex >=0) {
			newFieldWithSavedCorrectBenchPlayers.bench[viceCaptainOnBenchIndex] = { ...newFieldWithSavedCorrectBenchPlayers.bench[viceCaptainOnBenchIndex], isViceCaptain: false };
			newViceCaptainID = 0;
		}

		return { ...state, formation: payload, fieldTeam: newFieldWithSavedCorrectBenchPlayers, captainID: newCaptainID, viceCaptainID: newViceCaptainID };
	},
	[types.REMOVE_PLAYER]: (state, action) => {
		const { fieldTeam, captainID, viceCaptainID, fieldTeamLength, currentTotalPrice, fieldTeamIdsHashList, userTeamIdsHashList } = state;
		const { payload } = action;
		const { position, index, data, transferedOut }  = payload;
		const { current_price: price, id, isCaptain, isViceCaptain, benchPlayerID } = data;

		const newTeam = { ...fieldTeam };
		newTeam[position][index] = { position: data.position };
		let newFieldTeamLength = fieldTeamLength;
		let newPrice = currentTotalPrice - Number(price);
		const newFieldTeamIdsHashList = { ...fieldTeamIdsHashList };
		delete newFieldTeamIdsHashList[id];
		newFieldTeamLength--;
		if (!_.isEmpty(userTeamIdsHashList) && userTeamIdsHashList[id] || transferedOut) {
			newTeam[position][index] = {
				benchPlayerID,
				position: data.position
			};
		}

		const newTransferedOutPlayers = [ ...state.transferedOutPlayers ];
		let newTransferedInPlayers = [ ...state.transferedInPlayers ];

		const playerWasTransferedIn = newTransferedInPlayers.some(player => player.id === id);
		if (playerWasTransferedIn) {
			newTransferedInPlayers = newTransferedInPlayers.filter(player => player.id !== id);
		} else {
			newTransferedOutPlayers.push({ ...payload, ...payload.data });
		}

		return {
			...state,
			fieldTeam: newTeam,
			fieldTeamLength: newFieldTeamLength,
			currentTotalPrice: newPrice,
			fieldTeamIdsHashList: newFieldTeamIdsHashList,
			captainID: isCaptain ? 0 : captainID,
			viceCaptainID: isViceCaptain ? 0 : viceCaptainID,
			transferedOutPlayers: newTransferedOutPlayers,
			transferedInPlayers: newTransferedInPlayers,
		};
	},
	[types.SET_CAPTAIN]: (state, action) => {
		const { fieldTeam, captainID } = state;
		const { payload: { index, position, data: { id } = {} } } = action;
		const newTeam = { ...fieldTeam };
		let updatedCaptainID = captainID;

		if (captainID && id) {
			[...newTeam.forwards, ...newTeam.midfielders, ...newTeam.defenders, ...newTeam.goalkeepers, ...newTeam.bench].forEach(player => {
				if (player && player.isCaptain) {
					delete player.isCaptain;
					return;
				}
			});
		}
		
		if (!isNaN(index) && id && position) {
			newTeam[position][index].isCaptain = true;
			updatedCaptainID = id;
		}
		
		return { ...state, fieldTeam: newTeam, captainID: updatedCaptainID  };
	},
	[types.SET_VICE_CAPTAIN]: (state, action) => {
		const { fieldTeam, viceCaptainID } = state;
		const { payload: { index, position, data: { id } = {} } } = action;
		const newTeam = { ...fieldTeam };
		let updatedViceCaptainID = viceCaptainID;

		if (viceCaptainID && id) {
			[...newTeam.forwards, ...newTeam.midfielders, ...newTeam.defenders, ...newTeam.goalkeepers, ...newTeam.bench].forEach(player => {
				if (player && player.isViceCaptain) {
					delete player.isViceCaptain;
					return;
				}
			});
		}
		
		if (!isNaN(index) && id && position) {
			newTeam[position][index].isViceCaptain = true;
			updatedViceCaptainID = id;
		}
		
		return { ...state, fieldTeam: newTeam, viceCaptainID: updatedViceCaptainID  };
	},
	[types.GET_REQUEST_GLOBAL_FORMATIONS_SUCCESS]: (state, { payload }) => {
		const { formation, fieldTeam } = state;
		const newGlobalFormations = payload.map(formation => 
			({ ...formation, formationType: [...formation.formation_type.split("-").map(line => parseInt(line)), 1] }));
		const indexOfFourFourTwoFormation = _.findIndex(newGlobalFormations, { formation_type: "4-4-2" });
		const indexOfInitFormation = indexOfFourFourTwoFormation >= 0 ? indexOfFourFourTwoFormation: 0;
		const newSelectedFormation = formation.id ? formation : newGlobalFormations[indexOfInitFormation];
		const newBench = [{ position: "goalkeeper" }];
		const defendersLength = newSelectedFormation.formationType[FORMATIONS_MAP["defender"]];
		const midfieldersLength = newSelectedFormation.formationType[FORMATIONS_MAP["midfielder"]];
		const forwardsLength = newSelectedFormation.formationType[FORMATIONS_MAP["forward"]];
		const forwardsLengthBench = 3 - forwardsLength;
		const midfieldersLengthBench = 5 - midfieldersLength;
		const defendersLengthBench = 5 - defendersLength;
		if (defendersLengthBench) {
			for (let i = 0; i < defendersLengthBench; i ++) {
				newBench.push({ position: "defender" });
			}
		}
		if (midfieldersLengthBench) {
			for (let i = 0; i < midfieldersLengthBench; i ++) {
				newBench.push({ position: "midfielder" });
			}
		}
		if (forwardsLengthBench) {
			for (let i = 0; i < forwardsLengthBench; i ++) {
				newBench.push({ position: "forward" });
			}
		}
		let isNotEmptyOnField = false;
		Object.keys(fieldTeam).forEach(lineKey => {
			fieldTeam[lineKey].forEach(player => {
				if (player.id) {
					isNotEmptyOnField = true;
					return;
				}
			});
		});
		const newFieldTeam = isNotEmptyOnField ? fieldTeam :
			{
				bench: newBench,
				goalkeepers: Array(1).fill({ position: "goalkeeper" }),
				defenders: Array(defendersLength).fill({ position: "defender" }),
				midfielders: Array(midfieldersLength).fill({ position: "midfielder" }),
				forwards: Array(forwardsLength).fill({ position: "forward" }),
			};

		return { ...state,
			globalFormations: newGlobalFormations,
			formation: newSelectedFormation,
			fieldTeam: newFieldTeam,
		};
	},
	[types.POST_REQUEST_RANDOM_TEAM_START]: (state) => {
		const {  fieldTeam, fieldTeamIdsHashList, fieldTeamLength, currentTotalPrice, } = state;
		let newFieldTeamLength =  fieldTeamLength;
		let newPrice = currentTotalPrice;
		const newFieldTeam = { ...fieldTeam };
		const newFieldTeamIdsHashList = { ...fieldTeamIdsHashList };
		Object.keys(newFieldTeam).forEach(line => {
			newFieldTeam[line].forEach((player, index) => {
				if (player && player.isRandomGenerated) {
					newFieldTeamLength--;
					newPrice-=player.current_price;
					newFieldTeam[line][index] = { position: player.position };
				}
			});
		});
		return { 
			...state, 
			randomTeamIsLoading: true, 
			fieldErrors: false, 
			fieldTeam: newFieldTeam,
			fieldTeamIdsHashList: newFieldTeamIdsHashList, 
			currentTotalPrice: newPrice,  
			fieldTeamLength: newFieldTeamLength 
		};
	},
	[types.POST_REQUEST_RANDOM_TEAM_ERROR]: (state, { payload }) => {
		return { ...state, fieldErrors: payload, randomTeamIsLoading: false };
	},
	[types.POST_REQUEST_RANDOM_TEAM_SUCCESS]: (state) => {
		return { ...state, randomTeamIsLoading: false, fieldErrors: false };
	},
	[types.SELECT_PLAYER_FROM_LIST]: (state, { payload }) => {
		const { position, id } = payload;
		const {
			fieldTeamIdsHashList,
			fieldTeam,
			userTeamIdsHashList,
		} = state;
		const targetLine = fieldTeam[`${position}s`];
		let newFieldTeam = { ...fieldTeam };

		const newPlayerData = { ...payload };
		if (!_.isEmpty(userTeamIdsHashList) && !userTeamIdsHashList[id]) {
			newPlayerData.transferedIn = payload;
		}
		const targetLineEmptyPlaceIndex = targetLine.findIndex(player => !player || !player.id);
		const benchEmptyPlaceIndex = newFieldTeam.bench.findIndex(player => player.position === position && !player.id);
		const targetPlace = newFieldTeam[`${position}s`][targetLineEmptyPlaceIndex];
		const newPlayer =  { ...targetPlace, ...newPlayerData };

		const sameTransferedOutPlayer = state.transferedOutPlayers.find(player => player.id === id);

		let playerToSave;

		if (sameTransferedOutPlayer) {
			const position = sameTransferedOutPlayer.isBenchPlayer ? "bench" : `${sameTransferedOutPlayer.position}s`;
			const playerLineIndex = sameTransferedOutPlayer.playerLineIndex;

			const playerOnTargetIndex = newFieldTeam[position][playerLineIndex];
			newFieldTeam[position][playerLineIndex] = sameTransferedOutPlayer;

			if (playerOnTargetIndex?.id) {
				playerToSave = playerOnTargetIndex;
			}
		}  else {
			playerToSave = newPlayer;
		}
			
		if (playerToSave) {
			if (targetLineEmptyPlaceIndex >= 0) {
				newFieldTeam[`${position}s`][targetLineEmptyPlaceIndex] = playerToSave;
			} else {
				newFieldTeam.bench[benchEmptyPlaceIndex] = playerToSave;
			}
		}
		

		let newTransferedOutPlayers = [ ...state.transferedOutPlayers ];
		const newTransferedInPlayers = [ ...state.transferedInPlayers ];

		if (sameTransferedOutPlayer?.id) {
			newTransferedOutPlayers = newTransferedOutPlayers.filter(player => player.id !== id);
		} else {
			newTransferedInPlayers.push(payload);
		}

		const newFieldTeamIdsHashList = { ...fieldTeamIdsHashList };
		newFieldTeamIdsHashList[id] = newPlayerData;
		return { 
			...state, 
			fieldErrors: {}, 
			fieldTeam: newFieldTeam, 
			fieldTeamIdsHashList:  newFieldTeamIdsHashList,
			transferedOutPlayers: newTransferedOutPlayers,
			transferedInPlayers: newTransferedInPlayers,
		};
	},
	[types.SET_CURRENT_TEAM_NAME]: (state, { payload }) => {
		return { ...state, currentTeamName: payload };
	},
	[types.CLEAR_TRANSFERS]: (state) => {
		return { ...state, 
			transferedInPlayers: [],
			transferedOutPlayers: [],
		};
	},
	[settingsTypes.GET_REQUEST_SETTINGS_SUCCESS]: (state, { payload }) => {
		const { budget_limit } = payload;
		return { ...state, budgetLimit: budget_limit };
	},
	[userTeamsTypes.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_SUCCESS]: (state) => {
		return {
			...state,
			transferedInPlayers: [], 
			transferedOutPlayers: []	
		};
	}
});

export default footballFieldReducer;