import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Row, Col, Form, Input } from "reactstrap";
import _ from "lodash";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { errorsPropTypes } from "utils/propTypes/registrationUser";
import { formValidatorChangePwd } from "utils/validators";

import { actionUpdateUserDetails } from "redux/actions/auth";

import useEffectSkipFirst from "helpers/hooks/useEffectSkipFirst";

import SpinnerButton from "modules/common/SpinnerButton";
import ModalWindow from "modules/common/ModalWindow";
import ErrorMessage from "modules/common/ErrorMessage";
import ErrorComponent from "modules/common/ErrorComponent";



const ChangePasswordModal = (props) => {
	const {
		toggle,
		isOpen,
		userDetailsRequestLoading,
		userDetailsLastUpdateTime,
		userDetailsErrors,
		actionUpdateUserDetails,
		setChangedFields,
	} = props;

	const [errors, setErrors] = useState({});
	const [fields, setFields] = useState({});

	const { t } = useTranslation("change_password_modal");

	useEffect(() => {
		userDetailsErrors && setErrors({ ...errors, ...userDetailsErrors });
	}, [userDetailsErrors]);

	useEffectSkipFirst(() => {
		userDetailsLastUpdateTime && toggle();
	}, [userDetailsLastUpdateTime]);

	const hasFieldError = (fieldName) => classNames({
		error__field: errors[fieldName],
		"registration-form__field is-logged-variant": true,
	});

	const changeFieldByName = fieldName => e => {
		const { target: { value } } = e;
		setFields({ ...fields, [fieldName]: value });
	};

	const handleSubmit = () => {
		const errors = formValidatorChangePwd(fields);
		setErrors(errors);
		if (_.isEmpty(errors)) {
			actionUpdateUserDetails(fields).then((data) => {
				if (!(data && data.parsedTextErrors)) {
					setChangedFields([t("Password")]);
				}
			});
		}
	};

	return (
		<ModalWindow toggle={toggle} isOpen={isOpen}>
			<div className='fantasy-modal-title text-uppercase'>{t("title")}</div>
			<Row className={`w-100 ${errors.parsedTextErrorsWithoutKeys && errors.parsedTextErrorsWithoutKeys.length && ""}`}>
				{errors.parsedTextErrorsWithoutKeys && errors.parsedTextErrorsWithoutKeys.map((error, i) => (
					<Col className="px-0 margin-bottom-10px" key={Date.now()}>
						<ErrorComponent
							handleDelete={() => {
								const newParsedTextErrorsWithoutKeysValue = [...errors.parsedTextErrorsWithoutKeys];
								newParsedTextErrorsWithoutKeysValue.splice(i, 1);
								setErrors({
									...errors,
									parsedTextErrorsWithoutKeys: newParsedTextErrorsWithoutKeysValue,
								});
							}}
							text={error}
							isTextCentered
						/>
					</Col>
				))}
			</Row>
			<Form className="registration-form registration-form_mt w-100 is-logged-variant">
				<Col className="px-0" md={12}>
					<Input
						type="password"
						id="old_password"
						placeholder={t("Old Password")}
						className={hasFieldError("old_password")}
						onChange={changeFieldByName("old_password")}
					/>
					<ErrorMessage fieldName="old_password" errors={errors} />
				</Col>
				<Col className="margin-top-20px px-0" md={12}>
					<Input
						type="password"
						id="password"
						placeholder={t("New Password")}
						className={hasFieldError("password")}
						onChange={changeFieldByName("password")}
					/>
					<ErrorMessage className='pre-wrap' fieldName="password" errors={errors} />
				</Col>
				<Col className="margin-top-20px px-0" md={12}>
					<Input
						type="password"
						id="confirm_password"
						placeholder={t("Confirm New Password")}
						className={hasFieldError("confirm_password")}
						onChange={changeFieldByName("confirm_password")}
					/>
					<ErrorMessage fieldName="confirm_password" errors={errors} />
				</Col>
				<SpinnerButton
					isLoading={userDetailsRequestLoading}
					text={t("Save")}
					onClick={handleSubmit}
					className="col-4 margin-top-20px text-uppercase"
					size="medium"
				/>
			</Form>
		</ModalWindow>
	);
};

ChangePasswordModal.propTypes = {
	userDetailsLastUpdateTime: propTypes.number.isRequired,
	userDetailsErrors: errorsPropTypes,
	isOpen: propTypes.bool,
	userDetailsRequestLoading: propTypes.bool,
	toggle: propTypes.func.isRequired,
	actionUpdateUserDetails: propTypes.func.isRequired,
	setChangedFields: propTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const { userDetailsErrors, userDetailsRequestLoading, userDetailsLastUpdateTime } = authReducer;
	return {
		userDetailsRequestLoading,
		userDetailsLastUpdateTime,
		userDetailsErrors
	};
};

export default connect(
	mapStateToProps,
	{ actionUpdateUserDetails }
)(ChangePasswordModal);

ChangePasswordModal.displayName = "cpm";