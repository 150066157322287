import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Spinner } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";

import frontendUrls from "routes/frontendUrls";

import { actionGetRequestSeasonLeagueInfo } from "redux/actions/leaguesSeason";
import { actionGetRequestCountryInfo } from "redux/actions/leaguesCountry";
import { actionGetRequestQuadLeagues, actionGetQuadLeagueInfo } from "redux/actions/leaguesQuad";
import { actionGetRequestRoundLeagueInfo } from "redux/actions/leaguesRound";
import {
	getCommonLeagues,
	getFinishedAndLiveQuadLeagues,
	getFinishedAndLiveRounds,
	getPrivateLeagues,
} from "redux/selectors";
import {
	actionGetRequestPrivateLeaguesInfo,
	setLeavePrivateLeagueModalState,
	setEditPrivateLeagueModalState,
} from "redux/actions/leaguesPrivate";

import { roundedNumberWithSpaces } from "helpers/common";
import { Trans, useTranslation } from "react-i18next";

const emojiRegex = require("emoji-regex");

const handleReduceEmojisSize = (string) => {
	const emojiRegexExp = emojiRegex();
	if (emojiRegexExp.test(string)) {
		const splittedString = [...string];

		return splittedString
			.filter(el => el.charCodeAt(0) !== 65039)
			.map((el, i) => {
				let currentItem = emojiRegexExp.test([...string][i]);
				const isCurrentElementEmoji = emojiRegexExp.test(el) || currentItem || emojiRegexExp.test(splittedString[i]);
				return isCurrentElementEmoji ? <span className="emoji">{el}</span> : el;
			});
	}
	return string || '';

};

const LeaguesList = ({
	leagues,
	typeLeagues,
	className,
	activeSeasonId,
	countryCode,
	withRating,
	lastFinishedRoundId,
	privateLeaguesStoreUpdateTime,
	lastJoinedLeagueCode,
	lastCreatedLeagueName,
	privateLeaguesInfoRequestErrorHashList,
	activeQuadLeagueId,
	actionGetRequestSeasonLeagueInfo,
	actionGetRequestQuadLeagues,
	actionGetRequestRoundLeagueInfo,
	actionGetRequestPrivateLeaguesInfo,
	actionGetQuadLeagueInfo,
	privateLeaguesRequestLoading,
	isSeasonLeagueMemberLoading,
	isCountryLeagueMemberLoading,
	isRoundLeagueMemberLoading,
	isOtherUserPage,
	quadLeagueInfoLoading,
	actionGetRequestCountryInfo,
	setLeavePrivateLeagueModalState,
	setEditPrivateLeagueModalState,
	otherUserId,
}) => {
	const { t } = useTranslation("leagues_list");
	const isLeaguesPage = window.location.pathname.includes(frontendUrls.urlLeagues);
	const ref = useRef();
	let scrollBarHeight = 220;
	const [isScrollOnTop, setIsScrollOnTop] = useState(true);
	const [isScrollOnBottom, setIsScrollOnBottom] = useState(false);

	useEffect(() => {
		if (typeLeagues === "common" && activeSeasonId && countryCode) {
			actionGetRequestQuadLeagues(activeSeasonId);
			actionGetRequestSeasonLeagueInfo(activeSeasonId, otherUserId);
			actionGetRequestCountryInfo(activeSeasonId, countryCode, otherUserId);
		}
	}, [countryCode, activeSeasonId]);

	useEffect(() => {
		if (typeLeagues === "private" && activeSeasonId) {
			actionGetRequestPrivateLeaguesInfo(activeSeasonId);
		}
	}, [activeSeasonId]);

	useEffect(() => {
		if (lastJoinedLeagueCode || lastCreatedLeagueName) {
			if (typeLeagues === "private") {
				actionGetRequestPrivateLeaguesInfo(activeSeasonId);
			}
		}
	}, [lastJoinedLeagueCode, lastCreatedLeagueName]);

	useEffect(() => {
		if (typeLeagues === "common" && lastFinishedRoundId && isLeaguesPage) {
			actionGetRequestRoundLeagueInfo(lastFinishedRoundId, otherUserId);
		}
	}, [lastFinishedRoundId]);


	useEffect(() => {
		if (typeLeagues === "common" && activeSeasonId > 0 && activeQuadLeagueId) {
			actionGetQuadLeagueInfo(activeQuadLeagueId, otherUserId);
		}
	}, [activeQuadLeagueId]);

	const renderView = ({ style, ...props }) => {
		let customStyle = (typeLeagues === "private") ? {
			overflowX: "hidden",
			height: `${scrollBarHeight + 2}px`,
			margin: "0",
		} : {
			overflow: "hidden",
		};
		return (
			<div {...props} style={{ ...style, ...customStyle }} className={`${typeLeagues === "private" && 'private-leagues-scroll'}`} />
		);
	};

	const isLoading =
		(typeLeagues === "private" && (
			!privateLeaguesStoreUpdateTime ||
			privateLeaguesRequestLoading
		))
		|| (
			typeLeagues === "common" && (
				quadLeagueInfoLoading ||
				isRoundLeagueMemberLoading ||
				quadLeagueInfoLoading ||
				isSeasonLeagueMemberLoading ||
				isCountryLeagueMemberLoading
			));

	return (
		<div className={`d-flex justify-content-center leagues-board leaguesList ${className} ${typeLeagues}`}>
			<h3 className="leagues-board__header">
				<Trans
					i18nKey={typeLeagues === "common" ? "common_header": "private_header"}
					ns="leagues_list"
					values={{
						typeLeagues,
					}}
					components={[
						<span key="0" className='color-accent1' />
					]}
				/>
			</h3>
			<div className={`${(leagues?.length > 4) && typeLeagues === 'private' && 'private-leagues-scroll-wrapper'} ${isScrollOnTop && 'isScrollOnTop'} ${isScrollOnBottom && 'isScrollOnBottom'} leagues-board__list`}>
				<Scrollbars
					renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
					renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
					renderTrackVertical={props => <div {...props} className="track-vertical" style={{ display: `${typeLeagues === "common" ? "none" : "block"}` }} />}
					renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ display: `${typeLeagues === "common" ? "none" : "block"}` }} />}
					style={{ height: `${scrollBarHeight}px` }}
					renderView={renderView}
					onScrollFrame={(e) => {
						if (!e.top) {
							setIsScrollOnTop(true);
						} else if (e.top === 1) {
							setIsScrollOnBottom(true);
						} else {
							setIsScrollOnTop(false);
							setIsScrollOnBottom(false);
						}
					}}
				>
					{!isLoading && leagues ? leagues?.map(league => {
						const isPrivateLeague = typeLeagues === "private";

						const isOwnLeague = isPrivateLeague && league.own;
						const link = isPrivateLeague ?
							`${frontendUrls.urlLeaguesRanking}${typeLeagues}/?league_id=${league.classificationType}` :
							`${frontendUrls.urlLeaguesRanking}${typeLeagues}/${league.classificationType}/`

						return (
							<li key={league.id} className={`leagues-board__league-item ${isPrivateLeague && 'private'} ${isOtherUserPage && "disabled-link"}`}>
								<Link
									to={link}
									tabIndex="0"
								>
									<Row className="flex-nowrap">
										<Col xs={7} xl={isPrivateLeague ? 7 : 8}>
											<div className="leagues-board__name">
												<p className="mb-0">
													{handleReduceEmojisSize(league.name || '')}
												</p>
											</div>
											<div>
												<div className="leagues-board__members">{t('members', {
													number: '~' + roundedNumberWithSpaces(league.members || 0)
												})}</div>
											</div>
										</Col>
										<Col className="d-flex align-items-center justify-content-end pl-1">
											{privateLeaguesRequestLoading && !league.points && isPrivateLeague && !privateLeaguesInfoRequestErrorHashList[league.id] ?
												<Spinner />
												:
												<div className={`leagues-board__points ${isPrivateLeague && 'justify-content-end isPrivateLeague'}`}>
													{isPrivateLeague &&
														<button
															className={`settings-btn ${isOwnLeague && 'isOwnLeague'}`}
															onClick={e => {
																e.preventDefault();
																if (isOwnLeague) {
																	setEditPrivateLeagueModalState(league)
																} else {
																	setLeavePrivateLeagueModalState(league);
																}
															}}
														/>
													}
													<p className="mb-0">
														{typeof league.rank === "number" ? league.rank : "-"} {withRating && league.placeInPercentage > 0 && `[ ${league.placeInPercentage}%]`}
													</p>
												</div>
											}
										</Col>
									</Row>
								</Link>
							</li>
						);
					})

						:
						<li className="leagues-board__league-item d-flex justify-content-center align-items-center h-100 isSpinnerWrapper">
							<Spinner />
						</li>
					}
					{typeLeagues === "private" && !!privateLeaguesStoreUpdateTime && !leagues.length &&
						<li className="leagues-board__league-item d-flex justify-content-center source-sans-pro empty-private" ref={ref}>
							<span>
								{t('Your team is not participating in Private Leagues.')}
								
								{' '}
								{!isLeaguesPage && <Link className="color-accent1" to={frontendUrls.urlLeagues}>
									{t('Create or join Private Leagues now')}
								</Link>}
							</span>
						</li>
					}
				</Scrollbars>
			</div>
		</div>
	);
};
LeaguesList.propTypes = {
	typeLeagues: PropTypes.oneOf(["common", "private"]),
	leagues: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		members: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		id: PropTypes.string,
		classificationType: PropTypes.oneOf(["country", "quad", "round", "season"])
	})).isRequired,
	activeSeasonId: PropTypes.number,
	lastFinishedRoundId: PropTypes.number,
	activeQuadLeagueId: PropTypes.number,
	otherUserId: PropTypes.number,
	countryCode: PropTypes.string,
	className: PropTypes.string,
	privateLeaguesStoreUpdateTime: PropTypes.number.isRequired,
	lastJoinedLeagueCode: PropTypes.string.isRequired,
	lastCreatedLeagueName: PropTypes.string.isRequired,
	privateLeaguesInfoRequestErrorHashList: PropTypes.object.isRequired,
	withRating: PropTypes.bool,
	isCountryLeagueMemberLoading: PropTypes.bool,
	isQuadLeagueMemberLoading: PropTypes.bool,
	isRoundLeagueMemberLoading: PropTypes.bool,
	privateLeaguesRequestLoading: PropTypes.bool,
	isSeasonLeagueMemberLoading: PropTypes.bool,
	isOtherUserPage: PropTypes.bool.isRequired,
	quadLeagueInfoLoading: PropTypes.bool.isRequired,
	privateLeaguesInfoHashList: PropTypes.object.isRequired,
	actionGetRequestSeasonLeagueInfo: PropTypes.func.isRequired,
	actionGetRequestQuadLeagues: PropTypes.func.isRequired,
	actionGetRequestRoundLeagueInfo: PropTypes.func.isRequired,
	actionGetRequestPrivateLeaguesInfo: PropTypes.func.isRequired,
	actionGetQuadLeagueInfo: PropTypes.func.isRequired,
	setLeavePrivateLeagueModalState: PropTypes.func.isRequired,
	setEditPrivateLeagueModalState: PropTypes.func.isRequired,
};

LeaguesList.defaultProps = {
	typeLeagues: "common",
	lastFinishedRoundId: null,
	countryCode: "",
	className: "",
	withRating: false,
};

const mapStateToProps = (state, { typeLeagues }) => {
	const {
		roundLeaguesStoreUpdateTime,
		privateLeaguesInfoHashList,
		privateLeaguesRequestLoading,
		privateLeaguesInfoLoadingHashList,
		privateLeaguesInfoRequestErrorHashList,
		privateLeaguesStoreUpdateTime,
		lastJoinedLeagueCode,
		lastCreatedLeagueName,
		countryDataRequestLoading,
		seasonLeagueInfoRequestLoading,
		quadLeagueInfoLoading,
	} = state.leaguesReducer;
	const finishedAndLiveQueadLeagues = getFinishedAndLiveQuadLeagues(state);
	const activeQuadLeagueId = finishedAndLiveQueadLeagues.length && finishedAndLiveQueadLeagues[0].id;

	const { seasonsListData } = state.leagueSeasonsReducer;
	const { userTeamsData } = state.userTeamsReducer;
	const finishedAndLiveRounds = getFinishedAndLiveRounds(state);
	let lastFinishedRound = !!finishedAndLiveRounds.length && finishedAndLiveRounds[finishedAndLiveRounds.length - 1];

	const leagues = typeLeagues === "common"
		? getCommonLeagues(state)
		: getPrivateLeagues(state);
	const isOtherUserPage = window.location.pathname.includes(frontendUrls.urlOtherUserTeam);
	const urlParams = new URLSearchParams(window.location.search);
	const otherUserId = urlParams.get("user_id");
	const { otherUserResultsReducer: { otherUserResultsHashList } } = state;
	const otherUserData = otherUserResultsHashList?.[otherUserId];
	const countryCode = isOtherUserPage ? otherUserData?.user_country : userTeamsData[0]?.user?.country;

	return {
		activeSeasonId: seasonsListData[0]?.id,
		countryCode,
		leagues,
		lastFinishedRoundId: lastFinishedRound && lastFinishedRound.id,
		activeQuadLeagueId,
		privateLeaguesInfoHashList,
		privateLeaguesRequestLoading,
		privateLeaguesInfoLoadingHashList,
		privateLeaguesStoreUpdateTime,
		lastJoinedLeagueCode,
		lastCreatedLeagueName,
		privateLeaguesInfoRequestErrorHashList,
		isOtherUserPage,
		quadLeagueInfoLoading,
		isSeasonLeagueMemberLoading: seasonLeagueInfoRequestLoading,
		isCountryLeagueMemberLoading: countryDataRequestLoading,
		isRoundLeagueMemberLoading: !roundLeaguesStoreUpdateTime,
		otherUserId,
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetRequestSeasonLeagueInfo,
		actionGetRequestQuadLeagues,
		actionGetRequestRoundLeagueInfo,
		actionGetRequestPrivateLeaguesInfo,
		actionGetQuadLeagueInfo,
		actionGetRequestCountryInfo,
		setLeavePrivateLeagueModalState,
		setEditPrivateLeagueModalState,
	}
)(LeaguesList);

LeaguesList.displayName = "ll";