import React from "react";
import { useTranslation } from "react-i18next";

import SignUpClosedComponent from "modules/registration/components/SignUpClosed";

const SignUpClosed = () => {
	const { t } = useTranslation("sign_up_closed");
	const sendProps = {
		years: t("season"),
		startTime: t("startTime"),
	};

	return (
		<SignUpClosedComponent { ...sendProps } />
	);
};

export default SignUpClosed;

SignUpClosed.displayName = "succon";