import * as types from "redux/constants/prizes";

import createReducer from "redux/createReducer";

const prizesInitialState = {
	prizesWinnerType1Data: [],
	prizesWinnerType1RequestError: {},
	prizesWinnerType1RequestLoading: false,
	prizesWinnerType2Data: [],
	prizesWinnerType2RequestError: {},
	prizesWinnerType2RequestLoading: false,
	prizesWinnerType3Data: [],
	prizesWinnerType3RequestError: {},
	prizesWinnerType3RequestLoading: false,
};

const prizesReducer = createReducer(prizesInitialState)({
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_1_START]: state => ({
		...state,
		prizesWinnerType1RequestError: {},
		prizesWinnerType1RequestLoading: true,
	}),
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_1_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			prizesWinnerType1Data: payload,
			prizesWinnerType1RequestLoading: false,
		};
	},
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_1_ERROR]: (state, { payload }) => ({
		...state,
		prizesWinnerType1RequestError: payload,
		prizesWinnerType1RequestLoading: false,
	}),

	[types.GET_REQUEST_PRIZES_WINNER_TYPE_2_START]: state => ({
		...state,
		prizesWinnerType2RequestError: {},
		prizesWinnerType2RequestLoading: true,
	}),
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_2_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			prizesWinnerType2Data: payload,
			prizesWinnerType2RequestLoading: false,
		};
	},
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_2_ERROR]: (state, { payload }) => ({
		...state,
		prizesWinnerType2RequestError: payload,
		prizesWinnerType2RequestLoading: false,
	}),

	[types.GET_REQUEST_PRIZES_WINNER_TYPE_3_START]: state => ({
		...state,
		prizesWinnerType3RequestError: {},
		prizesWinnerType3RequestLoading: true,
	}),
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_3_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			prizesWinnerType3Data: payload,
			prizesWinnerType3RequestLoading: false,
		};
	},
	[types.GET_REQUEST_PRIZES_WINNER_TYPE_3_ERROR]: (state, { payload }) => ({
		...state,
		prizesWinnerType3RequestError: payload,
		prizesWinnerType3RequestLoading: false,
	}),
});

export default prizesReducer;