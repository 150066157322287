import * as types from "redux/constants/userTransfers";

import createReducer from "redux/createReducer";

const userTransfersInitialState = {
	postUserTransfersRequestError: {},
	postUserTransfersRequestLoading: false,
	getUserTransfersRequestError: {},
	getUserTransfersRequestLoading: false,
};

const userTransfersReducer = createReducer(userTransfersInitialState)({
	[types.POST_REQUEST_USER_TRANSFERS_START]: state => ({
		...state,
		createUserTransfersError: {},
		createUserTransfersLoading: true,
		transfersLoadedStoreUpdateTime: 0,
	}),
	[types.POST_REQUEST_USER_TRANSFERS_SUCCESS]: (state) => ({
		...state,
		createUserTransfersLoading: false,
	}),
	[types.POST_REQUEST_USER_TRANSFERS_ERROR]: (state, { payload }) => ({
		...state,
		createUserTransfersError: payload,
		createUserTransfersLoading: false,
	}),
	[types.GET_REQUEST_USER_TRANSFERS_START]: state => ({
		...state,
		getUserTransfersError: {},
	}),
	[types.GET_REQUEST_USER_TRANSFERS_SUCCESS]: (state, { payload }) => ({
		...state,
		transfersLoadedStoreUpdateTime: new Date().getTime(),
	}),
	[types.GET_REQUEST_USER_TRANSFERS_ERROR]: (state, { payload }) => ({
		...state,
		getUserTransfersError: payload,
		transfersLoadedStoreUpdateTime: new Date().getTime(),
	}),
});

export default userTransfersReducer;