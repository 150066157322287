import React from "react";
import propTypes from "prop-types";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";


const Welcome = ({ fullName }) => {
	const { t } = useTranslation("welcome");
	return (<div className="welcome">
		<Container className="welcome__container">
			<h1 className="welcome__text">
				<span className="welcome__text_transparent">{t("Hello,")}&nbsp;</span>{fullName}
			</h1>
		</Container>
	</div>);
};

const mapStateToProps = state => ({
	fullName: state.authReducer.fullName || "",
});

Welcome.propTypes = {
	fullName: propTypes.string.isRequired,
};

export default connect(
	mapStateToProps,
	{}
)(Welcome);

Welcome.displayName = "welcom";