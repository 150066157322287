import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { actionDeleteUserAccount } from "redux/actions/auth";

import SpinnerButton from "modules/common/SpinnerButton";
import ModalWindow from "modules/common/ModalWindow";



const DeleteAccountModal = (props) => {
	const {
		toggle,
		isOpen,
		deleteUserAccountLoading,
		actionDeleteUserAccount,
	} = props;

	const { t } = useTranslation("delete_account_modal");

	const handleSubmit = () => {
		actionDeleteUserAccount();
	};

	return (
		<ModalWindow toggle={toggle} isOpen={isOpen}>
			<div className="fantasy-modal-title">{t("Confirm Account Deletion")}</div>
			<div className="fantasy-modal-subtitle">
				{t("All your personal details and all the other account related data will be permanently removed. Are you sure ?")}
			</div>
			<SpinnerButton
				isLoading={deleteUserAccountLoading}
				text={t("confirm")}
				onClick={handleSubmit}
				className="col-4 text-uppercase"
				size="medium"
			/>
		</ModalWindow>
	);
};

DeleteAccountModal.propTypes = {
	isOpen: propTypes.bool,
	deleteUserAccountLoading: propTypes.bool,
	toggle: propTypes.func.isRequired,
	actionDeleteUserAccount: propTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const { deleteUserAccountLoading } = authReducer;
	return {
		deleteUserAccountLoading
	};
};

export default connect(
	mapStateToProps,
	{ actionDeleteUserAccount }
)(DeleteAccountModal);

DeleteAccountModal.displayName = "dam";