import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/leagues";

import { FOR_REPLACEMENT } from "helpers/constants/common";
import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";


const getCountryLeaguesStart = payload => ({
	type: types.GET_REQUEST_COUNTRY_LEAGUES_START,
	payload,
});

const getCountryLeaguesSuccess = payload => ({
	type: types.GET_REQUEST_COUNTRY_LEAGUES_SUCCESS,
	payload,
});

const getCountryLeaguesError = payload => ({
	type: types.GET_REQUEST_COUNTRY_LEAGUES_ERROR,
	payload,
});

const getCountryInfo = payload => ({
	type: types.GET_REQUEST_LEAGUES_COUNTRY_INFO_START,
	payload,
});

const getCountryInfoSuccess = payload => ({
	type: types.GET_REQUEST_LEAGUES_COUNTRY_INFO_SUCCESS,
	payload,
});

const getCountryInfoError = payload => ({
	type: types.GET_REQUEST_LEAGUES_COUNTRY_INFO_ERROR,
	payload,
});

export const actionGetRequestCountryLeagues = (activeSeasonId, countryCode) => dispatch => {
	dispatch(getCountryLeaguesStart());
	const url = backendApiUrls.leagueCountryLeague.replace(FOR_REPLACEMENT.ID, activeSeasonId)
		.replace(FOR_REPLACEMENT.COUNTRY_CODE, countryCode);
	const config = {
		method: "GET",
		url,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getCountryLeaguesSuccess(data));
		})

		.catch((error) => {
			dispatch(getCountryLeaguesError(errorsParser(error)));
		});
};

export const actionGetRequestCountryInfo = (activeSeasonId, userCountryCode, otherUserId) => dispatch => {
	dispatch(getCountryInfo());
	const config = {
		method: "GET",
		url: `${backendApiUrls.leagueCountryLeagueInfo.replace(FOR_REPLACEMENT.ID, activeSeasonId)
			.replace(FOR_REPLACEMENT.COUNTRY_CODE, userCountryCode)}${otherUserId ? `?user_id=${otherUserId}` : ""}`,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getCountryInfoSuccess(data));
		})

		.catch((error) => {
			dispatch(getCountryInfoError(errorsParser(error)));
		});
};
