import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { RULES_TAB_URL, FAQ_TAB_URL, tabs } from "modules/rulesAndFaq/constants";

import RulesTab from "./RulesTab";
import FAQTab from "./FAQTab";


const Tabs = () => {
	const { t } = useTranslation("rules_and_faq_tabs");
	let location = useLocation();
	let history = useHistory();

	const [activeTab, setActiveTab] = useState(location.search || RULES_TAB_URL);
	const isSomeTabMatch = tabs.some(tab => location.search === tab);
	useEffect(() => {
		location.search && setActiveTab(location.search);
	}, [location]);

	const toggle = tab => {
		if(activeTab !== tab) {
			setActiveTab(tab);
			history.push(tab);
		}
	};

	return (
		<Container className="rules__container margin-top-14-30px px-md-0">
			<Nav tabs className="rules-tabs">
				<div className="rules-tabs__wrapper">
					<NavItem className="rules-tabs__item">
						<NavLink
							className={classnames({ active: activeTab === RULES_TAB_URL }, "rules-tabs__link")}
							onClick={() => { toggle(RULES_TAB_URL); }}
							tabIndex="0"
							onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && toggle(RULES_TAB_URL)}
						>
							{t("rules")}
						</NavLink>
					</NavItem>
					<NavItem className="rules-tabs__item">
						<NavLink
							className={classnames({ active: activeTab === FAQ_TAB_URL }, "rules-tabs__link")}
							onClick={() => { toggle(FAQ_TAB_URL); }}
							tabIndex="0"
							onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && toggle(FAQ_TAB_URL)}
						>
							{t("FAQ")}
						</NavLink>
					</NavItem>
				</div>
			</Nav>
			<TabContent activeTab={activeTab}>
				{activeTab === RULES_TAB_URL &&
					<TabPane tabId={RULES_TAB_URL}>
						<RulesTab activeTab={activeTab} />
					</TabPane>
				}
				<TabPane tabId={FAQ_TAB_URL}>
					<FAQTab activeTab={activeTab} />
				</TabPane>
			</TabContent>
		</Container>
	);
};

export default Tabs;

Tabs.displayName = "faqtab";