import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import successIcon from "static/assets/images/success-green.svg"
import warningIcon from "static/assets/images/warning-red.svg"

import AdUnit from "modules/common/AdUnit"

const DownloadTheApp = () => {
  let displaySuccess = true;

  var userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    //window.open("https://play.google.com/store/apps/details?id=com.sportsyte.serieafantasy", "_self");
  } else if (/ipad|iphone/i.test(userAgent)) {
    window.open("https://apps.apple.com/app/seriea-fantasy/id6636546642", "_self");
  } else {
    displaySuccess = false;
  }

  return (
    <Container className="global-container">
			<Row>
			  <Col>
			    <div className="email-verified-wrapper">
			      <div className="blue-plank-top-service" />
			      {displaySuccess ?
			        <Fragment>
                <div className="email-verified-txt download-the-app-txt">
                  <Trans
                        i18nKey="download_app_success"
                        ns="registration_completed"
                        components={
                            [<div key="0" className="blue"/>]
                        }
                    />
                </div>
                <img className="email-verified-img" src={successIcon} alt="" />
			        </Fragment>
              :
              <Fragment>
                <div className="email-verified-txt download-app-txt">
                  <Trans
                        i18nKey="download_app_error"
                        ns="registration_completed"
                        components={
                            [<div key="0" className="blue"/>]
                        }
                    />
                </div>
                <img className="email-verified-img" src={warningIcon} alt="" />
			        </Fragment>
			      }
			    </div>
			  </Col>
			</Row>
		</Container>
  );
};

export default DownloadTheApp;

DownloadTheApp.displayName = "dta";