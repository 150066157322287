import * as types from "redux/constants/news";

import createReducer from "redux/createReducer";

const newsReducerInitialState = {
	newsPaginationData: [],
	getNewsPaginationRequestLoading: false,
	getNewsPaginationError: {},

	countersData: {},
	countersRequestLoading: false,
	countersError: {},

	selectedNewsItemData: {},
	selectedNewsItemRequestLoading: false,
	selectedNewsItemError: {},
};

const newsReducer = createReducer(newsReducerInitialState)({
	[types.GET_NEWS_PAGINATION_START]: (state) => {
		return { 
			...state, 
			getNewsPaginationRequestLoading: true,
			getNewsPaginationError: {},
		};
	},
	[types.GET_NEWS_PAGINATION_SUCCESS]: (state, action) => {
		return {
			...state,
			newsPaginationData: action.payload,
			getNewsPaginationRequestLoading: false,
		};
	},
	[types.GET_NEWS_PAGINATION_ERROR]: (state, action) => {
		return {
			...state,
			getNewsPaginationError: action.payload,
			getNewsPaginationRequestLoading: false,
		};
	},

	[types.GET_COUNTERS_START]: (state) => {
		return { 
			...state, 
			countersRequestLoading: true,
			countersError: {},
		};
	},
	[types.GET_COUNTERS_SUCCESS]: (state, action) => {
		return {
			...state,
			countersData: action.payload,
			countersRequestLoading: false,
		};
	},
	[types.GET_COUNTERS_ERROR]: (state, action) => {
		return {
			...state,
			countersError: action.payload,
			countersRequestLoading: false,
		};
	},
	
	[types.GET_SELECTED_NEWS_ITEM_START]: (state) => {
		return { 
			...state, 
			selectedNewsItemRequestLoading: true,
			selectedNewsItemError: {},
		};
	},
	[types.GET_SELECTED_NEWS_ITEM_SUCCESS]: (state, action) => {
		return {
			...state,
			selectedNewsItemData: action.payload,
			selectedNewsItemRequestLoading: false,
		};
	},
	[types.GET_SELECTED_NEWS_ITEM_ERROR]: (state, action) => {
		return {
			...state,
			selectedNewsItemError: action.payload,
			selectedNewsItemRequestLoading: false,
		};
	},
});

export default newsReducer;