import * as types from "redux/constants/rules";

import createReducer from "redux/createReducer";

const rulesAndFAQInitialState = {
	sectionsHowToPlayData: [],
	sectionsHowToPlayRequestError: {},
	sectionsHowToPlayRequestLoading: false,

	sectionsRulesData: [],
	sectionsRulesRequestError: {},
	sectionsRulesRequestLoading: false,

	rulesItemHowToPlayData: [],
	rulesItemHowToPlayRequestError: {},
	rulesItemHowToPlayRequestLoading: false,

	rulesItemRulesData: [],
	rulesItemRulesRequestError: {},
	rulesItemRulesRequestLoading: false,

	faqListData: [],
	faqListRequestError: {},
	faqListRequestLoading: false,
};

const rulesAndFAQReducer = createReducer(rulesAndFAQInitialState)({
	[types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_START]: state => ({
		...state,
		sectionsHowToPlayRequestError: {},
		sectionsHowToPlayRequestLoading: true,
	}),
	[types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_SUCCESS]: (state, { payload }) => ({
		...state,
		sectionsHowToPlayData: payload,
		sectionsHowToPlayRequestLoading: false,
	}),
	[types.GET_REQUEST_SECTIONS_HOW_TO_PLAY_ERROR]: (state, { payload }) => ({
		...state,
		sectionsHowToPlayRequestError: payload,
		sectionsHowToPlayRequestLoading: false,
	}),

	[types.GET_REQUEST_SECTIONS_RULES_START]: state => ({
		...state,
		sectionsRulesRequestError: {},
		sectionsRulesRequestLoading: true,
	}),
	[types.GET_REQUEST_SECTIONS_RULES_SUCCESS]: (state, { payload }) => ({
		...state,
		sectionsRulesData: payload,
		sectionsRulesRequestLoading: false,
	}),
	[types.GET_REQUEST_SECTIONS_RULES_ERROR]: (state, { payload }) => ({
		...state,
		sectionsRulesRequestError: payload,
		sectionsRulesRequestLoading: false,
	}),

	[types.GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_START]: state => ({
		...state,
		rulesItemHowToPlayRequestError: {},
		rulesItemHowToPlayRequestLoading: true,
	}),
	[types.GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_SUCCESS]: (state, { payload }) => ({
		...state,
		rulesItemHowToPlayData: payload,
		rulesItemHowToPlayRequestLoading: false,
	}),
	[types.GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_ERROR]: (state, { payload }) => ({
		...state,
		rulesItemHowToPlayRequestError: payload,
		rulesItemHowToPlayRequestLoading: false,
	}),

	[types.GET_REQUEST_RULES_ITEM_RULES_START]: state => ({
		...state,
		rulesItemRulesRequestError: {},
		rulesItemRulesRequestLoading: true,
	}),
	[types.GET_REQUEST_RULES_ITEM_RULES_SUCCESS]: (state, { payload }) => ({
		...state,
		rulesItemRulesData: payload,
		rulesItemRulesRequestLoading: false,
	}),
	[types.GET_REQUEST_RULES_ITEM_RULES_ERROR]: (state, { payload }) => ({
		...state,
		rulesItemRulesRequestError: payload,
		rulesItemRulesRequestLoading: false,
	}),

	[types.GET_REQUEST_FAQ_LIST_START]: state => ({
		...state,
		faqListRequestError: {},
		faqListRequestLoading: true,
	}),
	[types.GET_REQUEST_FAQ_LIST_SUCCESS]: (state, { payload }) => ({
		...state,
		faqListData: payload,
		faqListRequestLoading: false,
	}),
	[types.GET_REQUEST_FAQ_LIST_ERROR]: (state, { payload }) => ({
		...state,
		faqListRequestError: payload,
		faqListRequestLoading: false,
	}),
});

export default rulesAndFAQReducer;
