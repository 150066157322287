import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

import {
	footballRoundPropType,
} from "utils/propTypes";

import { getSortedRounds } from "redux/selectors";

import ScheduleAndResults from "modules/scheduleAndResults/components/ScheduleAndResults";


const RoundResultsList = ({
	isLoading,
	footballRounds,
}) => {

	const sendProps = {
		footballRounds,
	};

	return isLoading || !footballRounds?.length
		? <div className="spinner-wrapper">
			<Spinner />
		</div>
		: <ScheduleAndResults {...sendProps} />;
};

RoundResultsList.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	footballRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.resultGame)).isRequired,
	dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	footballRounds: getSortedRounds(state),
	isLoading: state.seasonRoundsReducer.seasonRoundsRequestLoading,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RoundResultsList);

RoundResultsList.displayName = "rrlcon";