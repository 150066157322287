export const POST_REQUEST_USER_TEAM_START = "POST_REQUEST_USER_TEAM_START";
export const POST_REQUEST_USER_TEAM_SUCCESS = "POST_REQUEST_USER_TEAM_SUCCESS";
export const POST_REQUEST_USER_TEAM_ERROR = "POST_REQUEST_USER_TEAM_ERROR";

export const GET_REQUEST_USER_TEAMS_REQUEST_START = "GET_REQUEST_USER_TEAMS_REQUEST_START";
export const GET_REQUEST_USER_TEAMS_REQUEST_SUCCESS = "GET_REQUEST_USER_TEAMS_REQUEST_SUCCESS";
export const GET_REQUEST_USER_TEAMS_REQUEST_ERROR = "GET_REQUEST_USER_TEAMS_REQUEST_ERROR";

export const CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START = "CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START";
export const CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_SUCCESS = "CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_SUCCESS";
export const CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_ERROR = "CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_ERROR";

export const CLEAN_USER_TEAM_ERROR = "CLEAN_USER_TEAM_ERROR";

export const HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_START = "HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_START";
export const HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_SUCCESS = "HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_SUCCESS";
export const HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_ERROR = "HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_ERROR";

export const HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_START = "HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_START";
export const HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_SUCCESS = "HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_SUCCESS";
export const HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_ERROR = "HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_ERROR";

export const PATCH_IS_WILDCARD_ROUND_START = "PATCH_IS_WILDCARD_ROUND_START";
export const PATCH_IS_WILDCARD_ROUND_SUCCESS = "PATCH_IS_WILDCARD_ROUND_SUCCESS";
export const PATCH_IS_WILDCARD_ROUND_ERROR = "PATCH_IS_WILDCARD_ROUND_ERROR";
export const PATCH_IS_WILDCARD_ROUND_CLEAN_ERROR = "PATCH_IS_WILDCARD_ROUND_CLEAN_ERROR";

export const PATCH_USER_TEAM_NAME_START = "PATCH_USER_TEAM_NAME_START";
export const PATCH_USER_TEAM_NAME_SUCCESS = "PATCH_USER_TEAM_NAME_SUCCESS";
export const PATCH_USER_TEAM_NAME_ERROR = "PATCH_USER_TEAM_NAME_ERROR";