import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { actionGetRequestPrizes } from "redux/actions/prizes";
import { getRoundWinnerPrizes, getQuadWinnerPrizes, getSeasonWinnerPrizes } from "redux/selectors";

import Spinner from "modules/common/Spinner";
import { ROUND_WINNER_TAB_URL, SEASON_WINNER_TAB_URL, QUAD_ROUND_WINNER_TAB_URL } from "modules/prizes/constants";

import Place from "./Place";


const WinnerTab = ({
	activeTab,
	prizes,
	prizesFromStore,
	isLoading,
	actionGetRequestPrizes,
}) => {
	useEffect(() => {
		if (_.isEmpty(prizesFromStore)) {
			activeTab === ROUND_WINNER_TAB_URL &&
				actionGetRequestPrizes({ winner_type: 3 });
			activeTab === SEASON_WINNER_TAB_URL &&
				actionGetRequestPrizes({ winner_type: 2 });
			activeTab === QUAD_ROUND_WINNER_TAB_URL &&
				actionGetRequestPrizes({ winner_type: 1 });	
		}
	}, [activeTab]);

	if (isLoading)
		return <Spinner className="height-37vh" />;
	return (
		<div className="prizes__places-list">
			<Place prizes={prizes.firstPlace} />
			<Place prizes={prizes.secondPlace} />
			<Place prizes={prizes.thirdPlace} />
		</div>
	);
};

WinnerTab.propTypes = {
	activeTab: PropTypes.oneOf([ROUND_WINNER_TAB_URL, SEASON_WINNER_TAB_URL, QUAD_ROUND_WINNER_TAB_URL]).isRequired,
	prizesFromStore: PropTypes.arrayOf(PropTypes.object),
	prizes: PropTypes.shape({
		firstPlace: PropTypes.object,
		secondPlace: PropTypes.object,
		thirdPlace: PropTypes.object,
	}),
	isLoading: PropTypes.bool.isRequired,
	actionGetRequestPrizes: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
	const { prizesReducer } = state;
	const {
		prizesWinnerType1Data,
		prizesWinnerType1RequestLoading,
		prizesWinnerType2Data,
		prizesWinnerType2RequestLoading,
		prizesWinnerType3Data,
		prizesWinnerType3RequestLoading,
	} = prizesReducer;
	let prizes = getRoundWinnerPrizes(state);
	let prizesFromStore = prizesWinnerType3Data;
	let isLoading = prizesWinnerType3RequestLoading;

	const isSeasonWinnerTab = props.activeTab === SEASON_WINNER_TAB_URL;
	const isQuadWinnerTab = props.activeTab === QUAD_ROUND_WINNER_TAB_URL;

	if (isSeasonWinnerTab) {
		prizes = getSeasonWinnerPrizes(state);
		prizesFromStore = prizesWinnerType2Data;
		isLoading = prizesWinnerType2RequestLoading;
	} else if (isQuadWinnerTab) {
		prizes = getQuadWinnerPrizes(state);
		prizesFromStore = prizesWinnerType1Data;
		isLoading = prizesWinnerType1RequestLoading;	
	}
	
	return {
		prizes,
		prizesFromStore,
		isLoading,
	};
};

export default connect(
	mapStateToProps,
	{ actionGetRequestPrizes }
)(WinnerTab);

WinnerTab.displayName = "wt";