import moment from "moment";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Row, Spinner, Table } from "reactstrap";
import PropTypes from "prop-types";

const columns = [
	"football_round_number",
	"match_datetime",
	"fixture",
];

const Fixtures = ({
	fixtures = [],
	isLoading = false,
}) => {
	const { t } = useTranslation("player_info_modal");
	const [lastSortIsUpKey, setLastSortIsUpKey] = useState(false);
	const [fixturesLocal, setFixturesLocal] = useState([]);

	useEffect(() => {
		fixtures.length && setFixturesLocal(fixtures.sort(function (a, b) {
			const compareValueA = a["football_round_number"] || 0;
			const compareValueB = b["football_round_number"] || 0;
			if (compareValueA < compareValueB) {
				return -1;
			}
			if (compareValueA > compareValueB) {
				return 1;
			}
			return 0;
		})
		);
	}, [fixtures]);

	const getHeadTableHeadValue = ({ key, fixture = {}, isHeader }) => {
		if (!isHeader && !fixture) {
			return <div>-</div>;
		}
		switch (key) {
		case "football_round_number": {
			if (isHeader) {
				return t("Round");
			}
			return fixture.football_round_number;
		}
		case "match_datetime": {
			if (isHeader) {
				return t("Date");
			}
			return <span>
				{moment(fixture.match_datetime).format("DD MMM")}
				<br />
				{moment(fixture.match_datetime).format("YYYY")}
			</span>;
		}
		case "fixture": {
			if (isHeader) {
				return t("Fixture");
			}
			return <Trans
				i18nKey="command_vs_command"
				ns="player_info_modal"
				components={fixture.own_team_name === fixture.team_1_full_name ? [
					<span key="0" className='color-accent1' />,
					<span key="0" />
				] : [
					<span key="0" />,
					<span key="0" className='color-accent1' />
				]}
				values={{
					team1: fixture.team_1_full_name,
					team2: fixture.team_2_full_name,
				}}
			/>;
		}
		default: {
			return null;
		}
		}
	};

	const sortTable = key => {
		const currentFixtures = [...fixturesLocal];

		if (lastSortIsUpKey !== key) {
			const newFixtures = currentFixtures.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA < compareValueB) {
					return 1;
				}
				if (compareValueA > compareValueB) {
					return -1;
				}
				return 0;
			});
			setFixturesLocal(newFixtures);
			setLastSortIsUpKey(key);
		} else {
			const newFixtures = currentFixtures.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA > compareValueB) {
					return 1;
				}
				if (compareValueA < compareValueB) {
					return -1;
				}
				return 0;
			});
			setFixturesLocal(newFixtures);
			setLastSortIsUpKey(false);
		}

	};

	if (isLoading) {
		return (
			<Row className='w-100 min-height-90px px-0 mx-0 d-flex align-items-center justify-content-center'>
				<Spinner />
			</Row>
		);
	}

	return fixtures.length ? (
		<div className='table-container fixtures'>
			<Table className='player-info-modal_table fixtures'>
				<thead>
					<tr>
						{columns.map((tableKey, index) => (
							<th onClick={() => sortTable(tableKey)} key={`player-stat${index}-${tableKey}`}>
								{getHeadTableHeadValue({ key: tableKey, isHeader: true })}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{fixturesLocal.map((fixture, index) => {
						const prefix = "" + fixture?.football_round_number + fixture?.match_datetime;
						return (
							<tr key={`player-stat-${index}-${prefix}`}>
								{columns.map((tableKey, pointIndex) => (
									<td key={`player-stat-value${index}-${prefix}-${pointIndex}-${tableKey}`}>
										{getHeadTableHeadValue({ key: tableKey, fixture })}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	) : <div className='font-h4 p-5 text-center'>{t("This player does not have any fixtures this season yet")}</div>;
};

export default Fixtures;


Fixtures.propTypes = {
	fixtures: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

Fixtures.displayName = "pimf";