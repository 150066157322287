import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Row, Col } from "reactstrap";
import { Trans } from "react-i18next";


const Place = ({ prizes }) => {
	const { image, textItems } = prizes;
	const placeNumber = textItems && textItems[0] && textItems[0].text_for_place;
	let title;
	switch (placeNumber) {
	case 1:
		title = <span>
			<Trans
				i18nKey="first_place"
				ns="place"
				components={[
					<span key="0" className="blue" />
				]}
			/>
		</span>;
		break;
	case 2:
		title = <span>
			<Trans
				i18nKey="second_place"
				ns="place"
				components={[
					<span key="0" className="blue" />
				]}
			/>
		</span>;
		break;
	case 3:
		title = <span>
			<Trans
				i18nKey="third_place"
				ns="place"
				components={[
					<span key="0" className="blue" />
				]}
			/>
		</span>;
		break;
	}

	const wrapperCssClass = classnames(
		"place-item",
		{ "place-item_other-places": placeNumber !== 1 },
		{ "d-block": placeNumber !== 1 },
	);
	const titleCssClass = classnames(
		"place-item__title",
		{ "place-item__title_first-place": placeNumber === 1 },
	);
	const listItemCssClass = classnames(
		"place-item__list-item",
		{ "place-item__list-item_first-place": placeNumber === 1 },
	);
	return (
		<Row className={wrapperCssClass}>
			{placeNumber === 1 ?
				<Col lg={8} className="order-lg-2">
					<Row className="justify-content-center h-100">
						<div className="main-image-wrapper">
							{image && <img className="main-image-wrapper__image" src={image} alt="" />}
							<div className="main-image-wrapper__shape-1" />
							<div className="main-image-wrapper__shape-3" />
							<div className="main-image-wrapper__shape-2" />
							<div className="main-image-wrapper__shape-4" />
							<div className="main-image-wrapper__shape-5" />
						</div>
					</Row>
				</Col>
				:
				(image && <img className="place-item__image" src={image} alt="" />)
			}
			<Col lg={placeNumber === 1 && 4}>
				<h3 className={titleCssClass}>{title}</h3>
				{prizes.textItems &&
					<ul className="place-item__list">
						{prizes.textItems.map(itemText => (
							<li 
								key={itemText.id} 
								className={listItemCssClass}
								dangerouslySetInnerHTML={{ __html: itemText.item_text.replace(/(\r\n|\n|\r)/gm, "") }} 
							/>
						))}
					</ul>
				}
			</Col>
		</Row>
	);
};

Place.propTypes = {
	prizes: PropTypes.shape({
		image: PropTypes.string,
		textItems: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			item_text: PropTypes.string,
			text_for_place: PropTypes.number,
		})),
	}).isRequired
};

export default Place;

Place.displayName = "pcom";