import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/teamsList";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const teamsListRequestStart = payload => ({
	type: types.GET_REQUEST_TEAMS_LIST_START,
	payload,
});

const teamsListRequestSuccess = payload => ({
	type: types.GET_REQUEST_TEAMS_LIST_SUCCESS,
	payload,
});

const teamsListRequestError = payload => ({
	type: types.GET_REQUEST_TEAMS_LIST_ERROR,
	payload,
});

export const actionTeamsListRequest = (payload) => dispatch => {
	dispatch(teamsListRequestStart(payload));

	axiosPlus({
		method: "GET",
		url: `${backendApiUrls.teamsList}`
	})
		.then(function (response) {
			const { data: { results = [] } = {} } = response;
			const sortedResults = results.sort(function (a, b) {
				if (a.full_name > b.full_name) {
					return 1;
				}
				if (a.full_name < b.full_name) {
					return -1;
				}
				return 0;
			});
			results.length && dispatch(teamsListRequestSuccess(sortedResults));
		})
		.catch(function (error) {
			dispatch(teamsListRequestError(errorsParser(error)));
		});
};