import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

import { roundGamePropType } from "utils/propTypes";

import MatchInfoComponent from "modules/common/MatchInfoComponent";

import Status from "./Status";

import { NavLink } from "react-router-dom";
import frontendUrls from "routes/frontendUrls";


const GameItem = ({ roundGame }) => {
	const [ switchModal, setSwitchModal ] = useState(false);
	const handleToggle = () => setSwitchModal(!switchModal);
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});
	const changedStyele = () => {
		isMobileResolution && handleToggle();
	};
	const infoButtonCssClass = classNames(
		"game-item__info-button",
		{ "game-item__info-button_active": switchModal && isMobileResolution }
	);
	const isCanceledGame = roundGame && roundGame.status === "cancelled";
	return (
		<div className="game-item-wrapper">
			<div className="game-item" onClick={changedStyele}>
				<Row sm={12} className="mx-0">
					<Team { ...roundGame.team_1 }/>
					<Result
						score1={isCanceledGame ? "-" : roundGame && roundGame.team_1_score}
						score2={isCanceledGame ? "-" : roundGame && roundGame.team_2_score}
						status={roundGame && roundGame.status}
						matchLocaleTime={roundGame.matchLocaleTime}
					/>
					<Team { ...roundGame.team_2 }/>
				</Row>
				<NavLink to={`${frontendUrls.urlMatchInfo}?match_id=${roundGame.id}`}>
          <button className={infoButtonCssClass} onClick={handleToggle}>
            i
          </button>
				</NavLink>
			</div>
		</div>
	);
};

export const Team = ({
	logo_image,
	full_name,
	fullwidth,
}) => (
	<Col className="game-item__team px-0">
		<img className='football-club-logo' src={logo_image} alt="" />
		<span className={`text-center ${fullwidth ? "w-100" : ""}`}>{full_name}</span>
	</Col>
);

export const Result = ({
	score1,
	score2,
	status,
	matchLocaleTime,
}) => (
	<Col className="game-item__score px-0">
		<Row className="d-flex justify-content-center">
			{status === "scheduled"
				? <span>{matchLocaleTime}</span>
				: 
				<span>
					<span className='line-height-120'>{score1}</span>
					<span className="mx-1">:</span>
					<span className='line-height-120'>{score2}</span>
				</span>
			}
		</Row>
		<Row>
			{status === "live" && (
				<Col className="d-flex justify-content-center mt-3" >
					<Status />
				</Col>
			)}
		</Row>
	</Col>
);

GameItem.propTypes = {
	roundGame: PropTypes.shape(roundGamePropType.resultGame).isRequired,
};

Team.propTypes = {
	logo_image: PropTypes.string,
	full_name: PropTypes.string.isRequired,
	fullwidth: PropTypes.bool
};

Team.defaultProps = {
	image: "",
	fullwidth: false,
};

Result.propTypes = {
	score1: PropTypes.number.isRequired,
	score2: PropTypes.number.isRequired,
	status: PropTypes.oneOf([ "scheduled", "live", "finished", "cancelled" ]),
	matchLocaleTime: PropTypes.string.isRequired,
};

Result.defaultProps = {
	status: "",
};

export default GameItem;

Result.displayName = "r";
Team.displayName = "s";
GameItem.displayName = "gi";