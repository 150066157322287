import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Shape from "static/assets/images/shape-empty-field.svg";
import PitchMobile from "static/assets/images/m-pitch.svg";
import PitchSmall from "static/assets/images/pitch-small.svg";
import logoLarge from "static/assets/images/logo-large.svg";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType, roundGamePropType,
} from "utils/propTypes";
import { parsedFieldTeamType, userTeamPropType } from "utils/propTypes/footballTeam";
import { userFootballPlayerType } from "utils/propTypes/footballPlayer";

import {
	actionInitTeam, actionResetTeam, actionCleanTeam,
} from "redux/actions/footballField";
import {
	getFinishedAndLiveRounds,
	getFinishedRounds,
	getRunningRound,
} from "redux/selectors";
import { actionGetUserRoundTeamsRequest } from "redux/actions/userRoundTeams";
import { actionClearRoundGamesHashList } from "redux/actions/roundGames";

import FootballFieldContainer from "modules/footballField/containers/FootballFieldContainer";
import SpinnerButton from "modules/common/SpinnerButton";
import RoundResultsList from "modules/home/containers/RoundResultsList";
import LeaguesList from "modules/leagues/components/LeaguesList";
import RoundStatistic from "modules/common/RoundStatistic";
import Status from "modules/common/Status";

import AdUnit from "modules/common/AdUnit"

const LivePreviousRoundTeamsContainer = ({
	userTeam, isLoading, userRoundsResultsHashList, fieldTeam,
	actionInitTeam, usersRoundsTeamsHashList, userID,
	seasonRoundsDataUpdateTime, actionClearRoundGamesHashList,
	actionGetUserRoundTeamsRequest,
	currentUserId, liveAndFinishedRounds, actionCleanTeam,
	usersRoundsTeamsRequestLoading, usersRoundsTeamsInited,
	roundLeagueId }) => {
	const { t } = useTranslation("live_previous_round_teams");
	const [selectedRound, setSelectedRound] = useState({});
	const [isExistNext, setIsExistNext] = useState({});
	const [isExistPrevious, setIsExistPrevious] = useState({});
	const selectedRoundID = selectedRound && selectedRound.id;
	const userRoundTeamForSelectedRound = !!userID && !!selectedRoundID && !!usersRoundsTeamsHashList[userID] && usersRoundsTeamsHashList[userID][selectedRoundID];
	const isNoLiveAndPreviousRoundsForThisSeason = seasonRoundsDataUpdateTime && !liveAndFinishedRounds.length;

	useEffect(() => {
		actionCleanTeam();
		actionClearRoundGamesHashList();
		return () => {
			actionClearRoundGamesHashList();
		};
	}, []);

	useEffect(() => {
		if (userID && selectedRoundID && !userRoundTeamForSelectedRound) {
			actionGetUserRoundTeamsRequest({
				user: currentUserId,
				football_round: selectedRound.id,
			});
		}
	}, [selectedRoundID, userID]);

	useEffect(() => {
		if (liveAndFinishedRounds && liveAndFinishedRounds.length) {
			setSelectedRound(liveAndFinishedRounds[liveAndFinishedRounds.length - 1]);
		}
	}, [liveAndFinishedRounds]);

	useEffect(() => {
		const currentRoundIndex = liveAndFinishedRounds.findIndex(round => round.id === selectedRoundID);
		setIsExistNext(liveAndFinishedRounds[currentRoundIndex + 1]);
		setIsExistPrevious(liveAndFinishedRounds[currentRoundIndex - 1]);
	}, [selectedRoundID]);

	useEffect(() => {
		const currentFootballPlayersIDs = [];
		Object.keys(fieldTeam).forEach(line => {
			fieldTeam[line].forEach(player => {
				const { id: playerID } = player;
				playerID && currentFootballPlayersIDs.push(playerID);
			});
		});
	}, [fieldTeam, usersRoundsTeamsRequestLoading, usersRoundsTeamsInited]);

	useEffect(() => {
		if (userRoundTeamForSelectedRound?.football_players?.length) {
			const roundUserTeam = {
				...userRoundTeamForSelectedRound,
				football_players: userRoundTeamForSelectedRound.football_players,
				name: userRoundTeamForSelectedRound.user_team_name,
			};
			actionInitTeam(roundUserTeam);
		} else {
			actionResetTeam();
		}
	}, [userRoundTeamForSelectedRound, userID, selectedRoundID]);

	useEffect(() => {
		actionResetTeam();
	}, []);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const changeSelectedRound = value => {
		const currentRoundIndex = liveAndFinishedRounds.findIndex(round => round.id === selectedRoundID);
		const newRoundIndex = currentRoundIndex + value;

		if (newRoundIndex < liveAndFinishedRounds.length && newRoundIndex >= 0) {
			setSelectedRound(liveAndFinishedRounds[newRoundIndex]);
		}
	};

	const isEmptyTeamForThisRound = userRoundTeamForSelectedRound?.isEmpty;
	const allDataIsLoaded = isEmptyTeamForThisRound || (userRoundTeamForSelectedRound && userRoundsResultsHashList[userID]?.[roundLeagueId]);

	return (
		<div className="position-relative">
			{isNoLiveAndPreviousRoundsForThisSeason ?
				<Container className="d-flex align-items-center flex-column">
					<div className="blue-plank-top-service" />
					<div className="service-message">
						{t("There are currently no live or finished Rounds in current season")}
						<img src={logoLarge} alt="" />
					</div>
				</Container>
				: null
			}
			<Container key="lpr-1" className='select-team-container'>
			  <AdUnit adUnitId="9136300246" />
				<Row>
					{!isMobileResolution ?
						<Col className='live-previous-left col-3 pr-0 d-flex flex-column z-index-5'>
							<div>
								<RoundStatistic selectedRound={selectedRound} />
							</div>
							<LeaguesList typeLeagues="common" />
							<LeaguesList typeLeagues="private" />
						</Col> : null
					}
					<Col className={`${isMobileResolution
						? "col-12 p-0"
						: ""} d-flex flex-column align-items-center`}>
						<Row
							className='select-team-top-controls'>
							<SpinnerButton
								disabled={!isExistPrevious}
								isLeftNavigate
								text={t("Previous round")}
								isLoading={isLoading}
								onClick={() => changeSelectedRound(-1)}
								className={`select-team-button text-uppercase double ${!isExistPrevious && "invisible"}`}
								size="medium"
								color="white"
							/>
							<span className='d-flex flex-column align-items-center justify-content-center'>
								<h1 className="font-h4new-h3">
									{t("Round")}&nbsp;
									<span className="color-accent1">
										{selectedRound && selectedRound.number || ""}
									</span>
								</h1>
								<div className="live-previous-running-round" >
									{selectedRound && selectedRound.status === "live" && <Status />}
								</div>
							</span>
							<SpinnerButton
								disabled={!isExistNext}
								isRightNavigate
								isLoading={isLoading}
								text={t("Next round")}
								onClick={() => changeSelectedRound(1)}
								className="select-team-button hidable-when-disabled double"
								size="medium"
								color="white"
							/>
						</Row>
						{!isEmptyTeamForThisRound ? <FootballFieldContainer isLiveAndPreviousPage selectedRoundId={selectedRoundID} /> : null}
						{isEmptyTeamForThisRound ? <div className="football-field-container-small">
							<img className="football-field-image-empty" src={Shape} alt="" />
							<span className="football-field-image-empty-text">{t("Team not played")}</span>
							<img className="football-field-image" src={isMobileResolution ? PitchMobile : PitchSmall} alt="" />
						</div> : null}
						<Row className='team-name-text mb container'>
							{userTeam.name}
						</Row>
						{isMobileResolution ?
							<Col className="live-previous-left p-0 z-index-5">
								<div>
									<RoundStatistic selectedRound={selectedRound} />
								</div>
								<LeaguesList typeLeagues="common" />
								<LeaguesList typeLeagues="private" />
							</Col> : null
						}
					</Col>
				</Row>
			</Container>
			<div key="lpr-2" className={`round-list-live-previous ${allDataIsLoaded ? "" : "d-none"}`}>
				<RoundResultsList withoutFutureRounds />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { userDetails: { id: currentUserId } } = state.authReducer;
	const {
		userTeamsData, isUserTeamPlayedRequestDone,
	} = state.userTeamsReducer;
	const { userDetails: { id: userID } = {} } = state.authReducer;
	const { fieldTeam } = state.footballFieldReducer;
	const {
		usersRoundsTeamsRequestLoading, usersRoundsTeamsHashList, usersRoundsTeamsListDataUpdateTime, userRoundsResultsHashList, usersRoundsTeamsInited
	} = state.userRoundTeamsReducer;
	const userTeam = userTeamsData[0] || {};
	const isLoading = usersRoundsTeamsRequestLoading || !usersRoundsTeamsListDataUpdateTime;
	const liveAndFinishedRounds = getFinishedAndLiveRounds(state);
	const { seasonRoundsDataUpdateTime } = state.seasonRoundsReducer;
	const roundLeagueId = state.leaguesReducer?.roundLeaguesData?.id;

	return {
		roundLeagueId,
		liveRound: getRunningRound(state),
		previousRounds: getFinishedRounds(state),
		leagueSeasons: state.leagueSeasonsReducer.seasonsListData,
		usersRoundsTeamsHashList,
		userTeam,
		isLoading,
		isUserTeamPlayedRequestDone,
		usersRoundsTeamsRequestLoading,
		usersRoundsTeamsInited,
		fieldTeam,
		currentUserId,
		userID,
		liveAndFinishedRounds,
		userRoundsResultsHashList,
		seasonRoundsDataUpdateTime,
	};
};

export default connect(
	mapStateToProps,
	{
		actionInitTeam,
		actionGetUserRoundTeamsRequest,
		actionResetTeam,
		actionClearRoundGamesHashList,
		actionCleanTeam,
	}
)(LivePreviousRoundTeamsContainer);

LivePreviousRoundTeamsContainer.propTypes = {
	userTeamFootballPlayers: PropTypes.arrayOf(userFootballPlayerType),
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	isLoadingInfo: PropTypes.bool,
	currentRound: PropTypes.shape(footballRoundPropType.round),
	userTeam: PropTypes.oneOfType([PropTypes.object, userTeamPropType]),
	isLoading: PropTypes.bool.isRequired,
	liveRound: PropTypes.shape(footballRoundPropType.round),
	previousRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.round).isRequired),
	usersRoundsTeamsHashList: PropTypes.array,
	liveAndFinishedRounds: PropTypes.object,
	actionInitTeam: PropTypes.func.isRequired,
	userID: PropTypes.number,
	seasonRoundsDataUpdateTime: PropTypes.number,
	actionGetUserRoundTeamsRequest: PropTypes.func.isRequired,
	actionClearRoundGamesHashList: PropTypes.func.isRequired,
	actionResetTeam: PropTypes.func.isRequired,
	actionCleanTeam: PropTypes.func.isRequired,
	isUserTeamPlayedRequestDone: PropTypes.bool.isRequired,
	usersRoundsTeamsRequestLoading: PropTypes.bool.isRequired,
	usersRoundsTeamsInited: PropTypes.bool.isRequired,
	currentUserId: PropTypes.string.isRequired,
	userRoundsResultsHashList: PropTypes.shape(PropTypes.shape(roundGamePropType.resultGame)),
	roundLeagueId: PropTypes.number.isRequired,
};

LivePreviousRoundTeamsContainer.displayName = "lprtcon";