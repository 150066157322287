import {
	createValidator,
	combineValidators,
	composeValidators,
	hasLengthLessThan,
} from "revalidate";
import _ from "lodash";
import i18instance from "i18instance";

import { validateEmail } from "helpers/common";
import { TEAM_NAME_BAD_WORDS, TEAM_NAME_IS_REQUIRED, TEAM_NAME_ONLY_LATIN_OR_DIGITS, TEAM_NAME_SHOULD_BE_LESS_31 } from "helpers/constants/errors";

const Filter = require("bad-words");
const filter = new Filter();
const checkingEmailAddress = createValidator(
	message => email => {
		if (!validateEmail(email)) {
			return message;
		}
	},
	() => i18instance.t("validation_errors:email_should_have_correct_format_email_example_com")
);

const checkingPassword = createValidator(
	() => password => {
		if (password.length < 8 || !(/\d/.test(password)) || !(/[a-z]/.test(password)) || !(/[A-Z]/.test(password))) {
			return i18instance.t("validation_errors:password_requirements");
		}
	}, ""
);

const matchPasswords = createValidator(
	message => (value, payload) => {
		if ((!_.isEmpty(payload)) && (value !== payload.password)) {
			return message;
		}
	},
	i18instance.t("validation_errors:password_mismatch")
);

const isLatinOrSpace = createValidator(
	message => text => {
		if (!/^[\w\s]+$/.test(text) || /[0-9]/.test(text)) {
			return message;
		}
	},
	field => i18instance.t("validation_errors:should_contain_only_latin_letters", { field })
);

const isRequired = createValidator(
	message => text => {
		if (typeof text === "undefined" || !text || (typeof text === "string" && !text.replace(/\s/g, "").length)) {
			return message;
		}
	}, 
	field => i18instance.t("validation_errors:is_required", { field })
);

const isProfane = createValidator(
	message => text => {
		if (filter.isProfane(text)) {
			return message;
		}
	},
	field => i18instance.t("validation_errors:should_not_contain_inappropriate_offensive_words", { field })
);

const hasLengthLessThan30 = createValidator(
	message => text => {
		if (text.length > 30) {
			return message;
		}
	},
	field => i18instance.t("validation_errors:can_t_be_longer_than_30_characters", { field })
);

const checkTeamName = createValidator(
	() => teamName => {
		let message = "";

		if ((typeof teamName !== "undefined" && !teamName) || !teamName.replace(/\s/g, "").length) {
			message = TEAM_NAME_IS_REQUIRED;
		} else {
			if (!/^[\w\s]+$/.test(teamName)) {
				message += TEAM_NAME_ONLY_LATIN_OR_DIGITS;
			}
			if (filter.isProfane(teamName)) {
				message += TEAM_NAME_BAD_WORDS;
			}
			if (teamName.length > 30) {
				message += TEAM_NAME_SHOULD_BE_LESS_31;
			}
		}

		if (message) return message;
	}, ""
);

export const formValidatorRegister = combineValidators({
	first_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		isProfane,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:first_name")),
	last_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:last_name")),
	email: composeValidators(
		isRequired,
		checkingEmailAddress,
		hasLengthLessThan(51),
	)(i18instance.t("validation_errors:email")),
	country: composeValidators(
		isRequired,
		hasLengthLessThan(3),
	)(i18instance.t("validation_errors:country")),
	password: composeValidators(
		isRequired,
		checkingPassword,
	)(i18instance.t("validation_errors:password")),
	confirm_password: composeValidators(
		isRequired,
		matchPasswords,
	)(i18instance.t("validation_errors:confirm_password")),
});

export const formValidatorEditShort = combineValidators({
	first_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:first_name")),
	last_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:last_name")),
});

export const formValidatorEdit = combineValidators({
	first_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:first_name")),
	last_name: composeValidators(
		isRequired,
		isLatinOrSpace,
		hasLengthLessThan30,
	)(i18instance.t("validation_errors:last_name")),
	email: composeValidators(
		isRequired,
		checkingEmailAddress,
		hasLengthLessThan(51),
	)(i18instance.t("validation_errors:email")),
	team_name: composeValidators(
		isRequired,
		checkTeamName,
	)(i18instance.t("validation_errors:team_name")),
});

export const formValidatorChangePwd = combineValidators({
	old_password: composeValidators(
		isRequired,
	)(i18instance.t("validation_errors:old_password")),
	password: composeValidators(
		isRequired,
		checkingPassword,
	)(i18instance.t("validation_errors:password")),
	confirm_password: composeValidators(
		isRequired,
		matchPasswords,
	)(i18instance.t("validation_errors:confirm_password")),
});

export const formValidatorSetNewPwd = combineValidators({
	password: composeValidators(
		isRequired,
		checkingPassword,
	)(i18instance.t("validation_errors:password")),
	confirm_password: composeValidators(
		isRequired,
		matchPasswords,
	)(i18instance.t("validation_errors:confirm_password")),
});

export const formValidatorForgorPwd = combineValidators({
	email: composeValidators(
		isRequired,
		checkingEmailAddress,
	)(i18instance.t("validation_errors:email")),
});

const checkLeagueName = createValidator(
	() => name => {
		let message = "";

		if (name?.length > 30) {
			message = message + i18instance.t("validation_errors:league_name_can_t_be_longer_than_30_characters");
		}
		if (message) return message;
	}, ""
);

export const formValidatorCreateLeague = combineValidators({
	name: composeValidators(
		isRequired,
		checkLeagueName,
	)(i18instance.t("validation_errors:league_name")),
	selectedRoundCount: composeValidators(
		isRequired,
	)(i18instance.t("validation_errors:this_field")),
});

export const formValidatorJoinLeague = combineValidators({
	name: composeValidators(
		isRequired,
	)(i18instance.t("validation_errors:league_code")),
});

export const formValidatorEditLeague = combineValidators({
	name: composeValidators(
		isRequired,
		checkLeagueName,
	)(i18instance.t("validation_errors:league_name")),
});