import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import dropdownIcon from "static/assets/images/dropdown-arrow-black.svg";
import dropdownIconYellow from "static/assets/images/dropdown-arrow-yellow.svg";
import dropdownIconWhite from "static/assets/images/dropdown-opened.svg";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import useOnClickOutside from "helpers/hooks/useOnClickOutside";

function NavItemDropdown ({ className, handleCloseMenu, isOpenDropdown, setIsOpenDropdown, isShowMenu }) {
	const { t } = useTranslation("nav_item_dropdown");
	const mainRef = useRef();
	const toggleDropdown = () => setIsOpenDropdown(!isOpenDropdown);
	useOnClickOutside(mainRef, () => {
		if (window.innerWidth > 767) {
			setIsOpenDropdown(false);
		}
	});
	const dropdownItems = [
		{
			name: t("Next Round"),
			url: frontendUrls.urlNextRoundTeam
		},
		{
			name: t("Live & Previous Rounds"),
			url: frontendUrls.urlLivePreviousRoundTeams
		},
		{
			name: t("Transfers"),
			url: frontendUrls.urlTransfers
		}
	];

	const isOneOfDropdownElementActive = dropdownItems.some(el => el.url === window.location.pathname);

	useEffect(() => {
		if (isOneOfDropdownElementActive && window.innerWidth <= 767) {
			setIsOpenDropdown(true);
		}
	}, [isShowMenu]);


	const buttonClass = classNames(
		className, "dropdown__button", "nav-item-dropdown__button", "ml-0", "mr-3", "mt-0", { "selected": isOneOfDropdownElementActive }
	);
	const iconClass = classNames({
		"dropdown__icon_opened-single": isOpenDropdown,
		"dropdown__icon": !isOpenDropdown,
		"rotate": !isOneOfDropdownElementActive
	});
	const ulClass = classNames(
		"nav-item-dropdown__list",
	);
	let imageSrc = dropdownIcon;
	if (window.innerWidth <= 767) {
		imageSrc = isOneOfDropdownElementActive ? dropdownIconYellow: dropdownIconWhite;
	}
	
	const subMenuItems = (
		<div className={ulClass}>
			{dropdownItems.map((el) => (
				<NavLink
					key={el.name}
					to={el.url} 
					className="nav-item-dropdown__item" 
					activeClassName="selected"
					onClick={(e) => {
						handleCloseMenu(e);
						setIsOpenDropdown(false);
					}}
				>
					{el.name}
				</NavLink>
			))}
		</div>);
	
	return (
		<li ref={mainRef} className={`${className} dropdown nav-item-dropdown`}>
			<button onClick={toggleDropdown} className="position-relative">
				<span className={buttonClass}>
					{t("Team")}
				</span>
				<span className={iconClass}>
					<img src={imageSrc} alt="" />	
				</span>
			</button>
			<Collapse isOpen={isOpenDropdown} className="d-md-none">
				{subMenuItems}
			</Collapse>
			<div className={`d-none ${isOpenDropdown && "d-md-block"}`}>
				{subMenuItems}
			</div>
		</li>
	);
}

NavItemDropdown.propTypes = {
	className: propTypes.string,
	handleCloseMenu: propTypes.func.isRequired,
	isOpenDropdown: propTypes.bool.isRequired,
	isShowMenu: propTypes.bool.isRequired,
	setIsOpenDropdown: propTypes.func.isRequired,
};

NavItemDropdown.defaultProps = {
	className: "",
};

export default NavItemDropdown;

NavItemDropdown.displayName = "nid";