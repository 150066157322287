import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./i18instance";
import store from "redux/store";

import tokenRefreshing from "helpers/tokenRefreshing";

import App from "./App";

tokenRefreshing(true);
const tracesSampleRate = process.env.REACT_APP_TRACES_SAMPLE_RATE ? parseFloat(process.env.REACT_APP_TRACES_SAMPLE_RATE) : 0.2;

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		new Integrations.BrowserTracing(),
	],
	tracesSampleRate
});

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
			return this.replace(str, newStr);
		}

		return this.replace(new RegExp(str, "g"), newStr);

	};
}

ReactDOM.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<Sentry.ErrorBoundary
				beforeCapture={(_, error, componentStack) => {
					const state = store.getState();
					console.log("Sentry error's details");
					console.warn(`Sentry error's details, error: ${error}`);
					console.warn(`Sentry error's details, componentStack ${JSON.stringify(componentStack)}`);
					console.warn(`Sentry error's details, userDetails ${JSON.stringify(state?.authReducer?.userDetails || {})}`);
				}}
				fallback={"An error has occured"}
			>
				<App />
			</Sentry.ErrorBoundary>
		</ReduxProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
