export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export const convertName = (fullName, showFullFirstName = false) => {
	if (fullName) {
		const nameValues = fullName.split(" ");
		if(nameValues.length === 1 || (nameValues.length === 2 && nameValues[1] === "" && showFullFirstName))
			return nameValues[0];
		if(nameValues.length === 3)
			return `${nameValues[0][0]}.${nameValues[1][0]}.${nameValues[2]}`;
		return `${nameValues[0][0]}.${nameValues[1]}`;
	}
};

export const numberWithSpaces = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const roundedNumberWithSpaces = (number) => {
	let roundValue = 1;
	if (number <= 10) {
		return 10;
	} else if (number <= 1000) {
		roundValue = 10;
	} else if (number <= 100000) {
		roundValue = 100;
	} else {
		roundValue = 1000;
	}

	return numberWithSpaces(Math.ceil(number/roundValue) * roundValue);
};