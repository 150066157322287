import * as types from "redux/constants/leagues";

export const actionSetSelectedRoundLeague = payload => ({
	type: types.SET_SELECTED_ROUND_LEAGUE,
	payload,
});

export const actionClearSelectedRoundLeague = () => ({
	type: types.CLEAR_SELECTED_ROUND_LEAGUE,
});

export const actionHydrateSelectedRoundLeagueFromStorage = () => ({
	type: types.HYDRATE_SELECTED_ROUND_LEAGUE_FROM_STORAGE,
});