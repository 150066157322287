import React from "react";
import PropTypes from "prop-types";
import {
	Row,
	Col,
} from "reactstrap";

import RoundItem from "modules/scheduleAndResults/containers/RoundItem";

const RoundList = ({
	footballRounds,
}) => (
	<Row className="mx-0">
		<Col className="padding-x-10px px-md-0 mt-3">
			{footballRounds && footballRounds.map(round => (
				<RoundItem key={round.id} round={round} />
			))}
		</Col>
	</Row>
);

RoundList.propTypes = {
	footballRounds: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	})).isRequired,
};

export default RoundList;

RoundList.displayName = "rlcom";