import React, { useEffect, useState } from "react";
import {
	Row,
	Spinner,
	Table,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ACTION_TYPES_BLOCKS, ACTION_TYPES_ORDERED_LONG, ACTION_TYPES_ORDERED_SHORT } from "helpers/constants/common";

const RoundResults = ({
	matches,
	position,
	totalPointsList,
	isLoading,
}) => {
	const { t } = useTranslation("player_info_modal");
	const [showTitleFor, setShowTitleFor] = useState("");
	const [lastSortIsUpKey, setLastSortIsUpKey] = useState(false);
	const [matchesLocal, setMatchesLocal] = useState([]);

	useEffect(() => {
		matches.length && setMatchesLocal(matches);
	}, [matches]);

	const getHeadTableHeadValue = ({ key, data = {}, isTotal, isHeader, showTitle }) => {
		const { calculatedPoints } = data;
		if (!isHeader && data && ACTION_TYPES_BLOCKS[position][key]) {
			return <div>-</div>;
		}
		switch (key) {
		case "roundNumber": {
			if (isHeader) {
				return <div key={`${key}1`}>{t("Round")}</div>;
			}
			return <div key={`${key}1`} className='player-info-modal_table_top-text font-size-15'>{data[key]}</div>;
		}
		case "opponentTeam": {
			if (isHeader) {
				return [<div key={`${key}1`}>{ACTION_TYPES_ORDERED_SHORT[key]}</div>,
					<div key={`${key}2`} className='player-info-modal_table_score'>{t("score")}</div>];
			}
			if (isTotal) {
				return <div key={key} className="text-uppercase">{t("total")}</div>;
			}
			return [
				<div key={`${key}1`}>
					<span className='player-info-modal_table_top-text'>{data[key].name}</span>
						&nbsp;<span className='color-accent1'>{data[key].prefix}</span>
				</div>,
				<div key={`${key}2`} className='player-info-modal_table_score'>{data.score}</div>
			];
		}
		case "SA_OP":
		case "TBI":
		case "SoT": {
			if (isHeader) {
				return [<div key={`${key}1`}>{ACTION_TYPES_ORDERED_SHORT[key]}</div>,
					<div key={`${key}2`}>{(key === "SA_OP" || key === "SoT") ? "(2x)" : "(3x)"}{showTitle && <div className='player-info-title'>{ACTION_TYPES_ORDERED_LONG[key]}</div>}</div>];
			}
		}
		// eslint-disable-next-line no-fallthrough
		default: {
			if (data && data[key] && typeof calculatedPoints[key] === "number") {
				return [<div key={`${key}1`} >{data[key] || 0}</div>,
					<div className={`${isTotal ? "" : "color-accent1"}`} key={`${key}2`}>{` (${calculatedPoints[key] || 0})`}</div>];
			} else if (data && typeof data[key] === "number") {
				return <div>{data[key]}</div>;
			} else {
				return <div>{ACTION_TYPES_ORDERED_SHORT[key]}{showTitle && <div className='player-info-title'>{ACTION_TYPES_ORDERED_LONG[key]}</div>}</div>;
			}
		}
		}
	};

	const sortTable = key => {
		if (ACTION_TYPES_BLOCKS[position][key]) {
			return;
		}

		const currentMatches = [...matchesLocal];

		if (lastSortIsUpKey !== key) {
			const newMatches = currentMatches.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA < compareValueB) {
					return 1;
				}
				if (compareValueA > compareValueB) {
					return -1;
				}
				return 0;
			});
			setMatchesLocal(newMatches);
			setLastSortIsUpKey(key);
		} else {
			const newMatches = currentMatches.sort(function (a, b) {
				const compareValueA = a[key] || 0;
				const compareValueB = b[key] || 0;
				if (compareValueA > compareValueB) {
					return 1;
				}
				if (compareValueA < compareValueB) {
					return -1;
				}
				return 0;
			});
			setMatchesLocal(newMatches);
			setLastSortIsUpKey(false);
		}

	};
    
	if (isLoading) {
		return (
			<Row className='w-100 min-height-90px px-0 mx-0 d-flex align-items-center justify-content-center'>
				<Spinner/>
			</Row>
		);
	}

	return matches.length ? (
		<div className="table-outer">
			<div className='table-container'>
				<Table className='player-info-modal_table'>
					<thead>
						<tr>
							{Object.keys(ACTION_TYPES_ORDERED_SHORT).map((tableKey, index) => (
								<th onMouseEnter={() => setShowTitleFor(tableKey)} onMouseLeave={() => setShowTitleFor("")} onClick={() => sortTable(tableKey)} key={`player-stat${index}-${tableKey}`}>
									{getHeadTableHeadValue({ key: tableKey, isHeader: true, showTitle: showTitleFor === tableKey && ACTION_TYPES_ORDERED_LONG[tableKey] })}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{matchesLocal.map((match, matchIndex) => {
							const { opponentTeam: { name, prefix } } = match || {};
							return (
								<tr key={`player-stat-${matchIndex}-${name}-${prefix}`}>
									{Object.keys(ACTION_TYPES_ORDERED_SHORT).map((tableKey, pointIndex) => (
										<td key={`player-stat-value${matchIndex}-${name}-${prefix}-${pointIndex}-${tableKey}`}>
											{getHeadTableHeadValue({ key: tableKey, value: match[tableKey], data: match })}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							{Object.keys(ACTION_TYPES_ORDERED_SHORT).map((tableKey, pointIndex) => (
								<td key={`player-stat-value-total-${pointIndex}-${tableKey}`}>
									{getHeadTableHeadValue({ key: tableKey, data: totalPointsList, isTotal: true })}
								</td>
							))}
						</tr>
					</tfoot>
				</Table>
			</div>
		</div>
	) : <div className='font-h4 p-5 text-center'>{t("This player has not played any matches this season yet")}</div>;
};


RoundResults.propTypes = {
	matches: PropTypes.array.isRequired,
	position: PropTypes.string.isRequired,
	matchesLocal: PropTypes.array.isRequired,
	totalPointsList: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default RoundResults;

RoundResults.displayName = "pimrr";