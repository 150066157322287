import React, { useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyTextIcon from "static/assets/images/copy-text.svg";
import { useTranslation } from "react-i18next";

const CopyIcon = ({
	code,
	isModal = false,
}) => {
	const { t } = useTranslation("copy_icon");
	const [showCopyMessage, setShowCopyMessage] = useState(null);
	const [animationTimeout, setAnimationTimeout] = useState(null);
	
	return (
		<span className={`copy-icon-wrapper ${isModal && "in-modal"}`}>
			<CopyToClipboard text={code} onCopy={() => {
				animationTimeout && clearTimeout(animationTimeout);
				setShowCopyMessage(null);
				setTimeout(() => {
					setShowCopyMessage(true);
				}, 20);
				setAnimationTimeout(setTimeout(() => {
					setShowCopyMessage(false);
				}, 2000));
			}}>
				<img
					className='mb-1 copy-icon'
					src={CopyTextIcon} alt=""
				/>
			</CopyToClipboard>
			<span className={`copy-text ${showCopyMessage && "hide"}`}>{t("League Code copied to clipboard")}</span>
		</span>
	);
};

CopyIcon.propTypes = {
	code: PropTypes.number.isRequired,
	isModal: PropTypes.bool.isRequired,
};

CopyIcon.displayName = "ci";

export default CopyIcon;