import PropTypes from "prop-types";
import React from "react";

export default function Track({ source, target, getTrackProps, disabled }) {
	return (
		<div
			className='slider-track'
			style={{
				backgroundColor: disabled ? "#D1D1D1" : "#008FD7",
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`,
			}}
			{...getTrackProps()}
		/>
	);
}

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired,
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

Track.defaultProps = {
	disabled: false,
};

Track.displayName = "tra";