import React from "react";
import { useTranslation } from "react-i18next";

const Status = () => {
	const { t }  = useTranslation("status");
	return(
		<span className="status">
			{t("Live")}
		</span>
	);
};

export default Status;

Status.displayName = "st";