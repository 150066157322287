import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { countriesPropTypes, errorsPropTypes } from "utils/propTypes/registrationUser";
import {  formValidatorEditShort } from "utils/validators";

import { actionGetCountries } from "redux/actions/countries";
import { actionGetUserDetails, actionUpdateUserDetails } from "redux/actions/auth";

import PersonalDetailsShort from "../components/PersonalDetailsShort";

const PersonalDetails = ({
	userDetails,
	countriesData,
	actionGetCountries,
	userDetailsErrors,
	actionGetUserDetails,
	actionUpdateUserDetails,
	userDetailsRequestLoading,
	validateUserTeam,
	userTeamPlayersRequestLoading,
	handleCreateEditTeam,
}) => {
	const { t } = useTranslation("personal_details");
	const { first_name: userFirstName, last_name :userLastName, country: userCountry } = userDetails;
	
	useEffect(() => {
		actionGetUserDetails();
		actionGetCountries();
	}, []);

	const [first_name, setFirstName] = useState(userFirstName);
	const [last_name, setLastName] = useState(userLastName);
	const [country, setCountry] = useState(userCountry);
	const [changedFields, setChangedFields] = useState([]);

	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		if (!_.isEmpty(userDetails)) {
			setFirstName(userFirstName);
			setLastName(userLastName);
			setCountry(userCountry);
		}
	}, [JSON.stringify(userDetails)]);

	const changeFirstName = (e) => {
		const { target: { value } = {} } = e;
		setFirstName(value);
	};
	const changeLastName = (e) => {
		const { target: { value } = {} } = e;
		setLastName(value);
	};
	const changeCountry = ({ code }) => setCountry(code);

	const trimmedFirstName = first_name?.trim();
	const trimmedLastName = last_name?.trim();
	const payload = { first_name: trimmedFirstName,
		last_name: trimmedLastName,
		country,
	};

	const validationErrors = formValidatorEditShort(payload);

	const handleSubmit = () => {
		setShowErrors(true);
		const fieldErrors = validateUserTeam();
		const isValid = _.isEmpty(validationErrors) && !fieldErrors;
		console.warn("FE", fieldErrors);
		if (isValid) {
			const changedFieldsValue = [];
			if (userFirstName !== first_name) changedFieldsValue.push(t("First Name"));
			if (userLastName !== last_name) changedFieldsValue.push(t("Last Name"));
			if (userCountry !== country) changedFieldsValue.push(t("Country"));

			if (changedFieldsValue.length) {
				actionUpdateUserDetails(payload).then((data) => {
					if (!(data && data.parsedTextErrors)) {
						setChangedFields(changedFieldsValue);
						handleCreateEditTeam();
					}
				});
			} else {
				handleCreateEditTeam();
			}
		}
	};

	let errors = { ...userDetailsErrors };
	if (showErrors) {
		errors = { ...errors, ...validationErrors };
	}

	const sendProps = {
		countriesData,
		handleSubmit,
		changeFirstName,
		changeLastName,
		changeCountry,
		first_name,
		last_name,
		country,
		errors,
		changedFields,
		setChangedFields,
		userDetailsRequestLoading,
		userTeamPlayersRequestLoading,
	};

	return 	<PersonalDetailsShort { ...sendProps } />;
};

PersonalDetails.propTypes = {
	countriesData: countriesPropTypes.isRequired,
	userDetailsErrors: errorsPropTypes,
	createUserRequestLoading: propTypes.bool.isRequired,
	userDetailsRequestLoading: propTypes.bool.isRequired,
	userDetails: propTypes.shape(
		{ first_name: propTypes.string, last_name: propTypes.string, email: propTypes.string, country: propTypes.string }),
	actionGetCountries: propTypes.func.isRequired,
	actionGetUserDetails: propTypes.func.isRequired,
	userTeamPlayersRequestLoading: propTypes.bool.isRequired,
	validateUserTeam: propTypes.func.isRequired,
	actionUpdateUserDetails: propTypes.func.isRequired,
	handleCreateEditTeam: propTypes.func.isRequired,
};

const mapStateToProps = ({ countriesReducer, authReducer, userTeamsReducer }) => {
	const { userDetails = {}, userDetailsErrors, userDetailsRequestLoading } = authReducer;
	const { countriesData } = countriesReducer;
	const { userTeamPlayersRequestLoading } = userTeamsReducer;
	return {
		countriesData,
		userDetailsErrors,
		userDetails,
		userDetailsRequestLoading,
		userTeamPlayersRequestLoading,
	};
};

export { PersonalDetails as PersonalDetailsForTest };
export default connect(
	mapStateToProps,
	{ actionGetCountries, actionGetUserDetails, actionUpdateUserDetails }
)(PersonalDetails);

PersonalDetailsShort.displayName = "pds";