import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Close from "static/assets/images/player-close.svg";
import Info from "static/assets/images/player-info.svg";
import CloseMobile from "static/assets/images/m-player-close.svg";
import InfoMobile from "static/assets/images/m-player-info.svg";
import Background from "static/assets/images/player-placeholder-background.png";
import AddIcon from "static/assets/images/add.png";
import captain from "static/assets/images/captain.svg";
import viceCaptain from "static/assets/images/vice-captain.svg";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import { actionSetPlayerInfoModalID } from "redux/actions/playersList";

import useOnClickOutside from "helpers/hooks/useOnClickOutside";
import { convertName } from "helpers/common";


function FieldPlayerItem ({
	position, data, index, handleDrop, isSelectTeamPage, isNextRoundPage, actionRemovePlayer, positionShort,
	isLiveAndPreviousPage, withoutPlayerDeleteButton, lineLength, isBench,
	withoutDragAndWithDeletable, isDraggable, switchPlayersListModal,
	isCaptainMarkerSelected, isViceCaptainMarkerSelected, withoutCaptainIcon, handleSetCaptain, handleSetViceCaptain,
	actionSetPlayerInfoModalID, key, isDreamTeam, dreamTeamPlayerScore, opponentTeams
}) {
	const { t } = useTranslation("field_player_item");
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 991px)"
	});
	const playerRef = useRef();

	const [showOptions, setShowOptions] = useState(!isMobileResolution);

	useOnClickOutside(playerRef, () => setShowOptions(false));

	const {
		football_club : { abbreviation, jersey_goalkeeper, jersey_outfield } = {},
		position: playerPosition, status,
		full_name, current_price: price, id, transferedOut, football_player_info: footballPlayerInfo, opponents
	} = data || {} ;
	const isCaptain = data ? (isLiveAndPreviousPage ? data.is_captain : data.isCaptain) : false;
	const isViceCaptain = data ? (isLiveAndPreviousPage ? data.is_vice_captain : data.isViceCaptain) : false;
	const isGoalkeeper = playerPosition === "goalkeeper";
	const jersey = isGoalkeeper ? jersey_goalkeeper : jersey_outfield;

	const actionMovePlayer = (callback) => {
		handleDrop({ player: callback, target: {
			index, position, data
		} });
	};

	const [{ isDragging }, drag, preview] = useDrag({
		item: { type: "player", data, position, index },
		collect: monitor => {
			return {
				isDragging: !!monitor.isDragging(),
			};},
	});

	// eslint-disable-next-line no-empty-pattern
	const [{ highlighted, isActive }, drop] = useDrop({
		canDrop:(item) => {
			if (position === "bench" && item.position === "bench") {
				return item.data.position !== "goalkeeper" && data.position !== "goalkeeper";
			}
			if (
				(item.isViceCaptain && !isBench) 
				|| (item.isCaptain && !isBench) 
				|| (playerPosition === item.position || item.data && playerPosition === item.data.position)
			) {
				return true;
			}
		},
		options: { data },
		accept: ["player", "captain", "viceCaptain"],
		drop: (callback, next) => {
			actionMovePlayer(callback, next);
		},
		collect: monitor => {
			return { 
				isOver: !!monitor.isOver(), 
				highlighted: monitor.canDrop(),
				isActive: monitor.canDrop() && monitor.isOver(),
			};
		},
	});

	useEffect(() => {
		preview(getEmptyImage() ); }, []);

	const handleOptionsSwitch = (e) => {
		if (!showOptions || (isCaptainMarkerSelected && !isBench) || (isViceCaptainMarkerSelected && !isBench)) {
			e.stopPropagation();
			e.preventDefault();
		}
		if (isCaptainMarkerSelected && !isBench && !isViceCaptain) {
			handleSetCaptain({
				index, position, data
			});
		} else if (isViceCaptainMarkerSelected && !isBench && !isCaptain) {
			handleSetViceCaptain({
				index, position, data
			});
		} else {
			setShowOptions(true);
		}
	};

	let additionalNameClass = "";
	if (isNextRoundPage || isSelectTeamPage) {
		switch (status) {
            case "doubtful":
            case "minor-injury":
            case "unhealthy":
                additionalNameClass = "bg-color-yellow";
                break;
            case "international-duty":
            case "serious-injury":
            case "suspended":
            case "transferred-out":
                additionalNameClass = "bg-color-red";
                break;
		}
	}

	const [scaleValue, setScaleValue] = useState(1);


	const updateCssValues = () => {
		let newScaleValue = 1;
		if (window.innerWidth <= 500) {
			newScaleValue = 1 + ((window.innerWidth - 320) / 900);
		} else if (window.innerWidth > 500 && window.innerWidth <= 768)  {
			newScaleValue = 1.3;
		} else if (window.innerWidth > 768 && window.innerWidth <= 991)  {
			newScaleValue = 1.15;
		} else if (window.innerWidth > 991 && window.innerWidth < 1200) {
			newScaleValue = 0.95;
		}

		setScaleValue(newScaleValue);
	};

	useEffect(() => {
		updateCssValues();
		window.addEventListener("resize", updateCssValues);
		return () => window.removeEventListener("resize", updateCssValues);
	}, []);

	const { urlNextRoundTeam, urlTransfers, urlNextSeasonTeam, urlSelectTeam } = frontendUrls;

	let playerInfoBottomValue = "";
	if (isLiveAndPreviousPage) {
		playerInfoBottomValue = footballPlayerInfo;
	} else if ([urlNextRoundTeam, urlTransfers].some(url => window.location.pathname.includes(url))) {
		playerInfoBottomValue = opponents;
	}

	if (
		[urlTransfers, urlNextSeasonTeam, urlSelectTeam].some(url => window.location.pathname.includes(url))
	) {
		if (!playerInfoBottomValue) {
			opponentTeams.forEach((opponentTeam, i) => {
				if (!i) {
					playerInfoBottomValue = `${opponentTeam.abbreviation} (${opponentTeam.placeOfGame})`;
				} else {
					playerInfoBottomValue += `, ${opponentTeam.abbreviation} (${opponentTeam.placeOfGame})`;
				}
			});
		}
	}

	if (!playerInfoBottomValue) { playerInfoBottomValue = "-";}

	return (
		<div className={`${isActive ? "active-player" : 
			((highlighted || (isCaptainMarkerSelected && !isBench && !isViceCaptain) || (isViceCaptainMarkerSelected && !isBench && !isCaptain) ? "highlighted-player" : ""))
		}`} onClick={handleOptionsSwitch} ref={drop} key={key}>
			{id ?
				<div ref={playerRef}>
					<div ref={isDraggable ? drag : null}
						className={`${isDragging ? "hidden-modal" : `football-player-wrapper cursor-pointer line-${lineLength}`}`}
						style={{ transform: `scale(${scaleValue})` }}
					>

						{(isCaptain && !withoutCaptainIcon) ? <img className='captain-icon' src={captain} alt=""/> : null}
						{(isViceCaptain && !withoutCaptainIcon) ? <img className='captain-icon vice-captain' src={viceCaptain} alt=""/> : null}
						{(showOptions || !isMobileResolution) ?
							<div className="football-player-controls">
								<img
									onClick={() => {actionSetPlayerInfoModalID(id);}}
									className='player-controls-item'
									src={isMobileResolution ? InfoMobile : Info} alt=""/>
								{((isDraggable || withoutDragAndWithDeletable) && !withoutPlayerDeleteButton) ?
									<img
										className="player-controls-item"
										onClick={(e) => {
											e.stopPropagation();
											setShowOptions(!isMobileResolution);
											actionRemovePlayer({ data, position, index, transferedOut });
										}}
										src={isMobileResolution ? CloseMobile : Close} alt=""/>
									: null}
							</div>
							: null}
						<img className="football-player-shirt" src={jersey} alt=""/>
						<div className="football-player-info">
							<div className="football-player-info-side-wrapper">
								{abbreviation ?
									<div className="football-player-info-side-item">{abbreviation.slice(0,3)}</div> : null
								}
								{!isNaN(price) ?
									<div className="football-player-info-side-item">
										{Number(price).toFixed(1)}
									</div> : null
								}
							</div>
							<div className={`${additionalNameClass} football-player-info-name`}>
								<span className='player-info-name-text'>{convertName(full_name, true)}</span>
							</div>
							<div className="football-player-info-bottom-wrapper">
								{isDreamTeam ? <span className="football-player-info-bottom">
									{dreamTeamPlayerScore || dreamTeamPlayerScore === 0 ? dreamTeamPlayerScore: ""}
								</span> :
									<span className="football-player-info-bottom">
										<span className="abbreviation" key={key + "sentry-key-2"}>
											<span className="text-nowrap">
												{playerInfoBottomValue}
											</span>
										</span>
									</span>
								}
							</div>
						</div>
						{isBench ?
							<div className='bench-label position-absolute d-flex align-items-center'>
								<span className='color-secondary-text'>{index > 0 ? index : "GKP"}</span>
							</div> : null
						}
					</div>
				</div>
				: null}
			{(isDragging || !id) ?
				<div 
					key={key + positionShort + index} onClick={() => {
						if (switchPlayersListModal && isMobileResolution) {
							switchPlayersListModal(positionShort);
						}
					}} 
					className={`football-player-wrapper z-index-1 line-${lineLength}`}
					style={{ transform: `scale(${scaleValue})` }}
				>
					<span key="1" className="player-type-title">{t(positionShort)}</span>
					<img key="2" className="football-player-image" src={Background} alt=""/>
					{isDreamTeam ? null : <img key="3" className="football-player-add-image" src={AddIcon} alt=""/>}
					{isBench ?	
						<div className='bench-label position-absolute d-flex align-items-center'>
							<span className='color-secondary-text'>{index > 0 ? index : "GKP"}</span>
						</div> : null
					}
				</div>
				: null	}
		</div>
	);
}

FieldPlayerItem.propTypes = {
	position: PropTypes.oneOf(["goalkeepers", "forwards", "defenders", "midfielders", "bench"]).isRequired,
	data: PropTypes.object,
	index: PropTypes.number.isRequired,
	handleDrop: PropTypes.func.isRequired,
	actionRemovePlayer: PropTypes.func.isRequired,
	handleSetCaptain: PropTypes.func.isRequired,
	handleSetViceCaptain: PropTypes.func.isRequired,
	isCaptainMarkerSelected: PropTypes.bool.isRequired,
	isViceCaptainMarkerSelected: PropTypes.bool.isRequired,
	key: PropTypes.string.isRequired,
	dynamicField: PropTypes.bool,
	withoutDragAndWithDeletable: PropTypes.bool,
	withoutFieldTopControls: PropTypes.bool,
	isLiveAndPreviousPage: PropTypes.bool,
	positionShort: PropTypes.string,
	lineLength: PropTypes.number,
	dreamTeamPlayerScore: PropTypes.number,
	isBench: PropTypes.bool,
	isSelectTeamPage: PropTypes.bool,
	isNextRoundPage: PropTypes.bool,
	opponentTeams: PropTypes.array,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
	isDraggable: PropTypes.bool,
	withoutPlayerDeleteButton: PropTypes.bool,
	isDreamTeam: PropTypes.bool,
	withoutCaptainIcon: PropTypes.bool,
	switchPlayersListModal: PropTypes.func,
};

FieldPlayerItem.defaultProps = {
	opponentTeams: [],
	data: {},
	lineLength: 0,
	positionShort: "",
	isBench: false,
	isDraggable: true,
	isLiveAndPreviousPage: false,
	isSelectTeamPage: false,
	isNextRoundPage: false,
	isDreamTeam: false,
};

const mapStateToProps = ({ roundGamesReducer }, { data = {} } ) => {
	const { cloasestScheduledGamesData } = roundGamesReducer;
	const { football_club, round_score , season_score } = data;
	const footballClubId = football_club?.id;

	return {
		opponentTeams: cloasestScheduledGamesData[footballClubId] || [],
		dreamTeamPlayerScore: round_score || season_score,
	};
};

export default connect(
	mapStateToProps,
	{
		actionSetPlayerInfoModalID,
	}
)(FieldPlayerItem);

FieldPlayerItem.displayName = "fpi";