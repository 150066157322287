export const GET_REQUEST_PLAYERS_LIST_START = "GET_REQUEST_PLAYERS_LIST_START";
export const GET_REQUEST_PLAYERS_LIST_SUCCESS = "GET_REQUEST_PLAYERS_LIST_SUCCESS";
export const GET_REQUEST_PLAYERS_LIST_ERROR = "GET_REQUEST_PLAYERS_LIST_ERROR";
export const RESET_PLAYERS_LIST = "RESET_PLAYERS_LIST";

export const GET_REQUEST_PLAYER_INFO_START = "GET_REQUEST_PLAYER_INFO_START";
export const GET_REQUEST_PLAYER_INFO_SUCCESS = "GET_REQUEST_PLAYER_INFO_SUCCESS";
export const GET_REQUEST_PLAYER_INFO_ERROR = "GET_REQUEST_PLAYER_INFO_ERROR";

export const GET_REQUEST_PLAYER_DATA_START = "GET_REQUEST_PLAYER_DATA_START";
export const GET_REQUEST_PLAYER_DATA_SUCCESS = "GET_REQUEST_PLAYER_DATA_SUCCESS";
export const GET_REQUEST_PLAYER_DATA_ERROR = "GET_REQUEST_PLAYER_DATA_ERROR";

export const SWITCH_PLAYER_INFO_MODAL_OPEN_STATE = "SWITCH_PLAYER_INFO_MODAL_OPEN_STATE";

export const GET_REQUEST_PLAYER_FIXTURES_START = "GET_REQUEST_PLAYER_FIXTURES_START";
export const GET_REQUEST_PLAYER_FIXTURES_SUCCESS = "GET_REQUEST_PLAYER_FIXTURES_SUCCESS";
export const GET_REQUEST_PLAYER_FIXTURES_ERROR = "GET_REQUEST_PLAYER_FIXTURES_ERROR";

export const GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_START = "GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_START";
export const GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_SUCCESS = "GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_SUCCESS";
export const GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_ERROR = "GET_REQUEST_PLAYER_PAST_SEASON_RESULTS_ERROR";