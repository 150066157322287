import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Row, Input, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import blueClose from "static/assets/images/blue-close.svg";
import { useTranslation } from "react-i18next";
import i18instance from "i18instance";

import { basicFootballPlayerType } from "utils/propTypes/footballPlayer";

import { actionGetRequestPlayersList, actionResetPlayersList } from "redux/actions/playersList";
import { actionGetRequestFootballActions } from "redux/actions/footballActions";
import { getFieldPositionsEmptySpaces } from "redux/selectors";

import { ACTION_TYPES_BLOCKS, FIELD_POSITIONS_MAP, FROM_CHANGE_TO_REQUEST_DELAY_MS } from "helpers/constants/common";
import useEffectSkipFirst from "helpers/hooks/useEffectSkipFirst";
import useDebounce from "helpers/hooks/useDebounce";
import debounce from "helpers/debounce";

import PlayerListItem from "modules/registration/components/PlayerListItem";
import Select from "modules/common/Select";
import RangeSlider from "modules/common/Slider";

const SORT_BY = [
	{ name: i18instance.t("players_list:Points"), code: "season_score", suffix: i18instance.t("players_list:points") },
	{ name: i18instance.t("players_list:Price"), code: "current_price", prefix: "€ " },
	{ name: i18instance.t("players_list:selected_by"), code: "popularity_pct", suffix: "%" },
	{ name: i18instance.t("players_list:minutes_played"), code: "season_minutes_played" },
	{ name: i18instance.t("players_list:goals_scored_from_open_play"), code: "GS_OP" },
	{ name: i18instance.t("players_list:goals_scored_from_penalty"), code: "GS_PK" },
	{ name: i18instance.t("players_list:Assists"), code: "AS" },
	{ name: i18instance.t("players_list:penalties_earned"), code: "PE" },
	{ name: i18instance.t("players_list:shots_on_target"), code: "season_count_sot" },
	{ name: i18instance.t("players_list:clean_sheets_for_every_half_time"), code: "CS_HT" },
	{ name: i18instance.t("players_list:Tackles/blocks/interceptions"), code: "season_count_tbi" },
	{ name: i18instance.t("players_list:saves_from_open_play"), code: "season_count_saop" },
	{ name: i18instance.t("players_list:saves_from_penalty"), code: "SA_PK" },
	{ name: i18instance.t("players_list:goals_conceded"), code: "GC" },
	{ name: i18instance.t("players_list:own_goals"), code: "OG" },
	{ name: i18instance.t("players_list:penalties_missed"), code: "PM" },
	{ name: i18instance.t("players_list:yellow_cards"), code: "YC" },
	{ name: i18instance.t("players_list:red_cards"), code: "RC" }
];

export const SelectButton = ({ activeValue, value, onClick, t }) => {
	const handleClick = () => {
		onClick(value);
	};
	return (
		<Col onClick={handleClick} className={`selected-button${activeValue === value ? "__active" : ""}`}>
			{t(value, { ns: "players_list" })}
		</Col>
	);
};

function PlayersList({ positionsEmptySpaces, teamsListData, playersListData, playersListRequestLoading,
	fieldTeamIdsHashList, playersListRequestLoadingPagination, switchPlayersListModalHide, actionGetRequestPlayersList,
	actionGetRequestFootballActions, actionResetPlayersList, setPlayerListModalHideState, setPlayerListModalOpenState,
	isMobileResolution, openWithTab }) {
	const initialPosition = typeof openWithTab === "string" ? openWithTab : "GKP";
	const pageSize = process.env.REACT_APP_PLAYERS_PER_PAGE || 30;
	const [searchText, setSearchText] = useState("");
	const [requestForParams, setRequestForParams] = useState({});
	const [page, setPage] = useState(1);
	const [lastScrollHeight, setLastScrollHeight] = useState(0);
	const [playerPosition, setPlayerPosition] = useState(initialPosition);
	const [selectedTeam, setSelectedTeam] = useState(false);
	const [priceRange, setPriceRange] = useState([0, 15]);
	const [sortBy, setSortBy] = useState(SORT_BY[0]);
	const [quickChangesDelay, setQuickChangesDelay] = useState(0);
	const [localPlayersList, setLocalPlayersList] = useState([]);
	const searchTextDebounced = useDebounce(searchText, FROM_CHANGE_TO_REQUEST_DELAY_MS);
	const priceRangeDebounced = useDebounce(priceRange, FROM_CHANGE_TO_REQUEST_DELAY_MS);

	const scrollRef = useRef();
	const teamID = selectedTeam && selectedTeam.id;

	const { t } = useTranslation(["field_positions", "players_list"]);

	const setVhProperty = () => {
		debounce(() => {
			const vh = window.innerHeight * 0.01;
			const currentVh = parseFloat(document.documentElement.style.getPropertyValue("--vh"));
			if (vh !== currentVh) {
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}, 100);
	};


	useEffect(() => {
		actionGetRequestFootballActions();
		actionResetPlayersList();
		setVhProperty();

		window.addEventListener("scroll", setVhProperty);
		return () => {
			window.removeEventListener("scroll", setVhProperty);
		};
	}, []);

	useEffect(() => {
		setPage(1);
		setLastScrollHeight(0);
		scrollRef.current.view.scrollTop = 0;
	}, [priceRange, sortBy, playerPosition, searchText, teamID]);

	useEffect(() => {
		setLocalPlayersList(playersListData);
	}, [playersListData, fieldTeamIdsHashList, priceRange, playerPosition, sortBy]);

	const filteredPlayersRequest = () => {
		const params = { page, page_size: pageSize };
		if (teamID) {
			params.football_club_id = teamID;
		}

		if (searchText) {
			params.full_name = searchText;
		}

		if (sortBy) {
			params.ordering = `-${sortBy.code},football_club__full_name`;
		}

		params.min_price = priceRange[0];
		params.max_price = priceRange[1];
		params.position = FIELD_POSITIONS_MAP[playerPosition];
		setRequestForParams(params);
		if (!_.isEqual(params, requestForParams)) {
			if (!_.isEqual({ ...params, page: 0 }, { ...requestForParams, page: 0 })) {
				params.page = 1;
			}
			actionGetRequestPlayersList(params);
		}
	};

	useEffectSkipFirst(() => {
		filteredPlayersRequest();
	}, [selectedTeam, sortBy.code, page, playerPosition, searchTextDebounced, priceRangeDebounced.join("") ]);


	useEffectSkipFirst(() => {
		const lastChange = new Date().getTime();
		if (!quickChangesDelay) {
			setQuickChangesDelay(lastChange);
			setTimeout(() => {
				if (quickChangesDelay === lastChange) {
					setQuickChangesDelay(0);
				}
			}, FROM_CHANGE_TO_REQUEST_DELAY_MS);
		}
	}, [searchText, priceRange, quickChangesDelay]);

	useEffect(() => {
		if (!quickChangesDelay) {
			filteredPlayersRequest();
		}
	}, [quickChangesDelay]);

	const handleChangePosition = position => {
		setPlayerPosition(position !== playerPosition ? position : "");
	};

	const handleSelectTeam = team => {
		setSelectedTeam(team);
		setPage(1);
	};

	const handleScroll = (e) => {
		const scrollTop = e.target.scrollTop;
		const maxScroll = e.target.scrollHeight - e.target.clientHeight;
		if (scrollTop >= maxScroll - 100 && lastScrollHeight <= e.target.scrollHeight - 100) {
			setLastScrollHeight(e.target.scrollHeight);
			setPage(page + 1);
		}
	};

	const handleChangePriceRange = useCallback(values => {
		setPriceRange(values.map(value => Number(value).toFixed(1)));
	}, [priceRange]);

	const handleChangeSort = value => {
		setSortBy(value);
	};

	let sortByCode = sortBy.code;
	if (sortByCode === "season_count_tbi") {
		sortByCode = "TBI";
	} else if (sortByCode === "season_count_saop") {
		sortByCode = "SA_OP";
	}
	const isNonApplicableStat = ACTION_TYPES_BLOCKS[FIELD_POSITIONS_MAP[playerPosition]] && ACTION_TYPES_BLOCKS[FIELD_POSITIONS_MAP[playerPosition]][sortByCode];

	return (
		<Row className='select-team-left fullwidth mt-4 p-0 d-flex align-items-center flex-column'>
			{isMobileResolution &&
				<Row className='w-100 justify-content-center' onClick={() => {
					setPlayerListModalOpenState(false);
					setPlayerListModalHideState(false);
				}}>
					<img src={blueClose} className="mt-3 mb-3" alt="" />
				</Row>
			}
			<Input value={searchText} placeholder={t("Search", { ns: "players_list" })}
				onChange={(e) => setSearchText(e.target.value)}
				className={`search-player-textfield ${isMobileResolution && "mobile"} ${searchText && "active"}`} type="textarea" name="player-search"
				id="player-search"
			/>
			<Row className='buttons-container'>
				{Object.keys(FIELD_POSITIONS_MAP).map(positionShort => <SelectButton
					key={`position${positionShort}`} 
					value={positionShort}
					activeValue={playerPosition} 
					onClick={handleChangePosition}
					t={t}
				/>)}
			</Row>
			<div className='select-team-picker-container'>
				<Select
					isClearable={true}
					placeholder={t("Team", { ns: "players_list" })}
					handleChange={handleSelectTeam}
					values={teamsListData}
					labelObjectKey="full_name"
					valueObjectKey="id"
				/>
			</div>
			<div className='select-team-slider-container'>
				<RangeSlider
					domain={[0, 15]}
					values={priceRange}
					onChange={handleChangePriceRange} />
			</div>
			<div className='select-team-picker-short-container'>
				<Select
					isSearchable={false}
					placeholder={t("Sort by", { ns: "players_list" })}
					handleChange={handleChangeSort}
					values={SORT_BY}
					valueObjectKey="code"
					labelObjectKey="name"
					defaultOption={SORT_BY[0]}
				/>
			</div>
			<div className='mt-4 w-100 overflow-hidden'>
				{playerPosition &&
					<div className='position-orange'>
						<span className='text-capitalize source-sans-pro fw-600 font-size-12'>
							{t(FIELD_POSITIONS_MAP[playerPosition])}
						</span>
					</div>
				}
				<Scrollbars onScroll={handleScroll} ref={scrollRef} className='players-table'>
					{!playersListRequestLoading && localPlayersList.map((player) => (
						<PlayerListItem
							sortValue={sortBy}
							isNonApplicableStat={isNonApplicableStat}
							isPositionFull={!positionsEmptySpaces[player.position]}
							isSelected={!!fieldTeamIdsHashList[player.id]}
							toggleModal={switchPlayersListModalHide}
							key={`players-list-item${player.id}`}
							data={{ ...player, ...player.season_count_actions }} />))
					}
					{(playersListRequestLoading || playersListRequestLoadingPagination) &&
						<div className='players-table-item w-100 d-flex justify-content-center align-items-center'>
							<Spinner />
						</div>
					}
				</Scrollbars>
			</div>
		</Row>
	);
}

const mapStateToProps = (state) => {
	const { teamsListData } = state.teamsListReducer;
	const { paginatedPlayersListData, playersListRequestLoading, playersListRequestLoadingPagination } = state.playersListReducer;
	const { fieldTeamIdsHashList } = state.footballFieldReducer;
	const positionsEmptySpaces = getFieldPositionsEmptySpaces(state);
	const playersListData = Object.entries(paginatedPlayersListData)
		.sort((a, b) => a[0] - b[0]) // sort by page number
		.flatMap(el => el[1]); // extract data from pages

	const firstPageLoaded = paginatedPlayersListData["1"] && paginatedPlayersListData["1"].length;

	return {
		teamsListData,
		playersListData,
		fieldTeamIdsHashList,
		positionsEmptySpaces,
		playersListRequestLoading: playersListRequestLoading || !firstPageLoaded,
		playersListRequestLoadingPagination,
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetRequestPlayersList,
		actionGetRequestFootballActions,
		actionResetPlayersList,
	}
)(PlayersList);
export { PlayersList as PlayersListForTests };

PlayersList.propTypes = {
	switchPlayersListModalHide: PropTypes.func,
	teamsListData: PropTypes.array.isRequired,
	playersListData: PropTypes.arrayOf(basicFootballPlayerType).isRequired,
	positionsEmptySpaces: PropTypes.object.isRequired,
	playersListRequestLoading: PropTypes.bool.isRequired,
	fieldTeamIdsHashList: PropTypes.object.isRequired,
	actionGetRequestPlayersList: PropTypes.func.isRequired,
	actionGetRequestFootballActions: PropTypes.func.isRequired,
	actionResetPlayersList: PropTypes.func.isRequired,
	setPlayerListModalHideState: PropTypes.func.isRequired,
	setPlayerListModalOpenState: PropTypes.func.isRequired,
	isMobileResolution: PropTypes.bool.isRequired,
	playersListRequestLoadingPagination: PropTypes.bool.isRequired,
	openWithTab: PropTypes.string,
};

SelectButton.propTypes = {
	activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
	onClick: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	openWithTab: "",
};

SelectButton.displayName = "sb";
PlayersList.displayName = "pl";