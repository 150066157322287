import propTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import logoLarge from "static/assets/images/logo-large.svg";
import { Trans, useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import { actionRequestActivateAccount } from "redux/actions/register";
import { actionSwitchLoginModalOpenState } from "redux/actions/auth";

import useQuery from "helpers/hooks/useQuery";
import history from "helpers/history";

import MainButton from "modules/common/MainButton";

import successIcon from "static/assets/images/success-green.svg"
import warningIcon from "static/assets/images/warning-red.svg"


export const RegistrationCompleted = ({
	activateAccountRequestError,
	activateAccountRequestSuccess,
	isUserLoggedIn,
	actionRequestActivateAccount,
	actionSwitchLoginModalOpenState,
	emailVerified,
}) => {
	const { t } = useTranslation("registration_completed");
	const query = useQuery();
	const hash = query.get("hash");
	let displaySuccessIcon = true;
	let mainText = <Trans
                    i18nKey="email_verification_success"
                    ns="registration_completed"
                    components={
                        [<div key="0" className="blue"/>,
                        <div key="1" className="sub"/>]
                    }
                />


	useEffect(() => {
		hash && actionRequestActivateAccount(hash);
	}, []);

	const handleRegisterSuccessClick = () => {
		!isUserLoggedIn && actionSwitchLoginModalOpenState();
	};

	return (
		<Container>
			{activateAccountRequestSuccess || activateAccountRequestError.requestError
      ?
        <Row>
          {activateAccountRequestError.requestError &&
            activateAccountRequestError.parsedTextErrorsWithoutKeys.map(error => {
              if (error.startsWith("This email verification link has expired")) {
                mainText = <Trans
                                i18nKey="email_verification_link_expired"
                                ns="registration_completed"
                                components={
                                    [<div className="blue"/>,
                                    <div className="sub"/>]
                                }
                            />
                displaySuccessIcon = false;
              } else if (error.startsWith("Email has already been verified")) {
                mainText = <Trans
                                i18nKey="email_has_been_verified"
                                ns="registration_completed"
                                components={
                                    [<div className="blue"/>,
                                    <div className="sub"/>]
                                }
                            />
              } else {
                mainText = <Trans
                                i18nKey="email_verification_error"
                                ns="registration_completed"
                                components={
                                    [<div className="blue"/>,
                                    <div className="sub"/>]
                                }
                            />
                displaySuccessIcon = false;
              }
            })
          }

          <Col>
            <div className="email-verified-wrapper">
              <div className="blue-plank-top-service" />
              <div className="email-verified-txt">{mainText}</div>
              {displaySuccessIcon ?
                <Fragment>
                  <Link onClick={handleRegisterSuccessClick} key="completed" to={isUserLoggedIn ? "/main/" : "/"}>
                    <MainButton className='finish-register-btn single' size='medium' text={t("Start Fantasy")}/>
                  </Link>
                  <img className="email-verified-img" src={successIcon} alt="" />
                </Fragment>
              :
                <img className="email-verified-img" src={warningIcon} alt="" />
              }
            </div>
          </Col>
        </Row>
      :
        <div className="email-verified-wrapper">
            <Spinner/>
        </div>
			}
		</Container>
	);
};

const mapStateToProps = ({ registerReducer, authReducer }) => {
	const { emailVerified, activateAccountRequestError, activateAccountRequestSuccess } = registerReducer;
	const { isUserLoggedIn } = authReducer;
	return {
		activateAccountRequestError,
		activateAccountRequestSuccess,
		isUserLoggedIn,
		emailVerified,
	};
};

RegistrationCompleted.propTypes = {
	activateAccountRequestError: propTypes.object.isRequired,
	activateAccountRequestSuccess: propTypes.bool.isRequired,
	emailVerified: propTypes.bool.isRequired,
	isUserLoggedIn: propTypes.bool.isRequired,
	actionRequestActivateAccount: propTypes.func.isRequired,
	actionSwitchLoginModalOpenState: propTypes.func.isRequired,
};

export default connect(
	mapStateToProps,
	{ actionRequestActivateAccount, actionSwitchLoginModalOpenState },
)(RegistrationCompleted);

RegistrationCompleted.displayName = "rccon";