import i18instance from "i18instance";

/* errorsParser used for extract errors from BE response. You can be sure about opportunity to get error by key from
first error object field level or use Strings array with all collected information from BE
 */

/* incoming params:
/** @param {object} error - BE error response object

/* returned object {...}
/** @field {array of String} parsedTextErrors - array with Strings for cases when we can't attach error for specific
field on ui
/** @field {String} any else field - collected errors for specific field, if it returned from BE, for example
 {
 	name: "This field may not be blank"
 }
 */

export const errorsParser = (error) => {
	let resultError = {
		requestError: true,
		parsedTextErrors: [],
		parsedTextErrorsWithoutKeys: [],
	};

	if (error.response && error.response.status === 500) {
		resultError.parsedTextErrorsWithoutKeys.push(i18instance.t("network_errors:error_500"));
		return resultError;
	}

	const extractStringError = (errorObj) => {
		if (typeof errorObj === "string") {
			resultError.parsedTextErrors.push(errorObj);
			resultError.parsedTextErrorsWithoutKeys.push(errorObj);
		} else if (typeof errorObj === "object" && Object.keys(errorObj).length) {
			Object.keys(errorObj).forEach(key => {
				if (typeof errorObj[key] === "string") {
					resultError.parsedTextErrors.push(`${key}: ${errorObj[key]}`);
					resultError.parsedTextErrorsWithoutKeys.push(errorObj[key]);
					resultError[key] = errorObj[key];
				} else if (typeof errorObj[key] === "object" && errorObj[key].length){
					let newParsedTextBlock = `${key}: `;
					errorObj[key].forEach(message => {
						resultError.parsedTextErrorsWithoutKeys.push(message);
						newParsedTextBlock += `${message}`;
						resultError[key] = resultError[key] ? resultError[key] += message : message;
					});
					resultError.parsedTextErrors.push(newParsedTextBlock);
				} else {
					extractStringError(errorObj[key]);
				}
			});
		} else if (typeof errorObj === "object" && errorObj.length) {
			errorObj.forEach(value => {
				extractStringError(value);
			});
		}
	};
	
	if (error.response) {
		if (error.response.data) {
			extractStringError(error.response.data);
		} else if (error.response.status) {
			resultError.parsedTextErrors.push(i18instance.t("network_errors:status", { status:error.response.status }));
			if (error.response.statusCode) {
				resultError.parsedTextErrors.push(i18instance.t("network_errors:code", { status:error.response.statusCode }));
			}
		}
	} else {
		resultError.parsedTextErrors.push(i18instance.t("network_errors:server_error"));
	}

	if (error.response && error.response.status === 404 && !resultError.parsedTextErrorsWithoutKeys.length) {
		resultError.parsedTextErrorsWithoutKeys.push(i18instance.t("network_errors:wrong_request_url"));
		return resultError;
	}

	return resultError;
};