import propTypes from "prop-types";

export const countriesPropTypes = propTypes.arrayOf(propTypes.shape({
	code: propTypes.string,
	name: propTypes.string,
}));

export const errorsPropTypes = propTypes.shape({
	first_name: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	last_name: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	country: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	email: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	password: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	confirm_password: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
	privacy_policy: propTypes.bool,
});
