import * as types from "redux/constants/common";

import createReducer from "redux/createReducer";

export const commonInitialState = {
	lastGlobalMessageSuccess: "",
};

const commonReducer = createReducer(commonInitialState)({
	[types.ADD_GLOBAL_SUCCESS_MESSAGE]: (state, { payload }) => ({
		...state,
		lastGlobalMessageSuccess: payload,
	}),
});

export default commonReducer;
