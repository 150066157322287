import * as types from "redux/constants/leagues";

export const actionSetLeagueType = payload => ({
	type: types.SET_LEAGUE_TYPE,
	payload,
});

export const actionSetLeagueSubtype = payload => ({
	type: types.SET_LEAGUE_SUBTYPE,
	payload,
});
