import PropTypes from "prop-types";

const globalFormationPropType = {
	parsedGlobalFormation: PropTypes.shape({
		id: PropTypes.number,
		formation_type: PropTypes.string.isRequired,
		formationType: PropTypes.arrayOf(PropTypes.number),
	}).isRequired,
	globalFormation: PropTypes.shape({
		id: PropTypes.number,
		formation_type: PropTypes.string.isRequired,
		enabled: true,
	}).isRequired,
};

export default globalFormationPropType;
