import React from "react";
import PropTypes from "prop-types";
import {
	Container, Row, Col,
} from "reactstrap";

import { parsedDreamTeamType } from "utils/propTypes/footballTeam";

import Title from "modules/common/Title";
import FootballFieldSmall from "modules/footballField/components/FootballFieldSmall";

import Switch from "./Switch";
import Tabs from "./Tabs";

const DreamTeam = ({
	dreamTeam,
	switchValue,
	handlePrev,
	handleNext,
	setPeriodType,
	periodType,
	switchRange,
	roundIndex,
	isLoading,
}) => {
	return (
		<Container className="dream-team">
			<Row>
				<Col>
					<Title translation={{
						ns: "dream_team",
						key: "dream_team"
					}} />
					<Tabs setPeriodType={setPeriodType} value={periodType} />
					<Switch
						text={periodType}
						value={switchValue}
						handlePrev={handlePrev}
						handleNext={handleNext}
						switchRange={switchRange}
						index={roundIndex}
						isLoading={isLoading}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="d-flex justify-content-center p-0">
					<FootballFieldSmall
						isLoading={isLoading}
						fieldTeam={dreamTeam}
						isSelectTeamPage={false}
						isNextRoundPage={false}
						isLiveAndPreviousPage={false}
						isDreamTeam={true}
						formationType={[4, 4, 2, 1]}
					/>
				</Col>
			</Row>
		</Container>
	);
};

DreamTeam.propTypes = {
	handlePrev: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	setPeriodType: PropTypes.func.isRequired,
	dreamTeam: PropTypes.shape(parsedDreamTeamType).isRequired,
	switchValue: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
	periodType: PropTypes.string.isRequired,
	switchRange: PropTypes.number.isRequired,
	roundIndex: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default DreamTeam;


DreamTeam.displayName = "dt";