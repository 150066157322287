import axios from "axios";
import * as Sentry from "@sentry/react";
import i18instance from "i18instance";

import frontendUrls from "routes/frontendUrls";
import backendApiUrls from "routes/backendUrls";

import { actionUserLogout, actionSwitchLoginModalOpenState } from "redux/actions/auth";
import store from "redux/store";

import history from "helpers/history";

import tokenRefreshing from "./tokenRefreshing";

// This is upgraded axios function for handle TOKEN issues with following functionality blocks:
// 1) Get user request in object with fields {method, url, data(optional)}
// method:string ("GET", "POST", "PUT")
// url:string
// data: any type of data that allow XMLHttpRequest
// 2) Attach to the request JWT token from localStorage and make standart axios request
// 2.1) If response is OK/200: return response and exit this function flow
// 2.2) If response has 400 code: trying to take REFRESH token from localStorage,
// 		if it not exist, open login window (disscussional)
// 2.3) If REFRESH token exist: make POST request for update user ACCESS token
// 2.4) If server return ACCESS token: trying to make an initial request with a new token with standart flow
//      and record new token to localStorage
// 2.5) If server doesn't return token: reject initial request with error about this problem

const paramsBuilder = (url, params) => {
	if (!params || typeof params !== "object") {
		return url;
	}
	let newUrl = url;
	Object.keys(params).map((key,index) => {
		newUrl += `${index === 0 ? "?" : "&"}${key}=${params[key]}`;
	});
	return newUrl;
};

export default function axiosPlus(requestData) {
	const { method, url, data, params } = requestData;
	const accessToken = localStorage.getItem("accessToken");
	if (accessToken) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
	axios.defaults.headers.common["Accept-Language"] = i18instance.language;
	const newUrl = paramsBuilder(url, params);

	return new Promise((resolve, reject) => {
		axios({ method, url: newUrl, data }).then(response => {
			resolve(response);
		}).catch(error => {
			const { response: { request: { responseURL } = {}, data: { code, detail } = {}, status, config: { url } = {} } = {} } = error;
			if (error?.message == "Network Error") {
				console.log("Network error");
				console.warn("url: ", newUrl);
				console.warn("data: ", data);
				Sentry.captureMessage("Network error");
			}
			if (responseURL && responseURL.includes("maintenance")) {
				history.push(frontendUrls.urlMaintenance);
			}
			if (detail === "User didn't confirmed their email." && window.location.pathname !== frontendUrls.urlActivationSuccess) {
				history.push(frontendUrls.urlConfirmEmail);
			}
			//will check this while working on real protected endpoins
			const { login, sendVerificationEmail } = backendApiUrls;
			const { urlActivationSuccess, urlConfirmEmail } = frontendUrls;

			const exceptedEndpoints = [login, sendVerificationEmail];
			const accessToken = localStorage.getItem("accessToken");
			if (window.location.pathname !== urlConfirmEmail && window.location.pathname !== urlActivationSuccess && (code === "token_not_valid" || (status === 401 && !exceptedEndpoints.some((el) => el === url)))) {
				const refreshToken = localStorage.getItem("refreshToken");
				if (!refreshToken) {
					store.dispatch(actionUserLogout());
					history.push(frontendUrls.urlRoot);
					if (accessToken) {
						store.dispatch(actionSwitchLoginModalOpenState({ forceOpen: true }));
					}
				} else {
					tokenRefreshing().then(() => {
						axios({ method, url: newUrl, data })
							.then(response =>
								resolve(response))
							.catch(error => reject(error));
					}).catch(error => {
						history.push(frontendUrls.urlRoot);
						if (accessToken) {
							store.dispatch(actionSwitchLoginModalOpenState({ forceOpen: true }));
						}
						return reject(error);
					});
				}
			} else if (
				url === backendApiUrls.userTeamsCheckUserTeam 
				&& status === 404
				&& ![frontendUrls.urlConfirmEmail, frontendUrls.urlSelectTeam, frontendUrls.urlSetNewPassword, frontendUrls.urlNextSeasonTeam].includes(window.location.pathname)) {
				history.push(frontendUrls.urlNextSeasonTeam);
			} else {
				reject(error);
			}
		});
	});
}