import * as types from "redux/constants/auth";
import { POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS } from "redux/constants/register";

import createReducer from "redux/createReducer";

const authReducerInitialState = {
	loginModalOpenState: false,
	isUserLoggedIn: !!localStorage.getItem("accessToken"),
	userLoginRequestLoading: false,
	userLoginError: {},
	forgotPasswordModalOpenState: false,
	forgotPasswordSuccess: false,
	forgotPasswordRequestLoading: false,
	forgotPasswordError: {},
	userDetailsRequestLoading: false,
	userDetailsErrors: {},
	userDetailsLastUpdateTime: 0,
	setNewPasswordModalOpenState: false,
	setNewPasswordParamsFromMailUrl: {},
	setNewPasswordSuccess: false,
	setNewPasswordRequestLoading: false,
	setNewPasswordError: {},
	email: localStorage.getItem("userEmail") || "",
	fullName: localStorage.getItem("fullName"),
	userFirstName: localStorage.getItem("userFirstName"),
	userLastName: localStorage.getItem("userLastName"),
	userDetails: JSON.parse(localStorage.getItem("userDetails")) || {},
	deleteUserAccountLoading: false,
	deleteUserAccountError: {},
	isUserEmailConfirmed: JSON.parse(localStorage.getItem("isUserEmailConfirmed")) || false, 
};

const authReducer = createReducer(authReducerInitialState)({
	[types.SWITCH_LOGIN_MODAL_OPEN_STATE]: (state, action) => {
		const { payload: { forceOpen } = {} } = action;
		const { loginModalOpenState } = state;
		return {
			...state,
			userLoginRequestLoading: false,
			userLoginError: {},
			loginModalOpenState: !!forceOpen || !loginModalOpenState,
			setNewPasswordModalOpenState: false,
		};
	},
	[types.POST_REQUEST_LOGIN_START]: (state) => {
		return { ...state, userLoginRequestLoading: true, userLoginError: {} };
	},
	[types.POST_REQUEST_LOGIN_SUCCESS]: (state, action) => {
		const { payload } = action;
		const { access, refresh, email, full_name } = payload;
		localStorage.setItem("accessToken", access);
		localStorage.setItem("refreshToken", refresh);
		localStorage.setItem("userEmail", email.toLowerCase());
		localStorage.setItem("fullName", full_name);
		localStorage.setItem("userFirstName", full_name.slice(" ")[0]);
		localStorage.setItem("userLastName", full_name.split(" ")[1]);
		return {
			...state,
			userLoginRequestLoading: false,
			isUserLoggedIn: true,
			loginModalOpenState: false,
			fullName: full_name,
			userFirstName:  full_name.split(" ")[0],
			userLastName:  full_name.split(" ")[1],
			email: email.toLowerCase(),
		};
	},
	[types.GET_REQUEST_USER_DETAILS_START]: (state) => {
		return {
			...state,
			userDetailsRequestLoading: true,
		};
	},
	[types.GET_REQUEST_USER_DETAILS_ERROR]: (state, action) => {
		const { payload } = action;
		return {
			...state,
			userDetailsRequestLoading: false,
			userDetailsErrors: payload,
			isUserEmailConfirmed: false,
		};
	},
	[POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS]: state => {
		const { userDetails } = state;
		const newUserDetails =  { ...userDetails, is_email_verified: true };
		localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
		return {
			...state,
			isUserEmailConfirmed: true,
			userDetails: newUserDetails
		};
	},
	[types.GET_REQUEST_USER_DETAILS_SUCCESS]: (state, action) => {
		const { payload } = action;
		const { email, first_name, last_name, is_email_verified } = payload;
		const fullName = first_name + " " + last_name;
		localStorage.setItem("fullName", fullName);
		localStorage.setItem("userDetails", JSON.stringify(payload));
		localStorage.setItem("isUserEmailConfirmed", is_email_verified);
		return {
			...state,
			userDetails: payload,
			userDetailsLastUpdateTime: new Date().getTime(),
			userDetailsRequestLoading: false,
			fullName,
			email,
			isUserEmailConfirmed: is_email_verified,
		};
	},
	[types.PATCH_REQUEST_USER_DETAILS_START]: (state) => {
		return {
			...state,
			userDetailsRequestLoading: true,
			userDetailsErrors: {},
		};
	},
	[types.PATCH_REQUEST_USER_DETAILS_ERROR]: (state, action) => {
		const { payload } = action;
		return {
			...state,
			userDetailsRequestLoading: false,
			userDetailsErrors: payload,
		};
	},
	[types.POST_REQUEST_LOGIN_ERROR]: (state, { payload }) => {
		return { ...state, userLoginRequestLoading: false, isUserLoggedIn: false, userLoginError: payload };
	},
	[types.CLEAR_USER_LOGIN_ERROR]: (state) => {
		return { ...state, userLoginError: {} };
	},
	[types.SWITCH_FORGOT_PASSWORD_MODAL_OPEN_STATE]: (state) => {
		const { forgotPasswordModalOpenState } = state;
		return {
			...state,
			forgotPasswordSuccess: false,
			forgotPasswordRequestLoading: false,
			forgotPasswordError: {},
			forgotPasswordModalOpenState: !forgotPasswordModalOpenState,
			loginModalOpenState: false,
		};
	},
	[types.POST_REQUEST_FORGOT_PASSWORD_START]: (state) => {
		return { ...state, forgotPasswordRequestLoading: true, forgotPasswordError: {} };
	},
	[types.POST_REQUEST_FORGOT_PASSWORD_SUCCESS]: (state) => {
		return { ...state, forgotPasswordRequestLoading: false, forgotPasswordSuccess: true };
	},
	[types.POST_REQUEST_FORGOT_PASSWORD_ERROR]: (state, { payload }) => {
		return { ...state, forgotPasswordRequestLoading: false, forgotPasswordError: payload };
	},
	[types.SWITCH_SET_NEW_PASSWORD_MODAL_OPEN_STATE]: (state, { payload }) => {
		const { setNewPasswordModalOpenState } = state;
		return {
			...state,
			setNewPasswordParamsFromMailUrl: payload,
			setNewPasswordRequestLoading: false,
			setNewPasswordError: {},
			setNewPasswordModalOpenState: !setNewPasswordModalOpenState
		};
	},
	[types.POST_REQUEST_SET_NEW_PASSWORD_START]: (state) => {
		return { ...state, setNewPasswordRequestLoading: true, setNewPasswordError: {}, setNewPasswordSuccess: false };
	},
	[types.POST_REQUEST_SET_NEW_PASSWORD_SUCCESS]: (state) => {
		return { ...state, setNewPasswordRequestLoading: false,  setNewPasswordSuccess: true };
	},
	[types.POST_REQUEST_SET_NEW_PASSWORD_ERROR]: (state, { payload }) => {
		return {
			...state,
			setNewPasswordRequestLoading:false,
			setNewPasswordSuccess: false,
			setNewPasswordError: payload
		};
	},
	[types.USER_LOGOUT]: (state) => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("userEmail");
		localStorage.removeItem("fullName");
		localStorage.removeItem("userDetails");
		localStorage.removeItem("userFirstName");
		localStorage.removeItem("userLastName");
		localStorage.removeItem("isUserEmailConfirmed");
		return { ...state, isUserLoggedIn: false, email: "", fullName: "", userFirstName: "", userLastName: "", isUserEmailConfirmed: false, userDetails: {} };
	},
	[types.DELETE_REQUEST_USER_ACCOUNT_START]: (state) => {
		return { ...state, deleteUserAccountLoading: true, setNewPasswordError: {} };
	},
	[types.DELETE_REQUEST_USER_ACCOUNT_SUCCESS]: (state) => {
		return { ...state, deleteUserAccountLoading: false, setNewPasswordError: {} };
	},
	[types.DELETE_REQUEST_USER_ACCOUNT_ERROR]: (state, { payload }) => {
		return { ...state, deleteUserAccountLoading: false, setNewPasswordError: payload };
	},
});

export default authReducer;