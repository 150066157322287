import * as types from "redux/constants/userRoundTeams";
import * as footballFieldTypes from "redux/constants/footballField";

import createReducer from "redux/createReducer";

const userRoundTeamsInitialState = {
	usersRoundsTeamsHashList: {},
	usersRoundsTeamsListDataUpdateTime: 0,
	usersRoundsTeamsRequestLoading: false,
	usersRoundsTeamsRequestError: {},
	userRoundsResultsHashList: {},
	userRoundsResultsRequestLoading: false,
	userRoundsResultsRequestError: {},
};

const userRoundTeamsReducer = createReducer(userRoundTeamsInitialState)({
	[types.GET_REQUEST_USER_ROUND_TEAMS_START]: state => ({
		...state,
		usersRoundsTeamsRequestLoading: true,
		usersRoundsTeamsInited: false,
	}),
	[types.GET_REQUEST_USER_ROUND_TEAMS_SUCCESS]: (state, { payload }) => {
		const { user, football_round, data } = payload;
		const { usersRoundsTeamsHashList } = state;
		const currentDataForThisUser = usersRoundsTeamsHashList[user];
		const newUsersRoundsTeamsHashList = { ...usersRoundsTeamsHashList, [user]: { ...currentDataForThisUser, [football_round]: data } };
		return {
			...state,
			usersRoundsTeamsHashList: newUsersRoundsTeamsHashList,
			usersRoundsTeamsRequestLoading: false,
			usersRoundsTeamsListDataUpdateTime: new Date().getTime(),
		};
	},
	[footballFieldTypes.INIT_TEAM]: (state) => {
		return {
			...state,
			usersRoundsTeamsInited: true,
		};
	},
	[types.GET_REQUEST_USER_ROUND_TEAMS_ERROR]: (state, { payload }) => ({
		...state,
		usersRoundsTeamsRequestError: payload,
		usersRoundsTeamsRequestLoading: false,
		usersRoundsTeamsListDataUpdateTime: new Date().getTime(),
	}),
	[types.GET_REQUEST_USER_ROUND_RESULTS_START]: state => ({
		...state,
		userRoundsResultsRequestLoading: true,
	}),
	[types.GET_REQUEST_USER_ROUND_RESULTS_SUCCESS]: (state, { payload }) => {
		const { data, meta: { round_league_id, userId } = {} } = payload;
		const { userRoundsResultsHashList } = state;
		const currentDataForThisUser = userRoundsResultsHashList[userId] || {};
		const newUsersRoundsTeamsHashList = { ...userRoundsResultsHashList, [userId]: { ...currentDataForThisUser, [round_league_id]: data } };
		return {
			...state,
			userRoundsResultsHashList: newUsersRoundsTeamsHashList,
			userRoundsResultsRequestLoading: false,
		};
	},
	[types.GET_REQUEST_USER_ROUND_RESULTS_ERROR]: (state, { payload }) => ({
		...state,
		userRoundsResultsRequestError: payload,
		userRoundsResultsRequestLoading: false,
	}),
});

export default userRoundTeamsReducer;