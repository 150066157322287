import i18instance from "i18instance";

export const FIELD_POSITIONS_MAP = {
	"GKP": "goalkeeper",
	"DEF": "defender",
	"MID": "midfielder",
	"FWD": "forward",
};

export const FIELD_POSITIONS_MAP_REVERSE = {
	"goalkeeper": "GKP",
	"defender": "DEF",
	"midfielder": "MID",
	"forward": "FWD"
};

export const PLAYER_POSITIONS = ["midfielder", "forward", "defender", "goalkeeper"];

export const FROM_CHANGE_TO_REQUEST_DELAY_MS = process.env.REACT_APP_FROM_CHANGE_TO_REQUEST_DELAY_MS || 500;

export const FOR_REPLACEMENT = {
	ID: "ID_FOR_REPLACEMENT",
	LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE: "PAGE_SIZE_FOR_REPLACEMENT",
	COUNTRY_CODE: "COUNTRY_CODE_FOR_REPLACEMENT",
	PRIVATE_LEAGUE_ID: "PRIVATE_LEAGUE_ID_FOR_REPLACEMENT",
};

export const LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE = process.env.REACT_APP_LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE || 20;

export const ACTION_TYPES_ORDERED_GAME_INFO = {
	GS_OP: i18instance.t("action_types:GS_OP"),
	GS_PK: i18instance.t("action_types:GS_PK"),
	AS: i18instance.t("action_types:AS"),
	PE: i18instance.t("action_types:PE"),
	SoT: i18instance.t("action_types:SoT"),
	CS_HT: i18instance.t("action_types:CS_HT"),
	TBI: i18instance.t("action_types:TBI"),
	SA_OP: i18instance.t("action_types:SA_OP"),
	SA_PK: i18instance.t("action_types:SA_PK"),
	GC: i18instance.t("action_types:GC"),
	OG: i18instance.t("action_types:OG"),
	PM: i18instance.t("action_types:PM"),
	YC: i18instance.t("action_types:YC"),
	RC: i18instance.t("action_types:RC"),
};

export const ACTION_TYPES_ORDERED_SHORT = {
	roundNumber: i18instance.t("action_types_short:Round"),
	opponentTeam: i18instance.t("action_types_short:Contro"),
	minutes_played:  i18instance.t("action_types_short:MP"),
	GS_OP:  i18instance.t("action_types_short:GS_OP"),
	GS_PK: i18instance.t("action_types_short:GS_PK"),
	AS: i18instance.t("action_types_short:AS"),
	PE: i18instance.t("action_types_short:PE"),
	SoT: i18instance.t("action_types_short:SoT"),
	CS_HT: i18instance.t("action_types_short:CS_HT"),
	TBI: i18instance.t("action_types_short:TBI"),
	SA_OP: i18instance.t("action_types_short:SA_OP"),
	SA_PK: i18instance.t("action_types_short:SA_PK"),
	GC: i18instance.t("action_types_short:GC"),
	OG: i18instance.t("action_types_short:OG"),
	PM: i18instance.t("action_types_short:PM"),
	YC: i18instance.t("action_types_short:YC"),
	RC: i18instance.t("action_types_short:RC"),
	matchTotalPoints: i18instance.t("action_types_short:Points")
};

export const PAST_SEASON_ACTION_TYPES_ORDERED_SHORT = {
	season_name: i18instance.t("action_types_short:Season"),
	minutes_played:  i18instance.t("action_types_short:MP"),
	GS_OP:  i18instance.t("action_types_short:GS_OP"),
	GS_PK: i18instance.t("action_types_short:GS_PK"),
	AS: i18instance.t("action_types_short:AS"),
	PE: i18instance.t("action_types_short:PE"),
	SoT: i18instance.t("action_types_short:SoT"),
	CS_HT: i18instance.t("action_types_short:CS_HT"),
	TBI: i18instance.t("action_types_short:TBI"),
	SA_OP: i18instance.t("action_types_short:SA_OP"),
	SA_PK: i18instance.t("action_types_short:SA_PK"),
	GC: i18instance.t("action_types_short:GC"),
	OG: i18instance.t("action_types_short:OG"),
	PM: i18instance.t("action_types_short:PM"),
	YC: i18instance.t("action_types_short:YC"),
	RC: i18instance.t("action_types_short:RC"),
	total_points: i18instance.t("action_types_short:Points")
};

export const ACTION_TYPES_ORDERED_LONG = {
	minutes_played: i18instance.t("action_types_long:minutes_played"),
	GS_OP: i18instance.t("action_types_long:GS_OP"),
	GS_PK: i18instance.t("action_types_long:GS_PK"),
	AS: i18instance.t("action_types_long:AS"),
	PE: i18instance.t("action_types_long:PE"),
	SoT: i18instance.t("action_types_long:SoT"),
	CS_HT: i18instance.t("action_types_long:CS_HT"),
	TBI: i18instance.t("action_types_long:TBI"),
	SA_OP: i18instance.t("action_types_long:SA_OP"),
	SA_PK: i18instance.t("action_types_long:SA_PK"),
	GC: i18instance.t("action_types_long:GC"),
	OG: i18instance.t("action_types_long:OG"),
	PM: i18instance.t("action_types_long:PM"),
	YC: i18instance.t("action_types_long:YC"),
	RC: i18instance.t("action_types_long:RC"),
};

export const ACTION_TYPES_BLOCKS = {
	forward: {
		CS_HT: true,
		TBI: true,
		SA_OP: true,
		SA_PK: true,
		GC: true,
	},
	midfielder: {
		SA_OP: true,
		SA_PK: true,
		GC: true,
	},
	defender: {
		SA_OP: true,
		SA_PK: true,
	},
	goalkeeper: {}
};

export const FORMATIONS_MAP = {
	goalkeeper: 3,
	defender: 0,
	midfielder: 1,
	forward: 2,
};