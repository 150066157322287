import React from "react";
import propTypes from "prop-types";
import { Container } from "reactstrap";

const BlueSubheader = ({ whiteTextPart, blueTextPart }) => (
	<div className="welcome">
		<Container className="welcome__container">
			<h1 className="welcome__text">
				<span className="welcome__text_transparent">{blueTextPart}</span>{whiteTextPart}
			</h1>
		</Container>
	</div>
);

BlueSubheader.propTypes = {
	whiteTextPart: propTypes.string,
	blueTextPart: propTypes.string,
};

BlueSubheader.defaultProps = {
	whiteTextPart: "",
	blueTextPart: "",
};

export default BlueSubheader;

BlueSubheader.displayName = "bsh";