import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import dropdownIcon from "static/assets/images/dropdown-arrow-black.svg";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import { actionUserLogout } from "redux/actions/auth";
import { getUsernameAbbreviation } from "redux/selectors";

import useOnClickOutside from "helpers/hooks/useOnClickOutside";


function ProfileIcon ({ usernameAbbreviation, actionUserLogout, handleCloseMenu, isUserEmailConfirmed }) {
	const { t } = useTranslation("profile_icon");
	const mainRef = useRef();

	const [ isOpenDropdown, setIsOpenDropdown ] = useState(false);
	const toggleDropdown = () => setIsOpenDropdown(!isOpenDropdown);

	useOnClickOutside(mainRef, () => setIsOpenDropdown(false));

	const buttonClass = classNames(
		"dropdown__button", "mr-0", "mr-md-2", "mt-0"
	);
	const iconClass = classNames({
		"dropdown__icon_opened-single": isOpenDropdown,
		"dropdown__icon": !isOpenDropdown,
	}, "d-none d-md-inline");
	const ulClass = classNames(
		"profile-dropdown__list",
		"nav-item-dropdown__list",
		{ "nav-item-dropdown__list_opened": isOpenDropdown }
	);
	return (
		<div ref={mainRef} className="profile-dropdown">
			<button className="profile-dropdown__button dropdown " onClick={toggleDropdown}>
				<span className={buttonClass}>
					{usernameAbbreviation}
				</span>
				<span className={iconClass}>
					<img src={ dropdownIcon } alt="" />
				</span>
			</button>
			<div className={ulClass}>
				{isUserEmailConfirmed && window.location.pathname !== frontendUrls.urlSelectTeam && window.location.pathname !== frontendUrls.urlNextSeasonTeam &&
					<NavLink 
						to={frontendUrls.urlPersonalDetailsChange} 
						className="nav-item-dropdown__item"
						activeClassName="selected"
						onClick={(e) => {
							handleCloseMenu(e);
							setIsOpenDropdown(false);
						}}
					>
						{t("Personal Details")}
					</NavLink>
				}
				<a
					tabIndex="0"
					onClick={(e) => {actionUserLogout(); handleCloseMenu(e);}}
					onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && actionUserLogout()}
					className="nav-item-dropdown__item"
				>
					{t("Sign Out")}
				</a>
			</div>
		</div>
	);
}

ProfileIcon.propTypes = {
	usernameAbbreviation: PropTypes.string.isRequired,
	actionUserLogout: PropTypes.func.isRequired,
	handleCloseMenu: PropTypes.func.isRequired,
	isUserEmailConfirmed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({ 
	usernameAbbreviation: getUsernameAbbreviation(state),
	isUserEmailConfirmed: state.authReducer.isUserEmailConfirmed
});

export default connect(
	mapStateToProps,
	{ actionUserLogout }
)(ProfileIcon);

ProfileIcon.displayName = "pi";