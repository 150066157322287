import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import info from "static/assets/images/info-blue.svg";
import { connect } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import priceIncreased from "static/assets/images/position-up.svg";
import priceDecreased from "static/assets/images/position-down.svg";
import priceNotChanged from "static/assets/images/position-unchanged.svg";

import { basicFootballPlayerType } from "utils/propTypes/footballPlayer";

import { actionSetPlayerInfoModalID } from "redux/actions/playersList";
import { actionSelectPlayerFromList } from "redux/actions/footballField";

import priceToMillion from "helpers/priceToMillion";

const PlayerListItem = ({ 
	data, isPositionFull, isSelected, actionSelectPlayerFromList, actionSetPlayerInfoModalID, sortValue, isNonApplicableStat,
}) => {
	const { t } = useTranslation("players_list_item");
	const {
		football_club : { abbreviation, jersey_goalkeeper, jersey_outfield } = {},
		id, full_name, current_price: currentPrice, initial_price: initialPrice, status, position
	} = data;

	const isGoalkeeper = position === "goalkeeper";
	const jersey = isGoalkeeper ? jersey_goalkeeper : jersey_outfield;

	const [isUserJustSelected, setIsUserJustSelected] = useState(false);

	const getStatusColor = () => {
		switch (status) {
		case "fit":
			return "_gray";
		case "doubtful":
        case "minor-injury":
        case "unhealthy":
			return "_yellow";
		default: 
			return "_red";
		}
	};
	const color = getStatusColor();
	const handleClick = () => {
		if (!disabled) {
			setIsUserJustSelected(true);
			setTimeout(() => {
				setIsUserJustSelected(false);
			}, 1000);

			actionSelectPlayerFromList(data);
		}
	};

	const handleInfoClick = e => {
		e.stopPropagation();
		actionSetPlayerInfoModalID(id);
	};
	const value = sortValue.code === "popularity_pct" ? data[sortValue.code] ? data[sortValue.code] : "0.0" : data[sortValue.code];
	const disabled = isPositionFull || isSelected;

	const priceChange = currentPrice - initialPrice;
	const imageSrc = priceChange ? 
		priceChange < 0 ? priceDecreased : priceIncreased : 
		priceNotChanged;
		
	const sortedByPrice = sortValue.code === "current_price";

	return (
		<div onClick={handleClick} className={classNames("players-table-item", {
			"selected-player": disabled,
		})}>
			{isUserJustSelected ? 
				<div className="select-text-wrapper d-flex justify-content-center align-items-center w-100 h-100">
					<div className="font-h3 color-secondary-text text-uppercase">{t("Added to the pitch")}</div>
				</div> :
				<Fragment>
					<div className='players-info-section'>
						<img onClick={handleInfoClick} className='cursor-pointer' src={info} alt=""/>
						<span className={`gray-middle player-status-color${color}`}/>
					</div>
					<div className='players-data-section'>
						<div className='d-flex align-items-center justify-content-start'>
							<div className='football-wear'>
								<img className='w-100' src={jersey} alt=""/>
							</div>
							<div className='d-flex flex-column'>
								<span className='player-item-name source-sans-pro fw-600 font-size-15'>
									{full_name}
								</span>
								<span className='source-sans-pro fw-600 font-size-15 color-secondary-text'>
									{abbreviation}
								</span>
							</div>
						</div>
						<div className='d-flex player-list-item-points'>
							<div className='d-flex flex-column'>
								<span className='d-flex justify-content-end source-sans-pro fw-600 font-size-15 text-nowrap'>
									{sortValue.prefix || ""}{`${isNonApplicableStat ? "-" : value || 0}`}{" "}{sortValue.suffix || ""}
									{sortedByPrice && <img className="price-change mx-1" src={imageSrc} alt="" />}
								</span>
								<span className='d-flex justify-content-end fw-600 font-size-15 color-secondary-text'>
									{priceToMillion(currentPrice)}
									<img className="price-change mx-1" src={imageSrc} alt="" />
								</span>
							</div>
						</div>
					</div>
				</Fragment>
			}
		</div>
	);
};

PlayerListItem.propTypes = {
	toggleModal: PropTypes.func,
	data: basicFootballPlayerType.isRequired,
	isPositionFull: PropTypes.bool.isRequired,
	isNonApplicableStat: PropTypes.bool,
	isSelected: PropTypes.bool.isRequired,
	actionSelectPlayerFromList: PropTypes.func.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
	sortValue: PropTypes.shape({
		name: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		suffix: PropTypes.string,
		prefix: PropTypes.string
	})
};

PlayerListItem.defaultProps = {
	toggleModal: () => {},
};

export default connect(
	null,
	{
		actionSetPlayerInfoModalID,
		actionSelectPlayerFromList,
	}
)(PlayerListItem);
export { PlayerListItem as PlayerListItemForTest };

PlayerListItem.displayName = "pli";