import React from "react";

import Title from "modules/common/Title";
import Tabs from "./Tabs";

const RulesAndFaq = () => {
	return (
		<div className="rules container global-container">
			<Title translation={{
				ns: "rules_and_faq",
				key: "rules_and_faq"
			}}  />
			<Tabs />
		</div>
	);
};

export default RulesAndFaq;

RulesAndFaq.displayName = "faqraf";