import React from "react";
import { Container } from "reactstrap";
import logoLarge from "static/assets/images/logo-large.svg";
import { useTranslation } from "react-i18next";

import BlueSubheader from "modules/common/BlueSubheader";

const Maintenance = () => {
	const { t } = useTranslation("maintenance");
	return (
		<div className="main-page">
			<BlueSubheader whiteTextPart="Working..." />
			<Container className="d-flex align-items-center flex-column">
				<div className="blue-plank-top-service" />
				<div className="service-message">
					{t("Down for maintenance. Stay with us")}
					{"..."}
					<img src={logoLarge} alt="" />
				</div>
			</Container>
		</div>
	);
};

export default Maintenance;

Maintenance.displayName = "m";