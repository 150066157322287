import React from "react";
import { useDragLayer } from "react-dnd";

import { PlayerPreview } from "./PlayerPreview";

const layerStyles = {
	position: "fixed",
	pointerEvents: "none",
	zIndex: 100,
	left: -40,
	top: -25,
	width: "100%",
	height: "100%",
};
function getItemStyles(initialOffset, currentOffset, clientOffset = false) {
	if (!initialOffset || !currentOffset) {
		return {
			display: "none",
		};
	}
	let { x, y } = clientOffset;

	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
}
export const CustomDragLayer = () => {
	const {
		isDragging,
		item,
		initialOffset,
		currentOffset,
		clientOffset,
	} = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		clientOffset:  monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) {
		return null;
	}

	const { data } = item;

	return (
		<div style={layerStyles}>
			<div
				style={getItemStyles(initialOffset, currentOffset, clientOffset)}
			>
				{data && <PlayerPreview data={data}/>}
			</div>
		</div>
	);
};

CustomDragLayer.displayName = "cdl";