import backendApiUrls from "routes/backendUrls";
import frontendUrls from "routes/frontendUrls";

import * as types from "redux/constants/userRoundTeams";

import axiosPlus from "helpers/axiosPlus";
import { FOR_REPLACEMENT } from "helpers/constants/common";

export const actionGetUserRoundTeamsRequest = payload => ({
	type: types.GET_REQUEST_USER_ROUND_TEAMS_START,
	payload,
});

export const getUserRoundTeamsRequestSuccessAction = payload => ({
	type: types.GET_REQUEST_USER_ROUND_TEAMS_SUCCESS,
	payload,
});

export const getUserRoundTeamsRequestErrorAction = payload => ({
	type: types.GET_REQUEST_USER_ROUND_TEAMS_ERROR,
	payload,
});

const getUserRoundResultsRequestStart = payload => ({
	type: types.GET_REQUEST_USER_ROUND_RESULTS_START,
	payload,
});

export const getUserRoundResultsRequestSuccessAction = payload => ({
	type: types.GET_REQUEST_USER_ROUND_RESULTS_SUCCESS,
	payload,
});

export const getUserRoundResultsRequestErrorAction = payload => ({
	type: types.GET_REQUEST_USER_ROUND_RESULTS_ERROR,
	payload,
});

export const actionGetUserRoundResultsRequest = ({ round_league_id, userId }) => dispatch => {
	dispatch(getUserRoundResultsRequestStart({ round_league_id }));

	const isOtherUserPage = window.location.pathname.includes(frontendUrls.urlOtherUserTeam);

	const config = {
		method: "GET",
		url: `${backendApiUrls.roundLeagueStatistic.replace(
			FOR_REPLACEMENT.ID,
			round_league_id
		)}${userId && isOtherUserPage ? `?user_id=${userId}` : ""}`,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getUserRoundResultsRequestSuccessAction({ meta: { round_league_id, userId }, data }));
		})

		.catch(() => {
			dispatch(getUserRoundResultsRequestSuccessAction({ meta: { round_league_id, userId }, data: [] }));
		});
};
