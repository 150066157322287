import React, { Fragment, useEffect } from "react";
import {
	Row,
	Col,
	Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uuid4 } from "@sentry/utils";
import { Trans, useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import { actionGetUserRoundResultsRequest, actionGetUserRoundTeamsRequest } from "redux/actions/userRoundTeams";
import { getFinishedAndLiveRounds, } from "redux/selectors";
import { actionGetRequestRoundLeagueInfo } from "redux/actions/leaguesRound";

import useDebounce from "helpers/hooks/useDebounce";

const RoundStatistic = ({
	isLoading,
	usersRoundsTeamsHashList = [],
	userRoundsResultsHashList,
	roundID,
	roundNumber,
	actionGetUserRoundResultsRequest,
	currentUserId,
	lastFinishedOrLiveRoundId,
	actionGetRequestRoundLeagueInfo,
	roundLeagueId,
	actionGetUserRoundTeamsRequest
}) => {
	const { t } = useTranslation("round_statistic");
	const urlParams = new URLSearchParams(window.location.search);
	const otherUserId = urlParams.get("user_id");
	const isOtherUserPage = window.location.pathname.includes(frontendUrls.urlOtherUserTeam);
	const isMainPage = window.location.pathname.includes(frontendUrls.urlMain);
	const userId = isOtherUserPage ? otherUserId : currentUserId;
	const { avg_points, max_points, points, max_points_user_id } = userRoundsResultsHashList[userId] && userRoundsResultsHashList[userId][roundLeagueId] || {};
	const roundTeamForThisUser = usersRoundsTeamsHashList[userId] && usersRoundsTeamsHashList[userId][roundID];
	const { transfers_used, wildcard_used, penalty_points } = roundTeamForThisUser || {};
	const isExistTeamForThisRound = roundTeamForThisUser && !roundTeamForThisUser.isEmpty;
	const currentPointsValue = isExistTeamForThisRound ? points || 0 : "-";
	const currentTransfersValue = isExistTeamForThisRound ? transfers_used || 0 : "-";
	const currentPenaltyValue = isExistTeamForThisRound ? penalty_points || 0 : "-";

	useEffect(() => {
		if (window.location.pathname.includes(frontendUrls.urlMain)) {
			if (roundID && currentUserId && !usersRoundsTeamsHashList?.[currentUserId]?.[roundID]) {
				actionGetUserRoundTeamsRequest({ // "/api/v0/userteams/user-team-for-round/
					user: currentUserId,
					football_round: roundID,
				});
			}
		}

		if (lastFinishedOrLiveRoundId !== roundLeagueId
			&& roundID
			&& (userId || !isOtherUserPage)
		) {
			actionGetRequestRoundLeagueInfo(roundID, isOtherUserPage ? otherUserId : null);
		}
	}, [roundID, userId]);

	useEffect(() => {
		if (
			roundLeagueId
			&& userId
			&& userRoundsResultsHashList
			&& (!userRoundsResultsHashList[userId] || !userRoundsResultsHashList[userId][roundLeagueId])
		) {
			actionGetUserRoundResultsRequest(
				{ round_league_id: roundLeagueId, userId }
			);
		}
	}, [roundLeagueId, userId]);

	const debouncedIsLoading = useDebounce(isLoading, 50);

	return (
		<div className={`d-flex leagues-board round round-stat ${isMainPage && "is-main-page"}`}>
			<h3 className="leagues-board__header">
				<Trans
					i18nKey="round results"
					ns="round_statistic"
					values={{
						roundNumber,
					}}
					components={[
						<span key="0" className='color-accent1' />
					]}
				/>
			</h3>
			<div>
				{debouncedIsLoading ?
					<li className="leagues-board__league-item spinner d-flex align-items-center justify-content-center">
						<Spinner />
					</li>
					:
					<Fragment>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><p>{t("Points")}</p></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points">
										{roundLeagueId ? <span>{currentPointsValue || 0}</span> : <span>0</span>}
									</div>
								</Col>
							</Row>
						</li>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><p>
										<Trans
											i18nKey="transfers/deduction"
											ns="round_statistic"
											components={[
												<span key="0" className='color-accent1' />
											]}
										/>
									</p></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points">
										{!!roundID && [<span key={uuid4()}>{wildcard_used ? "∞" : (currentTransfersValue || 0)}</span>,
											<span key={uuid4()}>
												{isExistTeamForThisRound && <span className="color-accent1">&nbsp;({wildcard_used ? t("WC") : (currentPenaltyValue || 0)})</span>}
											</span>]
										}
										{!roundID && [<span key={uuid4()}>0</span>, <span key={uuid4()} className="color-accent1">&nbsp;(0)</span>]}
									</div>
								</Col>
							</Row>
						</li>
						<li className="leagues-board__league-item">
							<Row className='round-stat-line'>
								<Col xs={8}>
									<div className="leagues-board__name"><p>{t("Round Average")}</p></div>
								</Col>
								<Col xs={4} className="d-flex align-items-center justify-content-end">
									<div className="leagues-board__points"><span>{avg_points || 0}</span></div>
								</Col>
							</Row>
						</li>
						<Link className="leagues-board__league-item" to={currentUserId === max_points_user_id || !max_points_user_id ? frontendUrls.urlLivePreviousRoundTeams : `${frontendUrls.urlOtherUserTeam}?user_id=${max_points_user_id}`}>
							<li className="leagues-board__league-item  w-100 border-none">
								<Row className='round-stat-line'>
									<Col xs={8}>
										<div className="leagues-board__name"><p>{t("Round Highest")}</p></div>
									</Col>
									<Col xs={4} className="d-flex align-items-center justify-content-end">
										<div className="leagues-board__points"><span>{max_points || 0}</span></div>
									</Col>
								</Row>
							</li>
						</Link>
					</Fragment>
				}
			</div>
		</div>
	);
};

const mapStateToProps = (state, { selectedRound = {}, lastRoundOnly }) => {
	const { userRoundTeamsReducer: {
		userRoundsResultsHashList,
		usersRoundsTeamsHashList,
		userRoundsResultsRequestLoading,
		usersRoundsTeamsRequestLoading,
	}, seasonRoundsReducer: {
		seasonRoundsRequestLoading,
	},
	leaguesReducer: {
		roundLeaguesRequestLoading, roundLeaguesData
	}
	} = state;
	const selectedRoundID = !!selectedRound && selectedRound.id;

	const roundLeagueId = roundLeaguesData?.id;

	const currentRoundResults = roundLeagueId && userRoundsResultsHashList[roundLeagueId]
		? userRoundsResultsHashList[roundLeagueId]
		: {};
	const { userDetails: { id: userID } = {} } = state.authReducer;

	const liveAndFinishedRounds = getFinishedAndLiveRounds(state);
	const lastFinishedOrLiveRound = liveAndFinishedRounds.length && liveAndFinishedRounds[0] && liveAndFinishedRounds[liveAndFinishedRounds.length - 1] || {};
	const roundID = lastRoundOnly ? lastFinishedOrLiveRound.id : selectedRoundID;

	const roundNumber = lastRoundOnly ? lastFinishedOrLiveRound.number : selectedRound.number;
	const lastFinishedOrLiveRoundId = lastFinishedOrLiveRound.id;
	return {
		lastFinishedOrLiveRoundId,
		roundLeagueId,
		currentUserId: userID,
		userRoundsResultsHashList,
		roundID,
		roundNumber,
		usersRoundsTeamsHashList,
		isLoading: roundLeaguesRequestLoading
			|| userRoundsResultsRequestLoading
			|| usersRoundsTeamsRequestLoading
			|| seasonRoundsRequestLoading
			|| !roundNumber,
		roundLeaguesRequestLoading,
		...currentRoundResults
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetUserRoundResultsRequest,
		actionGetUserRoundTeamsRequest,
		actionGetRequestRoundLeagueInfo,
	}
)(RoundStatistic);

RoundStatistic.propTypes = {
	roundID: PropTypes.number,
	lastFinishedOrLiveRoundId: PropTypes.number,
	roundLeagueId: PropTypes.number,
	roundNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	usersRoundsTeamsHashList: PropTypes.object,
	actionGetUserRoundResultsRequest: PropTypes.func.isRequired,
	actionGetUserRoundTeamsRequest: PropTypes.func.isRequired,
	actionGetRequestRoundLeagueInfo: PropTypes.func.isRequired,
	userRoundsResultsHashList: PropTypes.object.isRequired,
	currentUserId: PropTypes.string.isRequired,
	lastRoundOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
};

RoundStatistic.defaultProps = {
	roundID: 0,
	lastFinishedOrLiveRoundId: 0,
	roundLeagueId: 0,
	roundNumber: "",
	lastRoundOnly: false,
};

RoundStatistic.displayName = "rs";