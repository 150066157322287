import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/settings";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getSettingsStart = payload => ({
	type: types.GET_REQUEST_SETTINGS_START,
	payload,
});

const getSettingsSuccess = payload => ({
	type: types.GET_REQUEST_SETTINGS_SUCCESS,
	payload,
});

const getSettingsError = payload => ({
	type: types.GET_REQUEST_SETTINGS_ERROR,
	payload,
});

export const actionGetRequestSettings = () => dispatch => {
	dispatch(getSettingsStart());
	const config = {
		method: "GET",
		url: backendApiUrls.settings,
	};

	axiosPlus(config)
		.then(response => {
			const { data } = response;
			dispatch(getSettingsSuccess(data));
		})

		.catch((error) => {
			dispatch(getSettingsError(errorsParser(error) ));
		});
};
