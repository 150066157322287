import * as types from "redux/constants/register";

import createReducer from "redux/createReducer";

const userInitialState = {
	createdUserData: {},
	createUserRequestSuccess: false,
	createUserRequestLoading: false,
	createUserRequestError: {},
	activateAccountRequestSuccess: false,
	activateAccountRequestLoading: false,
	activateAccountRequestError: {},
	resendEmailRequestSuccess: false,
	resendEmailRequestLoading: false,
	resendEmailRequestError: {},
	emailVerified: false,
};

const userReducer = createReducer(userInitialState)({
	[types.POST_REQUEST_CREATE_USER_START]: state => ({
		...state,
		createUserRequestLoading: true,
		createUserRequestError: {},
	}),
	[types.POST_REQUEST_CREATE_USER_SUCCESS]: (state, { payload }) => ({
		...state,
		createdUserData: payload,
		createUserRequestLoading: false,
	}),
	[types.POST_REQUEST_CREATE_USER_ERROR]: (state, { payload }) => {
		return {
			...state,
			createUserRequestError: payload,
			createUserRequestLoading: false,
		};
	},
	[types.POST_REQUEST_ACTIVATE_ACCOUNT_START]: state => ({
		...state,
		activateAccountRequestError: {},
		activateAccountRequestLoading: true,
		emailVerified: false,
	}),
	[types.POST_REQUEST_ACTIVATE_ACCOUNT_SUCCESS]: (state, { payload }) => ({
		...state,
		payload,
		activateAccountRequestLoading: false,
		activateAccountRequestSuccess: true,
	}),
	[types.POST_REQUEST_ACTIVATE_ACCOUNT_ERROR]: (state, { payload }) => {
		return {
			...state,
			activateAccountRequestError: payload.errors,
			emailVerified: payload.emailVerified,
			activateAccountRequestLoading: false,
			activateAccountRequestSuccess: false
		};
	},
	[types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_START]: state => ({
		...state,
		resendEmailRequestLoading: true,
		resendEmailRequestSuccess: false,
		resendEmailRequestError: {},
	}),
	[types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_SUCCESS]: (state) => ({
		...state,
		resendEmailRequestSuccess: true,
		resendEmailRequestLoading: false,
	}),
	[types.POST_REQUEST_RESEND_EMAIL_VERIFICATION_ERROR]: (state, { payload }) => ({
		...state,
		resendEmailRequestError: payload,
		resendEmailRequestLoading: false,
		resendEmailRequestSuccess: false,
	}),
	[types.RESET_EMAIL_VERIFICATION_RESULT]: (state) => ({
		...state,
		resendEmailRequestLoading: false,
		resendEmailRequestSuccess: false,
	})
});

export default userReducer;