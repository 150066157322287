import React, { useState } from "react";
import propTypes from "prop-types";

const CustomButton = (props) => {
	const { onClick, className } = props;
	const handleClick = (e) => { if (e) {e.preventDefault();} };
	const [isMobileClicked, setMobileClicked] = useState(false);
	return (
		<button
			onTouchStart={() => setMobileClicked(true)}
			onTouchEnd={() => setTimeout(() => {setMobileClicked(false);}, 200)}
			onMouseLeave={() => setMobileClicked(false)}
			className={`${className} ${isMobileClicked && "hovered-mobile"}`}
			onClick={onClick ? onClick : () => {}}
			onMouseDown={handleClick}
			onKeyUp={(e) => {if (e.kode === 13 || e.kode === 32) {handleClick();}}}
			type="button"
		/>
	);
};
CustomButton.propTypes = {
	onClick: propTypes.func,
	className: propTypes.string,
};

CustomButton.defaultProps = {
	className: "",
};

export default CustomButton;

CustomButton.displayName = "cb";