import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive/src";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { leaguesPropType } from "utils/propTypes/leaguesSeasons";

import frontendUrls from "routes/frontendUrls";

import { numberWithSpaces } from "helpers/common";

const MembersList = ({
	membersList = [],
}) => {
	const { t } = useTranslation("members_list");
	const isMobileResolution = useMediaQuery({
		query: "(max-width: 767px)"
	});
	return (
		<table className="leagues-table">
			<thead>
				<tr>
					<th className="leagues-table__cell-first leagues-table__cell-first_th">
					    <div className="place-number-th">
							{t("place")}
					    </div>
					</th>
					{isMobileResolution
						?
						<th className="leagues-table__cell-middle">{t("manager name")}<br/>{t("team name")}</th>
						:
						<>
							<th className="leagues-table__cell-middle">{t("team name")}</th>
							<th className="leagues-table__cell-middle">{t("manager name")}</th>
						</>
					}
					<th className="leagues-table__cell-last">{t("points")}</th>
				</tr>
			</thead>
			<tbody>
				{membersList.map((member, index) =>
					<NavLink
						className={`leagues-table__row-item ${member.own && "leagues-table__row-item_own"}`}
						key={`${member.manager}${member.name}${index}`}
						to={member.own ? frontendUrls.urlLivePreviousRoundTeams : `${frontendUrls.urlOtherUserTeam}?user_id=${member.user_id}`}
					>
						<td className="leagues-table__cell-first">
							<div className="place-number-td">
									{member.rank ? numberWithSpaces(member.rank) : "-"}
							</div>
						</td>
						{isMobileResolution
							?
							<td className="leagues-table__cell-middle">
								<span className="manager-name">{member.manager}</span>
								<span className="team-name">{member.name}</span>
							</td>
							:
							<>
								<td className="leagues-table__cell-middle">
								    <span className="team-name">
									    {member.name}
									</span>
								</td>
								<td className="leagues-table__cell-middle">
								    <span className="manager-name">
								        {member.manager}
								    </span>
								</td>
							</>
						}
						<td className="leagues-table__cell-last">
							<span className="leagues-board__points">
								{member.total_points === null ? "-" : numberWithSpaces(member.total_points || 0)}
							</span>
						</td>
					</NavLink>
				)}
			</tbody>
		</table>
	);
};

MembersList.propTypes = {
	membersList: PropTypes.arrayOf(leaguesPropType.leagues).isRequired,
};

export default MembersList;

MembersList.displayName = "ml";