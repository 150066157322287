import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { formValidatorSetNewPwd } from "utils/validators";

import {
	actionSwitchSetNewPasswordModalOpenState,
	actionPostRequestSetNewPassword,
	actionSwitchLoginModalOpenState,
} from "redux/actions/auth";


import ErrorComponent from "modules/common/ErrorComponent";
import SpinnerButton from "modules/common/SpinnerButton";
import ModalWindow from "modules/common/ModalWindow";

import SuccessComponent from "../common/SuccessComponent";

const SetNewPasswordModal = (props) => {
	const {
		setNewPasswordModalOpenState,
		setNewPasswordRequestLoading,
		setNewPasswordSuccess,
		setNewPasswordError,
		setNewPasswordParamsFromMailUrl,
		actionSwitchSetNewPasswordModalOpenState,
		actionPostRequestSetNewPassword,
		actionSwitchLoginModalOpenState,
	} = props;

	const { t } = useTranslation("set_new_password_modal");

	const history = useHistory();

	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
	const [successClosed, setSuccessClosed] = useState(false);

	const [requestErrors, setRequestErrors] = useState({});
	const [validationErrors, setValidationErrors] = useState({});

	useEffect(() => {
		setNewPasswordError && setRequestErrors({ ...requestErrors, ...setNewPasswordError });
	}, [setNewPasswordError]);

	const handleToggleModal = () => {
		history.push("/");
		actionSwitchSetNewPasswordModalOpenState();
	};

	const handleSetNewPasswordRequest = () => {
		const errors = formValidatorSetNewPwd({
			password: password1,
			confirm_password: password2,
		});
		setValidationErrors(errors);

		if (_.isEmpty(errors)) {
			setValidationErrors({});
			const { uid, token } = setNewPasswordParamsFromMailUrl;
			actionPostRequestSetNewPassword({ password1, password2, uid, token });
		}
	};

	const backToLogin = () => {
		history.push("/");
		actionSwitchLoginModalOpenState();
	};
	return (
		<ModalWindow toggle={handleToggleModal} isOpen={true || setNewPasswordModalOpenState}>
			{!setNewPasswordSuccess &&
				<div className='d-flex align-items-center flex-column'>
					<div className='fantasy-modal-title text-uppercase'>{t("reset password")}</div>
					{requestErrors.parsedTextErrorsWithoutKeys && requestErrors.parsedTextErrorsWithoutKeys.map((error, i) => (
						<Col className="px-0" key={Date.now()}>
							<div className="fantasy-modal-error-msg">
								<ErrorComponent
									handleDelete={() => {
										const newParsedTextErrorsWithoutKeysValue = [...requestErrors.parsedTextErrorsWithoutKeys];
										newParsedTextErrorsWithoutKeysValue.splice(i, 1);
										setRequestErrors({
											...requestErrors,
											parsedTextErrorsWithoutKeys: newParsedTextErrorsWithoutKeysValue
										});
									}}
									text={error}
									isTextCentered
								/>
							</div>
						</Col>
					))}

					<div className='fantasy-modal-subtitle'>
						{t("Please enter your new password")}
					</div>
					<input placeholder={t("New password")}
						className={`fantasy-modal-input ${validationErrors.password && "error"}`}
						type="password" value={password1}
						onChange={(e) => setPassword1(e.target.value)}
					/>
					{validationErrors.password && <div className='fantasy-modal-error pre-wrap w-100'>{validationErrors.password}</div>}

					<input placeholder={t("Confirm New Password")}
						className={`fantasy-modal-input ${validationErrors.confirm_password && "error"}`}
						type="password" value={password2}
						onChange={(e) => setPassword2(e.target.value)}
					/>
					{validationErrors.confirm_password && <div className='fantasy-modal-error w-100'>{validationErrors.confirm_password}</div>}

					<SpinnerButton
						isLoading={setNewPasswordRequestLoading}
						text={t("send")}
						onClick={handleSetNewPasswordRequest}
						className='col-4 mr-3 text-uppercase'
						size="medium"
					/>
				</div>
			}
			{setNewPasswordSuccess &&
				<div className='d-flex align-items-center flex-column'>
					<div className='fantasy-modal-title text-uppercase'>{t("reset password")}</div>
					<div className='fantasy-modal-subtitle'>
						{!successClosed &&
							<SuccessComponent
								className="mb-0"
								handleDelete={() => {
									setSuccessClosed(true);
								}}
								text={t("Password has been successfully changed")}
							/>
						}
					</div>
					<SpinnerButton
						isLoading={false}
						text={"sign in"}
						onClick={backToLogin}
						className="col-4 my-0 text-uppercase"
						size="medium"
					/>
				</div>
			}
		</ModalWindow>
	);
};

SetNewPasswordModal.propTypes = {
	setNewPasswordModalOpenState: propTypes.bool.isRequired,
	setNewPasswordError: propTypes.object.isRequired,
	setNewPasswordRequestLoading: propTypes.bool.isRequired,
	setNewPasswordSuccess: propTypes.bool.isRequired,
	setNewPasswordParamsFromMailUrl: propTypes.object.isRequired,
	actionSwitchSetNewPasswordModalOpenState: propTypes.func.isRequired,
	actionPostRequestSetNewPassword: propTypes.func.isRequired,
	actionSwitchLoginModalOpenState: propTypes.func.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const {
		setNewPasswordModalOpenState,
		setNewPasswordRequestLoading,
		setNewPasswordError,
		setNewPasswordSuccess,
		setNewPasswordParamsFromMailUrl,
	} = authReducer;
	return {
		setNewPasswordModalOpenState,
		setNewPasswordRequestLoading,
		setNewPasswordError,
		setNewPasswordSuccess,
		setNewPasswordParamsFromMailUrl
	};
};

export default connect(
	mapStateToProps,
	{ actionSwitchSetNewPasswordModalOpenState, actionPostRequestSetNewPassword, actionSwitchLoginModalOpenState },
)(SetNewPasswordModal);

SetNewPasswordModal.displayName = "snpm";