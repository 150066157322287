import i18instance from "i18instance";

import backendApiUrls from "routes/backendUrls";
import frontendUrls from "routes/frontendUrls";

import * as types from "redux/constants/auth";

import { errorsParser } from "helpers/errorsParser";
import { validateEmail } from "helpers/common";
import axiosPlus from "helpers/axiosPlus";
import history from "helpers/history";


const postRequestLoginStart = payload => ({
	type: types.POST_REQUEST_LOGIN_START,
	payload,
});


export function postRequestLoginSuccess(payload) {
	return dispatch => {
		dispatch({
			type: types.POST_REQUEST_LOGIN_SUCCESS,
			payload,
		});
	};
}

const postRequestLoginError = payload => ({
	type: types.POST_REQUEST_LOGIN_ERROR,
	payload,
});

export const actionClearUserLoginError = () => ({
	type: types.CLEAR_USER_LOGIN_ERROR,
});

const postRequestForgotPasswordStart = payload => ({
	type: types.POST_REQUEST_FORGOT_PASSWORD_START,
	payload,
});

const postRequestForgotPasswordSuccess = () => ({
	type: types.POST_REQUEST_FORGOT_PASSWORD_SUCCESS,
});

const postRequestForgotPasswordError = payload => ({
	type: types.POST_REQUEST_FORGOT_PASSWORD_ERROR,
	payload,
});

const postRequestSetNewPasswordStart = payload => ({
	type: types.POST_REQUEST_SET_NEW_PASSWORD_START,
	payload,
});

const postRequestSetNewPasswordSuccess = payload => ({
	type: types.POST_REQUEST_SET_NEW_PASSWORD_SUCCESS,
	payload,
});

const postRequestSetNewPasswordError = payload => ({
	type: types.POST_REQUEST_SET_NEW_PASSWORD_ERROR,
	payload,
});

export const actionSwitchLoginModalOpenState = payload => ({
	type: types.SWITCH_LOGIN_MODAL_OPEN_STATE,
	payload,
});

export const actionSwitchForgotPasswordModalOpenState = () => ({
	type: types.SWITCH_FORGOT_PASSWORD_MODAL_OPEN_STATE
});

export const actionSwitchSetNewPasswordModalOpenState = (payload) => ({
	type: types.SWITCH_SET_NEW_PASSWORD_MODAL_OPEN_STATE,
	payload
});

export const actionUserLogout = () => {
	history.push(`${frontendUrls.urlRoot}?no-modal=true`);
	return {
		type: types.USER_LOGOUT,
	};
};

const getUserDetailsRequestStart = () => ({
	type: types.GET_REQUEST_USER_DETAILS_START,
});

const getUserDetailsRequestSuccess = payload => ({
	type: types.GET_REQUEST_USER_DETAILS_SUCCESS,
	payload,
});

const getUserDetailsRequestError = payload => ({
	type: types.GET_REQUEST_USER_DETAILS_ERROR,
	payload,
});

const patchUserDetailsRequestStart = payload => ({
	type: types.PATCH_REQUEST_USER_DETAILS_START,
	payload,
});

const patchUserDetailsRequestError = payload => ({
	type: types.PATCH_REQUEST_USER_DETAILS_ERROR,
	payload,
});

const deleteUserAccountRequestStart = () => ({
	type: types.DELETE_REQUEST_USER_ACCOUNT_START,
});

const deleteUserAccountRequestSuccess = () => ({
	type: types.DELETE_REQUEST_USER_ACCOUNT_SUCCESS,
});

const deleteUserAccountRequestError = payload => ({
	type: types.DELETE_REQUEST_USER_ACCOUNT_ERROR,
	payload,
});

export const actionGetUserDetails = () => dispatch => {
	dispatch(getUserDetailsRequestStart());
	return axiosPlus({ method: "GET", url: backendApiUrls.userDetails })
		.then(function (response) {
			const { data } = response;
			const { is_email_verified: isEmailConfirmed } = data;
			data && dispatch(getUserDetailsRequestSuccess(data));
			return { isEmailConfirmed };
		})
		.catch(function (error) {
			dispatch(getUserDetailsRequestError(errorsParser(error)));
			return { isEmailConfirmed: false };
		});
};

export const actionUpdateUserDetails = (data) => dispatch => {
	dispatch(patchUserDetailsRequestStart(data));

	return axiosPlus({ method: "PATCH", url: backendApiUrls.userDetails, data })
		.then(function (response) {
			const { data } = response;
			data && dispatch(getUserDetailsRequestSuccess(data));
			return data;
		})
		.catch(function (error) {
			dispatch(patchUserDetailsRequestError(errorsParser(error)));
			return errorsParser(error);
		});
};

export const actionPostRequestLogin = payload => dispatch => {
	const { email, password } = payload;
	dispatch(postRequestLoginStart(payload));

	let frontValidator = {};
	if (!email) {
		frontValidator.email = i18instance.t("auth_errors:email_is_required");
	} else if (!validateEmail(email)) {
		frontValidator.email = i18instance.t("auth_errors:email_should_have_correct_format_email_example_com");
	}
	if (!password) {
		frontValidator.password = i18instance.t("auth_errors:password_is_required");
	}

	if (Object.keys(frontValidator).length) {
		dispatch(postRequestLoginError(frontValidator));
	} else {
		return axiosPlus({ method: "POST", url: backendApiUrls.login, data: payload })
			.then(function (response) {
				const { data } = response;
				const { access, refresh } = data;
				localStorage.setItem("accessToken", access);
				localStorage.setItem("refreshToken", refresh);
				dispatch(actionGetUserDetails()).then(({ isEmailConfirmed }) => {
					!isEmailConfirmed && window.location.pathname !== frontendUrls.urlConfirmEmail && history.push(frontendUrls.urlConfirmEmail);
					dispatch(postRequestLoginSuccess({ email, ...data }));
				}).catch(() => {
					dispatch(postRequestLoginSuccess({ email, ...data }));
				});
			})
			.catch(function (error) {
				dispatch(postRequestLoginError(errorsParser(error)));
			});
	}
};

export const actionPostRequestForgotPassword = payload => dispatch => {
	const { email } = payload;
	dispatch(postRequestForgotPasswordStart(payload));

	let frontValidator = {};
	if (email && !validateEmail(email)) {
		frontValidator.email = i18instance.t("auth_errors:please_use_correct_email_address");
	}

	if (frontValidator.email) {
		dispatch(postRequestForgotPasswordError(frontValidator));
	} else {
		return axiosPlus({ method: "POST", url: backendApiUrls.resetPassword, data: payload })
			.then(function () {
				dispatch(postRequestForgotPasswordSuccess());
			})
			.catch(function (error) {
				dispatch(postRequestForgotPasswordError(errorsParser(error)));
			});
	}
};

export const actionPostRequestSetNewPassword = payload => dispatch => {
	const { password1, password2, uid, token } = payload;
	dispatch(postRequestSetNewPasswordStart(payload));
	const data = {
		new_password1: password1,
		new_password2: password2,
		uid,
		token,
	};

	return axiosPlus({ method: "POST", url: backendApiUrls.setNewPassword, data })
		.then(function () {
			dispatch(postRequestSetNewPasswordSuccess());
		})
		.catch(function (error) {
			dispatch(postRequestSetNewPasswordError(errorsParser(error)));
		});
};

export const actionDeleteUserAccount = () => dispatch => {
	dispatch(deleteUserAccountRequestStart());

	return axiosPlus({ method: "DELETE", url: backendApiUrls.userDetails })
		.then(function () {
			dispatch(deleteUserAccountRequestSuccess());
			dispatch(actionUserLogout());
			history.push("/");
		})
		.catch(function (error) {
			dispatch(deleteUserAccountRequestError(errorsParser(error)));
		});
};