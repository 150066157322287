import { Col, Row } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import priceToMillion from "helpers/priceToMillion";

const PlayersListHeadStats = React.forwardRef(({ fieldTeamLength, budgetLeft }, ref) => {
	const { t } = useTranslation("players_list");
	return (
		<Row className='players-list-stats'>
			<Col className='col-6 d-flex flex-row justify-content-start p-0'>
				<div className='d-flex flex-column justify-content-between align-items-center'>
					<span className='font-size-12 source-sans-pro color-secondary-text'>
						{t("players")}
					</span>
					<span className='font-size-36 bebas-neue'>
						{fieldTeamLength}
						<span
							className={`${fieldTeamLength < 15 && "color-secondary-text"}`}>
							/15
						</span>
					</span>
				</div>
			</Col>
			<Col className='col-6 d-flex flex-row justify-content-end p-0'>
				<div className='d-flex flex-column justify-content-between align-items-center'>
					<span className='font-size-12 source-sans-pro color-secondary-text'>
						{t("budget")}
					</span>
					<span ref={ref} className='font-size-36 bebas-neue'>
						{priceToMillion(budgetLeft)}
					</span>
				</div>
			</Col>
		</Row>
	);
});

PlayersListHeadStats.propTypes = {
	fieldTeamLength: PropTypes.number.isRequired,
	budgetLeft: PropTypes.number.isRequired,
};

export default PlayersListHeadStats;

PlayersListHeadStats.displayName = "plhs";