import React from "react";
import { Container, Row } from "reactstrap";

import RoundResultsList from "modules/home/containers/RoundResultsList";
import DreamTeam from "modules/home/containers/DreamTeam";

import Welcome from "./Welcome";

import Boards from "modules/common/Boards";
import NewsBlock from "modules/news/containers/NewsBlock";
import Title from "modules/common/Title";
import AnnouncementBanner from "modules/common/AnnouncementBanner";

import AdUnit from "modules/common/AdUnit"

const Main = () => (
	<div>
		<Welcome/>
		<Container className="global-container">
		  <AdUnit adUnitId="7336133410" />
		  <Row>
        <Title translation={{
            ns: "news_and_rankings",
            key: "news_and_rankings"
        }} />
			</Row>
			<Boards />
			<AnnouncementBanner/>
			<NewsBlock />
			<DreamTeam />
			<RoundResultsList />
		</Container>
	</div>
);

export default Main;

Main.displayName = "main";