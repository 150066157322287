import * as types from "redux/constants/footballActions";

import createReducer from "redux/createReducer";

export const footballActionsInitialState = {
	footballActionsList: [],
	footballActionsRequestError: {},
	footballActionsRequestLoading: false,
};

const footballActionsReducer = createReducer(footballActionsInitialState)({
	[types.GET_REQUEST_FOOTBALL_ACTIONS_START]: state => ({
		...state,
		footballActionsRequestError: {},
		footballActionsRequestLoading: true,
	}),
	[types.GET_REQUEST_FOOTBALL_ACTIONS_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			footballActionsRequestLoading: false,
			footballActionsList: payload,
		};

	},
	[types.GET_REQUEST_FOOTBALL_ACTIONS_ERROR]: (state, { payload }) => ({
		...state,
		footballActionsRequestError: payload,
		footballActionsRequestLoading: false,
	})
});

export default footballActionsReducer;
