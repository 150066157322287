import { all, put, takeEvery, fork, call } from "redux-saga/effects";

import backendApiUrls from "routes/backendUrls";

import { GET_REQUEST_OTHER_USER_RESULTS_START } from "redux/constants/otherUserResults";

import {
	getOtherUserResultsRequestSuccessAction,
	getOtherUserResultsRequestErrorAction,
} from "redux/actions/otherUserResults";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

function createRequest({ method, url, data, params }) {
	return axiosPlus({ method, url, data, params });
}

function* getOtherUserResultsRequest({ payload }) {
	try {
		const otherUserResults = yield call(createRequest, { method: "GET", url: `${backendApiUrls.userTeamsResults}${payload.user}/` });
		yield put(getOtherUserResultsRequestSuccessAction({ data: otherUserResults.data, forUserID: payload.user }));
	} catch (error) {
		yield put(
			getOtherUserResultsRequestErrorAction(errorsParser(error).parsedTextErrors)
		);
	}
}

export function* sagaGetOtherUserResultsRequest() {
	yield takeEvery(GET_REQUEST_OTHER_USER_RESULTS_START, getOtherUserResultsRequest);
}

export default function* otherUserResultsSaga() {
	yield all([fork(sagaGetOtherUserResultsRequest)]);
}
