import React from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { SliderRail } from "modules/common/Slider/SliderRail";

import TooltipRail from "./TooltipRail";
import Handle from "./Handle";
import Track from "./Track";


const RangeSlider = ({ domain, values, onChange }) => {
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});
	return (
		<div className='slider-wrapper'>
			<Slider
				mode={1}
				step={0.1}
				domain={domain}
				className='slider-main'
				onUpdate={onChange}
				onChange={onChange}
				values={values}
			>
				<Rail>{railProps => isMobileResolution
					? <SliderRail {...railProps} />
					: <TooltipRail {...railProps} />}</Rail>
				<Handles>
					{({ handles, activeHandleID, getHandleProps }) => (
						<div className="slider-handles">
							{handles.map(handle => (
								<Handle
									key={`handle-${handle.id}`}
									handle={handle}
									domain={domain}
									isActive={handle.id === activeHandleID}
									getHandleProps={getHandleProps}
								/>
							))}
						</div>
					)}
				</Handles>
				<Tracks left={false} right={false}>
					{({ tracks, getTrackProps }) => (
						<div className="slider-tracks">
							{tracks.map(({ id, source, target }) => (
								<Track
									key={`track-slider-${id}`}
									source={source}
									target={target}
									getTrackProps={getTrackProps}
								/>
							))}
						</div>
					)}
				</Tracks>
			</Slider>
		</div>
	);
};

RangeSlider.propTypes = {
	domain: PropTypes.array.isRequired,
	values: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

RangeSlider.displayName = "rs";

export default React.memo(RangeSlider);