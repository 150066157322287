import { Spinner } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import arrowLeft from "static/assets/images/arrow-left.svg";
import arrowRight from "static/assets/images/arrow-right.svg";

import MainButton from "modules/common/MainButton";

const SpinnerButton = ({ text, onClick, size, color, className, isLoading, disabled, isLeftNavigate, isRightNavigate, children }) => (
	<MainButton
		handler={onClick}
		size={size}
		color={color}
		className={`${className} ${disabled && "button_disabled"}`} 
	>
		<div key={Math.random()}>
			{isLeftNavigate && <img src={arrowLeft} alt="" className="mr-2" />}
			{isLoading ? <Spinner className='height-15px width-15px'/>: text}
			{isRightNavigate && <img src={arrowRight} alt="" className="ml-2"/>}
			{children ? children : null}
		</div>
	</MainButton>
);

export default SpinnerButton;

SpinnerButton.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	isRightNavigate: PropTypes.bool,
	isLeftNavigate: PropTypes.bool,
	size: PropTypes.oneOfType([
		PropTypes.oneOf(["small", "medium"]),
		PropTypes.string,
	]),
	color: PropTypes.oneOfType([
		PropTypes.oneOf(["blue", "white", "red"]),
		PropTypes.string,
	]),
	children: PropTypes.node,
};

SpinnerButton.defaultProps = {
	className: "",
	isLoading: false,
	disabled: false,
	isRightNavigate: false,
	isLeftNavigate: false,
};

SpinnerButton.displayName = "sb";