import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Trans } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import appBadgeAppStore from "static/assets/images/app-badge-app-store.svg";
import appBadgeGooglePlay from "static/assets/images/app-badge-google-play.svg";
import screenSplash from "static/assets/images/screen-splash.png";
import screenSignIn from "static/assets/images/screen-sign-in.png";

const AnnouncementBanner = () => {
    const bannerText = <Trans
                        i18nKey="announcement_banner_text"
                        ns="main_screen"
                        components={
                            [<div key="0" className="blue"/>]
                        }
                    />

    return (
        <Row className="global-row">
            <Col>
                <div className="announcement-banner-wrapper">
                    <Row>
                        <Col>
                            <div className="announcement-banner-text-wrapper">
                                <div>
                                    <h1 className="announcement-banner-text"> {bannerText} </h1>
                                </div>
                                <div className="announcement-banner-badge-wrapper">
                                    <Link to={frontendUrls.urlDownloadTheApp}>
                                        <img className="app-badge" src={appBadgeAppStore} alt=""/>
                                    </Link>
                                    <Link to={frontendUrls.urlDownloadTheApp}>
                                        <img className="app-badge" src={appBadgeGooglePlay} alt=""/>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="announcement-banner-img-wrapper">
                                <img className="announcement-banner-img-1" src={screenSplash} alt=""/>
                                <img className="announcement-banner-img-2" src={screenSignIn} alt=""/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default AnnouncementBanner;