import * as types from "redux/constants/userTransfers";

export const actionGetUserTransfersRequest = payload => ({
	type: types.GET_REQUEST_USER_TRANSFERS_START,
	payload,
});

export const actionGetUserTransfersRequestSuccessAction = payload => ({
	type: types.GET_REQUEST_USER_TRANSFERS_SUCCESS,
	payload,
});

export const actionGetUserTransfersRequestErrorAction = payload => ({
	type: types.GET_REQUEST_USER_TRANSFERS_ERROR,
	payload,
});