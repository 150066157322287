import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/news";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

const getNewsPaginationStart = () => ({
	type: types.GET_NEWS_PAGINATION_START,
});

const getNewsPaginationSuccess = payload => ({
	type: types.GET_NEWS_PAGINATION_SUCCESS,
	payload,
});

const getNewsPaginationError = payload => ({
	type: types.GET_NEWS_PAGINATION_ERROR,
	payload,
});

export const actionGetNewsPagination = (page, pageSize) => dispatch => {
	dispatch(getNewsPaginationStart());
	return axiosPlus({ 
		method: "GET", 
		url: backendApiUrls.urlNews,
		params: {
			page,
			page_size: pageSize || 3,
		},
	})
		.then((response) => {
			const { data } = response;
			dispatch(getNewsPaginationSuccess(data));
		})
		.catch((error) => {
			dispatch(getNewsPaginationError(errorsParser(error)));
		});
};

const getCountersStart = () => ({
	type: types.GET_COUNTERS_START,
});

const getCountersSuccess = payload => ({
	type: types.GET_COUNTERS_SUCCESS,
	payload,
});

const getCountersError = payload => ({
	type: types.GET_COUNTERS_ERROR,
	payload,
});

export const actionGetCounters = () => dispatch => {
	dispatch(getCountersStart());
	return axiosPlus({ 
		method: "GET", 
		url: backendApiUrls.urlCounters,
	})
		.then((response) => {
			const { data } = response;
			dispatch(getCountersSuccess(data));
		})
		.catch((error) => {
			dispatch(getCountersError(errorsParser(error)));
		});
};

const getSelectedNewsItemStart = () => ({
	type: types.GET_SELECTED_NEWS_ITEM_START,
});

const getSelectedNewsItemSuccess = payload => ({
	type: types.GET_SELECTED_NEWS_ITEM_SUCCESS,
	payload,
});

const getSelectedNewsItemError = payload => ({
	type: types.GET_SELECTED_NEWS_ITEM_ERROR,
	payload,
});

export const actionGetSelectedNewsItem = (id) => dispatch => {
	dispatch(getSelectedNewsItemStart());
	return axiosPlus({ 
		method: "GET", 
		url: `${backendApiUrls.urlNews}${id}/`,
	})
		.then((response) => {
			const { data } = response;
			dispatch(getSelectedNewsItemSuccess(data));
		})
		.catch((error) => {
			dispatch(getSelectedNewsItemError(errorsParser(error)));
		});
};