import PropTypes from "prop-types";

// Season
const leaguesSeasonsPropType = {
	leagueSeasons: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string.isRequired,
		status: PropTypes.oneOf([ "open-for-registration", "active", "disabled" ]),
	}).isRequired,
};

export const leaguesPropType = {
	leagues: PropTypes.shape({
		manager: PropTypes.string,
		name: PropTypes.string,
		own: PropTypes.bool,
		rank: PropTypes.number,
		total_points: PropTypes.number,
	})
};

export default leaguesSeasonsPropType;
