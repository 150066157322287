export const GET_REQUEST_SECTIONS_HOW_TO_PLAY_START = "GET_REQUEST_SECTIONS_HOW_TO_PLAY_START";
export const GET_REQUEST_SECTIONS_HOW_TO_PLAY_SUCCESS = "GET_REQUEST_SECTIONS_HOW_TO_PLAY_SUCCESS";
export const GET_REQUEST_SECTIONS_HOW_TO_PLAY_ERROR = "GET_REQUEST_SECTIONS_HOW_TO_PLAY_ERROR";

export const GET_REQUEST_SECTIONS_RULES_START = "GET_REQUEST_SECTIONS_RULES_START";
export const GET_REQUEST_SECTIONS_RULES_SUCCESS = "GET_REQUEST_SECTIONS_RULES_SUCCESS";
export const GET_REQUEST_SECTIONS_RULES_ERROR = "GET_REQUEST_SECTIONS_RULES_ERROR";

export const GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_START = "GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_START";
export const GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_SUCCESS = "GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_SUCCESS";
export const GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_ERROR = "GET_REQUEST_RULES_ITEM_HOW_TO_PLAY_ERROR";

export const GET_REQUEST_RULES_ITEM_RULES_START = "GET_REQUEST_RULES_ITEM_RULES_START";
export const GET_REQUEST_RULES_ITEM_RULES_SUCCESS = "GET_REQUEST_RULES_ITEM_RULES_SUCCESS";
export const GET_REQUEST_RULES_ITEM_RULES_ERROR = "GET_REQUEST_RULES_ITEM_RULES_ERROR";

export const GET_REQUEST_FAQ_LIST_START = "GET_REQUEST_FAQ_LIST_START";
export const GET_REQUEST_FAQ_LIST_SUCCESS = "GET_REQUEST_FAQ_LIST_SUCCESS";
export const GET_REQUEST_FAQ_LIST_ERROR = "GET_REQUEST_FAQ_LIST_ERROR";
