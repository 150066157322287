import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootSaga from "redux/sagas/index";
import userTransfersReducer from "redux/reducers/userTransfers";

import settingsReducer from "./reducers/settings";
import footballFieldReducer from "./reducers/footballField";
import leagueSeasonsReducer from "./reducers/leagueSeasons";
import leaguesReducer from "./reducers/leagues";
import seasonRoundsReducer from "./reducers/seasonRounds";
import roundGamesReducer from "./reducers/roundGames";
import prizesReducer from "./reducers/prizes";
import authReducer from "./reducers/auth";
import countriesReducer from "./reducers/countries";
import dreamTeamsReducer from "./reducers/dreamTeams";
import teamsListReducer from "./reducers/teamsList";
import playersListReducer from "./reducers/playersList";
import userTeamsReducer from "./reducers/userTeams";
import registerReducer from "./reducers/register";
import rulesAndFAQReducer from "./reducers/rulesAndFAQ";
import userRoundTeamsReducer from "./reducers/userRoundTeams";
import otherUserResultsReducer from "./reducers/otherUserResults";
import commonReducer from "./reducers/common";
import footballActionsReducer from "./reducers/footballActions";
import newsReducer from "./reducers/news";


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
	settingsReducer,
	footballFieldReducer,
	leagueSeasonsReducer,
	leaguesReducer,
	seasonRoundsReducer,
	roundGamesReducer,
	prizesReducer,
	registerReducer,
	authReducer,
	countriesReducer,
	dreamTeamsReducer,
	teamsListReducer,
	playersListReducer,
	userTeamsReducer,
	rulesAndFAQReducer,
	userRoundTeamsReducer,
	otherUserResultsReducer,
	userTransfersReducer,
	commonReducer,
	footballActionsReducer,
	newsReducer,
});

function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		composeEnhancers(
			applyMiddleware(logger, thunk, sagaMiddleware)
		)
	);
}
const store = configureStore(window.REDUX_INITIAL_DATA);
sagaMiddleware.run(rootSaga);

export default store;
