import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";

const PrivateLeagueDeleteModal = ({
	onClick,
	onClose,
	isLoading,
}) => {
	const { t } = useTranslation("private_league_delete_modal");
	return (
		<ModalWindow contentClassName="private-league-settings-modal" isOpen={true} toggle={onClose}>
			<div className="fantasy-modal-title">{t("Confirm league removal")}</div>
			<div className="fantasy-modal-subtitle">
				{t("This private league will be permanently removed. Are you sure ?")}
			</div>
			<SpinnerButton
				text={t("confirm")}
				onClick={onClick}
				className="col-4 text-uppercase"
				size="medium"
				isLoading={isLoading}
			/>
		</ModalWindow>
	);
};

export default PrivateLeagueDeleteModal;


PrivateLeagueDeleteModal.propTypes = {
	onClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

PrivateLeagueDeleteModal.displayName = "pldm";