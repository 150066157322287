export const REQUEST_GET_LEAGUES_SEASONS_START = "REQUEST_GET_LEAGUES_SEASONS_START";
export const REQUEST_GET_LEAGUES_SEASONS_SUCCESS = "REQUEST_GET_LEAGUES_SEASONS_SUCCESS";
export const REQUEST_GET_LEAGUES_SEASONS_ERROR = "REQUEST_GET_LEAGUES_SEASONS_ERROR";

export const SET_LEAGUE_TYPE = "SET_LEAGUE_TYPE";
export const SET_LEAGUE_SUBTYPE = "SET_LEAGUE_SUBTYPE";
export const GET_REQUEST_COUNTRY_LEAGUES_START = "GET_REQUEST_COUNTRY_LEAGUES_START";
export const GET_REQUEST_COUNTRY_LEAGUES_SUCCESS = "GET_REQUEST_COUNTRY_LEAGUES_SUCCESS";
export const GET_REQUEST_COUNTRY_LEAGUES_ERROR = "GET_REQUEST_COUNTRY_LEAGUES_ERROR";
export const GET_REQUEST_QUAD_LEAGUES_START = "GET_REQUEST_QUAD_LEAGUES_START";
export const GET_REQUEST_QUAD_LEAGUES_SUCCESS = "GET_REQUEST_QUAD_LEAGUES_SUCCESS";
export const GET_REQUEST_QUAD_LEAGUES_ERROR = "GET_REQUEST_QUAD_LEAGUES_ERROR";
export const GET_REQUEST_QUAD_LEAGUE_INFO_START = "GET_REQUEST_QUAD_LEAGUE_INFO_START";
export const GET_REQUEST_QUAD_LEAGUE_INFO_SUCCESS = "GET_REQUEST_QUAD_LEAGUE_INFO_SUCCESS";
export const GET_REQUEST_QUAD_LEAGUE_INFO_ERROR = "GET_REQUEST_QUAD_LEAGUE_INFO_ERROR";
export const GET_REQUEST_ROUND_LEAGUES_START = "GET_REQUEST_ROUND_LEAGUES_START";
export const GET_REQUEST_ROUND_LEAGUES_SUCCESS = "GET_REQUEST_ROUND_LEAGUES_SUCCESS";
export const GET_REQUEST_ROUND_LEAGUES_ERROR = "GET_REQUEST_ROUND_LEAGUES_ERROR";
export const GET_REQUEST_PAGINATION_ROUND_LEAGUES_START = "GET_REQUEST_PAGINATION_ROUND_LEAGUES_START";
export const GET_REQUEST_PAGINATION_ROUND_LEAGUES_SUCCESS = "GET_REQUEST_PAGINATION_ROUND_LEAGUES_SUCCESS";
export const GET_REQUEST_PAGINATION_ROUND_LEAGUES_ERROR = "GET_REQUEST_PAGINATION_ROUND_LEAGUES_ERROR";
export const GET_REQUEST_SEASON_LEAGUE_INFO_START = "GET_REQUEST_SEASON_LEAGUE_INFO_START";
export const GET_REQUEST_SEASON_LEAGUE_INFO_SUCCESS = "GET_REQUEST_SEASON_LEAGUE_INFO_SUCCESS";
export const GET_REQUEST_SEASON_LEAGUE_INFO_ERROR = "GET_REQUEST_SEASON_LEAGUE_INFO_ERROR";
export const CLEAR_FIND_OWN_LEAGUES_ERROR = "CLEAR_FIND_OWN_LEAGUES_ERROR";
export const GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_START = "GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_START";
export const GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_SUCCESS = "GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_SUCCESS";
export const GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_ERROR = "GET_REQUEST_FIND_OWN_LEAGUES_SUBTYPE_ERROR";
export const GET_REQUEST_LEAGUES_COUNTRY_INFO_START = "GET_REQUEST_LEAGUES_COUNTRY_INFO_START";
export const GET_REQUEST_LEAGUES_COUNTRY_INFO_SUCCESS = "GET_REQUEST_LEAGUES_COUNTRY_INFO_SUCCESS";
export const GET_REQUEST_LEAGUES_COUNTRY_INFO_ERROR = "GET_REQUEST_LEAGUES_COUNTRY_INFO_ERROR";
export const GET_REQUEST_PAGINATION_LEAGUES_START = "GET_REQUEST_PAGINATION_LEAGUES_START";
export const GET_REQUEST_PAGINATION_LEAGUES_SUCCESS = "GET_REQUEST_PAGINATION_LEAGUES_SUCCESS";
export const GET_REQUEST_PAGINATION_LEAGUES_ERROR = "GET_REQUEST_PAGINATION_LEAGUES_ERROR";
export const RESET_LEAGUE_MEMBERS_PAGINATION_LIST = "RESET_LEAGUE_MEMBERS_PAGINATION_LIST";
export const GET_REQUEST_PRIVATE_LEAGUES_INFO_START = "GET_REQUEST_PRIVATE_LEAGUES_INFO_START";
export const GET_REQUEST_PRIVATE_LEAGUES_INFO_SUCCESS = "GET_REQUEST_PRIVATE_LEAGUES_INFO_SUCCESS";
export const GET_REQUEST_PRIVATE_LEAGUES_INFO_ERROR = "GET_REQUEST_PRIVATE_LEAGUES_INFO_ERROR";
export const POST_REQUEST_PRIVATE_LEAGUES_START = "POST_REQUEST_PRIVATE_LEAGUES_START";
export const POST_REQUEST_PRIVATE_LEAGUES_SUCCESS = "POST_REQUEST_PRIVATE_LEAGUES_SUCCESS";
export const POST_REQUEST_PRIVATE_LEAGUES_ERROR = "POST_REQUEST_PRIVATE_LEAGUES_ERROR";
export const POST_REQUEST_JOIN_PRIVATE_LEAGUE_START = "POST_REQUEST_JOIN_PRIVATE_LEAGUE_START";
export const POST_REQUEST_JOIN_PRIVATE_LEAGUE_SUCCESS = "POST_REQUEST_JOIN_PRIVATE_LEAGUE_SUCCESS";
export const POST_REQUEST_JOIN_PRIVATE_LEAGUE_ERROR = "POST_REQUEST_JOIN_PRIVATE_LEAGUE_ERROR";
export const GET_REQUEST_PRIVATE_LEAGUE_INFO_START = "GET_REQUEST_PRIVATE_LEAGUE_INFO_START";
export const GET_REQUEST_PRIVATE_LEAGUE_INFO_SUCCESS = "GET_REQUEST_PRIVATE_LEAGUE_INFO_SUCCESS";
export const GET_REQUEST_PRIVATE_LEAGUE_INFO_ERROR = "GET_REQUEST_PRIVATE_LEAGUE_INFO_ERROR";
export const CLEAR_PRIVATE_LEAGUE_INFO_ERROR = "CLEAR_PRIVATE_LEAGUE_INFO_ERROR";
export const CLEAR_CREATE_JOIN_LEAGUE_STATES = "CLEAR_CREATE_JOIN_LEAGUE_STATES";

export const LEAVE_PRIVATE_LEAGUE_START = "LEAVE_PRIVATE_LEAGUE_START";
export const LEAVE_PRIVATE_LEAGUE_SUCCESS = "LEAVE_PRIVATE_LEAGUE_SUCCESS";
export const LEAVE_PRIVATE_LEAGUE_ERROR = "LEAVE_PRIVATE_LEAGUE_ERROR";

export const DELETE_PRIVATE_LEAGUE_START = "DELETE_PRIVATE_LEAGUE_START";
export const DELETE_PRIVATE_LEAGUE_SUCCESS = "DELETE_PRIVATE_LEAGUE_SUCCESS";
export const DELETE_PRIVATE_LEAGUE_ERROR = "DELETE_PRIVATE_LEAGUE_ERROR";

export const EDIT_PRIVATE_LEAGUE_START = "EDIT_PRIVATE_LEAGUE_START";
export const EDIT_PRIVATE_LEAGUE_SUCCESS = "EDIT_PRIVATE_LEAGUE_SUCCESS";
export const EDIT_PRIVATE_LEAGUE_ERROR = "EDIT_PRIVATE_LEAGUE_ERROR";

export const REMOVE_TEAM_FROM_PRIVATE_LEAGUE_START = "REMOVE_TEAM_FROM_PRIVATE_LEAGUE_START";
export const REMOVE_TEAM_FROM_PRIVATE_LEAGUE_SUCCESS = "REMOVE_TEAM_FROM_PRIVATE_LEAGUE_SUCCESS";
export const REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR = "REMOVE_TEAM_FROM_PRIVATE_LEAGUE_ERROR";

export const SET_LEAVE_PRIVATE_LEAGUE_MODAL_STATE = "SET_LEAVE_PRIVATE_LEAGUE_MODAL_STATE";
export const SET_EDIT_PRIVATE_LEAGUE_MODAL_STATE = "SET_EDIT_PRIVATE_LEAGUE_MODAL_STATE";

export const CLEAR_PRIVATE_LEAGUE_MODAL_ERRORS = "CLEAR_PRIVATE_LEAGUE_MODAL_ERRORS";

export const SET_SELECTED_ROUND_LEAGUE = "SET_SELECTED_ROUND_LEAGUE";
export const CLEAR_SELECTED_ROUND_LEAGUE = "CLEAR_SELECTED_ROUND_LEAGUE";
export const HYDRATE_SELECTED_ROUND_LEAGUE_FROM_STORAGE = "HYDRATE_SELECTED_ROUND_LEAGUE_FROM_STORAGE";

export const GET_SUGGESTED_USER_TEAMS_START = "GET_SUGGESTED_USER_TEAMS_START";
export const GET_SUGGESTED_USER_TEAMS_SUCCESS = "GET_SUGGESTED_USER_TEAMS_SUCCESS";
export const GET_SUGGESTED_USER_TEAMS_ERROR = "GET_SUGGESTED_USER_TEAMS_ERROR";

export const CLEAR_SUGGESTIONS_USER_TEAMS = "CLEAR_SUGGESTIONS_USER_TEAMS";