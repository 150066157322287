import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { NavLink, Link, useLocation } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavItem,
	CardImg,
	Container,
} from "reactstrap";
import classNames from "classnames";
import logo from "static/assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import frontendUrls from "routes/frontendUrls";

import { actionSwitchLoginModalOpenState } from "redux/actions/auth";

import MainButton from "modules/common/MainButton";

import ProfileIcon from "./ProfileIcon";
import NavItemDropdown from "./NavItemDropdown";
import LanguageSelect from "./LanguageSelect";


const MenuToggleButton = ({ handleToggle, isShowMenu }) => {
	const showMenuClass = classNames(
		"header__btn-burger",
		{ "header__btn-burger_cross": isShowMenu }
	);
	return (
		<div onClick={handleToggle} className='header__btn-burger__wrapper'>
			<button className={showMenuClass}>
				<span />
			</button>
		</div>
	);
};

MenuToggleButton.propTypes = {
	handleToggle: propTypes.func.isRequired,
	isShowMenu: propTypes.bool.isRequired,
};

const Header = ({ actionSwitchLoginModalOpenState, isUserLoggedIn }) => {
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const { t } = useTranslation("header");

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	let location = useLocation();
	const isMaintenancePage = location.pathname === frontendUrls.urlMaintenance;
	const isEmailConfirmPage = location.pathname === frontendUrls.urlConfirmEmail;
	const isSelectedTeamPage = location.pathname === frontendUrls.urlSelectTeam;
	const isNextSeasonTeam = location.pathname === frontendUrls.urlNextSeasonTeam;

	const [isShowMenu, setShowMenu] = useState(false);
	const [scrollPosition, setSrollPosition] = useState(0);

	const handleToggle = () => setShowMenu(!isShowMenu);

	useEffect(() => {
		if (!isShowMenu && isOpenDropdown) {
			setIsOpenDropdown(false);
		}
	}, [isShowMenu]);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setSrollPosition(position);
	};

	const showMenuClass = classNames(
		"header__menu",
		{ "header__menu_visible": isShowMenu },
		{ "flex-md-row-reverse": isUserLoggedIn }
	);
	const menuHeaderCssClass = classNames(
		"header",
	    { "header_sticky": scrollPosition > 50 },
        { "header_menu_shown": isShowMenu}
	);
	const handleCloseMenu = e => {
		if (e.target.tagName === "A") {
			setShowMenu(false);
		}
	};

	useEffect(() => {
		isShowMenu
			? document.body.classList.add("lock")
			: document.body.classList.remove("lock");
	}, [isShowMenu]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const isPageWithoutMenu = isNextSeasonTeam || isEmailConfirmPage || (isSelectedTeamPage && isUserLoggedIn);
	const selectedElementClassName = "selected";
	return (
		<header className={menuHeaderCssClass}>
			<Container className="px-0">
				<div className="header__body">
					{!isMaintenancePage && !isPageWithoutMenu &&
						<NavLink to={isUserLoggedIn ? frontendUrls.urlMain : frontendUrls.urlRoot} className="header__logo" onClick={() => setShowMenu(false)}>
							<CardImg src={logo} alt="" />
						</NavLink>
					}
					{isPageWithoutMenu &&
						<div className="header__logo">
							<CardImg src={logo} alt="" />
						</div>
					}
					{isMaintenancePage &&
						<span className="header__logo" onClick={() => setShowMenu(false)}>
							<CardImg src={logo} alt="" />
						</span>
					}	
					{!isMaintenancePage && isMobileResolution && <LanguageSelect />}
					{!isMaintenancePage &&
						<MenuToggleButton handleToggle={handleToggle} isShowMenu={isShowMenu} />
					}
					{!isMaintenancePage &&
						<div className={showMenuClass}>
							{isUserLoggedIn &&
								<ProfileIcon handleToggle={handleToggle} handleCloseMenu={handleCloseMenu} />
							}
							{!isMobileResolution && isUserLoggedIn && <LanguageSelect />}
							{!isPageWithoutMenu &&
								<Navbar expand="md" className="navigation">
									<Nav navbar className="navigation__ul">
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={isUserLoggedIn ? frontendUrls.urlMain : frontendUrls.urlRoot}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>{t("Home")}</NavLink>
										</NavItem>
										{isUserLoggedIn &&
											<NavItemDropdown
												className="navigation__item"
												handleCloseMenu={handleCloseMenu}
												isOpenDropdown={isOpenDropdown}
												setIsOpenDropdown={setIsOpenDropdown}
												isShowMenu={isShowMenu}
											/>
										}
										{isUserLoggedIn &&
											<NavItem className="navigation__item">
												<NavLink
													exact
													to={frontendUrls.urlLeagues}
													activeClassName={selectedElementClassName}
													onClick={handleCloseMenu}
												>
													{t("Leagues")}
												</NavLink>
											</NavItem>
										}
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={frontendUrls.urlScheduleAndResults}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>
												{t("Schedule")}
											</NavLink>
										</NavItem>
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={frontendUrls.urlNews + "?page=1"}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>
												{t("News")}
											</NavLink>
										</NavItem>
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={frontendUrls.urlPrizes}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>{t("Prizes")}</NavLink>
										</NavItem>
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={frontendUrls.urlRulesAndFaq}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>{t("Rules & FAQ")}</NavLink>
										</NavItem>
										<NavItem className="navigation__item">
											<NavLink
												exact
												to={frontendUrls.urlContactUs}
												activeClassName={selectedElementClassName}
												onClick={handleCloseMenu}
											>{t("Contact Us")}</NavLink>
										</NavItem>
									</Nav>
								</Navbar>
							}
							{!isMobileResolution && !isUserLoggedIn && <LanguageSelect />}
							{!isUserLoggedIn && !isEmailConfirmPage &&
								<div className="header-sign-in-buttons">
									<MainButton
										text={t("Sign In")}
										className="button button_medium outline mobile-bordered double sign-in-btn"
										color="white"
										handler={() => { setShowMenu(false); actionSwitchLoginModalOpenState(); }}
									/>
									<Link to={frontendUrls.urlSelectTeam} tabIndex="-1">
										<MainButton text={t("Sign Up")} className="button_inverse-sm mobile-bordered double"
											handler={() => setShowMenu(false)}
										/>
									</Link>
								</div>
							}
						</div>
					}
				</div>
			</Container>
		</header>
	);
};

Header.propTypes = {
	isUserLoggedIn: propTypes.bool.isRequired,
	actionSwitchLoginModalOpenState: propTypes.func.isRequired,
	scrollPosition: propTypes.number,
};

Header.defaultProps = {
	scrollPosition: 0,
};

const mapStateToProps = state => ({
	isUserLoggedIn: state.authReducer.isUserLoggedIn,
});

export default connect(
	mapStateToProps,
	{ actionSwitchLoginModalOpenState }
)(Header);

Header.displayName = "head";