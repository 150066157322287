import * as types from "redux/constants/userTeams";
import { USER_LOGOUT } from "redux/constants/auth";
import { POST_REQUEST_RANDOM_TEAM_ERROR } from "redux/constants/footballField";

import createReducer from "redux/createReducer";

export const userTeamInitialState = {
	userTeamsData: [],
	userTeamsEditCreateRequestErrors: [],
	userTeamsRequestLoading: false,
	createUserTeamLoading: false,
	userTeamPlayersRequestLoading: false,
	setIsWildcardRoundRequestLoading: false,
	setIsWildcardRoundRequestErrors: {},
	isUserTeamPlayedRequestDone: false,
	checkUserTeamRequestLoading: false,
	isUserTeamExist: false,
	isUserTeamExistStoreUpdateTime: 0,
	userTeamUpdateDataTime: 0,
	userTeamsForFinishedSeasonsData: [],
	userTeamsForFinishedSeasonsDataStoreTimeUpdate: 0,
};

const userTeamReducer = createReducer(userTeamInitialState)({
	[types.POST_REQUEST_USER_TEAM_START]: state => ({
		...state,
		userTeamsEditCreateRequestErrors: [],
		createUserTeamLoading: true,
	}),
	[types.POST_REQUEST_USER_TEAM_SUCCESS]: (state) => ({
		...state,
		createUserTeamLoading: false,
	}),
	[types.POST_REQUEST_USER_TEAM_ERROR]: (state, { payload }) => ({
		...state,
		userTeamsEditCreateRequestErrors: [...new Set([...state.userTeamsEditCreateRequestErrors, ...payload.parsedTextErrorsWithoutKeys])],
		createUserTeamLoading: false,
	}),
	[types.GET_REQUEST_USER_TEAMS_REQUEST_START]: state => ({
		...state,
		userTeamsRequestLoading: true,
	}),
	[types.GET_REQUEST_USER_TEAMS_REQUEST_SUCCESS]: (state, { payload }) => {
		const { data, isForFinishedSeasons } = payload;
		return {
			...state,
			userTeamsData: isForFinishedSeasons ? state.userTeamsData : data,
			userTeamsDataStoreTimeUpdate: isForFinishedSeasons ? state.userTeamsDataStoreTimeUpdate : new Date().getDate(),
			userTeamsForFinishedSeasonsData: isForFinishedSeasons ? data : state.userTeamsForFinishedSeasonsData,
			userTeamsForFinishedSeasonsDataStoreTimeUpdate: !isForFinishedSeasons ? state.userTeamsDataStoreTimeUpdate : new Date().getDate(),
			userTeamsRequestLoading: false,
			userTeamUpdateDataTime: new Date().getTime(),
		};
	},
	[types.GET_REQUEST_USER_TEAMS_REQUEST_ERROR]: (state, { payload }) => ({
		...state,
		userTeamsEditCreateRequestErrors: [...new Set([...state.userTeamsEditCreateRequestErrors, ...payload.parsedTextErrorsWithoutKeys])],
		userTeamsRequestLoading: false,
	}),

	[types.PATCH_USER_TEAM_NAME_SUCCESS]: (state, { payload }) => {
		const { name } = payload;

		const newUserTeamsData = state.userTeamsData;
		newUserTeamsData[0].name = name;
		return {
			...state,
			userTeamsData: newUserTeamsData,
		};
	},

	[types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START]: state => ({
		...state,
		userTeamPlayersRequestLoading: true,
		userTeamsEditCreateRequestErrors: []
	}),
	[types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_SUCCESS]: (state) => {
		return {
			...state,
			userTeamPlayersRequestLoading: false,
			userTeamUpdateDataTime: new Date().getTime(),
		};
	},
	[types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_ERROR]: (state, { payload }) => ({
		...state,
		userTeamsEditCreateRequestErrors: [...new Set([...state.userTeamsEditCreateRequestErrors, ...payload.parsedTextErrorsWithoutKeys])],

		userTeamPlayersRequestLoading: false,
	}),
	[types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_START]: (state) => {
		return {
			...state,
			checkUserTeamRequestLoading: true,
		};
	},
	[types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_SUCCESS]: (state) => {
		return {
			...state,
			checkUserTeamRequestLoading: false,
			isUserTeamExist: true,
			isUserTeamExistStoreUpdateTime: new Date().getTime(),
		};
	},
	[types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_ERROR]: (state) => {
		return {
			...state,
			checkUserTeamRequestLoading: false,
			isUserTeamExist: false,
			isUserTeamExistStoreUpdateTime: new Date().getTime(),
		};
	},
	[types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_START]: (state) => {
		return {
			...state,
			isUserTeamPlayedRequestDone: false,
		};
	},
	[types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_SUCCESS]: (state) => ({
		...state,
		isUserTeamPlayedRequestDone: true,
	}),
	[types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_ERROR]: (state) => ({
		...state,
		isUserTeamPlayedRequestDone: true,
	}),
	[POST_REQUEST_RANDOM_TEAM_ERROR]: (state, { payload }) => ({
		...state,
		userTeamsEditCreateRequestErrors: [...new Set([...state.userTeamsEditCreateRequestErrors, ...payload.parsedTextErrorsWithoutKeys])],

	}),
	[types.CLEAN_USER_TEAM_ERROR]: (state, { payload }) => {
		const newErrors = payload ?
			state.userTeamsEditCreateRequestErrors.filter(error => error !== payload)
			: [];
		return {
			...state,
			userTeamsEditCreateRequestErrors: newErrors
		};
	},
	[types.PATCH_IS_WILDCARD_ROUND_START]: (state) => {
		return {
			...state,
			setIsWildcardRoundRequestLoading: true,
		};
	},
	[types.PATCH_IS_WILDCARD_ROUND_SUCCESS]: (state, { payload }) => {
		const { wildcard_used, has_unlimited_transfers, has_unlimited_transfers_reason } = payload;
		const newUserTeamsData = [...state.userTeamsData];
		if (newUserTeamsData?.[0]) {
			newUserTeamsData[0].has_unlimited_transfers = has_unlimited_transfers;
			newUserTeamsData[0].wildcard_used = wildcard_used;
			newUserTeamsData[0].has_unlimited_transfers_reason = has_unlimited_transfers_reason;
		}
		return {
			...state,
			setIsWildcardRoundRequestLoading: false,
			userTeamsData: newUserTeamsData,
		};
	},
	[types.PATCH_IS_WILDCARD_ROUND_ERROR]: (state, { payload }) => ({
		...state,
		setIsWildcardRoundRequestLoading: false,
		setIsWildcardRoundRequestErrors: payload,
	}),
	[types.PATCH_IS_WILDCARD_ROUND_CLEAN_ERROR]: (state) => ({
		...state,
		setIsWildcardRoundRequestErrors: {},
	}),
	[USER_LOGOUT]: () => ({
		...userTeamInitialState
	})
});

export default userTeamReducer;