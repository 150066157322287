export const GET_REQUEST_ROUND_GAMES_START = "GET_REQUEST_ROUND_GAMES_START";
export const GET_REQUEST_ROUND_GAMES_SUCCESS = "GET_REQUEST_ROUND_GAMES_SUCCESS";
export const GET_REQUEST_ROUND_GAMES_ERROR = "GET_REQUEST_ROUND_GAMES_ERROR";

export const GET_ROUND_GAME_ITEM_START = "GET_ROUND_GAME_ITEM_START";
export const GET_ROUND_GAME_ITEM_SUCCESS = "GET_ROUND_GAME_ITEM_SUCCESS";
export const GET_ROUND_GAME_ITEM_ERROR = "GET_ROUND_GAME_ITEM_ERROR";

export const CLEAR_ROUND_GAMES_HASH_LIST = "CLEAR_ROUND_GAMES_HASH_LIST";

export const GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_START = "GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_START";
export const GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_SUCCESS = "GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_SUCCESS";
export const GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_ERROR = "GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_ERROR ";

export const GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_START = "GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_START";
export const GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_SUCCESS = "GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_SUCCESS";
export const GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_ERROR = "GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_ERROR";