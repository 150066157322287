import axios from "axios";
import * as Sentry from "@sentry/react";

import backendApiUrls from "routes/backendUrls";

import store from "redux/store";
import { actionUserLogout } from "redux/actions/auth";


export default function tokenRefreshing(withoutPromiseReject) {
	return new Promise((resolve, reject) => {
		if (localStorage.getItem("refreshToken")){
			const data = {
				method: "POST",
				url: backendApiUrls.refresh,
				data: { refresh: localStorage.getItem("refreshToken") }
			};
			console.time("refresh time");
			const startTime = performance.now();
			console.warn("refresh start time ", startTime);

			axios(data).then((response => {
				const { data: { access } = {} } = response;
				access && localStorage.setItem("accessToken", access);
				axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
				resolve(access);
				console.timeEnd("refresh time");
			})).catch(error => {
				console.log("Something went wrong with refresh");
				console.timeEnd("refresh time");
				const endTime = performance.now();
				console.warn("refresh end time ", endTime);

				console.warn(`Something went wrong with refresh: localStorage refresh ${localStorage.getItem("refreshToken")}`);
				console.warn(`Something went wrong with refresh: localStorage ${localStorage.getItem("accessToken")}`);
				console.warn(`Something went wrong with refresh: error ${JSON.stringify(error)}`);
				console.warn(`Something went wrong with refresh: error keys ${JSON.stringify(Object.keys(error || {}))}`);
				console.warn(`Something went wrong with refresh: error.message ${JSON.stringify(error?.message)}`);
				console.warn(`Something went wrong with refresh: error.response ${JSON.stringify(error?.response)}`);
				console.warn(`Something went wrong with refresh: error.response.data ${JSON.stringify(error?.response?.data)}`);
				console.warn(`Something went wrong with refresh: error.response.status ${JSON.stringify(error?.response?.status)}`);
				console.warn(`Something went wrong with refresh: header ${JSON.stringify(axios?.defaults?.headers?.common)}`);
				Sentry.captureMessage("Something went wrong with refresh token");
				store.dispatch(actionUserLogout());
				!withoutPromiseReject && reject(error);
			});
		}
	});
}