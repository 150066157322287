import React, { useState } from "react";
import propTypes from "prop-types";

const MainButton = (props) => {
	const { text, handler, size, color, className, children, disabled } = props;
	const handleClick = (e) => { if (e) {e.preventDefault();} };
	const [isMobileClicked, setMobileClicked] = useState(false);
	return (
		<button
			onTouchStart={() => setMobileClicked(true)}
			onTouchEnd={() => setTimeout(() => {setMobileClicked(false);}, 200)}
			onMouseLeave={() => setMobileClicked(false)}
			className={`button button_${size} 
			button_${color} ${className} ${disabled && "button_disabled"} ${isMobileClicked && "activeBtn"}`}
			onClick={handler ? handler : () => {}}
			onMouseDown={handleClick}
			onKeyUp={(e) => {if (e.kode === 13 || e.kode === 32) {handleClick();}}}
			type="button"
		>
			<div>
				{text ? text: null}
				{children ? children: null}
			</div>
		</button>
	);
};
MainButton.propTypes = {
	disabled: propTypes.bool,
	text: propTypes.string,
	children: propTypes.node,
	handler: propTypes.func,
	className: propTypes.string,
	size: propTypes.oneOfType([
		propTypes.oneOf(["small", "medium"]),
		propTypes.string,
	]),
	color: propTypes.oneOfType([
		propTypes.oneOf(["blue", "white", "red"]),
		propTypes.string,
	]),
};

MainButton.defaultProps = {
	text: "",
	className: "",
	size: "small",
	color: "blue",
	disabled: false,
};

export default MainButton;

MainButton.displayName = "mb";