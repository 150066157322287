import { all, put, takeEvery, fork, call } from "redux-saga/effects";

import backendApiUrls from "routes/backendUrls";
import frontendUrls from "routes/frontendUrls";

import {
	POST_REQUEST_USER_TEAM_START,
	GET_REQUEST_USER_TEAMS_REQUEST_START,
	CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START } from "redux/constants/userTeams";
import {
	GET_REQUEST_USER_ROUND_TEAMS_START } from "redux/constants/userRoundTeams";
import { GET_REQUEST_USER_TRANSFERS_START } from "redux/constants/userTransfers";

import {
	getUserRoundTeamsRequestSuccessAction,
	getUserRoundTeamsRequestErrorAction,
} from "redux/actions/userRoundTeams";
import {
	createEditUserTeamRequestErrorAction,
	getUserTeamsRequestSuccessAction,
	getUserTeamsRequestErrorAction,
	createEditUserTeamPlayersRequestSuccessAction,
	createEditUserTeamPlayersRequestErrorAction } from "redux/actions/userTeams";
import {
	actionGetUserTransfersRequestErrorAction,
	actionGetUserTransfersRequestSuccessAction
} from "redux/actions/userTransfers";
import store from "redux/store";
import { createUserError, createUserSuccess } from "redux/actions/register";
import { actionPostRequestLogin } from "redux/actions/auth";

import {
	TEAM_NAME_IS_REQUIRED,
	TEAM_NAME_ONLY_LATIN_OR_DIGITS,
	TEAM_NAME_BAD_WORDS
} from "helpers/constants/errors";
import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";
import history from "helpers/history";

const Filter = require("bad-words");
const filter = new Filter();

function createRequest({ method, url, data, params }) {
	return axiosPlus({ method, url, data, params });
}

function* getUserTransfersRequest({ payload }) {
	try {
		const userTransfers = yield call(createRequest, { method: "GET", url: backendApiUrls.userTransfers, params: { football_round: payload } });
		yield put(actionGetUserTransfersRequestSuccessAction(userTransfers.data.count));
	} catch (error) {
		yield put(
			actionGetUserTransfersRequestErrorAction(errorsParser(error).parsedTextErrors)
		);
	}
}

function* getUserRoundTeamsRequest({ payload }) {
	try {
		const state = store.getState();
		const { authReducer: { userDetails: { id: userFromState } } = {} } = state || {};
		const { user: userFromPage } = payload;
		const requestedUser = userFromPage || userFromState;
		const userRoundTeams = yield call(createRequest, { method: "GET", url: backendApiUrls.roundUserTeams, params: { ...payload, user: requestedUser } });
		const newTeam = userRoundTeams.data.results[0] || { isEmpty: true };
		yield put(getUserRoundTeamsRequestSuccessAction({ data: newTeam, ...payload }));
	} catch (error) {
		yield put(
			getUserRoundTeamsRequestErrorAction(errorsParser(error).parsedTextErrors)
		);
	}
}

function* createEditTeamRequest({ payload }) {
	const { data = {} } = payload;
	const { teamName, seasonID, teamID } = data;
	const method = teamID ? "PATCH" : "POST";
	const errors = [];
	if (!teamName && !teamID) {
		errors.push(TEAM_NAME_IS_REQUIRED);
	} else {
		if (!/^[\w\s]+$/.test(teamName)) {
			errors.push(TEAM_NAME_ONLY_LATIN_OR_DIGITS);
		}
		if (filter.isProfane(teamName)) {
			errors.push(TEAM_NAME_BAD_WORDS);
		}
	}
	if (errors.length) {
		yield put(createEditUserTeamRequestErrorAction(errors));
	} else {
		try {
			const parsedData = { name: teamName, season: seasonID, abbreviation: teamName };
			yield call(createRequest, { method, url: backendApiUrls.userTeams, data: parsedData });
			const updatedTeam = yield call(createRequest, { method: "GET", url: backendApiUrls.userTeams });
			yield put(getUserTeamsRequestSuccessAction({ data: updatedTeam.data.results }));
		} catch (error) {
			yield put(
				getUserTeamsRequestErrorAction(errorsParser(error).parsedTextErrors)
			);
		}
	}
}

function* getUserTeamsRequest({ payload }) {
	const  { isForFinishedSeasons } = payload || {};
	try {
		const userTeams = yield call(createRequest, { method: "GET", url: backendApiUrls.userTeams, params: { season_status: isForFinishedSeasons ? "finished" : "live" } });
		yield put(getUserTeamsRequestSuccessAction({ data: userTeams.data.results, isForFinishedSeasons }));
	} catch (error) {
		yield put(
			getUserTeamsRequestErrorAction(errorsParser(error).parsedTextErrors)
		);
	}
}

function* createEditUserTeamPlayersRequest( { payload } ) {
	const {
		currentTeam = {},
		newTeam,
		newTeamName,
		newFormationID,
		newCaptainID,
		newViceCaptainID,
		currentUserTeamFootballPlayersHashList,
		transferedPlayers,
		nextRoundID,
		isRegister,
		formData,
		isForNewSeason,
		onlyTransfers,
		errorCallback = () => {},
		successCallback = () => {},
	} = payload;
	let updatedUserTeam = { ...currentTeam };

	let newUserTeamFootballPlayersHashList = { ...currentUserTeamFootballPlayersHashList };



	try {
		//Register flow
		if (isRegister) {
			const parsedData = { formation: newFormationID };

			parsedData.user = { ...formData };

			const { forwards, midfielders, defenders, goalkeepers, bench } = newTeam;
			const payloadBenchPlayers = [];
			const payloadFieldPlayers = [];


			//Step 3: for field players we collect football_player_id and record player position (1-15)
			[...forwards, ...midfielders, ...defenders, ...goalkeepers].forEach(player => {
				const newPlayer = { football_player_id: player.id, position_index: payloadFieldPlayers.length + 1 };
				payloadFieldPlayers.push(newPlayer);
			});

			//Step 4: for bench we need to do the same, but use priority field for bench players (and also keep position index, 12-15)
			bench.forEach((player, index) => {
				const newPlayer = { football_player_id: player.id, priority: index, position_index: payloadFieldPlayers.length + 1 };
				payloadFieldPlayers.push(newPlayer);
				payloadBenchPlayers.push(newPlayer);
			});

			parsedData.football_players = payloadFieldPlayers;
			parsedData.bench_players = payloadBenchPlayers;
			parsedData.captain = newCaptainID;
			parsedData.vice_captain = newViceCaptainID;
			parsedData.user_team = {
				name: newTeamName,
				formation: newFormationID,
			};

			const userData = yield call(createRequest, { method: "POST", url: backendApiUrls.userTeams, data: parsedData });

			yield put(createUserSuccess(userData.data));
			yield put(actionPostRequestLogin({ ...formData }));
			history.push(frontendUrls.urlConfirmEmail);
			const updatedTeam = yield call(createRequest, { method: "GET", url: backendApiUrls.userTeams });
			updatedUserTeam = updatedTeam.data.results[0];
			yield put(getUserTeamsRequestSuccessAction({ data: updatedTeam.data.results }));
		} else if (isForNewSeason) {
			//Update team for new season flow
			const parsedData = { formation: newFormationID };

			parsedData.user = { ...formData };

			const { forwards, midfielders, defenders, goalkeepers, bench } = newTeam;
			const payloadBenchPlayers = [];
			const payloadFieldPlayers = [];


			//Step 3: for field players we collect football_player_id and record player position (1-15)
			[...forwards, ...midfielders, ...defenders, ...goalkeepers].forEach(player => {
				const newPlayer = { football_player_id: player.id, position_index: payloadFieldPlayers.length + 1 };
				payloadFieldPlayers.push(newPlayer);
			});

			//Step 4: for bench we need to do the same, but use priority field for bench players (and also keep position index, 12-15)
			bench.forEach((player, index) => {
				const newPlayer = { football_player_id: player.id, priority: index, position_index: payloadFieldPlayers.length + 1 };
				payloadFieldPlayers.push(newPlayer);
				payloadBenchPlayers.push(newPlayer);
			});

			parsedData.football_players = payloadFieldPlayers;
			parsedData.bench_players = payloadBenchPlayers;
			parsedData.captain = newCaptainID;
			parsedData.vice_captain = newViceCaptainID;
			parsedData.user_team = {
				name: newTeamName,
				formation: newFormationID,
			};

			yield call(createRequest, { method: "POST", url: backendApiUrls.userTeamsNewSeason, data: parsedData });
			history.push(frontendUrls.urlMain);
			const updatedTeam = yield call(createRequest, { method: "GET", url: backendApiUrls.userTeams });
			updatedUserTeam = updatedTeam.data.results[0];
			yield put(getUserTeamsRequestSuccessAction({ data: updatedTeam.data.results }));
			yield put(createEditUserTeamPlayersRequestSuccessAction());
		} else {
			//Update team for current season flow
			if (transferedPlayers && transferedPlayers.length) {
				updatedUserTeam.football_players.forEach(userPlayer => {
					newUserTeamFootballPlayersHashList[userPlayer.football_player.id] = userPlayer;
				});
				//Step 1: processing transfers & update team after finish
				const transfersParsedData = transferedPlayers.map(transfer => ({
					football_player_added: transfer.transferedIn.id,
					user_football_player_deleted: newUserTeamFootballPlayersHashList[transfer.transferedOut.id].id
				}));
				yield call(createRequest, {
					method: "POST",
					url: backendApiUrls.userTransfers,
					data: { transfers: transfersParsedData } });
				const userTransfers = yield call(createRequest, { method: "GET", url: backendApiUrls.userTransfers, params: { football_round: nextRoundID } });
				yield put(actionGetUserTransfersRequestSuccessAction(userTransfers.data.count));
			}


			const { forwards, midfielders, defenders, goalkeepers, bench } = newTeam;

			const allPlayers = updatedUserTeam.football_players;

			const newBenchIDs = [];
			const payloadBenchPlayers = [];
			const payloadFieldPlayers = [];


			//Step 3: for field players we collect user_football_player_id from already saved user team players
			[...forwards, ...midfielders, ...defenders, ...goalkeepers].forEach(player => {
				const userPlayer = allPlayers.find(currentPlayer => currentPlayer.football_player.id == player.id);
				const userPlayerID = userPlayer && userPlayer.id;
				const newPlayer = { user_football_player_id: userPlayerID, position_index: payloadFieldPlayers.length + 1, football_player: player.id, user_team: updatedUserTeam.id, position: player.position, transfered: player.transferedIn };
				payloadFieldPlayers.push(newPlayer);
			});

			//Step 4: for bench we need to determine 2 cases: existed bench player changed priority, OR new player was'nt in bench.
			// For different cases we need to use different requests and data structure
			bench.forEach((player, index) => {
				const position = index ? player.position : "goalkeeper";
				const userPlayer = allPlayers.find(currentPlayer => currentPlayer.football_player.id == player.id);
				const userPlayerID = userPlayer && userPlayer.id;

				const newPlayer = { priority: index, user_football_player_id: userPlayerID, position_index: payloadFieldPlayers.length + index + 1, football_player: player.id, user_team: updatedUserTeam.id, position, transfered: player.transferedIn };

				payloadBenchPlayers.push(newPlayer);
				newBenchIDs.push(player.id);
			});


			if (!onlyTransfers) {
				const captainUserPlayerID = updatedUserTeam.football_players.find(player => player.football_player.id === newCaptainID).id;
				const viceCaptainUserPlayerID = updatedUserTeam.football_players.find(player => player.football_player.id === newViceCaptainID).id;
				const updateTeamData = {
					captain: captainUserPlayerID,
					vice_captain: viceCaptainUserPlayerID,
					user_team: { formation_id: newFormationID },
					user_football_players: [...payloadFieldPlayers, ...payloadBenchPlayers],
					user_team_bench: payloadBenchPlayers
				};
				yield call(createRequest, { method: "PUT", url: backendApiUrls.updateLiveTeam, data: updateTeamData });
			}

			const userTeamsDataResponse = yield call(createRequest, { method: "GET", url: backendApiUrls.userTeams });
			updatedUserTeam = userTeamsDataResponse.data.results[0];
			yield put(getUserTeamsRequestSuccessAction({ data: userTeamsDataResponse.data.results }));
			yield put(createEditUserTeamPlayersRequestSuccessAction());
			successCallback();
		}
	} catch (error) {
		//Remove long url (images urls) and recursive/unimportant fields for send brief information to Sentru
		const errorTeam = { ...currentTeam };
		const benchPlayersParsed = [];
		const footballPlayersParsed = [];
		const transfersParsedData = transferedPlayers?.map(transfer => ({
			football_player_added: transfer.transferedIn.id,
			user_football_player_deleted: newUserTeamFootballPlayersHashList[transfer.transferedOut.id].id
		}));

		currentTeam.football_players && currentTeam.football_players.forEach(userPlayer => {
			const { id: userPlayerID, football_player: { id: footballPlayerID, full_name: footballPlayerName } = {} } = userPlayer || {};
			footballPlayersParsed.push({ userPlayerID, footballPlayerID, footballPlayerName });
		});

		currentTeam.bench_players && currentTeam.bench_players.forEach(benchPlayer => {
			const { priority, id: benchPlayerID, user_football_player: { position_index, football_player: { id: footballPlayerID, full_name: footballPlayerName } = {}, id: userFootballPlayerID } = {} } = benchPlayer || {};
			benchPlayersParsed.push({ priority, benchPlayerID, footballPlayerID, userFootballPlayerID, footballPlayerName, position_index,  });
		});

		errorTeam.football_players = footballPlayersParsed;
		errorTeam.bench_players = benchPlayersParsed;

		const { id: userPlayerID,
			position,
			position_index,football_player:{ full_name: footballPlayerName, id: footballPlayerID } = {} } = currentTeam.captain || {};

		const { id: viceCaptainUserPlayerID,
			viceCaptainPosition,
			vice_captain_position_index,football_player:{ full_name: viceCaptainFootballPlayerName, id: viceCaptainFootballPlayerID } = {} } = currentTeam.vice_captain || {};

		errorTeam.captain = { userPlayerID, position, position_index, footballPlayerName, footballPlayerID };
		errorTeam.vice_captain = { userPlayerID: viceCaptainUserPlayerID, position: viceCaptainPosition, position_index: vice_captain_position_index, footballPlayerName: viceCaptainFootballPlayerName, footballPlayerID: viceCaptainFootballPlayerID };

		console.error("CREATE/EDIT TEAM ERROR: ", JSON.stringify(error.response.data));
		console.error("errorTeam: ", JSON.stringify(errorTeam));
		console.error("transferedPlayers: ", JSON.stringify(transfersParsedData));

		if (errorCallback) {
			errorCallback();
		}
		const userErrors = error.response.data.user;
		if (userErrors) {
			delete error.response.data.user;
		}
		yield put(
			createEditUserTeamPlayersRequestErrorAction(errorsParser(error))
		);
		if (isRegister) {
			yield put(createUserError(userErrors));
		}
	}
}


export function* sagaCreateEditTeamRequest() {
	yield takeEvery(POST_REQUEST_USER_TEAM_START, createEditTeamRequest);
}

export function* sagaGetUserTeamsRequest() {
	yield takeEvery(GET_REQUEST_USER_TEAMS_REQUEST_START, getUserTeamsRequest);
}

export function* sagaCreateEditUserTeamPlayersRequest() {
	yield takeEvery(CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START, createEditUserTeamPlayersRequest);
}

export function* sagaGetUserRoundTeamsRequest() {
	yield takeEvery(GET_REQUEST_USER_ROUND_TEAMS_START, getUserRoundTeamsRequest);
}

export function* sagaGetUserTransfersRequest() {
	yield takeEvery(GET_REQUEST_USER_TRANSFERS_START, getUserTransfersRequest);
}

export default function* userTeamSaga() {
	yield all([fork(sagaCreateEditTeamRequest)]);
	yield all([fork(sagaGetUserTeamsRequest)]);
	yield all([fork(sagaCreateEditUserTeamPlayersRequest)]);
	yield all([fork(sagaGetUserRoundTeamsRequest)]);
	yield all([fork(sagaGetUserTransfersRequest)]);
}
