import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
	setLeavePrivateLeagueModalState,
	actionRequestLeavePrivateLeague,
} from "redux/actions/leaguesPrivate";

import ModalWindow from "modules/common/ModalWindow";
import SpinnerButton from "modules/common/SpinnerButton";

const PrivateLeagueSettingsModal = ({
	setLeavePrivateLeagueModalState,
	actionRequestLeavePrivateLeague,
	leagueData,
	isLoading,
}) => {
	const { t } = useTranslation("private_league_settings_modal");
	return (
		<ModalWindow contentClassName="private-league-settings-modal" isOpen={leagueData} toggle={() => {
			setLeavePrivateLeagueModalState(null);
		}}>
			<div className="fantasy-modal-title">{t("Confirm league exit")}</div>
			<div className="fantasy-modal-subtitle">
				{t("Your team will be permanently removed from this Private League. Are you sure ?")}
			</div>
			<SpinnerButton
				text={t("confirm")}
				onClick={() => {
					actionRequestLeavePrivateLeague({
						leagueID: leagueData.id
					});
				}}
				className="col-4"
				size="medium"
				isLoading={isLoading}
			/>
		</ModalWindow>
	);
};

const mapStateToProps = state => {
	return {
		leagueData: state?.leaguesReducer?.leavePrivateLeagueModalState,
		isLoading: state?.leaguesReducer?.removePrivateLeagueLoading,
	};
};

export default connect(
	mapStateToProps,
	{
		setLeavePrivateLeagueModalState,
		actionRequestLeavePrivateLeague,
	},
)(PrivateLeagueSettingsModal);


PrivateLeagueSettingsModal.propTypes = {
	setLeavePrivateLeagueModalState: PropTypes.func.isRequired,
	actionRequestLeavePrivateLeague: PropTypes.func.isRequired,
	leagueData: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

PrivateLeagueSettingsModal.displayName = "plsm";