import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import frontendUrls from "routes/frontendUrls";

import { actionSwitchLoginModalOpenState, actionUserLogout } from "redux/actions/auth";

import MainButton from "modules/common/MainButton";

const SignInUpButtons = ({ actionSwitchLoginModalOpenState, isUserLoggedIn, actionUserLogout }) => {
	const { t } = useTranslation("sign_in_up_buttons");
	return (
		<div className="sign-in-up-buttons">
			{isUserLoggedIn && <MainButton
				text={t("Logout")}
				size="medium"
				color="white"
				className="mr-2"
				handler={actionUserLogout} />}
			{!isUserLoggedIn &&
				<div>
					<MainButton
						text={t("Sign In")}
						size="medium"
						color="white"
						handler={actionSwitchLoginModalOpenState} />
					<Link to={frontendUrls.urlSelectTeam} tabIndex="-1">
						<MainButton
							text={t("Sign Up")}
							size="medium"
						/>
					</Link>
				</div>
			}
		</div>
	);
};

SignInUpButtons.propTypes = {
	actionSwitchLoginModalOpenState: PropTypes.func.isRequired,
	actionUserLogout: PropTypes.func.isRequired,
	isUserLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ authReducer }) => {
	const { isUserLoggedIn } = authReducer;
	return {
		isUserLoggedIn
	};
};

export default connect(
	mapStateToProps,
	{ actionSwitchLoginModalOpenState, actionUserLogout }
)(SignInUpButtons);

SignInUpButtons.displayName = "siub";