import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/userTeams";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

import frontendUrls from "../../routes/frontendUrls";
import history from "../../helpers/history";

export const createEditUserTeamRequestErrorAction = payload => ({
	type: types.POST_REQUEST_USER_TEAM_ERROR,
	payload,
});

export const actionGetRequestUserTeams = payload => ({
	type: types.GET_REQUEST_USER_TEAMS_REQUEST_START,
	payload,
});

export const getUserTeamsRequestSuccessAction = payload => ({
	type: types.GET_REQUEST_USER_TEAMS_REQUEST_SUCCESS,
	payload,
});

export const getUserTeamsRequestErrorAction = payload => ({
	type: types.GET_REQUEST_USER_TEAMS_REQUEST_ERROR,
	payload,
});

export const createEditUserTeamPlayersRequestStartAction = payload => ({
	type: types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_START,
	payload,
});

export const createEditUserTeamPlayersRequestSuccessAction = payload => ({
	type: types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_SUCCESS,
	payload,
});

export const createEditUserTeamPlayersRequestErrorAction = payload => ({
	type: types.CREATE_EDIT_USER_TEAM_PLAYERS_REQUEST_ERROR,
	payload,
});

export const actionCleanUserTeamError = payload => ({
	type: types.CLEAN_USER_TEAM_ERROR,
	payload,
});

const checkUserTeamStart = payload => ({
	type: types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_START,
	payload,
});

const checkUserTeamSuccess = payload => ({
	type: types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_SUCCESS,
	payload,
});

const checkUserTeamError = payload => ({
	type: types.HEAD_REQUEST_CHECK_LIVE_SEASON_USER_TEAM_ERROR,
	payload,
});

export const headCheckUserTeamPlayedThisSeasonRequestStartAction = payload => ({
	type: types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_START,
	payload,
});

export const headCheckUserTeamPlayedThisSeasonRequestSuccessAction = payload => ({
	type: types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_SUCCESS,
	payload,
});

export const headCheckUserTeamPlayedThisSeasonRequestErrorAction = payload => ({
	type: types.HEAD_REQUEST_CHECK_USER_TEAM_PLAYED_THIS_SEASON_ERROR,
	payload,
});

export const actionHeadCheckLiveSeasonUserTeamRequest = (redirectUrl) => dispatch => {
	dispatch(checkUserTeamStart());
	const config = {
		method: "HEAD",
		url: backendApiUrls.userTeamsCheckUserTeam,
	};

	axiosPlus(config)
		.then(() => {
			dispatch(checkUserTeamSuccess());
			redirectUrl && history.push(redirectUrl);
		})
		.catch(() => {
			dispatch(checkUserTeamError());
			!window.location.pathname.includes(frontendUrls.urlNextSeasonTeam) && history.push(frontendUrls.urlNextSeasonTeam);
		});
};


export const actionHeadCheckUserTeamPlayedThisSeasonRequest = () => dispatch => {
	dispatch(headCheckUserTeamPlayedThisSeasonRequestStartAction());
	const config = {
		method: "HEAD",
		url: backendApiUrls.userTeamsCheckUserTeamPlayedThisSeason,
	};

	axiosPlus(config)
		.then(() => {
			dispatch(headCheckUserTeamPlayedThisSeasonRequestSuccessAction());
		})

		.catch(() => {
			dispatch(headCheckUserTeamPlayedThisSeasonRequestErrorAction());
		});
};

const actionSetIsWildcardRoundStart = () => ({
	type: types.PATCH_IS_WILDCARD_ROUND_START,
});

const actionSetIsWildcardRoundSuccess = (payload) => ({
	type: types.PATCH_IS_WILDCARD_ROUND_SUCCESS,
	payload,
});

const actionSetIsWildcardRoundError = payload => ({
	type: types.PATCH_IS_WILDCARD_ROUND_ERROR,
	payload,
});

export const actionSetIsWildcardRoundCleanError = () => ({
	type: types.PATCH_IS_WILDCARD_ROUND_ERROR,
});

export const actionSetIsWildcardRoundRequest = (callback) => dispatch => {
	dispatch(actionSetIsWildcardRoundStart());
	const config = {
		method: "PATCH",
		url: backendApiUrls.userTeamsUseWildcardForRound,
	};

	axiosPlus(config)
		.then((response) => {
			const { data } = response;
			dispatch(actionSetIsWildcardRoundSuccess(data));
			callback();
		})
		.catch((error) => {
			dispatch(actionSetIsWildcardRoundError(errorsParser(error)));
		});
};

const actionPatchUserTeamNameStart = () => ({
	type: types.PATCH_USER_TEAM_NAME_START,
});

const actionPatchUserTeamNameSuccess = (payload) => ({
	type: types.PATCH_USER_TEAM_NAME_SUCCESS,
	payload,
});

const actionPatchUserTeamNameError = payload => ({
	type: types.PATCH_USER_TEAM_NAME_ERROR,
	payload,
});

export const actionPatchUserTeamName = (teamName) => dispatch => {
	dispatch(actionPatchUserTeamNameStart());
	const config = {
		method: "PATCH",
		url: backendApiUrls.userTeamName,
		data: {
			name: teamName,
		}
	};

	return axiosPlus(config)
		.then((response) => {
			const { data } = response;
			dispatch(actionPatchUserTeamNameSuccess(data));
		})
		.catch((error) => {
			dispatch(actionPatchUserTeamNameError(errorsParser(error)));
		});
};