import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Container,
	Row,
	Col,
} from "reactstrap";
import classNames from "classnames";
import dropdownIcon from "static/assets/images/dropdown-closed.svg";
import moment from "moment";

import { roundGamePropType } from "utils/propTypes";

import GameItem from "./GameItem";

const RoundBoard = ({
	matchDateAndDayOfWeek,
	games,
	modifier,
}) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [isOpenDropdown, setIsOpenDropdown] = useState(true);
	const toggleDropdown = () => setIsOpenDropdown(!isOpenDropdown);
	const handleClick = () => {
		if (width < 768) {
			return toggleDropdown();
		}
	};
	const updateWidthAndHeight = () => {
		setWidth(window.innerWidth);
	};
	games.sort((a, b) => moment(a.match_datetime).diff(moment(b.match_datetime)));
	games.sort((a, b) => {
		if (a.match_datetime === b.match_datetime) {
			if (a.team_1.full_name < b.team_1.full_name) {
				return -1;
			}
			if (a.team_1.full_name > b.team_1.full_name) {
				return 1;
			}
		}	
	});

	useEffect(() => {
		window.addEventListener("resize", updateWidthAndHeight);
		return () => window.removeEventListener("resize", updateWidthAndHeight);
	});

	const roundBoardClass = classNames(
		"round-board",
		{
			"round-board_border": modifier !== "gray",
			"round-board_mt-blue": modifier !== "gray",
			"round-board_mt-gray": modifier === "gray",
		}
	);
	const headerClass = classNames(
		"round-board__header", "round-board__header_hover",
		{
			"round-board__header_gray": modifier === "gray",
			"round-board__header_blue": modifier !== "gray",
		},
	);
	const dropdownClass = classNames({
		"dropdown__icon_opened-single": isOpenDropdown,
		"dropdown__icon": !isOpenDropdown,
		"d-md-none padding-inherit": true,
	});

	return (
		<Row>
			<Col>
				<Container className={roundBoardClass}>
					<Row
						className={headerClass}
						onClick={handleClick}
					>
						<Col className="dropdown position-relative d-flex justify-content-center">
							<span className="round-board__title">
								{matchDateAndDayOfWeek}
							</span>
							<span className={dropdownClass}>
								<img src={ dropdownIcon } alt="" />
							</span>
						</Col>
					</Row>
					{isOpenDropdown &&
						<Row className="games-wrapper">
							{games && games.map(gameData =>
								<GameItem
									key={`game-item-${gameData.id}`}
									roundGame={gameData}
								/>
							)}
						</Row>
					}
				</Container>
			</Col>
		</Row>
	);
};

RoundBoard.propTypes = {
	matchDateAndDayOfWeek: PropTypes.string.isRequired,
	games: PropTypes.arrayOf(PropTypes.shape(roundGamePropType.resultGame)).isRequired,
	modifier: PropTypes.string,
};

RoundBoard.defaultProps = {
	modifier: "blue",
};

RoundBoard.displayName = "rb";

export default RoundBoard;
