import PropTypes from "prop-types";

import globalFormationPropType from "utils/propTypes/globalFormation";
import { userPropType } from "utils/propTypes/user";
import { leaguesSeasonsPropType } from "utils/propTypes";

import { PLAYER_POSITIONS } from "helpers/constants/common";

export const basicFootballPlayerType = PropTypes.shape({
	football_club: PropTypes.shape({
		abbreviation: PropTypes.string.isRequired,
		// country: PropTypes.string.isRequired,
		full_name: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		jersey_goalkeeper: PropTypes.string.isRequired,
		jersey_outfield: PropTypes.string.isRequired,
	}),
	id: PropTypes.number.isRequired,
	full_name: PropTypes.string.isRequired,
	position: PropTypes.oneOf(PLAYER_POSITIONS),
	status: PropTypes.string.isRequired,
	popularity_pct: PropTypes.number
});

export const userFootballPlayerType = PropTypes.shape({
	position: PropTypes.oneOf(PLAYER_POSITIONS),
	id: PropTypes.number,
	football_player: basicFootballPlayerType,
});


export const userBenchPlayerType = PropTypes.shape({
	user_football_player: userFootballPlayerType,
	id: PropTypes.number,
	priority: PropTypes.number,
});

export const userCaptainPlayerType = PropTypes.shape({
	football_player: basicFootballPlayerType,
	id: PropTypes.number,
	position: PropTypes.oneOf(PLAYER_POSITIONS),
});

export const userViceCaptainPlayerType = PropTypes.shape({
	football_player: basicFootballPlayerType,
	id: PropTypes.number,
	position: PropTypes.oneOf(PLAYER_POSITIONS),
});

export const userTeamsDataType = PropTypes.shape({
	id: PropTypes.number,
	name: PropTypes.string,
	abbreviation: PropTypes.string,
	wildcard_used: PropTypes.bool,
	season: leaguesSeasonsPropType.leagueSeasons,
	user: userPropType,
	formation: globalFormationPropType.globalFormation,
	captain: userCaptainPlayerType,
	viceCaptain: userViceCaptainPlayerType,
	football_players: PropTypes.arrayOf(userFootballPlayerType),
	bench_players: PropTypes.arrayOf(userBenchPlayerType),
});
