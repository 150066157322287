import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import {
	footballRoundPropType,
	roundGamePropType,
} from "utils/propTypes";

import {
	ROUND_ID,
} from "routes/backendFilters";

import { actionClearRoundGamesHashList, actionGetRequestRoundGames } from "redux/actions/roundGames";
import {
	getSortedRounds,
	getNextScheduledRound,
	getFinishedAndLiveRounds, getScheduledRounds,
} from "redux/selectors";

import RoundResultsListComponent from "modules/home/components/RoundResultsList";

const RoundResultsList = ({
	footballRounds,
	finishedAndLiveRounds,
	roundGames,
	isLoading,
	nextRound,
	withoutFutureRounds,
	onlyFutureRounds,
	scheduledRounds,
	dispatch,
	translation={
		key: "title",
		ns: "round_results"
	},
}) => {
	let roundsSelected = withoutFutureRounds ? finishedAndLiveRounds : footballRounds;
	const indexOfRunningRound = withoutFutureRounds ? roundsSelected.length - 1 : _.findIndex(roundsSelected, round => round.status === "live");
	const lastFinishedRoundIndex = _.findLastIndex(roundsSelected, round => round.status === "finished");
	const indexOfSelectedRound = lastFinishedRoundIndex >= 0 ? lastFinishedRoundIndex : _.findIndex(roundsSelected, round => round.id === nextRound.id);
	
	const [indexRound, setIndexRound] = useState(indexOfRunningRound || roundsSelected[0]);
	if (onlyFutureRounds) {
		roundsSelected = scheduledRounds;
	}

	//ToDo find solution without recalculating data for this module
	const currentRoundNumber = roundsSelected && roundsSelected[indexRound] && roundsSelected[indexRound].number;
	const currentRoundID = roundsSelected && roundsSelected[indexRound] && roundsSelected[indexRound].id;	let dates = [];
	dates = _.map(roundGames[currentRoundID], "match_datetime");
	const dateAndDayOfWeekWithFormattedDate =
		_.map(dates, date => moment(date).format("DD MMM YYYY | dddd"));
	const uniqDates = _.uniq(dateAndDayOfWeekWithFormattedDate);


	useEffect(() => {
		dispatch(actionClearRoundGamesHashList());
		return () => {
			dispatch(actionClearRoundGamesHashList());
		};
	}, []);

	useEffect (() => {
		if (!onlyFutureRounds) {
			setIndexRound( indexOfRunningRound >=0 ? indexOfRunningRound : indexOfSelectedRound );
		} else {
			setIndexRound(0);
		}
	}, [indexOfRunningRound, indexOfSelectedRound]);


	const handleNext = () => {
		if (indexRound < roundsSelected.length -1) {
			setIndexRound(indexRound + 1);
		}
	};

	const handlePrev = () => {
		if (indexRound > 0) {
			setIndexRound(indexRound - 1);
		}
	};

	const idsOfCurrentRound = roundsSelected && roundsSelected[indexRound] && roundsSelected[indexRound].id;
	useEffect(() => {
		!isLoading && idsOfCurrentRound && !roundGames[idsOfCurrentRound] && dispatch(actionGetRequestRoundGames(ROUND_ID, idsOfCurrentRound, currentRoundNumber));
	}, [roundsSelected, indexRound, roundGames, isLoading]);

	const sendProps = {
		indexRound,
		matchDatesAndDaysOfWeek: uniqDates,
		roundGames: roundGames[currentRoundID],
		handlePrev,
		handleNext,
		isLoading,
		currentRoundNumber,
		roundsLength: roundsSelected.length,
		translation,
	};

	return (
		<RoundResultsListComponent {...sendProps} />
	);
};

RoundResultsList.propTypes = {
	matchDatesAndDaysOfWeek: PropTypes.arrayOf(PropTypes.string),
	roundGames: PropTypes.shape(roundGamePropType.resultGame).isRequired,
	nextRound: PropTypes.shape(footballRoundPropType.round),
	footballRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.resultGame)).isRequired,
	finishedAndLiveRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.resultGame)).isRequired,
	isLoading: PropTypes.bool.isRequired,
	withoutFutureRounds: PropTypes.bool,
	onlyFutureRounds: PropTypes.bool,
	scheduledRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.resultGame)).isRequired,
	dispatch: PropTypes.func.isRequired,
	translation: PropTypes.shape({ ns: PropTypes.string, key: PropTypes.string }).isRequired,
};

RoundResultsList.defaultProps = {
	withoutFutureRounds: false,
	onlyFutureRounds: false,
};

const mapStateToProps = state => {
	const allRounds = getSortedRounds(state);
	const nextRoundWithoutModyfiedDate = getNextScheduledRound(state);
	const nextRound = nextRoundWithoutModyfiedDate ? allRounds.find(round => round.id === nextRoundWithoutModyfiedDate.id) : {};
	const { roundGamesReducer: { roundGamesHashList } } = state;
	return {
		footballRounds: getSortedRounds(state),
		finishedAndLiveRounds: getFinishedAndLiveRounds(state),
		roundGames: roundGamesHashList,
		isLoading: state.roundGamesReducer.roundGamesRequestLoading,
		scheduledRounds: getScheduledRounds(state),
		nextRound,
	};
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RoundResultsList);

RoundResultsList.displayName = "rrlcn";