import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Container, Row, Col, Spinner } from "reactstrap";

import { roundGamePropType } from "utils/propTypes";

import Title from "modules/common/Title";
import RoundBoard from "modules/common/RoundBoard";
import Status from "modules/common/Status";

import Switch from "./Switch";

const RoundResultsList = ({
	roundGames,
	indexRound,
	matchDatesAndDaysOfWeek,
	handlePrev,
	handleNext,
	isLoading,
	currentRoundNumber,
	roundsLength,
	translation,
}) => {
	return (
		<Container className="padding-x-10px px-md-0">
			<Row className="margin-bottom-65px">
				<Col>
					<Title translation={translation} />
					{roundGames && !!roundGames.length &&
						<Switch
							handlePrev={handlePrev}
							handleNext={handleNext}
							text={"round"}
							value={currentRoundNumber}
							switchRange={roundsLength}
							index={indexRound}
							isLoading={isLoading}
						/>
					}
					{!isLoading && roundGames && roundGames[0] && roundGames[0].football_round.status === "live" && (
						<Col className="d-flex justify-content-center margin-top-14-30px live-status-container" >
							<Status />
						</Col>
					)}
					{!isLoading && matchDatesAndDaysOfWeek.map(matchDateAndDayOfWeek => {
						const gamesByMatchDateAndDayOfWeek = _.filter(roundGames, game =>
							game.matchDateAndDayOfWeek === matchDateAndDayOfWeek);
						return (
							<RoundBoard
								key={matchDateAndDayOfWeek}
								matchDateAndDayOfWeek={matchDateAndDayOfWeek}
								games={gamesByMatchDateAndDayOfWeek}
							/>
						);
					})}
					{isLoading &&
						<div className='w-100 d-flex justify-content-center align-items-center margin-top-20-40px'>
							<Spinner/>
						</div>
					}
				</Col>
			</Row>
		</Container>
	);
};

RoundResultsList.propTypes = {
	matchDatesAndDaysOfWeek: PropTypes.arrayOf(PropTypes.string),
	roundGames: PropTypes.shape(roundGamePropType.resultGame),
	indexRound: PropTypes.number.isRequired,
	handlePrev: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	translation: PropTypes.shape({ ns: PropTypes.string, key: PropTypes.string }),
	currentRoundNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	roundsLength: PropTypes.number.isRequired,
};

RoundResultsList.defaultProps = {
	currentRoundNumber: "",
};

RoundResultsList.displayName = "rrlcm";

export default RoundResultsList;
