import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import priceIncreased from "static/assets/images/position-up.svg";
import priceDecreased from "static/assets/images/position-down.svg";
import priceNotChanged from "static/assets/images/position-unchanged.svg";

import priceToMillion from "helpers/priceToMillion";

const Header = ({
	jersey,
	fullName,
	clubName,
	currentPrice,
	initialPrice,
	position,
	status,
	percent,
}) => {
	const { t } = useTranslation("player_info_modal");

	const priceChange = currentPrice - initialPrice;
	const imageSrc = priceChange ? 
		priceChange < 0 ? priceDecreased : priceIncreased : 
		priceNotChanged;
		
	return (
		<div className='player-info-modal_header'>
			<div className='player-info-modal_header_left'>
				<img className="player-shirt" src={jersey} alt="" />
				<div className='player-info-modal_header_left_info'>
					<div className="font-m1"> {fullName}</div>
					<div className="player-info-text">
						{clubName} / {priceToMillion(currentPrice)}
						<img className="price-change mx-1" src={imageSrc} alt="" />
						({priceToMillion(initialPrice)})
					</div>
					<div className="player-info-subtext">{t(position)}</div>
				</div>
			</div>
			<div className='player-info-modal_header_right'>
				<div>
					{t(status)}
				</div>
				<div>
					<span className="text-uppercase">
						{t("selected by", {
							percent
						})}
					</span>
				</div>
			</div>
		</div>	
	);
};


Header.propTypes = {
	jersey: PropTypes.string.isRequired,
	fullName: PropTypes.string.isRequired,
	clubName: PropTypes.string.isRequired,
	currentPrice: PropTypes.number.isRequired,
	initialPrice: PropTypes.number.isRequired,
	position: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	percent: PropTypes.number.isRequired,
};

export default Header;

Header.displayName = "pimh";