import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/footballField";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";

export const actionSetCaptain = payload => ({
	type: types.SET_CAPTAIN,
	payload,
});

export const actionSetViceCaptain = payload => ({
	type: types.SET_VICE_CAPTAIN,
	payload,
});

export const actionSetField = payload => ({
	type: types.SET_FIELD,
	payload,
});

export const actionInitTeam = payload => ({
	type: types.INIT_TEAM,
	payload,
});

export const actionResetTeam = payload => ({
	type: types.RESET_TEAM,
	payload,
});

export const actionCleanTeam = payload => ({
	type: types.CLEAR_TEAM,
	payload,
});

export const actionSetCurrentTeamName = payload => ({
	type: types.SET_CURRENT_TEAM_NAME,
	payload,
});

export const actionMovePlayer = payload => ({
	type: types.MOVE_PLAYER,
	payload,
});

export const actionChangeFormation = payload => ({
	type: types.CHANGE_FORMAT,
	payload,
});

export const actionRemovePlayer = payload => ({
	type: types.REMOVE_PLAYER,
	payload,
});

export const actionSelectPlayerFromList = payload => ({
	type: types.SELECT_PLAYER_FROM_LIST,
	payload,
});

export const actionClearTransfers = () => ({
	type: types.CLEAR_TRANSFERS,
});

export const getRequestGlobalFormationsStart = payload => ({
	type: types.GET_REQUEST_GLOBAL_FORMATIONS_START,
	payload,
});

export const getRequestGlobalFormationsSuccess = payload => ({
	type: types.GET_REQUEST_GLOBAL_FORMATIONS_SUCCESS,
	payload,
});

export const getRequestGlobalFormationsError = payload => ({
	type: types.GET_REQUEST_GLOBAL_FORMATIONS_ERROR,
	payload,
});

export const actionGetRequestGlobalFormations = params => async dispatch => {
	dispatch(getRequestGlobalFormationsStart(params));

	axiosPlus({ method: "GET", url: backendApiUrls.globalFormations })
		.then(function (response) {
			const { data = [] } = response;
			dispatch(getRequestGlobalFormationsSuccess(data.reverse()));
		})
		.catch(function (error) {
			dispatch(getRequestGlobalFormationsError( errorsParser(error)));
		});
};

export const postRequestRandomTeamStart = payload => ({
	type: types.POST_REQUEST_RANDOM_TEAM_START,
	payload,
});

export const postRequestRandomTeamSuccess = payload => ({
	type: types.POST_REQUEST_RANDOM_TEAM_SUCCESS,
	payload,
});

export const postRequestRandomTeamError = payload => ({
	type: types.POST_REQUEST_RANDOM_TEAM_ERROR,
	payload,
});

export const actionPostRequestRandomTeam = data => async dispatch => {
	dispatch(postRequestRandomTeamStart(data));

	let counter = 0;

	const request = (requestData) => {
		return axiosPlus({ method: "POST", url: backendApiUrls.randomTeam, data: requestData })
			.then(function (response) {
				const { data: { main, bench } } = response;
				dispatch(postRequestRandomTeamSuccess());
				dispatch(actionInitTeam({ football_players: [...main, ...bench.map((benchPlayer => ({
					...benchPlayer,
					position_index: benchPlayer.priority + 12,
				})))], isRandomGenerated: true }));
				return response;
			})
			.catch(function (error) {
				if (counter < 2) {
					counter += 1;
					request(requestData);
				} else {
					dispatch(postRequestRandomTeamError( errorsParser(error)));
					return errorsParser(error);
				}
			});
	};

	return request(data);
};	