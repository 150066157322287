import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
	Row,
	Col,
} from "reactstrap";
import { Trans } from "react-i18next";

const Title = ({ text1, text2, color, lineColor, mb=15, mbMd=30, className, translation = false }) => {
	const lineClass = classNames(
		"title__short-line",
		`margin-bottom-${mb}px`,
		`margin-bottom-md-${mbMd}px`,
		{ [`title__short-line_${lineColor}`]: lineColor }
	);
	const text1Class = classNames(
		"title__text", "mb-0",
		{ [`color-${color}`]: color }
	);
	return (
		<Col className={`title ${className}`}>
			<Row className="d-flex justify-content-center">
				<div className={lineClass} />
			</Row>
			<Row className="d-flex justify-content-center">
				<h2 className={text1Class}>
					{translation ? <Trans
						i18nKey={translation.key}
						ns={translation.ns}
						components={[
							<span key="0" className='color-accent1' />
						]}
						values={translation.values || {}}
					/> :
						<>{text1} <span className="color-accent1">{text2}</span></>
					}
				</h2>
			</Row>
		</Col>
	);};

Title.propTypes = {
	text1: PropTypes.string,
	translation: PropTypes.object,
	text2: PropTypes.string,
	color: PropTypes.string,
	lineColor: PropTypes.string,
	mb: PropTypes.number,
	mbMd: PropTypes.number,
	className: PropTypes.string,
};

Title.defaultProps = {
	text1: "",
	text2: "",
	color: "",
	lineColor: "",
	className: "",
};
  
export default Title;

Title.displayName = "t";