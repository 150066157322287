import backendApiUrls from "routes/backendUrls";

import {
	REQUEST_GET_LEAGUES_SEASONS_START,
	REQUEST_GET_LEAGUES_SEASONS_SUCCESS,
	REQUEST_GET_LEAGUES_SEASONS_ERROR,
} from "redux/constants/leagues";

import axiosPlus from "helpers/axiosPlus";
import { errorsParser } from "helpers/errorsParser";

const getLeaguesSeasonsStart = () => ({
	type: REQUEST_GET_LEAGUES_SEASONS_START,
});

const getLeaguesSeasonsSuccess = (payload, roundId, currentRoundNumber) => ({
	type: REQUEST_GET_LEAGUES_SEASONS_SUCCESS,
	payload,
	meta: { roundId, currentRoundNumber },
});

const getLeaguesSeasonsError = error => ({
	type: REQUEST_GET_LEAGUES_SEASONS_ERROR,
	payload: error,
});

export const actionGetRequestLeagueSeasons = (filter = "", value= "") => dispatch => {
	dispatch(getLeaguesSeasonsStart());
	const config = {
		method: "GET",
		url: `${backendApiUrls.leagueSeasons}${filter}${value}`,
	};

	return axiosPlus(config)
		.then((response) => {
			const { data: { results } = {} } = response;
			dispatch(getLeaguesSeasonsSuccess(results));
		})

		.catch((error) => {
			dispatch(getLeaguesSeasonsError( errorsParser(error) ));
		});
};
