import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { countriesPropTypes, errorsPropTypes } from "utils/propTypes/registrationUser";
import { formValidatorEdit, formValidatorRegister } from "utils/validators";

import { actionGetCountries } from "redux/actions/countries";
import { actionRequestCreateUser } from "redux/actions/register";
import { actionGetUserDetails, actionUpdateUserDetails } from "redux/actions/auth";
import { actionPatchUserTeamName } from "redux/actions/userTeams";

import PersonalDetailsComponent from "modules/registration/components/PersonalDetails";
import ChangePasswordModal from "modules/auth/ChangePasswordModal";
import DeleteAccountModal from "modules/auth/DeleteAccountModal";


const PersonalDetails = ({
	isUserLoggedIn,
	userDetails,
	countriesData,
	actionGetCountries,
	createUserRequestError,
	userDetailsErrors,
	actionGetUserDetails,
	actionPatchUserTeamName,
	actionUpdateUserDetails,
	userDetailsRequestLoading,
	createUserRequestLoading,
	onlyForm,
	requestCreateUser,
	validateUserTeam,
	isRegisterLoading,
	userTeamPlayersRequestLoading,
	userTeamsRequestLoading,
}) => {
	const { t } = useTranslation("personal_details");
	const { 
		email: userEmail, 
		first_name: userFirstName, 
		last_name :userLastName, 
		country: userCountry,
		team_name: userTeamName,
	} = userDetails;
	
	useEffect(() => {
		isUserLoggedIn && actionGetUserDetails();
		actionGetCountries();
	}, []);

	const [first_name, setFirstName] = useState(userFirstName);
	const [last_name, setLastName] = useState(userLastName);
	const [email, setEmail] = useState(userEmail);
	const [team_name, setTeamName] = useState(userTeamName);
	const [country, setCountry] = useState(userCountry);
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [confirmTerms, setConfirmTerms] = useState(false);
	const [changedFields, setChangedFields] = useState([]);

	const [passwordModalOpenState, changePasswordModalOpenState] = useState(false);
	const [deleteAccountModalOpenState, changeDeleteAccountModalOpenState] = useState(false);

	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		if (!_.isEmpty(userDetails)) {
			setFirstName(userFirstName);
			setLastName(userLastName);
			setCountry(userCountry);
			setEmail(userEmail);
			setTeamName(userTeamName);
		}
	}, [JSON.stringify(userDetails)]);
	
	const switchConfirmTerms = () => {
		setConfirmTerms(!confirmTerms);
	};

	const changeFirstName = (e) => {
		const { target: { value } = {} } = e;
		setFirstName(value);
	};
	const changeLastName = (e) => {
		const { target: { value } = {} } = e;
		setLastName(value);
	};
	const changeEmail = (e) => {
		const { target: { value } = {} } = e;
		setEmail(value);
	};
	const changeTeamName = (e) => {
		const { target: { value } = {} } = e;
		setTeamName(value);
	};
	const changeCountry = ({ code }) => setCountry(code);
	const changePassword = (e) => {
		const { target: { value } = {} } = e;
		setPassword(value);
	};
	const changeConfirmPassword = (e) => {
		const { target: { value } = {} } = e;
		setConfirmPassword(value);
	};

	const togglePasswordModalOpenState = value => {
		changePasswordModalOpenState(value === false ? value : !passwordModalOpenState);
	};

	const toggleDeleteAccountModalOpenState = value => {
		changeDeleteAccountModalOpenState(value === false ? value : !deleteAccountModalOpenState);
	};

	const trimmedFirstName = first_name?.trim();
	const trimmedLastName = last_name?.trim();
	const trimmedTeamName = team_name?.trim();
	const payload = isUserLoggedIn ? { 
		first_name: trimmedFirstName, 
		last_name: trimmedLastName, 
		email,
		team_name: trimmedTeamName,
	} : {
		first_name: trimmedFirstName,
		last_name: trimmedLastName,
		country,
		email,
		password,
		confirm_password,
		privacy_policy: true,
	};

	const validationErrors = isUserLoggedIn ? formValidatorEdit(payload) : formValidatorRegister(payload);

	const handleSubmit = () => {
		if (confirmTerms || isUserLoggedIn) {
			const isValid = _.isEmpty(validationErrors);
			setShowErrors(true);
			validateUserTeam && validateUserTeam();
			
			if (isValid) {
				if (isUserLoggedIn) {
					const changedFieldsValue = [];
					const isTeamNameChanged = userTeamName !== team_name;
					const isFirstNameChanged = userFirstName !== first_name;
					const isLastNameChanged = userLastName !== last_name;

					if (isTeamNameChanged) changedFieldsValue.push(t("Team Name"));
					if (isFirstNameChanged) changedFieldsValue.push(t("First Name"));
					if (isLastNameChanged) changedFieldsValue.push(t("Last Name"));
					
					if (isTeamNameChanged) {
						actionPatchUserTeamName(trimmedTeamName).then((data) => {
							if (!(data && data.parsedTextErrors)) {
								setChangedFields(changedFieldsValue);
							}
						});
					}
					if (isFirstNameChanged || isLastNameChanged) {
						actionUpdateUserDetails(payload).then((data) => {
							if (!(data && data.parsedTextErrors)) {
								setChangedFields(changedFieldsValue);
							}
						});
					}
				} else if (!createUserRequestLoading && !userTeamPlayersRequestLoading) {
					requestCreateUser(payload);
				}
			}
		}
	};

	let errors = { ...createUserRequestError, ...userDetailsErrors };
	if (showErrors) {
		errors = { ...errors, ...validationErrors };
	}

	const sendProps = {
		countriesData,
		confirmTerms,
		handleSubmit,
		changeFirstName,
		changeLastName,
		changeEmail,
		changeTeamName,
		changeCountry,
		changePassword,
		changeConfirmPassword,
		first_name,
		last_name,
		country,
		email,
		team_name,
		password,
		isRegisterLoading,
		isUserLoggedIn,
		changePasswordModalOpenState,
		changeDeleteAccountModalOpenState,
		switchConfirmTerms,
		errors,
		changedFields,
		setChangedFields,
		userDetailsRequestLoading,
		createUserRequestLoading,
		onlyForm,
		userTeamPlayersRequestLoading,
		userTeamsRequestLoading,
	};

	return (
		<>
			<PersonalDetailsComponent { ...sendProps } />
			{passwordModalOpenState && <ChangePasswordModal isOpen={passwordModalOpenState} toggle={togglePasswordModalOpenState} setChangedFields={setChangedFields}/>}
			{deleteAccountModalOpenState &&
				<DeleteAccountModal isOpen={deleteAccountModalOpenState} toggle={toggleDeleteAccountModalOpenState}/>
			}
		</>
	);
};

PersonalDetails.propTypes = {
	countriesData: countriesPropTypes.isRequired,
	createUserRequestError: errorsPropTypes,
	userDetailsErrors: errorsPropTypes,
	createUserRequestLoading: propTypes.bool.isRequired,
	isRegisterLoading: propTypes.bool.isRequired,
	userDetailsRequestLoading: propTypes.bool.isRequired,
	isUserLoggedIn: propTypes.bool.isRequired,
	userDetails: propTypes.shape(
		{ first_name: propTypes.string, last_name: propTypes.string, email: propTypes.string, country: propTypes.string, team_name: propTypes.string }),
	actionGetCountries: propTypes.func.isRequired,
	actionRequestCreateUser: propTypes.func.isRequired,
	actionGetUserDetails: propTypes.func.isRequired,
	actionPatchUserTeamName: propTypes.func.isRequired,
	actionUpdateUserDetails: propTypes.func.isRequired,
	onlyForm: propTypes.bool.isRequired,
	userTeamPlayersRequestLoading: propTypes.bool.isRequired,
	requestCreateUser: propTypes.func.isRequired,
	validateUserTeam: propTypes.func.isRequired,
	userTeamsRequestLoading: propTypes.bool.isRequired,
};

const mapStateToProps = ({ countriesReducer, registerReducer, authReducer, userTeamsReducer }) => {
	const { userDetails = {}, isUserLoggedIn, userDetailsErrors, userDetailsRequestLoading } = authReducer;
	const { countriesData } = countriesReducer;
	const { createUserRequestError, createUserRequestLoading } = registerReducer;
	const { userTeamPlayersRequestLoading, userTeamsRequestLoading, userTeamsData } = userTeamsReducer;
	const userTeamName = userTeamsData?.[0]?.name;

	return {
		countriesData,
		createUserRequestError,
		userDetailsErrors,
		createUserRequestLoading,
		userDetails: {
			...userDetails,
			team_name: userTeamName,
		},
		isUserLoggedIn,
		userDetailsRequestLoading,
		userTeamPlayersRequestLoading,
		userTeamsRequestLoading,
	};
};

export { PersonalDetails as PersonalDetailsForTest };
export default connect(
	mapStateToProps,
	{ actionGetCountries, actionRequestCreateUser, actionGetUserDetails, actionUpdateUserDetails, actionPatchUserTeamName }
)(PersonalDetails);

PersonalDetails.displayName = "pd";