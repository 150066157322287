import * as types from "redux/constants/settings";

import createReducer from "redux/createReducer";

const settingsInitialState = {
	budgetLimit: 0,
	deductionPoints: 0,
	settingsRequestError: {},
	settingsRequestLoading: false,
};

const settingsReducer = createReducer(settingsInitialState)({
	[types.GET_REQUEST_SETTINGS_START]: state => ({
		...state,
		settingsRequestError: {},
		settingsRequestLoading: true,
	}),
	[types.GET_REQUEST_SETTINGS_SUCCESS]: (state, { payload }) => {
		const {
			budget_limit: budgetLimit,
			extra_transfer_penalty_points: deductionPoints,
			nmb_of_fp_from_fc: playersFromOneClubLimit,
		} = payload;
		return {
			...state,
			budgetLimit,
			deductionPoints,
			playersFromOneClubLimit,
		};

	},
	[types.GET_REQUEST_SETTINGS_ERROR]: (state, { payload }) => ({
		...state,
		settingsRequestError: payload,
		settingsRequestLoading: false,
	})
});

export default settingsReducer;
