import backendApiUrls from "routes/backendUrls";

import * as types from "redux/constants/playersList";

import { errorsParser } from "helpers/errorsParser";
import axiosPlus from "helpers/axiosPlus";
import { ACTION_TYPES_BLOCKS } from "helpers/constants/common";

const getRequestPlayersListStart = payload => ({
	type: types.GET_REQUEST_PLAYERS_LIST_START,
	payload,
});

const getRequestPlayersListSuccess = payload => ({
	type: types.GET_REQUEST_PLAYERS_LIST_SUCCESS,
	payload,
});

const getRequestPlayersListError = payload => ({
	type: types.GET_REQUEST_PLAYERS_LIST_ERROR,
	payload,
});

const getRequestPlayerInfoStart = payload => ({
	type: types.GET_REQUEST_PLAYER_INFO_START,
	payload,
});

const getRequestPlayerInfoSuccess = payload => ({
	type: types.GET_REQUEST_PLAYER_INFO_SUCCESS,
	payload,
});

const getRequestPlayerInfoError = payload => ({
	type: types.GET_REQUEST_PLAYER_INFO_ERROR,
	payload,
});

const getRequestPlayerDataStart = payload => ({
	type: types.GET_REQUEST_PLAYER_DATA_START,
	payload,
});

const getRequestPlayerDataSuccess = payload => ({
	type: types.GET_REQUEST_PLAYER_DATA_SUCCESS,
	payload,
});

const getRequestPlayerDataError = payload => ({
	type: types.GET_REQUEST_PLAYER_DATA_ERROR,
	payload,
});

export const actionSetPlayerInfoModalID = payload => ({
	type: types.SWITCH_PLAYER_INFO_MODAL_OPEN_STATE,
	payload,
});


export const actionResetPlayersList = () => ({
	type: types.RESET_PLAYERS_LIST
});

export const actionGetRequestPlayersList = params => dispatch => {
	const { football_club_id, full_name, ordering, min_price, max_price, position } = params;
	dispatch(getRequestPlayersListStart({ forParams: { football_club_id, full_name, ordering, min_price, max_price, position } }));

	axiosPlus({ method: "GET", url: backendApiUrls.playersList, params })
		.then(function (response) {
			const { data: { results = [] } = {} } = response;
			const preparedData = results.map((playerInfo) => ({
				...playerInfo,
				full_name: `${playerInfo.first_name} ${playerInfo.last_name}`
			}));
			dispatch(getRequestPlayersListSuccess({ 
				data: preparedData, 
				forParams: { football_club_id, full_name, ordering, min_price, max_price, position } ,
				page: params.page
			}));	
		})
		.catch(function (error) {
			dispatch(getRequestPlayersListError( errorsParser(error)));
		});
};

export const actionGetRequestPlayerData = ({ playerID }) => dispatch => {
	dispatch(getRequestPlayerDataStart(playerID));

	axiosPlus({ method: "GET", url: `${backendApiUrls.playersList}${playerID}/` })
		.then(function (response) {
			const { data } = response;
			dispatch(getRequestPlayerDataSuccess({
				data,
				playerID }));
		})
		.catch(function (error) {
			dispatch(getRequestPlayerDataError( errorsParser(error)));
		});
};

export const actionGetRequestPlayerInfo = params => dispatch => {
	const { playerID } = params;
	dispatch(getRequestPlayerInfoStart(playerID));
	axiosPlus({
		method: "GET", 
		url: `${backendApiUrls.footballPlayerInfo}${playerID}/`, 
		params: { ...params, ordering: "round_game__match_datetime" }
	})
		.then(function (response) {
			const { data: { results } } = response;
			if (results.length) {
				dispatch(actionGetRequestPlayerData({ playerID }));
				const { football_player } = results[0];

				const parsedMathces = [];
				const totalPointsList = {
					calculatedPoints: {},
				};
				results.forEach(match => {
					let matchTotalPoints = 0;
					const { round_game, round_actions, football_player: currentMatchFootballPlayer, played_for_team_id } = match;
					const { football_round: { number: roundNumber } } = round_game;
					const { position } =  currentMatchFootballPlayer;
					const opponentTeam = round_game.team_1.id === played_for_team_id
						? { name: round_game.team_2.abbreviation.slice(0,3), prefix: "(H)" } : { name: round_game.team_1.abbreviation.slice(0,3), prefix: "(A)" };
					const userResults = {
						calculatedPoints: {},
						opponentTeam,
						roundNumber,
						score: `${round_game.team_1_score}-${round_game.team_2_score}`,
						isLiveMatch: round_game.status === "live"
					};
					Object.keys(round_actions).forEach(actionKey => {
						const { points, count } = round_actions[actionKey];
						if (!ACTION_TYPES_BLOCKS[position][actionKey]) {
							matchTotalPoints += points;
							userResults[actionKey] = count;
							userResults.calculatedPoints[actionKey] = points;
							totalPointsList.calculatedPoints[actionKey] = totalPointsList.calculatedPoints[actionKey] ?
								totalPointsList.calculatedPoints[actionKey] + points : points;
							totalPointsList[actionKey] = totalPointsList[actionKey]
								? totalPointsList[actionKey] + count : count;
						}
					});
					totalPointsList["matchTotalPoints"] = totalPointsList["matchTotalPoints"] ? totalPointsList["matchTotalPoints"] + matchTotalPoints : matchTotalPoints;
					parsedMathces.push({ ...userResults, matchTotalPoints, roundNumber });
				});
				dispatch(getRequestPlayerInfoSuccess({
					data: { matches: parsedMathces, ...football_player, totalPointsList },
					playerID }));
			} else {
				dispatch(actionGetRequestPlayerData({ playerID }));
			}
		})
		.catch(function (error) {
			dispatch(getRequestPlayerInfoError( errorsParser(error)));
		});
};
