import React from "react";

import Title from "modules/common/Title";
import Progress from "modules/registration/components/Progress";
import Form from "modules/registration/components/Form";

const PersonalDetails = (
	sendProps,
) => {
	return (
		sendProps.onlyForm ? 
			<div>
				<Title translation={{
					ns: "personal_details",
					key: "personal_details"
				}} className="margin-bottom-20-40px d-md-block"/>
				<Form {...sendProps} /> 
			</div>
			:
			<div>
				{sendProps.isUserLoggedIn ? 
					<div>
						<Title translation={{
							ns: "personal_details",
							key: "personal_details"
						}} className="margin-bottom-20-40px d-md-block"/>
					</div>
					: <Progress step={2} />}
				<Form {...sendProps} />
			</div>
	);
};

export default PersonalDetails;

PersonalDetails.displayName = "pdcom";