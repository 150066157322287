import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import Title from "modules/common/Title";
import RoundList from "./RoundList";

import AdUnit from "modules/common/AdUnit"

const ScheduleAndResults = ({
	footballRounds,
}) => {
	return (
		<Container className="global-container">
		  <AdUnit adUnitId="9013039560" />
			<Title translation={{
				ns: "schedule_and_results",
				key: "schedule_and_results"
			}} />
			<RoundList footballRounds={footballRounds} />
		</Container>
	);
};

ScheduleAndResults.propTypes = {
	footballRounds: PropTypes.arrayOf(PropTypes.object),
};

export default ScheduleAndResults;

ScheduleAndResults.displayName = "sar";