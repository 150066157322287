import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
	Row, Spinner
} from "reactstrap";
import classNames from "classnames";
import dropdownClosed from "static/assets/images/dropdown-closed.svg";
import dropdownOpened from "static/assets/images/dropdown-opened.svg";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType,
	roundGamePropType,
} from "utils/propTypes";

import RoundBoard from "modules/common/RoundBoard";
import Status from "modules/common/Status";
import Deadline from "modules/common/Deadline";

const RoundItem = ({
	round,
	roundGames,
	handleToggleDropdown,
	isOpenDropdown,
	isLoading,
}) => {
	const { t } = useTranslation("round_item");
	const gamesFilteredByRoundNumber = _.filter(roundGames, game => game.football_round.number === round.number);
	const datesFilteredByRoundNumber = _.uniq(_.map(gamesFilteredByRoundNumber, "matchDateAndDayOfWeek"));

	const headerOpened = classNames({
		"schedule-item__header_opened": isOpenDropdown,
		"schedule-item__header_hover": true,
	});
	const scheduleOpened = classNames({
		"schedule-item_opened": isOpenDropdown,
	});
	const statusOpened = classNames({
		"schedule-item__status-info_opened": isOpenDropdown,
	});
	const dropdownIconOpened = classNames({
		"dropdown__icon_opened": isOpenDropdown,
		"dropdown__icon": !isOpenDropdown,
	});

	return (
		<div className={`${scheduleOpened} schedule-item dropdown`}>
			<Row className={`${headerOpened} schedule-item__header`} onClick={() => handleToggleDropdown(round.id)}>
				<div className="schedule-item__title">{t("Round", { round: round.number })}</div>
				<div className={`${statusOpened} schedule-item__status-info `}>
					{round.status === "live" && <Status />}
					{round.status === "scheduled" &&
						<Deadline userteamDeadline={round.userteam_deadline} isOpenDropdown={isOpenDropdown}/>
					}
					{round.status === "finished" && <span className="text-uppercase">{t("completed")}</span>}
					{round.status === "cancelled" &&<span className="text-uppercase">{t("cancelled")}</span>}
					<span className={dropdownIconOpened}>
						<img src={isOpenDropdown ? dropdownOpened : dropdownClosed } alt="" />
					</span>
				</div>
			</Row>
			{isOpenDropdown ?
				<div>
					{isLoading && !datesFilteredByRoundNumber.length ? 
						<div className="w-100 justify-content-center d-flex py-3">
							<Spinner />
						</div> :
						<div className="schedule-item__content dropdown__content">
							{datesFilteredByRoundNumber.map(matchDateAndDayOfWeek => {
								const gamesByMatchDatetime = _.filter(gamesFilteredByRoundNumber, game =>
									game.matchDateAndDayOfWeek === matchDateAndDayOfWeek);
								return <RoundBoard
									key={matchDateAndDayOfWeek}
									matchDateAndDayOfWeek={matchDateAndDayOfWeek}
									games={gamesByMatchDatetime}
									modifier="gray"
								/>;
							})}
						</div>
					}
				</div> : null
			}
		</div>
	);
};

RoundItem.propTypes = {
	round: PropTypes.shape(footballRoundPropType.round).isRequired,
	roundGames: PropTypes.arrayOf(PropTypes.shape(roundGamePropType.resultGame)).isRequired,
	handleToggleDropdown: PropTypes.func.isRequired,
	isOpenDropdown: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
};

RoundBoard.defaultProps = {
	isOpenDropdown: false,
};

export default RoundItem;

RoundItem.displayName = "ricom";