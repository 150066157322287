import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NewsComponent from "../components/News";

const News = ({
	news,
}) => {
	return <NewsComponent news={news} />;
};

News.propTypes = {
	news: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})).isRequired,
};

const mapStateToProps = state => {
	return {
		news: state.newsReducer.newsPaginationData?.results || [],
	};
};

export default connect(
	mapStateToProps
)(News);

News.displayName = "nws";