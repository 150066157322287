import { Col, Row } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import priceToMillion from "helpers/priceToMillion";

const PlayersListHeadStatsNextRound = React.forwardRef(({ transfers, isTransfersLoaded, deduction, budgetLeft, showInfinitySign, classNames }, ref) => {
	const { limit, current } = transfers;
	const { t } = useTranslation("players_list");
	return (
		<Row className={`players-list-stats-next-round ${classNames}`}>
			<Col className='col-auto d-flex flex-row justify-content-center p-0'>
				<div className='d-flex flex-column justify-content-between align-items-center'>
					<span className='font-size-12 source-sans-pro color-secondary-text'>
						{t("transfers")}
					</span>
					{!!isTransfersLoaded &&
						<span className='
						font-size-36-ms-30 bebas-neue'>
							{showInfinitySign ?
								"∞": (
									<span>
										{current}
										<span
											className={`${current > limit && "color-secondary-text"}`}>
											{`/${limit}`}
										</span>
									</span>
								)}
						</span>
					}
				</div>
			</Col>
			<Col className='col-auto d-flex flex-row justify-content-center p-0'>
				<div className='d-flex flex-column justify-content-between align-items-center'>
					<span className='font-size-12 source-sans-pro color-secondary-text'>
						{t("deduction")}
					</span>
					{!!isTransfersLoaded &&
						<span ref={ref} className='font-size-36-ms-30 bebas-neue'>
							{showInfinitySign ? "∞" : deduction}
						</span>
					}
				</div>
			</Col>
			<Col className='col-auto d-flex flex-row justify-content-center p-0'>
				<div className='d-flex flex-column justify-content-between align-items-center'>
					<span className='font-size-12 source-sans-pro color-secondary-text'>
						{t("budget")}
					</span>
					<span ref={ref} className='font-size-36-ms-30 bebas-neue'>
						{!!isTransfersLoaded && priceToMillion(budgetLeft)}
					</span>
				</div>
			</Col>
		</Row>
	);
});

PlayersListHeadStatsNextRound.propTypes = {
	transfers: PropTypes.shape({ limit: PropTypes.number.isRequired, current: PropTypes.number.isRequired }),
	deduction: PropTypes.number.isRequired,
	budgetLeft: PropTypes.number.isRequired,
	showInfinitySign: PropTypes.bool.isRequired,
	isTransfersLoaded: PropTypes.bool,
	classNames: PropTypes.string,
};

PlayersListHeadStatsNextRound.defaultProps = {
	classNames: "",
};

PlayersListHeadStatsNextRound.displayName = "plhsnr";

export default PlayersListHeadStatsNextRound;