import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import { actionRequestResendEmailVerification, actionResetEmailVerificationResult } from "redux/actions/register";

import ConfirmEmailComponent from "modules/registration/components/ConfirmEmail";

const ConfirmEmail = ({
	actionRequestResendEmailVerification,
	resendEmailRequestError,
	resendEmailRequestSuccess,
	actionResetEmailVerificationResult,
	userEmail,
}) => {
	const handleSendEmail = () => {
		actionRequestResendEmailVerification();
	};

	const handleCloseSuccessMessage = () => {
		actionResetEmailVerificationResult();
	};

	const sendProps = {
		userEmail,
		handleSendEmail,
		resendEmailRequestError,
		resendEmailRequestSuccess,
		handleCloseSuccessMessage,
	};

	return (
		<ConfirmEmailComponent { ...sendProps } />
	);
};

ConfirmEmail.propTypes = {
	userEmail: propTypes.string.isRequired,
	actionRequestResendEmailVerification: propTypes.func.isRequired,
	actionResetEmailVerificationResult: propTypes.func.isRequired,
	resendEmailRequestError: propTypes.object.isRequired,
	resendEmailRequestSuccess: propTypes.bool.isRequired,
};

const mapStateToProps = ({ registerReducer, authReducer }) => {
	const  { resendEmailRequestError, resendEmailRequestSuccess } = registerReducer;
	const { email: userEmail } = authReducer;

	return {
		resendEmailRequestError, resendEmailRequestSuccess, userEmail
	};
};

export default connect(
	mapStateToProps,
	{ 
		actionRequestResendEmailVerification,
		actionResetEmailVerificationResult,
	}
)(ConfirmEmail);
export { ConfirmEmail as ConfirmEmailForTest };

ConfirmEmail.displayName = "ce";