import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { actionGetSelectedNewsItem } from "redux/actions/news";

import useQuery from "helpers/hooks/useQuery";

import ArticleComponent from "../components/Article";


const Article = ({
	selectedArticle,
	actionGetSelectedNewsItem,
}) => {
	const articleId = useQuery().get("article_id");

	useEffect(() => {
		actionGetSelectedNewsItem(articleId);
	}, [articleId]);
	
	return <ArticleComponent article={selectedArticle} />;
};

Article.propTypes = {
	selectedArticle: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})).isRequired,
	actionGetSelectedNewsItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		selectedArticle: state.newsReducer.selectedNewsItemData,
	};
};

export default connect(
	mapStateToProps, {
		actionGetSelectedNewsItem,
	}
)(Article);

Article.displayName = "art";