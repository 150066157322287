import * as types from "redux/constants/teamsList";

import createReducer from "redux/createReducer";

const selectTeamInitialState = {
	teamsListData: [],
	teamsListRequestError: false,
	teamsListRequestLoading: false,
};

const selectTeamReducer = createReducer(selectTeamInitialState)({
	[types.GET_REQUEST_TEAMS_LIST_START]: state => ({
		...state,
		teamsListRequestLoading: true,
	}),
	[types.GET_REQUEST_TEAMS_LIST_SUCCESS]: (state, { payload }) => ({
		...state,
		teamsListData: payload,
		teamsListRequestLoading: false,
	}),
	[types.GET_REQUEST_TEAMS_LIST_ERROR]: (state, { payload }) => ({
		...state,
		teamsListRequestError: payload,
		teamsListRequestLoading: false,
	}),
});

export default selectTeamReducer;