import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/it";

import resources from "./static/translations";

export const supportedLanguages = ["en", "it"];

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		supportedLngs: supportedLanguages,
		react: {
			wait: true,
		},
		interpolation: {
			escapeValue: false
		}
	}).then(() => {
		const currentLanguage = i18n.language;
		moment.locale(currentLanguage);
	});

export default i18n;