export const GET_REQUEST_PRIZES_WINNER_TYPE_1_START = "GET_REQUEST_PRIZES_WINNER_TYPE_1_START";
export const GET_REQUEST_PRIZES_WINNER_TYPE_1_SUCCESS = "GET_REQUEST_PRIZES_WINNER_TYPE_1_SUCCESS";
export const GET_REQUEST_PRIZES_WINNER_TYPE_1_ERROR = "GET_REQUEST_PRIZES_WINNER_TYPE_1_ERROR";

export const GET_REQUEST_PRIZES_WINNER_TYPE_2_START = "GET_REQUEST_PRIZES_WINNER_TYPE_2_START";
export const GET_REQUEST_PRIZES_WINNER_TYPE_2_SUCCESS = "GET_REQUEST_PRIZES_WINNER_TYPE_2_SUCCESS";
export const GET_REQUEST_PRIZES_WINNER_TYPE_2_ERROR = "GET_REQUEST_PRIZES_WINNER_TYPE_2_ERROR";

export const GET_REQUEST_PRIZES_WINNER_TYPE_3_START = "GET_REQUEST_PRIZES_WINNER_TYPE_3_START";
export const GET_REQUEST_PRIZES_WINNER_TYPE_3_SUCCESS = "GET_REQUEST_PRIZES_WINNER_TYPE_3_SUCCESS";
export const GET_REQUEST_PRIZES_WINNER_TYPE_3_ERROR = "GET_REQUEST_PRIZES_WINNER_TYPE_3_ERROR";
